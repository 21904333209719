import {useEffect} from "react";
import {IQualifierTypesResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {QUALIFIER_TYPES} from "./qualifierTypes.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

const useQualifierTypes = (pageSize: number = -1, pageNumber: number = 0, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data } = useQuery<IQualifierTypesResponse>(QUALIFIER_TYPES, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize
        },
    });

    useEffect(() => {
        handleErrorRaised(error, QUALIFIER_TYPES, ApiErrors.Qualifiers, onError);
    }, [error, onError]);

    return {
        state: {
            qualifierTypes: data?.qualifierTypes?.edges,
            qualifierTypesPageSize: pageSize,
            qualifierTypesLoading: loading
        }
    };
};

export default useQualifierTypes;