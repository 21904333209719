import { gql } from "@apollo/client";

const GETINSTALLERS = gql`
    query {
        getInstaller{
            productName
            productVersion
            changelogUrl
            urlWin
            urlMac
        }
    }
`;

export { GETINSTALLERS };