
import {gql} from "@apollo/client";

export const UPDATE_PLAYLIST = gql`
    mutation updatePlaylists($input: [UpdatePlaylistInput!]!) {
        updatePlaylists(input: $input) {
            playlists{
                id
                name
            }
        }
    }
`;