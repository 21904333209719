export enum FilterChipIcon {
    Trophy,
    Event,
    Stopwatch,
    Person,
    Download,
    People,
    Crosshair,
    Stadium,
    Calendar,
    Coach,
    Referee,
    NoIcon,
}
