import {ApolloError, useQuery} from "@apollo/client";
import {TAG} from "./tags.gql";
import {Tag, TagResult, TagVariables} from "./__types__/Tags";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseTag {
    loading: boolean;
    tag: Tag | null ;
}

const useTag = ({ id }: { id: string }, onError?: (error: ApolloError, errorMessage: string) => void): UseTag => {
    const { loading, error, data } = useQuery<TagResult, TagVariables>(TAG, {
        variables: {
            id: id
        }
    });

    useEffect(() => {
        handleErrorRaised(error, TAG, ApiErrors.TagError, onError);
    }, [error, TAG]);

    return {
        tag: data?.tag as Tag,
        loading
    };
};

export default useTag;
