import React, {useEffect, useRef, useState} from 'react';
import './expandable.sass';
import {Tooltip} from "@catapultsports/referee-react";
import {IconChevronLeft} from "@catapultsports/referee-react/icons";

interface ExpandableProps {
    children: React.ReactNode;
}

const Expandable: React.FC<ExpandableProps> = ({
                                                   children
                                               }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [isExpanded, setExpanded] = useState<boolean>(true);
    const [totalWidth, setTotalWidth] = useState<number>(0);

    useEffect(() => {
        if (contentRef.current) {
            const rect = contentRef.current.scrollWidth;
            if(rect > totalWidth)
                setTotalWidth(rect);
        }
    }, [children, isExpanded]);

    const collapseLabel = isExpanded ? 'Collapse' : 'Expand';

    return (
        <div
            className={'root'}
            style={
            {
                '--expanded-width': totalWidth + 'px',
            } as React.CSSProperties
        }
            data-open={isExpanded ? 'expanded' : 'collapsed'}
        >
            <div className={'hiddenRoot'}>
                <div
                    className={'visibleRoot'}
                >
                    <Tooltip text={collapseLabel} position="right" noArrow>
                        <button
                            aria-label={collapseLabel}
                            className={'collapseButton'}
                            onClick={() => setExpanded(!isExpanded)}
                        >
                            <span className={'collapseButtonInside'}>
                                <IconChevronLeft/>
                            </span>
                        </button>
                    </Tooltip>
                    {isExpanded? (
                        <div className={'expandable-content'} ref={contentRef}>
                            {children}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default Expandable;
