export function arraysEqual(a: any[] | null, b: any[] | null) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const first = [...a].sort((n1, n2) => {
        if(n1 && n2) {
            if (n1.id && n2.id) {
                if (n1.id > n2.id) {
                    return 1;
                }

                if (n1.id < n2.id) {
                    return -1;
                }
            }
        }
        return 0;
    });

    const second = [...b].sort((n1, n2) => {
        if(n1 && n2) {
            if (n1.id && n2.id) {
                if (n1.id > n2.id) {
                    return 1;
                }

                if (n1.id < n2.id) {
                    return -1;
                }
            }
        }
        return 0;
    });

    for (let i = 0; i < first.length; ++i) {
        if(first[i] && second[i]) {
            if (first[i].id !== second[i].id) return false;
        }
    }
    return true;
}