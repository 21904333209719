import React, {useCallback, useEffect, useMemo} from "react";
import './clips.sass';
import {Outlet, useLocation} from "@tanstack/react-location";
import {ClipsList} from "../../../components/clipList/clipList";
import {FreeTextSearch} from "../../../components/freeTextSearch/freeTextSearch";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {
    IMetaDataGroupEdges
} from "../../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {FilterItemType} from "../../../api/Hub/enum/Enum";
import {
    MetadataGroupNestedSelect
} from "../../../components/metadataGroupNestedSelect/MetadataGroupNestedSelect";
import {Typography} from "@catapultsports/referee-react";
import {HUB} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";

// Use this to hide bad filters from server - metadataGroup -> name (always lowercase)
const ClipFiltersHide = [
    'competitionid', 'optagameid', 'gamelongname', 'gameshortname', 'mt_tag_number', 'mt_tag_session_time',
    'videoclipsourceid', 'matchdatalongidentifier', 'matchstarttime', 'start time', 'media type', 'associated player',
    '', 'driverabbname', 'driver name', 'driver number', 'epoch (ms)', 'focus_tag_number', 'clock', 'highlightcolourg',
    'highlightcolourr', 'lapnumber', 'lap number', 'laptime', 'lap type', 'short description', 'sector3time',
    'sector1starttime', 'sector1time', 'sector2starttime', 'sector2time', 'sector3starttime', 'turn16',
    'category', 'descriptor', 'breakdown', 'fullname', 'lap', 'length', 'location', '12 - start location y',
    'b2 dist', 'b2 dur', 'b3 dist', 'b3 dur', 'b4 dist', 'b4 dur', 'b5 dist', 'b5 dur', 'b6 dist', 'b6 dur',
    'distance (m)', 'overalltrackstate', 'performance', 'pitentrystate', 'pitexitstate', 'pitlanestate',
    'shortdescription', 'state', 'tagdescriptor', 'year', 'acceleration (m/s/s)', 'playingposition'
]
const ClipFilterHideRules = (name: string) => ClipFiltersHide.includes(name) || name.startsWith('msstate')
    || name.endsWith('crossingtime') || name.endsWith('start location t') || name.startsWith('peak band from ');

// These filters will be transformed into dropdowns locked to top of filters - always lowercase

export const Clips: React.FC<any> = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const setVideoAngle = useSessionStore(state => state.setSelectedHubVideoAngle, shallow);

    useEffect(() => {
        setVideoAngle(undefined);
    }, []);

    const groupFilterFunc = useCallback((edge: IMetaDataGroupEdges) => {
        return !edge.hidden && !edge.hideInFilterView && !ClipFilterHideRules(edge.name.toLowerCase())
    }, []);

    const metadataFilterIds = useMemo(() => {
        let locationSearch = location.current.search?.filters
        if(locationSearch?.filterIds){
            return locationSearch.filterIds
        }
        return []
    }, [location, location.current.search]);

    return (
        <article className={'clips'}>
            <nav className={'clips-nav'}>
                <Typography variant={'heading-2'}>{t(HUB.CLIPS)}</Typography>
                <div className={"clips-nav-actions-bar"}>
                    <FreeTextSearch />
                    <MetadataGroupNestedSelect
                        itemType={FilterItemType.Tag}
                        groupFilter={groupFilterFunc}
                        id={"clips-page"}
                        metadataFilterIds={metadataFilterIds}
                    />
                </div>
            </nav>
            <section className='clips-container'>
                <ClipsList metadataFilterIds={metadataFilterIds}/>
                <Outlet />
            </section>
        </article>
    )
};
