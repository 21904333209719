import {ApolloError, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {IMetaDataGroupEdges, IMetadataGroups, IMetadataGroupsFilters} from "./__types__/MetadataGroup";
import {METADATA_GROUPS} from "./metadataGroups.gql";
import MetadataGroupData from './__mocks__/metadataGroups.json';
import MetadataGroupSessionData from './__mocks__/metadataGroupsSession.json';
import {FilterItemType} from "../enum/Enum";
import {IPagedVariables} from "../common/Common";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseMetadataGroups {
    state: {
        loading: boolean;
        pageSize: number;
        metadataGroups: IMetaDataGroupEdges[] | null | undefined;
    };
}

const useMetadataGroups = (
    pageSize: number,
    itemType: FilterItemType,
    isMock?: boolean,
    groupsFilter?: (edge: IMetaDataGroupEdges) => boolean,
    onError?: (error: ApolloError, errorMessage: string) => void): UseMetadataGroups => {

    const [elements, setElements] = useState<IMetaDataGroupEdges[] | undefined>(undefined);
    let dataGroupResponse: any;
    let errorResponse: ApolloError | undefined;
    let loadingResponse: boolean;

    if (isMock) {
        if (itemType === FilterItemType.Session) {
            dataGroupResponse = MetadataGroupSessionData.data;
        } else {
            dataGroupResponse = MetadataGroupData.data;
        }
        loadingResponse = false;
    } else {
        const {loading, error, data} = useQuery<IMetadataGroups, IPagedVariables<IMetadataGroupsFilters>>(METADATA_GROUPS, {
            variables: {
                filter: { itemType: itemType },
                pageSize: pageSize
            }
        });
        dataGroupResponse = data;
        errorResponse = error;
        loadingResponse = loading;
    }

    useEffect(() => {
        if (dataGroupResponse?.metadataGroups?.edges)
            if (groupsFilter) {
                setElements(dataGroupResponse?.metadataGroups?.edges?.filter((x: IMetaDataGroupEdges) => groupsFilter(x)));
            } else {
                setElements(dataGroupResponse?.metadataGroups?.edges?.filter((x: IMetaDataGroupEdges) => !x.hidden && !x.hideInFilterView));
            }
    }, [dataGroupResponse?.metadataGroups?.edges]);

    useEffect(() => {
        handleErrorRaised(errorResponse, METADATA_GROUPS, ApiErrors.MetadataGroups, onError);
    }, [errorResponse, METADATA_GROUPS]);

    return {
        state: {
            metadataGroups: elements,
            pageSize,
            loading: loadingResponse
        }
    };
};

export default useMetadataGroups;
