import {ApolloError, useMutation} from "@apollo/client";
import {DELETE_TAGS} from "./deleteTags.gql"
import {ApiErrors} from "../../../enums/apiErrors";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {GET_PLAYLIST} from "../../collections/collections.gql";
export const useDeleteTags = (onError?: (error: ApolloError, errorMessage: string) => void, playlistId?: string) => {
    const [deleteTagMutation, { error }] = useMutation(DELETE_TAGS, {
        // updated the refetch call in order to work with the apollo mock tests
        // with the previous code, the mock tests were throwing an unknown query warning
        refetchQueries: [
            {query: GET_PLAYLIST, variables: {id: playlistId}},
        ],
    });

    useEffect(() => {
        handleErrorRaised(error, DELETE_TAGS, ApiErrors.CreateMetadata, onError);
    }, [error, DELETE_TAGS]);

    return {
        deleteTag: (deleteTagObject: {id: string} []) => deleteTagMutation({
            variables: {
                input: deleteTagObject
            },
        })
    };
};
