import { create } from "zustand";
import {devtools, persist} from 'zustand/middleware';
import {PermanentState} from "./slices/StoreSlice";
import {immer} from "zustand/middleware/immer";
import {createUrlSlice} from "./slices/url";
import {createJoyrideSlice} from "./slices/joyride";

const createSlices = () => create<PermanentState>()(
	immer(persist(
		devtools((...a) => {
			return {
				...createUrlSlice(...a),
				...createJoyrideSlice(...a)
			};
		}, {name:'permanent-storage'}), { name: 'temp-permanent-storage'}
	))
)

// @ts-ignore
export let usePermanentStore = createSlices();
