import React, {useCallback, useEffect, useMemo} from "react";
import {Spinner, Typography as Text, useAlerts} from "@catapultsports/referee-react";
import {usePortalNavigation} from "../../utils/routerUtils";
import {useMixPanel} from "../../providers/Mixpanel";
import useTags from "../../api/Hub/tags/useTags";
import InfiniteScroll from "react-infinite-scroller";
import {Clip} from "../clip/clip";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import Expandable from "../expandableWrapper/expandable";
import {Comments} from "../comments/comments";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import {HUB} from "../../services/i18n/i18n-constants";
import {ApiPageSize} from "../../api/enums/apiMagic";
import {MakeGenerics, useLocation, useSearch} from "@tanstack/react-location";
import "./clipList.sass"
import {IAsset} from "../../api/Hub/common/Common";

// Use this to hide bad filters from server - metadataGroup -> name (always lowercase)
const ClipFiltersHide = [
    'competitionid', 'optagameid', 'gamelongname', 'gameshortname', 'mt_tag_number', 'mt_tag_session_time',
    'videoclipsourceid', 'matchdatalongidentifier', 'matchstarttime', 'start time', 'media type', 'associated player',
    '', 'driverabbname', 'driver name', 'driver number', 'epoch (ms)', 'focus_tag_number', 'clock', 'highlightcolourg',
    'highlightcolourr', 'lapnumber', 'lap number', 'laptime', 'lap type', 'short description', 'sector3time',
    'sector1starttime', 'sector1time', 'sector2starttime', 'sector2time', 'sector3starttime', 'turn16',
    'category', 'descriptor', 'breakdown', 'fullname', 'lap', 'length', 'location', '12 - start location y',
    'b2 dist', 'b2 dur', 'b3 dist', 'b3 dur', 'b4 dist', 'b4 dur', 'b5 dist', 'b5 dur', 'b6 dist', 'b6 dur',
    'distance (m)', 'overalltrackstate', 'performance', 'pitentrystate', 'pitexitstate', 'pitlanestate',
    'shortdescription', 'state', 'tagdescriptor', 'year', 'acceleration (m/s/s)', 'playingposition'
]
const ClipFilterHideRules = (name: string) => ClipFiltersHide.includes(name) || name.startsWith('msstate')
    || name.endsWith('crossingtime') || name.endsWith('start location t') || name.startsWith('peak band from ');

// These filters will be transformed into dropdowns locked to top of filters - always lowercase
const ClipTopLevelFilters = ['player', 'game', 'competition name', 'competition year', 'team'];

export type ClipListSearchTypes = MakeGenerics<{
    Search: {
        viewMode: "list" | "detail",
        tagName?: string
    };
}>;


interface ClipListProps {
    metadataFilterIds: string[]
}

export const ClipsList: React.FC<ClipListProps> = (props) => {
    const { metadataFilterIds } = props;
    const { viewMode, tagName } = useSearch<ClipListSearchTypes>();
    const mixpanel = useMixPanel();
    const {navigate} = usePortalNavigation();
    const setCommentTag = useSessionStore(state => state.setCommentTag, shallow);
    const freeTextSearch = useSessionStore(state => state.freeTextSearch, shallow);
    const { t } = useTranslation();
    const location = useLocation();
    const commentTagIdState = useSessionStore(state => state.comments.tagId, shallow);
    const commentTagNameState = useSessionStore(state => state.comments.tagName, shallow);
    const { showError } = useAlerts()
    const {
        state: { tags, tagsLoading, tagsHasNextPage },
        operations: { loadNextPage }
    } = useTags(
        ApiPageSize.Thirty,
        metadataFilterIds,
        [],
        () => showError(t(HUB.CLIP_ERROR)),
        false,
        false,
        freeTextSearch
    );

    const selectedTagId = useMemo(() => {
        const splitPathName = location?.current?.pathname?.split("/")
        return splitPathName[splitPathName?.length - 1]
    }, [location?.current?.pathname]);

    useEffect(() => {
        if (selectedTagId !== commentTagIdState || tagName !== commentTagNameState) {
            setCommentTag(selectedTagId, tagName ?? "");
        }
    }, [tagName, selectedTagId]);

    const onClipClick = useCallback((assets: IAsset[], id: string, name: string) => {
        if(selectedTagId != id) {
            mixpanel.track(MixPanelEvents.CLIP_CLICK, { tagId: id, tagName: name });
            navigate({ to: `${id}`, replace: true , search: { viewMode: "list", tagName: name, filters: {filterIds: metadataFilterIds} }});
        }
    }, [selectedTagId, metadataFilterIds]);

    const onArrowClick = useCallback((id: string, name: string) => {
        navigate({ to: `${id}`, replace: true , search: { viewMode: "detail", tagName: name, filters: {filterIds: metadataFilterIds} }});
    }, [metadataFilterIds]);

    const onBackClick = useCallback(() => {
        navigate({search: { viewMode: "list", filters: {filterIds: metadataFilterIds} }});
    }, [metadataFilterIds]);

    return (
        <Expandable>
            <section className='clips-controls'>
                { tagsLoading
                    ? <Spinner key='spinner' size="medium"/>
                    : !viewMode || viewMode === "list"
                        ? <div className={'clips-scroller'} data-testid={'clips-scroller'}>
                            {tags && tags.length > 0 ?
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={loadNextPage}
                                    hasMore={tagsHasNextPage}
                                    loader={<Spinner key='spinner' size="medium"/>}
                                    useWindow={false}>
                                    {
                                        tags && tags.map((value) =>
                                            <Clip
                                                onClick={onClipClick}
                                                key={value.id}
                                                id={value.id}
                                                name={value.name}
                                                colour={value.colour}
                                                commentCount={value.commentCount}
                                                isActive={value.id === selectedTagId}
                                                onArrowClick={onArrowClick}/>)
                                    }
                                </InfiniteScroll> :
                                <Text className={"no-results-text-clips"} id={"no-results-text-clips"}>{t(HUB.NO_RESULTS)}</Text>}
                        </div>
                        : <Comments onClipBackClick={onBackClick} />
                }
            </section>
        </Expandable>
    )
};
