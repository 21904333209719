import {FINDER, GENERAL, HUB, NAV, SEARCH} from "../../../services/i18n/i18n-constants";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from 'react-i18next';
import {
    Button,
    Checkbox,
    DateRangePicker,
    Select,
    SelectOption,
    Typography as Text,
    useAlerts,
    usePositioner
} from "@catapultsports/referee-react";
import {useMixPanel} from "../../../providers/Mixpanel";
import {useDatePresets} from "../../../utils/dateUtils";
import useCompetitions from "../../../api/MatchTracker/competitions/useCompetitions";
import useTeams from "../../../api/MatchTracker/teams/useTeams";
import usePlayers from "../../../api/MatchTracker/players/usePlayers";
import {ISeason} from "../../../api/MatchTracker/__types__/Common";
import useSeasons from "../../../api/MatchTracker/seasons/useSeasons";
import {IconFilterSmall} from "@catapultsports/referee-react/icons";
import {SearchFilter, TabOption} from "../../../components/searchFilter/searchFilter";
import {categories, filterOptions, QualifierValueType} from "../../../components/searchFilter/__mocks__/data";
import {FilterChipList} from "../../../components/filterChipListNextGen/filterChipList";
import {QueryGroup, QueryOperator, ResultType} from "../__types__";
import _ from 'lodash';

export interface SearchControlProps {
    activeFilters: QueryGroup[];
    onUpdateActiveFilters: (activeFiltersUpdated: QueryGroup[]) => void;
    resultType: ResultType;
    onUpdateResultType: (resultType: ResultType) => void;
}

export const SearchControls: React.FC<SearchControlProps> = (props) => {
    const {activeFilters, onUpdateActiveFilters, resultType, onUpdateResultType} = props;
    const {t} = useTranslation();
    const mixpanel = useMixPanel();
    const {showError} = useAlerts();
    const datePresets = useDatePresets();

    const {state: {seasons, seasonsLoading, seasonsPageSize}} =
        useSeasons(undefined, undefined, undefined, undefined, () => showError(t(HUB.SEASONS_ERROR)));

    const resultsOptions = useMemo(() => {
        if (resultType == ResultType.Matches) {
            return [
                {id: ResultType.Matches, value: t(NAV.MATCHES), isSelected: true},
                {id: ResultType.Events, value: t(FINDER.EVENTS), isSelected: false}
            ]
        } else {
            return [
                {id: ResultType.Matches, value: t(NAV.MATCHES), isSelected: false},
                {id: ResultType.Events, value: t(FINDER.EVENTS), isSelected: true}
            ]
        }
    }, [resultType]);

    // TODO: this should be paged once infinite scroll is available in select component
    const {state: {competitions}} =
        useCompetitions(undefined, undefined, () => showError(t(HUB.COMPETITIONS_ERROR)));

    const {state: {teams}} = useTeams(
        [],
        [],
        undefined,
        undefined,
        () => showError(t(HUB.TEAMS_ERROR)));

    const {state: {players, playersLoading}} = usePlayers(
        [],
        undefined,
        undefined,
        () => showError(t(HUB.PLAYERS_ERROR)));

    const convertCurrentPreviousSeasonValues = useCallback((item: ISeason) => {
        let valueToReturn = item.endYear.toString()

        let start = item.startYear
        let end = item.endYear
        if ((start !== null && end !== null))
            if (start !== end) {
                valueToReturn = `${start}/${end}`
            }
        if (item.isCurrentSeason) {
            valueToReturn = t(FINDER.CURRENT_SEASON)
        }
        if (item.isPreviousSeason) {
            valueToReturn = t(FINDER.PREVIOUS_SEASON)
        }
        return valueToReturn
    }, [t])

    const seasonOptions = useMemo(() => {
        if (seasons && seasons.length > 0) {
            return [
                ...seasons.map((item) => ({
                    id: item.base.id,
                    value: convertCurrentPreviousSeasonValues(item),
                    startYear: item.startYear.toString(),
                    endYear: item.endYear.toString(),
                    isCurrentSeason: item.isCurrentSeason,
                    isPreviousSeason: item.isPreviousSeason
                }))
            ];
        }

        return [];
    }, [seasons, convertCurrentPreviousSeasonValues]);

    const teamOptions = useMemo(() => {
        if (teams && teams.length > 0) {
            return [
                ...teams.map((item) => ({
                    id: item.base.id,
                    value: item.name,
                    isSelected: activeFilters.some(filters => filters.qualifierTypeName == item.name)
                }))
            ];
        }

        return [];
    }, [teams, activeFilters]);

    const playerOptions = useMemo(() => {
        if (!playersLoading) {
            if (players && players.length > 0) {
                return [
                    ...players.map((item) => ({
                        id: item.base.id,
                        value: item.firstName + ' ' + item.lastName,
                        isSelected: activeFilters.some(filters => filters.qualifierTypeName == item.firstName + ' ' + item.lastName)
                    }))
                ];
            }
        }
        return [];
    }, [players, activeFilters]);

    const competitionOptions = useMemo(() => {
        if (competitions && competitions.length > 0) {
            return [
                {id: '', value: t(GENERAL.ANY)},
                ...competitions.map((item) => ({id: item.base.id, value: item.name}))
            ];
        }

        return [];
    }, [competitions]);

    const onSeasonFilterSelect = (items: SelectOption[]) => {
        const selectedSeasonIDs = items
            .filter(season => season.id !== null)
            .map(season => season.id.toString())

        // TODO: add season to active filters
    }

    const onCompetitionFilterSelect = (items: SelectOption[]) => {
        const selectedCompetitionIDs = items
            .filter(competition => competition.id !== null)
            .map(competition => competition.id.toString())

        // TODO: add competition to active filters
    }

    const handleFilterSelect = useCallback((items: SelectOption[], valueType: QualifierValueType) => {
        const filters = _.cloneDeep(activeFilters);
        const selectedItems = items.filter(item => item.id !== null);

        const updatedFilters = selectedItems?.map((item, index) => {
            return {
                qualifierTypeName: item.value,
                qualifierTypeId: item.id,
                valueType: valueType,
                formatType: 'fixed',
                conditions: [{
                    operator: QueryOperator.For,
                    value: item.value
                }]
            };
        }).filter(updatedFilter =>
            !filters.some(existingFilter =>
                existingFilter.qualifierTypeName === updatedFilter.qualifierTypeName &&
                existingFilter.qualifierTypeId === updatedFilter.qualifierTypeId
            )
        );

        const filtersToRemove = filters.filter(existingFilter =>
            existingFilter.valueType === valueType &&
            !selectedItems.some((item) =>
                existingFilter.qualifierTypeName === item.value &&
                existingFilter.qualifierTypeId === item.id
            )
        );

        const finalFilters = [...filters.filter(filter =>
            !filtersToRemove.includes(filter)),
            ...updatedFilters
        ];

        onUpdateActiveFilters(finalFilters);
    }, [activeFilters]);

    const onTeamFilterSelect = (items: SelectOption[]) => {
        handleFilterSelect(items, QualifierValueType.Team);
    };

    const onPlayerFilterSelect = (items: SelectOption[]) => {
        handleFilterSelect(items, QualifierValueType.Player);
    };

    const { getPopperProps, getReferenceProps } = usePositioner({
        placement: 'bottom-start',
    });
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = useCallback(() => setShowFilter(!showFilter), [showFilter, setShowFilter]);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const onSearch = useCallback((value: string | undefined) => setSearchText(value), []);
    const onCategory = useCallback((value: string | undefined) => {
        if (value) {
            if (selectedCategories.includes(value)) {
                setSelectedCategories(_.without(selectedCategories, value));
            } else {
                setSelectedCategories([...selectedCategories, value]);
            }
        }
    }, [selectedCategories, setSelectedCategories]);
    const [activeOnly, setActiveOnly] = useState(false);
    const onActiveOnlyChanged = useCallback((activeOnly: boolean) => setActiveOnly(activeOnly), [setActiveOnly]);

    const flattenedQueryIds = useMemo(() =>
        activeFilters.map(activeFilter => {return activeFilter.qualifierTypeId}),
        [activeFilters]
    );

    const viewableFilters = useMemo(() => {
        const options = searchText ? filterOptions.filter(option => option.name.includes(searchText)) : filterOptions.map((filterValue) => {
            const matchingFilter = activeFilters.find((activeFilter) => activeFilter.qualifierTypeId === filterValue.id.toString());

            if (matchingFilter && matchingFilter.conditions) {
                // If a matching active filter is found, replace the value in filterValue
                    if(matchingFilter.conditions.length > 1) {
                        return { ...filterValue, selectedValues: matchingFilter.conditions.map(x => x.value) };
                    } else {
                        return { ...filterValue, selectedValues: matchingFilter.conditions[0].value };
                    }
            }

            // If no matching active filter is found, keep the original filterValue
            return filterValue;
        });
        return activeOnly ? options.filter(option => flattenedQueryIds.includes(option.id.toString())) : options;
    }, [activeOnly, searchText, activeFilters, flattenedQueryIds]);

    const onRemoveActiveFilter = useCallback((id: number) => {
        const filters = _.cloneDeep(activeFilters);
        const updatedFilters = filters.filter(filter => filter.qualifierTypeId !== id.toString());
        onUpdateActiveFilters(updatedFilters);

        return;
    }, [activeFilters, onUpdateActiveFilters])

    const onUpdateFilterChip = useCallback((group: QueryGroup) => {
       const updatedFilters = activeFilters.map(filter => {
                if(filter.qualifierTypeId != group.qualifierTypeId) {
                    return filter;
                } else {
                    return group;
                }
            }
        ).filter(filter => filter.conditions && filter.conditions.length > 0);

        onUpdateActiveFilters(updatedFilters);
    }, [activeFilters, onUpdateActiveFilters])

    return (
        <section className={'flex vertical'} data-testid={'top-content-container'}>
            <section className={'flex horizontal filter-options'}>
                <section className={'results-type-section'}>
                    <Select testId={'results-type'} label={`${t(FINDER.RESULTS_TYPE)}`}
                            menuItems={resultsOptions}
                            isExternalUpdated={true}
                            defaultOption={resultsOptions.find((resultsOption) => resultsOption.id == resultType)}
                            onSelectItem={(items) => {
                                if (items.length > 0) {
                                    mixpanel.track(`Hub-Portal: Search results type selected.`, {resultsType: items[0]});
                                    onUpdateResultType(items[0].id.toString() == ResultType.Matches ? ResultType.Matches : ResultType.Events);
                                }
                            }}
                    />
                </section>
                <section className={'flex vertical date-range'}>
                    <Text className={'date-label'} variant={"label-1"}>{t(FINDER.DATE)}</Text>
                    <DateRangePicker
                        onChange={(newDateRange) => {
                            mixpanel.track(`Hub-Portal: Search date range selected.`, {dateRange: newDateRange});
                            // TODO: adding date ranges to active filters
                        }}
                        value={datePresets.LAST_30_DAYS}
                        presets={datePresets}
                        placeholder={'All'}
                        isClearButtonVisible={true}
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.COMPETITION)}`}
                            menuItems={competitionOptions}
                            defaultOption={competitionOptions[0]}
                            isExternalUpdated
                            disabled={competitionOptions.length == 0}
                            onSelectItem={onCompetitionFilterSelect}
                            isSearchable
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.SEASON)}`}
                            menuItems={seasonOptions}
                            onSelectItem={onSeasonFilterSelect}
                            disabled={!seasons || seasons?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.TEAM)}`}
                            menuItems={teamOptions}
                            onSelectItem={onTeamFilterSelect}
                            disabled={!teams || teams?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.PLAYER)}`}
                            menuItems={playerOptions}
                            onSelectItem={onPlayerFilterSelect}
                            disabled={!players || players?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'filter-checkboxes'}>
                    {resultType === ResultType.Matches && (
                        <div>
                            <Checkbox
                                label={`${t(FINDER.VIDEO)}`}
                                checked={true}
                                onChange={() => {
                                    mixpanel.track(`Hub-Portal: Search has video toggled.`, {hasVideo: undefined});
                                    // TODO: adding video filter to active filters
                                }}
                            />
                            <Checkbox
                                label={`${t(FINDER.EVENTS)}`}
                                checked={true}
                                onChange={() => {
                                    mixpanel.track(`Hub-Portal: Search has events toggled.`, {hasEvents: undefined});
                                    // TODO: adding events filter to active filters
                                }}
                            />
                        </div>
                    )}
                </section>
                <section className={'perfect-match'}>
                    <Checkbox
                        label={`${t(FINDER.USE_PERFECT_MATCH)}`}
                        checked={true}
                        disabled
                        onChange={() => {
                            mixpanel.track(`Hub-Portal: Search perfect match toggled.`, {perfectMatch: undefined});
                            // TODO: adding perfect match to active filters
                        }}/>
                </section>
            </section>
            <section className={'filter-dropdown-options'}>
                <Button className={'filterButton'} size={'medium'} variant={"outline"} onClick={toggleFilter} {...getReferenceProps()}>{t(SEARCH.FILTERS)} <IconFilterSmall/></Button>
                <FilterChipList
                    activeFilters={activeFilters}
                    onRemove={onRemoveActiveFilter}
                    onFilterUpdated={onUpdateFilterChip}
                />
                {showFilter &&
                    <SearchFilter
                        perfectMatch={true}
                        onPerfectMatchChanged={() => console.log('perfect match')}
                        onCategorySelected={onCategory}
                        categories={categories}
                        selectedCategoryIds={selectedCategories}
                        onSearch={onSearch}
                        onClose={toggleFilter}
                        selectedTab={TabOption.Basic}
                        onTabSelected={(selected: TabOption) => console.log(`Tab option selected: ${selected}`)}
                        popperProps={getPopperProps}
                        filterOptions={viewableFilters}
                        hasMoreFilters={true}
                        onLoadNextPage={() => console.log('Load next filter page!!')}
                        activeOnly={activeOnly}
                        onActiveOnlyChanged={onActiveOnlyChanged}
                        onUpdateActiveFilters={onUpdateActiveFilters}
                        activeFilters={activeFilters}
                        flattenedFilterIds={flattenedQueryIds}/>}
            </section>
        </section>
    )
};
