import React, {useEffect, useMemo, useState} from "react";
import {Button, Spinner, Table, useAlerts} from "@catapultsports/referee-react";
import './licensingUsersTable.sass';
import {HeaderGroup, Row} from "react-table";
import {useGetUsersPage} from "../../../api/Customer/Users/useGetUsersPage";
import {useMixPanel} from "../../../providers/Mixpanel";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {useTranslation} from "react-i18next";
import {LICENSING, LOGIN} from "../../../services/i18n/i18n-constants";
import {useSessionStore} from "../../../state/SessionState";
import {ILicenseFormattedUser} from "../../../api/Customer/__types__/LicenseUsers";

export const LicenceUsersTable = () => {
    const mixpanel = useMixPanel();
    const {t} = useTranslation();
    const currentCustomer = useSessionStore(state => state.currentCustomer)
    const [users, setUsers] = useState<any[]>([]);
    const { showError } = useAlerts();

    const customerIDsFilter = useMemo(
        () => {
            if (currentCustomer) {
                const id = currentCustomer['customerId']
                return id ? [id] : []
            }
        },
        [currentCustomer]
    )

    const {
        state: {usersForCustomer, usersPageLoading, usersPageError, usersHasNextPage}, operations: {loadNextPage}
    } = useGetUsersPage(undefined, undefined, customerIDsFilter, undefined, () => showError(t(LICENSING.USER_FOR_CUSTOMER_ERROR)));

    useEffect(() => {
        if (usersForCustomer) {
            const users = usersForCustomer.map((user) => {
                return {
                    email: user.email,
                    fullName: user.firstName + " " + user.lastName,
                    accountStatus: user.accountStatus,
                } as ILicenseFormattedUser;
            });
            setUsers(users);
        }
    }, [usersForCustomer])

    const handlePasswordReset = (user: ILicenseFormattedUser) => {
        // Handle password reset logic
        mixpanel.track(MixPanelEvents.LICENSING_REQUEST_NEW_PASSWORD, user)
    };

    const handleDeleteUser = (user: ILicenseFormattedUser) => {
        // Handle delete user logic
        mixpanel.track(MixPanelEvents.LICENSING_DELETE_USER, user)
    };

    return (<section data-testid={'licence-users-table'} className="licence-users-table">
        {usersPageLoading && <Spinner size={'large'} variant={'muted'}/>}
        {users && users.length > 0 &&
            <Table<ILicenseFormattedUser> data={users}
                 columns={[
                     {
                         Header: t(LOGIN.EMAIL),
                         accessor: "email",
                         disableSortBy: true,
                     },
                     {
                         Header: t(LICENSING.FULL_NAME),
                         accessor: "fullName",
                         disableSortBy: true,
                     },
                     {
                         Header: t(LICENSING.ACCOUNT_STATUS),
                         accessor: "accountStatus",
                         disableSortBy: true,
                     },
                     {
                         Header: t(LICENSING.PASSWORD_RESET),
                         Cell: ({row}: { row: Row<ILicenseFormattedUser> }) => (
                             <div>
                                 {row.values.accountStatus === "VALID" && (
                                     <Button variant={'outline'}
                                             onClick={() => handlePasswordReset(row.values)}>
                                         {t(LICENSING.REQUEST_NEW_PASSWORD)}
                                     </Button>)}
                             </div>),
                         disableSortBy: true,
                     },
                     {
                         Header: "Delete User",
                         Cell: ({row}: { row: Row<ILicenseFormattedUser> }) => (
                             <Button onClick={() => handleDeleteUser(row.values)}>
                                 {t(LICENSING.DELETE_USER)}
                             </Button>),
                         disableSortBy: true,
                     }]}
                 mapColumnIdToName={(column: HeaderGroup) => {
                     return column.Header;
                 }}
                 requestMoreData={loadNextPage}
                 hasMoreData={usersHasNextPage}
            />
        }
    </section>);
};
