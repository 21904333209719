import {ApolloError, useMutation} from "@apollo/client";
import {UPDATE_TAG_NOTES} from "./updateTag.gql";
import {ApiErrors} from "../../../enums/apiErrors";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../../utils/apolloUtils";

export const useUpdateTagNotes = (onError?: (error: ApolloError, errorMessage: string) => void, onSuccess?: () => void) => {
    const [updateTagMutation, { error }] = useMutation(UPDATE_TAG_NOTES, {
        onCompleted: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    useEffect(() => {
        handleErrorRaised(error, UPDATE_TAG_NOTES, ApiErrors.CreateMetadata, onError);
    }, [error, UPDATE_TAG_NOTES]);

    return {
        updateTag: (objectToUpdate? ) => updateTagMutation({
            variables: {
                input: objectToUpdate
            },
        })
    };
};
