import { MutableRefObject, ReactNode } from "react";
import Plyr from "@sbgsportssoftware/plyr";

export const SET_PLAYER_LOADED = "SET_PLAYER_LOADED";
export const SET_PLAYER_CURRENT_TIME = "SET_PLAYER_CURRENT_TIME";
export const SET_PLAYER_CURRENT_SOURCE = "SET_PLAYER_CURRENT_SOURCE";
export const SET_PLAYER_START_TIME = "SET_PLAYER_START_TIME";
export const SET_PLAYER_CURRENT_MATCH_TIME = "SET_PLAYER_CURRENT_MATCH_TIME";
export const SET_PLAYER_DURATION = "SET_PLAYER_DURATION";
export const SET_PLAYER_EDITOR_ZOOM_MIN = "SET_PLAYER_EDITOR_ZOOM_MIN";
export const SET_PLAYER_EDITOR_ZOOM_MAX = "SET_PLAYER_EDITOR_ZOOM_MAX";
export const SET_PLAYER_EDITOR_ZOOM_VALUE = "SET_PLAYER_EDITOR_ZOOM_VALUE";
export const SET_PLAYER_FULLSCREEN = "SET_PLAYER_FULLSCREEN";
export const SET_PLAYER_ENDED = "SET_PLAYER_ENDED";

interface SetPlayerLoadedAction {
    type: typeof SET_PLAYER_LOADED;
    loaded: boolean;
}

interface SetPlayerCurrentTimeAction {
    type: typeof SET_PLAYER_CURRENT_TIME;
    currentTime: number;
}

interface SetPlayerCurrentMatchTimeAction {
    type: typeof SET_PLAYER_CURRENT_MATCH_TIME;
    currentMatchTime: string;
}

interface SetPlayerDurationAction {
    type: typeof SET_PLAYER_DURATION;
    duration: number;
}

interface SetPlayerFullscreenAction {
    type: typeof SET_PLAYER_FULLSCREEN;
    fullscreen: boolean;
}

interface SetPlayerEndedAction {
    type: typeof SET_PLAYER_ENDED;
    ended: boolean;
}

interface SetPlayerCurrentSourceAction {
    type: typeof SET_PLAYER_CURRENT_SOURCE;
    currentSource: Plyr.SourceInfo | Plyr.SourceInfo[] | null;
}

interface SetPlayerStartTimeAction {
    type: typeof SET_PLAYER_START_TIME;
    startTime: number;
}

export type Action =
    | SetPlayerCurrentTimeAction
    | SetPlayerCurrentMatchTimeAction
    | SetPlayerDurationAction
    | SetPlayerFullscreenAction
    | SetPlayerEndedAction
    | SetPlayerLoadedAction
    | SetPlayerCurrentSourceAction
    | SetPlayerStartTimeAction;
export type Dispatch = (action: Action) => void;
export type State = {
    playerRef: MutableRefObject<Plyr | undefined>;
    loaded: boolean;
    currentTime: number;
    currentMatchTime: string;
    duration: number;
    fullscreen: boolean;
    ended: boolean;
    currentSource: Plyr.SourceInfo | Plyr.SourceInfo[] | null;
};
export type PlayerProviderProps = { children: ReactNode };
