import React, {useCallback, useMemo, useState} from "react";
import {
    GroupedSelectOptions,
    Select,
    SelectOption,
    useAlerts
} from "@catapultsports/referee-react";
import {IMetaDataGroupEdges} from "../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {FilterItemType} from "../../api/Hub/enum/Enum";
import useMetadataGroups from "../../api/Hub/metadataGroups/useMetadatagroups";
import {GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import {CleanupTypeName} from "../../utils/stringUtils";
import {FilterChip, FilterChipList} from "../filterChipList/filterChipList";
import {useLocation} from "@tanstack/react-location";
import {useUpdateFilter} from "../../state/stateUtil";
import {usePortalNavigation} from "../../utils/routerUtils";
import {FilterChipIcon} from "../../enums/FilterChipIcon";
import "./MetadataGroupNestedSelect.sass"

interface MetadataGroupFilterProps {
    itemType?: FilterItemType
    isMock?: boolean,
    groupFilter?: (edge: IMetaDataGroupEdges) => boolean,
    pageSize?: number
    id?: string
    metadataFilterIds?: string[]
}

interface SelectedMetadataFilters {
    id: string,
    value: string,
    isSelected: boolean,
    label: string,
}


export const MetadataGroupNestedSelect: React.FC<MetadataGroupFilterProps> = (props) => {
    const {
        itemType,
        isMock,
        groupFilter,
        pageSize,
        id,
        metadataFilterIds
    } = props;

    const {navigate} = usePortalNavigation();
    const { showError } = useAlerts();
    const { t } = useTranslation();
    const location = useLocation()
    const NO_VALUE = 'NO_VALUE';

    const [isUpdatedExternally, setIsUpdatedExternally] = useState(false);
    const updateFilter = useUpdateFilter({location: location, navigate: navigate});

    const {
        state: {metadataGroups, loading},
    } = useMetadataGroups(pageSize ?? -1, itemType || FilterItemType.Playlist, isMock, groupFilter, () => showError(t(HUB.FILTERS_ERROR)));

    const filterChipList = useMemo(() => {
        const chipList: FilterChip[] = []
        metadataGroups?.map((group) => {
            group.metadata.map((metadataItem) => {
                let valueToDisplay = metadataItem.value.includes(NO_VALUE) ? t(HUB.ENABLED) : `${CleanupTypeName(metadataItem.value)}`
                if(metadataFilterIds && metadataFilterIds.includes(metadataItem.id)) {
                    chipList.push({
                        id: metadataItem.id,
                        label: `${CleanupTypeName(group.name)}: ${valueToDisplay}`,
                        icon: FilterChipIcon.NoIcon,
                        isEventFilter: true
                    })
                }
            })
        })

        return chipList
    }, [metadataFilterIds, metadataGroups, t])

    const nestedGroupItems = useMemo(() => {
       if(metadataGroups){
           const groups: GroupedSelectOptions[] = [];
           metadataGroups.map((group) => {
               groups.push({
                   groupName: CleanupTypeName(group.name),
                   startIcon: undefined,
                   menuItems: group.metadata.map((metadataItem) => {
                       let valueToDisplay = metadataItem.value.includes(NO_VALUE) ? t(HUB.ENABLED) : `${CleanupTypeName(metadataItem.value)}`
                       return (
                           {
                               id: metadataItem.id,
                               value: valueToDisplay,
                               isSelected: metadataFilterIds && metadataFilterIds.includes(metadataItem.id),
                               label: `${CleanupTypeName(group.name)}: ${valueToDisplay}`,
                           })
                   })
               })
           })

           return groups.filter((group, index) => {
               return groups.findIndex((item) => item.groupName === group.groupName) === index;
           })
       }
        // used to ensure the select menu is updated
       setIsUpdatedExternally(true)
       return [];
    }, [metadataGroups, t, metadataFilterIds]);

    const areThereFilters = useMemo(() => {
        return nestedGroupItems.length !== 0
    }, [nestedGroupItems])

    const onSelectItemFunc = useCallback((items: SelectedMetadataFilters[] | SelectOption[]) => {
        updateFilter({filterIds: items.map((item) => String(item.id))})
    }, [updateFilter])

    const handleRemoveChip = useCallback((filterId: string | undefined) => {
        if(filterId && metadataFilterIds){
            updateFilter({filterIds: metadataFilterIds.filter((item) => item !== filterId)})
        }
    }, [metadataFilterIds, updateFilter])

    const handleClearAll = useCallback(() => {
        updateFilter({filterIds: []})
    }, [updateFilter])

    const urlFilterData = useMemo(() => {
        if(filterChipList){
            return filterChipList.map((item) => ({
                id: item.id,
                name: item.label
            }))
        }
    }, [filterChipList])

    return(
        <div data-testid={`select-menu-metadata-filters-${id}`}>
            <div className={"select-menu"}>
                <Select
                    testId={`metadata-filter-${id}`}
                    placeholder={t(GENERAL.SELECT_FILTER) as string}
                    groupedMenuItems={nestedGroupItems}
                    onSelectItem={onSelectItemFunc}
                    disabled={!areThereFilters}
                    isNestedGroups
                    isMultiselect
                    isSearchable
                    deselectAllOnClear={false}
                    resetGroupOnClear
                    remainOpenOnClear
                    withDebounce
                    maxDisplayValues={3}
                    isExternalUpdated={isUpdatedExternally}
                    size={"small"}
                />
            </div>
            {filterChipList.length > 0 ? (
                <div className={"filter-chips-list"}>
                    <FilterChipList
                        data={filterChipList}
                        filterData={urlFilterData}
                        onRemove={handleRemoveChip}
                        displayClearAll={true}
                        clearAllCallback={handleClearAll}
                    />
                </div>
            ) : null}
        </div>

    )

}