import { createContext, useContext, useRef, useReducer, ReactElement } from "react";
import Plyr from "@sbgsportssoftware/plyr";
import { State, PlayerProviderProps, Dispatch } from "./player.types";
import playerReducer from "./player.reducer";

const PlayerStateContext = createContext<State | undefined>(undefined);
const PlayerDispatchContext = createContext<Dispatch | undefined>(undefined);

const PlayerProvider = ({ children }: PlayerProviderProps): ReactElement => {
    const [state, dispatch] = useReducer(playerReducer, {
        playerRef: useRef<Plyr | undefined>(),
        loaded: false,
        currentTime: 0,
        currentMatchTime: "",
        duration: 0,
        fullscreen: false,
        ended: false,
        currentSource: null
    });

    return (
        <PlayerStateContext.Provider value={state}>
            <PlayerDispatchContext.Provider value={dispatch}>{children}</PlayerDispatchContext.Provider>
        </PlayerStateContext.Provider>
    );
};

const usePlayerState = (): State => {
    const context = useContext(PlayerStateContext);
    if (context === undefined) {
        throw new Error("usePlayerState must be used within a PlayerProvider");
    }
    return context;
};

const usePlayerDispatch = (): Dispatch => {
    const context = useContext(PlayerDispatchContext);
    if (context === undefined) {
        throw new Error("usePlayerDispatch must be used within a PlayerProvider");
    }
    return context;
};

export { PlayerProvider, usePlayerState, usePlayerDispatch };
