import {useTranslation} from "react-i18next";
import {DATE_PRESETS} from "../services/i18n/i18n-constants";
import {DateTime} from "luxon";
import {DateRange} from "../__types__/globalTypes";

const MINIMUM_EPOCH = -2208988800;  // 1-1-1900
export const useDatePresets = () => {
    const {t} = useTranslation();

    return {
        ALL: {
            label: t(DATE_PRESETS.ALL),
            from: null,
            to: null
        },

        TODAY: {
            label: t(DATE_PRESETS.TODAY),
            from: DateTime.now().startOf('day').toJSDate(),
            to: DateTime.now().toJSDate(),
        },

        LAST_7_DAYS: {
            label: t(DATE_PRESETS.LAST_SEVEN_DAYS),
            from: DateTime.now().minus({days: 7}).toJSDate(),
            to: DateTime.now().toJSDate(),
        },

        LAST_30_DAYS: {
            label: t(DATE_PRESETS.LAST_THIRTY_DAYS),
            from: DateTime.now().minus({days: 30}).startOf('day').toJSDate(),
            to: DateTime.now().toJSDate(),
        },

        LAST_YEAR: {
            label: t(DATE_PRESETS.LAST_YEAR),
            from: DateTime.now().minus({years: 1}).startOf('day').toJSDate(),
            to: DateTime.now().toJSDate(),
        },

        LAST_TWO_YEARS: {
            label: t(DATE_PRESETS.LAST_TWO_YEARS),
            from: DateTime.now().minus({years: 2}).startOf('day').toJSDate(),
            to: DateTime.now().toJSDate(),
        },

        LAST_FIVE_YEARS: {
            label: t(DATE_PRESETS.LAST_FIVE_YEARS),
            from: DateTime.now().minus({years: 5}).startOf('day').toJSDate(),
            to: DateTime.now().toJSDate(),
        },
    };
};

export const compressDateRangeSeconds = (date: DateRange): string => {
    return `${date.from ? Math.round(date.from.getTime() / 1000) : ''},${date.to ? Math.round(date.to.getTime() / 1000) : ''}`;
};

export const decompressDateRangeSeconds = (date: string): DateRange => {
    const parts = date.split(',');
    return {
        from: parts[0].length > 0 ? DateTime.fromSeconds(Number.parseInt(parts[0])).toJSDate() : undefined,
        to: parts[1].length > 0 ? DateTime.fromSeconds(Number.parseInt(parts[1])).toJSDate() : undefined
    };
};

export const addOrSubtractDays = (days: number, add?: boolean, startingDate?: Date) => {
    if (startingDate && startingDate.getTime() < MINIMUM_EPOCH) return startingDate

    let luxonDate = DateTime.fromJSDate(startingDate || new Date())
    if (add) {
        luxonDate = luxonDate.plus({ days: days })
    } else {
        luxonDate = luxonDate.minus({ days: days })
    }

    return luxonDate.toJSDate()
}

export const getDateString = (date: Date) => {
    if (date.getTime() < MINIMUM_EPOCH) return 'N/A'
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
}