import React, {useState} from "react";
import {Tooltip, useAlerts} from "@catapultsports/referee-react";
import {IconTag} from "@catapultsports/referee-react/icons";
import '../playlistControlsHeaderComponent.sass';
import {COLLECTIONS, HUB} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import "../playlistControls.sass"
import {LabelsDialogComponent} from "../../labels/labelsDialog/labelsDialog";
import {usePlaylistMetadata} from "../../../api/Hub/metadata/usePlaylistMetadata";
interface PlaylistControlsHeaderComponentEditLabelsProps {
    playListName?: string
    collectionId?: string
}

export const PlaylistControlsHeaderComponentEditLabels: React.FC<PlaylistControlsHeaderComponentEditLabelsProps> = (props) => {
    const {
        playListName,
        collectionId
    } = props;
    const {showError} = useAlerts();
    const {t} = useTranslation();

    const {metadata, metadataGroups} = usePlaylistMetadata(collectionId ?? '', () => showError(t(HUB.LABELS_ERROR)));

    const [isLabelsModalOpen, setIsLabelsModalOpen] = useState<boolean>(false);

    return (
        <>
            <Tooltip text={t(COLLECTIONS.EDIT_COLLECTION_LABELS)}>
                <span tabIndex={0} className={"tooltip-span-style"}>
                     <IconTag
                         className={"icon tag"}
                         onClick={() => setIsLabelsModalOpen(true)}
                         data-testid={'edit-collection-labels'}
                     />
                </span>
            </Tooltip>
            <div className={"outer-dialog-component"}>
                <LabelsDialogComponent
                    isModalOpen={isLabelsModalOpen}
                    setIsModalOpen={setIsLabelsModalOpen}
                    metadata={metadata}
                    metadataGroups={metadataGroups}
                    modalType={"collection"}
                    inputValue={playListName}
                    collectionOrTagId={collectionId}
                />
            </div>
        </>
    )
}