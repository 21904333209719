import {useEffect} from 'react';
import {useLocation} from "@tanstack/react-location";
import {usePersistedStore} from '../state/PersistedState';
import {shallow} from 'zustand/shallow';
import {Products} from "../enums/Products";
import {getPathBackToPage, usePortalNavigation} from "../utils/routerUtils";
import {Routes} from "../enums/Routes";
import {getFirstProductRoute} from "../utils/routes";

// @ts-ignore
export const RequireProduct = (props: { product: Products , children}) => {
    const { product, children } = props;
    const location = useLocation();
    const { navigate } = usePortalNavigation();
    const userProducts = usePersistedStore(state => state.user.products, shallow) as Products[];

    useEffect(() => {
        if (!userProducts.includes(product)) {
            navigate({
                to: getPathBackToPage(getFirstProductRoute(userProducts) || Routes.LICENSING_PAGE, location.current.pathname, location.current.pathname != '/'),
                replace: true
            });
        }
    }, [location, location.current.pathname, userProducts, product]);

    return userProducts && userProducts.length > 0 && userProducts.includes(product)
        ? children
        : null;
}
