import {QualifierType, QualifierValueType} from "../__mocks__/data";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Input,
    Select,
    SelectOption,
    Switch,
    Tooltip,
    Typography,
    useDebounce
} from "@catapultsports/referee-react";
import {OperatorMenu} from "./operatorMenu";
import {IconRemove} from "@catapultsports/referee-react/icons";
import MultiRangeSlider, {ChangeResult} from "../../multiRangeSlider/multirangeslider";
import {QueryCondition, QueryGroup, QueryOperator} from "../../../screens/nextGenFinder/__types__";
import './filterMenuRow.sass'

export interface FilterMenuRowProps {
    filterOption: QualifierType;
    onUpdated: (query: QueryGroup) => void;
    activeFilter: QueryGroup | undefined
}

export const FilterMenuRow: React.FC<FilterMenuRowProps> = (props) => {
    const {filterOption, onUpdated, activeFilter} = props;

    const [currentOperator, setCurrentOperator] = useState<QueryOperator>(QueryOperator.EqualExact);

    const [minValue, setMinValue] = useState<number>(filterOption.limits ? filterOption.limits.min : 0);
    const [maxValue, setMaxValue] = useState<number>(filterOption.limits ? filterOption.limits.max : 100);

    useEffect(() => {
        if (activeFilter && activeFilter?.conditions) {
            if (activeFilter.valueType == QualifierValueType.Numeric) {
                if (activeFilter?.conditions?.length > 1) {
                    setCurrentOperator(QueryOperator.Between);
                } else {
                    if (activeFilter?.conditions) {
                        setCurrentOperator(activeFilter?.conditions[0].operator);
                        setMinValue(activeFilter?.conditions[0].limits?.min ?? 0);
                    }
                }
            } else {
                setCurrentOperator(activeFilter?.conditions[0].operator);
            }
        }
    }, [activeFilter, activeFilter?.conditions])

    const updateFilter = useCallback((conditions: QueryCondition[]) => {
        onUpdated({
            qualifierTypeId: filterOption.id,
            qualifierTypeName: filterOption.name,
            valueType: filterOption.valueType,
            formatType: filterOption.formatType,
            conditions: conditions,
            selectableOptions: filterOption.valueType == QualifierValueType.List ?
                filterOption.values : undefined
        });
    }, [onUpdated, filterOption.id, filterOption.name, filterOption.valueType, filterOption.formatType, filterOption.values])

    const onFilterRowRemoved = useCallback(() => {
        setCurrentOperator(QueryOperator.EqualExact);
        updateFilter([]);
        setMinValue(filterOption.limits ? filterOption.limits.min : 0);
        setMaxValue(filterOption.limits ? filterOption.limits.max : 100);
    }, [filterOption.limits, updateFilter]);

    const onOperatorUpdated = useCallback((operator: QueryOperator) => {
        setCurrentOperator(operator);

        if (filterOption.valueType === QualifierValueType.Numeric) {
            if (operator === QueryOperator.Between) {
                updateFilter([
                    {
                        value: minValue,
                        operator: QueryOperator.GreaterThanEqualTo,
                        limits: filterOption.limits
                    },
                    {
                        value: maxValue,
                        operator: QueryOperator.LessThanEqualTo,
                        limits: filterOption.limits
                    }
                ]);
            } else {
                updateFilter([
                    {
                        value: maxValue,
                        operator: operator,
                        limits: filterOption.limits
                    }
                ]);
            }
        } else {
            if (activeFilter && activeFilter.conditions) {
                updateFilter(activeFilter.conditions.map(x => ({...x, operator: operator})));
            }
        }
    }, [minValue, maxValue, filterOption.valueType, filterOption.limits, activeFilter, updateFilter]);


    const onSelect = useCallback((items: SelectOption[]) => {
        //create multiple conditions
        if (items.length > 0) {
            const conditions: QueryCondition[] = [];

            items.forEach(x => {
                conditions.push({
                    value: x.value,
                    operator: currentOperator
                })
            })

            updateFilter(conditions);
        } else {
            updateFilter([]);
        }
    }, [currentOperator, updateFilter]);

    const onSwitch = useCallback(() => {
        if (activeFilter == undefined) {
            updateFilter([{
                value: true,
                operator: QueryOperator.EqualExact
            }]);
            return;
        }
        updateFilter([]);
    }, [activeFilter, updateFilter]);

    const inputUpdate = useCallback((input: number, isMax: boolean) => {
        if (currentOperator === QueryOperator.Between) {
            const minValue = isMax
                ? filterOption.selectedValues instanceof Array
                    ? filterOption.selectedValues[0]
                    : 0
                : input;

            const maxValue = isMax
                ? input
                : filterOption.selectedValues instanceof Array
                    ? filterOption.selectedValues[1]
                    : 100;

            setMinValue(!isMax ? input : minValue);
            setMaxValue(isMax ? input : maxValue);

            updateFilter([
                {
                    value: minValue,
                    operator: QueryOperator.GreaterThanEqualTo,
                    limits: filterOption.limits
                },
                {
                    value: maxValue,
                    operator: QueryOperator.LessThanEqualTo,
                    limits: filterOption.limits
                }
            ]);
        } else {
            setMaxValue(input);

            updateFilter([
                {
                    value: input,
                    operator: currentOperator,
                    limits: filterOption.limits
                }
            ]);
        }
    }, [currentOperator, filterOption.limits, filterOption.selectedValues, updateFilter]);

    const debouncedUpdate = useDebounce((e: ChangeResult) => sliderUpdate(e), 200);

    const sliderUpdate = useCallback((e: ChangeResult) => {
        setMinValue(e.minValue);
        setMaxValue(e.maxValue);

        if (e.minValue >= filterOption.limits?.min || e.maxValue <= filterOption.limits?.max) {
            const minValueFilter = {
                value: e.minValue,
                operator: QueryOperator.GreaterThanEqualTo,
                limits: filterOption.limits
            };

            const maxValueFilter = {
                value: e.maxValue,
                operator: currentOperator === QueryOperator.Between
                    ? QueryOperator.LessThanEqualTo
                    : currentOperator,
                limits: filterOption.limits
            };

            updateFilter(currentOperator === QueryOperator.Between
                ? [minValueFilter, maxValueFilter]
                : [maxValueFilter]
            );
        }
    }, [currentOperator, filterOption.limits, updateFilter]);

    const switchValue = useMemo(() => {
        return activeFilter != undefined;
    }, [activeFilter]);

    const filterValue = useMemo(() => {
        switch (filterOption.valueType) {
            case QualifierValueType.List: {
                const selectOptions =
                    filterOption.values instanceof Array ?
                        filterOption.values?.map((value) => ({
                            id: value.toString(),
                            value: value.toString(),
                            isSelected: filterOption.selectedValues instanceof Array ? filterOption.selectedValues.some(cond => cond === value) : filterOption.selectedValues == value
                        })) :
                        [];
                return <Select
                    isSearchable
                    isMultiselect
                    menuItems={selectOptions}
                    size="small"
                    onSelectItem={onSelect}
                    isExternalUpdated/>;
            }
            case QualifierValueType.Boolean: {
                return <Switch value={switchValue} size="small" onChange={onSwitch}/>;
            }
            case QualifierValueType.Numeric: {
                if (currentOperator != QueryOperator.Between) {
                    return <div className={'single-input'}>
                        <Input
                            type={"number"}
                            min={filterOption.limits?.min}
                            max={filterOption.limits?.max}
                            fullWidth
                            value={filterOption.selectedValues ? filterOption.selectedValues : filterOption.limits?.min}
                            size={"small"}
                            defaultValue={filterOption.limits?.min}
                            onInput={input => inputUpdate(+input.target.value, true)}
                        />
                        <MultiRangeSlider
                            min={filterOption.limits?.min}
                            max={filterOption.limits?.max}
                            step={(filterOption.limits?.max ?? 100) / 20}
                            minValue={minValue}
                            maxValue={filterOption.selectedValues ? filterOption.selectedValues : filterOption.limits?.min}
                            label={false}
                            singleSlider
                            onChange={(e: ChangeResult) => debouncedUpdate(e)}
                        />
                    </div>
                } else {
                    return <div className={'rangeValue'}>
                        <Input
                            type={"number"}
                            min={filterOption.limits?.min}
                            max={maxValue}
                            fullWidth
                            size={"small"}
                            value={(filterOption.selectedValues instanceof Array && filterOption.selectedValues.length > 0) ? filterOption.selectedValues[0] : filterOption.limits?.min}
                            onInput={input => inputUpdate(+input.target.value, false)}
                        />
                        {/* step count should be max/10*/}
                        <MultiRangeSlider
                            min={filterOption.limits?.min}
                            max={filterOption.limits?.max}
                            step={(filterOption.limits?.max ?? 100) / 20}
                            minValue={(filterOption.selectedValues instanceof Array && filterOption.selectedValues.length > 0) ? filterOption.selectedValues[0] : filterOption.limits?.min}
                            maxValue={(filterOption.selectedValues instanceof Array && filterOption.selectedValues.length > 1) ? filterOption.selectedValues[1] : filterOption.limits?.min}
                            label={false}
                            onChange={(e: ChangeResult) => debouncedUpdate(e)}
                        />
                        <Input
                            type={"number"}
                            min={minValue}
                            max={filterOption.limits?.max}
                            fullWidth
                            size={"small"}
                            value={(filterOption.selectedValues instanceof Array && filterOption.selectedValues.length > 0) ? filterOption.selectedValues[1] : filterOption.limits?.min}
                            onInput={input => inputUpdate(+input.target.value, true)}/>
                    </div>
                }
            }
            default: {
                return <Typography className={'filter-name'} variant={'body-2'}>Unknown Type</Typography>
            }
        }
    }, [filterOption.valueType, filterOption.values, filterOption.selectedValues, filterOption.limits?.min, filterOption.limits?.max, onSelect, switchValue, onSwitch, currentOperator, minValue, inputUpdate, debouncedUpdate, maxValue]);


    return <div className={"filter-row-option"} data-testid={`filter-option:${filterOption.id}`}>
        <Tooltip text={filterOption.name}>
            <Typography numberOfLines={1} className={"filter-name"} variant={"body-2"}>{filterOption.name}</Typography>
        </Tooltip>
        <OperatorMenu activeOperator={currentOperator} filterType={filterOption.valueType}
                      operatorUpdated={onOperatorUpdated}/>
        <div className={"filter-value"}>
            {filterValue}
        </div>
        <div className={"options-area"}>
            {/*
            TODO Add this back when needed
            <Button variant={'text'} size={'small'}>
                <IconMoreSmall/>
            </Button>
            */}
            {activeFilter &&
                <Button onClick={onFilterRowRemoved} variant={"text"} size={"small"}>
                    <IconRemove/>
                </Button>
            }
        </div>
    </div>;
}
