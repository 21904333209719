export enum FixtureFilterType {
    CompetitionID = 'competitionIDs',
    CompetitionSeasonIDs = 'competitionSeasonIDs',
    EventTypeIDs = 'eventTypeIDs',
    QualifierTypeIDs = 'qualifierTypeIDs',
    PhaseTypeIDs = 'phaseTypeIDs',
    TeamIDs = 'teamIDs',
    PlayerIDs = 'playerIDs',
    ProviderIDs = 'providerIDs',
    VenueIDs = 'venueIDs',
    FixtureIDs = 'fixtureIDs',
    CoachIDs = 'coachIDs',
    RefereeIDs = 'refereeIDs',
    FutureFixtureBoolean = "displayFutureFixtures",
    Unknown = 'unknown'
}

export enum ResultType {
    Matches = 'Matches',
    Events = 'Events'
}
