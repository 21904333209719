import {ApolloError, useMutation} from "@apollo/client";
import {UPDATE_PLAYLISTS} from "./updatePlaylists.gql";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../../enums/apiErrors";
import {useTranslation} from "react-i18next";
import {client as hubClient} from "../../../Hub/client";
import {clearSpecificCacheForAGivenClient} from "../../../commonApolloClientFunctions";
import {
    IUpdateCollectionResponse
} from "../../__types__/Common";

const useUpdatePlaylist = ( onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { t } = useTranslation();

    const [updatePlaylistAction, { error }] = useMutation(UPDATE_PLAYLISTS, {
        onCompleted: (response: IUpdateCollectionResponse) => {
            clearSpecificCacheForAGivenClient(hubClient, "playlists");
        },
    });

    useEffect(() => {
        handleErrorRaised(error, UPDATE_PLAYLISTS, ApiErrors.UpdateCollection, onError);
    }, [error]);

    return {
        updatePlaylists: (
            updatePlaylistInput: {
                id: string;
                createTags: {eventID: string}[];
                preRoll?: number;
                postRoll?: number;
            }
        ) => updatePlaylistAction({
            variables: {
                "playlists": [updatePlaylistInput]
            },
        })
    };
};

export default useUpdatePlaylist;