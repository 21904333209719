import React, {useCallback} from "react";
import './freeTextSearch.sass';
import {FormControl, SearchInput, useDebounce} from "@catapultsports/referee-react";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import {HUB} from "../../services/i18n/i18n-constants";
import {IconMagnifier} from "@catapultsports/referee-react/icons";
import {SEARCH_DEBOUNCE_DEFAULT} from "../../api/enums/clientMagic";

interface FreeTextSearchProps {
}

export const FreeTextSearch: React.FC<FreeTextSearchProps> = (props) => {
    const {} = props;
    const freeTextSearch = useSessionStore(state => state.freeTextSearch, shallow);
    const setFreeTextSearch = useSessionStore(state => state.setFreeTextSearch, shallow);
    const [value, setValue] = React.useState(freeTextSearch);
    const { t } = useTranslation();

    const debouncedText = useDebounce((value: string) => {
        setFreeTextSearch(value);
    }, SEARCH_DEBOUNCE_DEFAULT);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        debouncedText(event.target.value);
    }, []);

    return (
        <section className={'free-text-search'} data-testid={'free-text-container'}>
            <SearchInput placeholder={t(HUB.SEARCH_PLACEHOLDER) as string} value={value} onChange={handleChange} endAdornmentSlot={<IconMagnifier />} />
        </section>
    )
};
