import React, {MutableRefObject, useRef, useState} from "react";
import {Button, SelectOptions, usePositioner} from "@catapultsports/referee-react";
import {IconCamera} from "@catapultsports/referee-react/icons";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {useMixPanel} from "../../../providers/Mixpanel";
import './angleOptions.sass';
import {ITagItem} from "../../../api/Hub/tags/__types__/Tags";
import {HubTagToStreamUrl} from "../../../utils/stringUtils";

interface AngleOptionsProps {
    tag: ITagItem | undefined;
    onStreamChange: (angleSelected: AngleSelectedEvent) => void;
    currentStream: string | undefined;
}

export interface AngleSelectedEvent {
    name: string;
    s3Link: string | undefined;
}

export const AngleOptions: React.FC<AngleOptionsProps> = (props) => {
    const {tag, onStreamChange, currentStream} = props;
    const [ streamOpen, setStreamOpen ] = useState(false);
    const mixpanel = useMixPanel();
    const anglesPositioner = usePositioner({ placement: 'bottom-end' });
    const anglesButtonRef = useRef<HTMLElement>(null) as MutableRefObject<HTMLElement>;

    return (
        <>
            <section className='angle-options'>
                <nav>
                    <Button variant={'outline'} {...anglesPositioner.getReferenceProps({
                        onClick: () => setStreamOpen(!streamOpen),
                        ref: anglesButtonRef
                    })}>
                        <IconCamera/> Angle ({tag?.assets?.length ?? 0} available)
                    </Button>
                </nav>
            </section>
            <SelectOptions
                popperProps={anglesPositioner.getPopperProps}
                parentRef={anglesButtonRef}
                setIsVisible={setStreamOpen}
                isVisible={streamOpen}
                onSelectItem={() => setStreamOpen(false)}
                menuItems={tag?.assets?.map(asset => ({
                    id: asset.id,
                    value: asset.name,
                    isSelected: (currentStream && asset.file.s3Link && currentStream.includes(asset.file.s3Link)) || false,
                    onClick: () => {
                        mixpanel.track(MixPanelEvents.COLLECTION_CLIP_ANGLE_CHANGE, {
                            assetId: asset.id,
                            assetName: asset.name
                        });
                        const stream = HubTagToStreamUrl(tag, asset);
                        onStreamChange({
                            name: asset.name,
                            s3Link: stream
                        });
                    }
                })) ?? []}
            />
        </>
    )
};
