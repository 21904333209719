import React, {useCallback, useMemo, useState} from "react";
import {
    noop,
    Tooltip, Typography,
    useAlerts,
} from "@catapultsports/referee-react";
import {IPlaylistResponse} from "../../../api/Hub/collections/__types__/Playlist";
import {IconDeleteSmall} from "@catapultsports/referee-react/icons";
import '../playlistControlsHeaderComponent.sass';
import {COLLECTIONS, GENERAL, HUB} from "../../../services/i18n/i18n-constants";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import "../playlistControls.sass"
import {useMixPanel} from "../../../providers/Mixpanel";
import {useDeleteTags} from "../../../api/Hub/tags/deleteTags/useDeleteTags";
import {usePortalNavigation} from "../../../utils/routerUtils";
import {HubDialogComponent} from "../../hubDialog/hubDialog";

interface PlaylistControlsHeaderComponentDeleteProps {
    data: IPlaylistResponse
    selectedTagIds?: string[]
    setSelectedTagIds?: (value: string[]) => void
}

export const insideCollectionDeleteId= "delete-clips-inside-collection-modal"

export const PlaylistControlsHeaderDelete: React.FC<PlaylistControlsHeaderComponentDeleteProps> = (props) => {
    const {
        data: playlistData,
        selectedTagIds,
        setSelectedTagIds
    } = props;
    const mixpanel = useMixPanel();
    const { showError, showInfo } = useAlerts();
    const {navigate} = usePortalNavigation()
    const {t} = useTranslation();

    const collectionId = useSessionStore(state => state.playlist.id, shallow);
    const [isDeleteClipModalOpen, setIsDeleteClipModalOpen] = useState<boolean>(false);


    const errorAlert = useCallback(() => {
        let message = t(HUB.DELETE_CLIP_FROM_PLAYLIST_ERROR_SINGLE)
        if(selectedTagIds && selectedTagIds?.length > 1) message = t(HUB.DELETE_CLIP_FROM_PLAYLIST_ERROR_MULTIPLE)
        showError(message)
    }, [selectedTagIds, showError, t])

    const onSuccess = useCallback(() => {
        let message = t(HUB.DELETE_CLIP_FROM_PLAYLIST_SUCCESS_SINGLE)
        if(selectedTagIds && selectedTagIds?.length > 1) message = t(HUB.DELETE_CLIP_FROM_PLAYLIST_SUCCESS_MULTIPLE)
        showInfo(message)
        let clipsIds = selectedTagIds?.join(", ")
        mixpanel.track(`Clips Deleted From Collection`, { collectionID: collectionId, clipsId: clipsIds });

        if(playlistData?.playlist?.tags && playlistData?.playlist?.tags?.length === selectedTagIds?.length){
            navigate({to: "", replace: true});
        }
        setSelectedTagIds && setSelectedTagIds([])

    }, [collectionId, mixpanel, navigate, playlistData?.playlist?.tags, selectedTagIds, setSelectedTagIds, showInfo, t])

    const {deleteTag} = useDeleteTags(errorAlert, playlistData?.playlist?.id)

    const handleDeleteConfirmation = useCallback(() => {
        setIsDeleteClipModalOpen(!isDeleteClipModalOpen)
        let tagIdsObjetArray = selectedTagIds?.map((tagId) => ({"id": tagId}))
        if(tagIdsObjetArray && tagIdsObjetArray?.length > 0) {
            deleteTag(tagIdsObjetArray).then(() => {
                onSuccess()
            }).catch((error) => {
                throw new Error(error)
            })
        }

    }, [deleteTag, isDeleteClipModalOpen, onSuccess, selectedTagIds])

    const areTagsSelected = useMemo(() => {
        return selectedTagIds && selectedTagIds?.length > 0
    } , [selectedTagIds])

    return (
        <>

            <Tooltip
                text={
                    areTagsSelected ? t(COLLECTIONS.DELETE_CLIPS_FROM_COLLECTION) :
                    t(COLLECTIONS.SELECT_CLIPS)
                }
            >
                    <span tabIndex={0} className={"tooltip-span-style"}>
                         <IconDeleteSmall
                             className={`icon ${areTagsSelected ? "" : "disabled-icon"}`}
                             onClick={areTagsSelected ? () => setIsDeleteClipModalOpen(true) : noop}
                             data-testid={"collection-actions-delete-icon"}
                         />
                    </span>
            </Tooltip>
            <div className={"outer-dialog-component"}>
                {isDeleteClipModalOpen?
                    <HubDialogComponent
                        dialogTheme={"dark"}
                        dialogSize={'small'}
                        dialogHeader={t(COLLECTIONS.DELETE_CLIPS_MODAL_HEADER) as string}
                        isModalOpen={isDeleteClipModalOpen}
                        setIsModalOpen={setIsDeleteClipModalOpen}
                        modalContent={
                            <div
                                data-testid={`${insideCollectionDeleteId}-content-div`}
                                id={`${insideCollectionDeleteId}-content-div`}
                            >
                                <Typography
                                    testId={`${insideCollectionDeleteId}-content-sub-header`}
                                    id={`${insideCollectionDeleteId}-content-sub-header`}
                                    variant={"body-1"}>{t(COLLECTIONS.DELETE_CLIPS_MODAL_TEXT)}
                                </Typography>
                            </div>
                        }
                        displayDialogFooterButtons={true}
                        hideCloseButton={true}
                        footerPrimaryButtonText={t(GENERAL.DELETE) as string}
                        footerPrimaryButtonAction={handleDeleteConfirmation}
                        footerPrimaryButtonDisabled={false}
                        dialogId={insideCollectionDeleteId}
                        allowDialogClosure={false}
                    />
                    : null
                }
                </div>
        </>
    )
}