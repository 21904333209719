import React, {useCallback, useState} from "react";
import MatchTrackerApolloProvider from "../../providers/ApolloProviders/MatchTrackerApolloProvider";
import './nextGenFinder.sass'
import {SearchControls} from "./subPages/searchControls";
import {ResultsSection} from "./subPages/resultsSection";
import {QueryGroup, ResultType} from "./__types__";

export const NextGenFinder: React.FC<any> = () => {
    const [activeFilters, setActiveFilters] = useState<QueryGroup[]>([]);
    const [resultsType, setResultsType] = useState<ResultType>(ResultType.Matches);
    const onUpdateActiveFilters = useCallback((activeFiltersUpdated: QueryGroup[]) => setActiveFilters(activeFiltersUpdated), [setActiveFilters]);

    return (
        <MatchTrackerApolloProvider>
            <article className={'screen'}>
                <section className={'flex vertical finder-screen'}>
                    <SearchControls activeFilters={activeFilters} onUpdateActiveFilters={onUpdateActiveFilters} resultType={resultsType} onUpdateResultType={setResultsType}/>
                    <ResultsSection/>
                </section>
            </article>
        </MatchTrackerApolloProvider>
    )
};
