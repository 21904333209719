import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {GENERAL, HUB} from '../../../services/i18n/i18n-constants';
import {ExpandableMenuItem} from "../../expandableMenuItem/expandableMenuItem";
import {
    Button,
    FormControl,
    Input,
    Pill,
    Select,
    SelectOption,
    Spinner,
    Typography
} from "@catapultsports/referee-react";
import "./labelsDialog.sass";
import {IconAddSmall, IconCancelSmall} from '@catapultsports/referee-react/icons';
import {scrollToReference} from "../../../utils/scrollingUtils";
import {MetadataGroup} from "./labelsDialog";
import useMetadataGroupValues from "../../../api/Hub/metadataGroups/useMetadataGroupValues";
import * as _ from 'lodash';

enum LabelOption {
    DELETE = "DELETE",
    EDIT = "EDIT"
}
const LabelOptionSeparator = ':';

export interface LabelsDialogContentComponentProps {
    currentMetadataGroups: MetadataGroup[];
    onMetadataGroupStateUpdate: (groupState: MetadataGroup[]) => void;
    modalType?: "collection" | "tag";
    inputValue?: string;
}

export const LabelsDialogContentComponent: React.FC<LabelsDialogContentComponentProps> = (props) => {
    const { currentMetadataGroups, modalType, inputValue, onMetadataGroupStateUpdate} = props;
    const { t } = useTranslation();
    const [showAddGroup, setShowAddGroup] = useState(false);
    const [newGroupName, setNewGroupName] = useState<string | undefined>(undefined);
    const newGroupRef = useRef<HTMLDivElement>(null);
    const [metadataGroupState, setMetadataGroupState] = useState<MetadataGroup[]>(currentMetadataGroups);
    const { metadataGroups, loading } = useMetadataGroupValues(metadataGroupState.map(group => group.name));
    const [editingGroup, setEditingGroup] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (onMetadataGroupStateUpdate)
            onMetadataGroupStateUpdate(metadataGroupState);
    }, [metadataGroupState, onMetadataGroupStateUpdate]);

    const defineSelectItemsToDisplay = useCallback((groupElement: { id: string; }) => {
        if (metadataGroupState && metadataGroups) {
            return metadataGroups.filter(group => group.id === groupElement.id)
                .flatMap(group =>
                    group.metadata
                        .filter(metadata => !metadataGroupState.find(stateGroup => stateGroup.id === group.id)?.values.map(value => value.id).includes(metadata.id))
                        .map(metadataValue => ({
                            value: metadataValue.value,
                            id: metadataValue.id
                        })));
        }
        return [];
    }, [metadataGroups, metadataGroupState]);


    const handleDeselectItem = useCallback((selectedOptionValue: string, metadataGroup: MetadataGroup) => {
        const newGroupsState = _.cloneDeep(metadataGroupState);
        newGroupsState
            .filter(group => group.name === metadataGroup.name)
            .forEach(matchingGroup => matchingGroup.values = matchingGroup.values.filter(value => value.value !== selectedOptionValue));
        setMetadataGroupState(newGroupsState);
    }, [metadataGroupState]);

    const handleSelectItem = useCallback((selectedOption: SelectOption [], metadataGroup: MetadataGroup) => {
        if(selectedOption.length > 0) {
            const selectedOptionValue = selectedOption[0];
            const newGroupsState = _.cloneDeep(metadataGroupState);
            newGroupsState
                .filter(group => group.name === metadataGroup.name)
                .forEach(matchingGroup => {
                    if (matchingGroup.values && matchingGroup.values.some(value => value.value === selectedOptionValue.value))
                        return;
                    matchingGroup.values = [...matchingGroup.values ?? [], {
                        id: isNaN(selectedOptionValue.id as number) ? selectedOptionValue.id as string : undefined,
                        value: selectedOptionValue.value
                    }];
                });
            setMetadataGroupState(newGroupsState);
        }
    }, [metadataGroupState, setMetadataGroupState]);

    const onAddGroupToggle = useCallback(() => {
        setNewGroupName(undefined);
        const flippedShowGroup = !showAddGroup;
        setShowAddGroup(flippedShowGroup);
    }, [newGroupRef.current, showAddGroup]);

    const onGroupNameChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event) {
            setNewGroupName(event.target.value);
        }
    }, []);

    const onNewGroupAdd = useCallback(() => {
        if (newGroupName && newGroupName.length > 0) {
            const newGroupsState = _.cloneDeep(metadataGroupState);
            setMetadataGroupState([...newGroupsState, { id: undefined, name: newGroupName, values: []}]);
            onAddGroupToggle();
        }
    }, [newGroupName, onAddGroupToggle, metadataGroupState, setMetadataGroupState]);

    useEffect(() => {
        if (showAddGroup) {
            scrollToReference(newGroupRef.current);
        }
    }, [showAddGroup]);

    const onLabelOptionSelected = useCallback((id: string | number) => {
        const actionWithId = (id as string).split(LabelOptionSeparator);
        switch (actionWithId[0]) {
            case LabelOption.DELETE:
                setMetadataGroupState(metadataGroupState.filter(group => group.name != actionWithId[1]));
                break;
            case LabelOption.EDIT:
                setEditingGroup(actionWithId[1]);
                break;
        }
    }, [metadataGroupState, setMetadataGroupState]);

    const onLabelGroupEditCancel = useCallback(() => {
        setEditingGroup(undefined);
    }, [setEditingGroup]);

    const onLabelGroupEdit = useCallback((value: string) => {
        const newMetadataGroupState = _.cloneDeep(metadataGroupState);
        const editingEntry = newMetadataGroupState.find(group => group.name === editingGroup);
        if (editingEntry) {
            editingEntry.name = value;
            editingEntry.id = undefined;
            editingEntry.values = editingEntry.values.map(value => ({ ...value, id: undefined }));
            setMetadataGroupState(newMetadataGroupState);
        }
        setEditingGroup(undefined);
    }, [editingGroup, metadataGroupState, setMetadataGroupState, setEditingGroup]);

    return (
        <div className={"modal-div"}>
            <div className={"clip-name"} data-testid={"clip-name-div"} id={"clip-name-div"}>
                <FormControl label={modalType === "collection"? t(HUB.COLLECTION_NAME) : t(HUB.CLIP_NAME)} testId={"clip-name"}>
                       <Input disabled={true} value={inputValue} data-testid={"clip-name-input"} id={"clip-name-input"}/>
                </FormControl>
            </div>
            <div className={"label-text"} id={"label-text"} data-testid={"label-text"}>
                 <Typography variant={"label-1"} className={"label-text"}>{t(HUB.LABELS)}</Typography>
            </div>
            <div className={"expandable-div"} data-testid={"expandable-div"} id={"expandable-div"}>
                 {metadataGroupState && !loading
                     ?
                     (
                         metadataGroupState.length > 0 ? metadataGroupState.map((metadataGroup: MetadataGroup, index: number) => {
                             return(
                                 <div className={"inner-expandable-div-items-outer-div"}
                                      key={`inner-expandable-div-items-outer-div-${index}`}
                                      id={`inner-expandable-div-items-outer-div-${index}`}
                                      data-testid={`inner-expandable-div-items-outer-div-${index}`}
                                 >
                                     <ExpandableMenuItem
                                         label={metadataGroup.name}
                                         value={metadataGroup.name}
                                         labelOptions={[
                                             {id: `${LabelOption.EDIT}${LabelOptionSeparator}${metadataGroup.name}`, value: t(GENERAL.EDIT)},
                                             {id: `${LabelOption.DELETE}${LabelOptionSeparator}${metadataGroup.name}`, value: t(GENERAL.DELETE)}]}
                                         onLabelOptionSelected={onLabelOptionSelected}
                                         enableEdit={editingGroup != undefined && metadataGroup.name === editingGroup}
                                         onLabelEditCancel={onLabelGroupEditCancel}
                                         onLabelEdit={onLabelGroupEdit}
                                     >
                                         <div className={"inner-expandable-div-items"}
                                              key={`inner-expandable-div-items-${index}`}
                                              id={`inner-expandable-div-items-${index}`}
                                              data-testid={`inner-expandable-div-items-${index}`}
                                         >
                                             {metadataGroup.values && metadataGroup.values.length > 0
                                                 ?
                                                 metadataGroup.values.map((element: any, index: number) =>
                                                     <Pill
                                                         testId={`edit-label-modal-${index}-${element.value}`}
                                                         value={element.value}
                                                         key={index}
                                                         variant={'clearable'}
                                                         shape={"square"}
                                                         onClose={() => {
                                                             handleDeselectItem(element.value, metadataGroup)
                                                         }}
                                                     />)
                                                 :
                                                 null
                                             }
                                             <div className={"select-element"}>
                                                 <Select
                                                     menuItems={defineSelectItemsToDisplay(metadataGroup)}
                                                     useOuterSearch={true}
                                                     placeholder={t(HUB.ADD_LABEL) as string}
                                                     isCreatable
                                                     onSelectItem={(selectedOption) => handleSelectItem(selectedOption, metadataGroup)}
                                                     testId={`select-element-${index}-${metadataGroup.name}`}
                                                     isExternalUpdated={true}
                                                 />
                                             </div>
                                         </div>
                                     </ExpandableMenuItem>
                                 </div>
                             )
                         }) : <Typography>{t(HUB.NO_LABEL_GROUPS)}</Typography>
                     )
                     :
                     <Spinner/>
                 }
                {showAddGroup &&
                    <div ref={newGroupRef} className={"add-group"}>
                        <div className={"flex horizontal"}>
                            <Typography className={"add-group-title"}>{t(HUB.ADD_GROUP)}</Typography>
                            <Button onClick={onAddGroupToggle} variant={"text"} size={"small"}><IconCancelSmall/></Button>
                        </div>
                        <div className={"add-group-input"}>
                            <FormControl label={t(HUB.GROUP_NAME) as string}>
                                <div className={'flex horizontal add-group-input-fields'}>
                                    <Input onChange={onGroupNameChange} size="small" placeholder={t(HUB.LABEL_GROUP_NAME) as string} />
                                    <Button onClick={onNewGroupAdd} size={"small"}>{t(HUB.ADD)}</Button>
                                </div>
                            </FormControl>
                        </div>
                    </div>
                }
            </div>
            <Button disabled={showAddGroup} onClick={onAddGroupToggle} className={"add-group-button"} size={"small"} variant={"text"} startSlot={<IconAddSmall/>}>{t(HUB.ADD_GROUP)}</Button>
        </div>
    )
};