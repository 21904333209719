import i18n from 'i18next';
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next).init({
    fallbackLng: 'en',
    backend: {
        loadPath: '/resources/i18n/locale_{{lng}}.json'
    },
    nonExplicitSupportedLngs: true,
    detection: {
        order: ['cookie'],
        caches: ['cookie']
    },
    interpolation: {
        escapeValue: false
    },
    debug: process.env.LOCAL === 'true',
})

export default i18n;
