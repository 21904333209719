import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    Tooltip,
    Typography as Text,
    useAlerts
} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {
    COLLECTIONS,
    FINDER,
    GENERAL,
    GRID_FOOTER
} from "../../services/i18n/i18n-constants";
import './gridFooter.sass';
import {useMixPanel} from "../../providers/Mixpanel";
import {GameResult} from "../../__types__/globalTypes";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {MATCHTRACKER_EXPORT_MAX} from "../../api/enums/clientMagic";
import useCreatePlaylist
    from "../../api/MatchTracker/playlists/createPlaylist/useCreatePlaylist";
import {ApolloError} from "@apollo/client";
import {Routes} from "../../enums/Routes";
import {ICreateCollectionResponse} from "../../api/MatchTracker/__types__/Common";
import {HubDialogComponent} from "../hubDialog/hubDialog";
import {
    CreateCollectionModalContent
} from "../createCollection/createCollectionModalContent";
import useUpdatePlaylists
    from "../../api/MatchTracker/playlists/updatePlaylist/useUpdatePlaylists";
import HubApolloProvider from "../../providers/ApolloProviders/HubApolloProvider";
interface GridFooterProps {
    resultType: 'game' | 'event',
    switchType?: (selectedResults: GameResult[]) => void
}

export interface SelectedCollection {
    id: string,
    name: string
}

interface FooterOption {
    label: string,
    action: () => void
    disabled?: boolean;
}

const VIEW_COLLECTION_DELAY_MS = 8000;

export const GridFooter: React.FC<GridFooterProps> = (props) => {
    const { resultType, switchType } = props;
    const { t } = useTranslation();
    const mixpanel = useMixPanel();
    const checkedIds = resultType === 'game' ?
        useSessionStore<string[]>(state => state.games.checkedIds, shallow) :
        useSessionStore<string[]>(state => state.events.checkedIds, shallow);
    const numberOfRecords = useSessionStore(state => state.numberOfRecords, shallow)
    const setCheckedEventIds = useSessionStore(state => state.setCheckedEventIds, shallow)
    const gameResults = useSessionStore<GameResult[]>(state => state.games.results, shallow);
    const matchtrackerIds = useSessionStore<string[]>(state => state.resultItems.matchtrackerIds, shallow);
    const { showInfo, showError, closeAlert } = useAlerts();
    const alertReference = useRef<string | undefined>(undefined);

    const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] = useState(false)
    const [collectionName, setCollectionName] = useState<string>("")
    const [tooltipText, setTooltipText] = useState(t(COLLECTIONS.NAME_COLLECTION) as string)

    const [preRollInput, setPreRollInput] = useState<string>("")
    const [postRollInput, setPostRollInput] = useState<string>("")
    const [isClipTooLong, setIsClipTooLong] = useState<boolean>(false)

    const [addToSelectedCollections, setAddToSelectedCollections] = useState<SelectedCollection[]>([])
    const [isCreateNewSelected, setIsCreateNewSelected] = useState<boolean>(true);
    const [isAddToExistingSelected, setIsAddToExistingSelected] = useState<boolean>(false);


    const sendToMatchtracker = (ids: string[]) => {
        mixpanel.track(`Hub-Portal: Grid footer matchtracker deep linking clicked.`, { matchtrackerIds: ids });
        window.location.assign(`matchtracker://view?ids=${ids.reduce((previous, current): string => `${previous},${current}`)}`);
    };

    const matchtrackerOption = {
        label: t(FINDER.SEND_TO_MATCHTRACKER),
        action: () => {
            showInfo(t(FINDER.SENDING_TO_MATCHTRACKER));
            sendToMatchtracker(matchtrackerIds);
        },
        disabled: checkedIds.length == 0 || checkedIds.length > MATCHTRACKER_EXPORT_MAX,
        tooManyForMatchTracker: checkedIds.length > MATCHTRACKER_EXPORT_MAX
    };

    const options: { label: string, disabled: boolean, action: () => void, tooManyForMatchTracker?: boolean}[] = resultType === 'game' ? [
            {
                label: t(FINDER.VIEW_EVENTS),
                action: () => {
                    if (switchType) {
                        setCheckedEventIds([]);
                        switchType(checkedIds.map((id) => gameResults.find((result) => id === result.id)).filter((result) => result != undefined) as GameResult[] || []);
                    }
                },
                disabled: checkedIds.length == 0
            },
            matchtrackerOption
        ] :
        [
            {
                label: t(FINDER.CREATE_A_COLLECTION),
                action: () => setIsCreateCollectionModalOpen(!isCreateCollectionModalOpen),
                disabled: checkedIds.length == 0
            },
            matchtrackerOption
        ];

    const errorAlert = (error: ApolloError, errorMessage: string) => {
        showError(t(FINDER.CREATE_COLLECTION_ERROR));
    };

    const closeLatestAlert = useCallback(() => {
        if (alertReference.current)
            closeAlert(alertReference.current);
    }, [alertReference, alertReference.current]);

    const onSuccess = useCallback((response: ICreateCollectionResponse) => {
        const first = response.createPlaylists.playlistIDs.find((id) => id);
        if (first) {
            alertReference.current = showInfo(
                <>
                    <Text>
                        {t(COLLECTIONS.COLLECTION_CREATED)}
                    </Text>
                    <a data-testid={'new-collection-link'} onClick={closeLatestAlert} href={`#/${Routes.HUB_PAGE}/${Routes.COLLECTIONS}/${first}`}>{t(COLLECTIONS.VIEW_NEW_COLLECTION)}</a>
                </>,
                {delay: VIEW_COLLECTION_DELAY_MS});
        } else {
            showInfo(t(COLLECTIONS.COLLECTION_CREATED));
        }
        mixpanel.track(`Created Collection ${collectionName}`, { eventsIds: checkedIds });
    }, [checkedIds, collectionName, mixpanel])

    const {createPlaylist} = useCreatePlaylist(errorAlert, onSuccess);

    let createCollectionFunction = useCallback(() => {
        // preRoll and postRoll are in milliseconds
        let preRoll = parseInt(preRollInput) * 1000
        let postRoll = parseInt(postRollInput) * 1000

        let eventTags = checkedIds.map(id => (
            {eventID: id})
        )

        let createPlaylistInput : {name: string, createTags:  {eventID: string} [], preRoll?: number, postRoll?: number} =
            {
                name: collectionName,
                createTags: eventTags,
            }

        // if preRollInput or postRollInput are not empty, add them to the createPlaylistInput
        // if they are empty they will not be added to the createPlaylistInput and
        // the backend will use the default values of 5 seconds
        if (preRollInput !== "") createPlaylistInput.preRoll = preRoll
        if (postRollInput !== "") createPlaylistInput.postRoll = postRoll

        createPlaylist(createPlaylistInput);
        setIsCreateCollectionModalOpen(false)
    }, [checkedIds, collectionName, createPlaylist, postRollInput, preRollInput])


    const {updatePlaylists} = useUpdatePlaylists(errorAlert);

    const addToExistingFunction = useCallback(() => {
        // preRoll and postRoll are in milliseconds
        let preRoll = parseInt(preRollInput) * 1000
        let postRoll = parseInt(postRollInput) * 1000

        // this will be needed for every collection id in addToSelectedCollections
        let eventTags = checkedIds.map(id => (
            {eventID: id})
        )

        const updatePromises = addToSelectedCollections.map(collection => {
            const updatePlaylistInput: {id: string, createTags:  {eventID: string} [], preRoll?: number, postRoll?: number} = {
                id: collection.id,
                createTags: eventTags,
            };

            if (preRollInput !== "") updatePlaylistInput.preRoll = preRoll
            if (postRollInput !== "") updatePlaylistInput.postRoll = postRoll
            return updatePlaylists(updatePlaylistInput);
        });

        let multipleCollections = addToSelectedCollections.length > 1

        Promise.all(updatePromises)
            .then(() => {
                alertReference.current = showInfo(
                        <>
                            <Text>
                                {multipleCollections ?
                                    t(COLLECTIONS.COLLECTION_UPDATED_MULTIPLE)
                                    :
                                    t(COLLECTIONS.COLLECTION_UPDATED_SINGLE)}:
                            </Text>
                            {addToSelectedCollections && addToSelectedCollections.length > 0 ? (
                                addToSelectedCollections.map((collection, index) => (
                                    <div key={`update-collection-div-${index}`}>
                                       <a
                                           key={`update-collection-link-${index}`}
                                           data-testid={`update-collection-link-${index}`}
                                           onClick={closeLatestAlert} href={`#/${Routes.HUB_PAGE}/${Routes.COLLECTIONS}/${collection.id}`}
                                       >
                                           {collection.name}</a><br/>
                                    </div>
                                ))
                            ):null
                            }
                        </>,
                        {delay: VIEW_COLLECTION_DELAY_MS});

                mixpanel.track(`Created Collection ${collectionName}`, { eventsIds: checkedIds });
                setIsCreateCollectionModalOpen(false)
            })
            .catch(error => {
                throw new Error(error);
            });

        },
        [checkedIds, postRollInput, preRollInput, addToSelectedCollections])


    const footerPrimaryButtonDisabled = useMemo(() => {
        let preValue = preRollInput === "" ? 0 : parseInt(preRollInput)
        let postValue = postRollInput === "" ? 0 : parseInt(postRollInput)

        let isClipTooLongVar = preValue + postValue > 60
        setIsClipTooLong(isClipTooLongVar)
        let collectionHasName = collectionName === ""
        let collectionsSelected = addToSelectedCollections.length === 0

        if(isCreateNewSelected){
            if(collectionHasName && isClipTooLongVar) {
                setTooltipText(`${t(COLLECTIONS.NAME_COLLECTION)} and ${t(COLLECTIONS.CLIPS_LENGTH_LIMIT)}`)
            }else if(isClipTooLongVar) {
                setTooltipText(t(COLLECTIONS.CLIPS_LENGTH_LIMIT) as string)
            }else{
                setTooltipText(t(COLLECTIONS.NAME_COLLECTION) as string)
            }

            if(collectionHasName || isClipTooLongVar) return true
        }else{
            if(collectionsSelected && isClipTooLongVar){
                setTooltipText(`${t(COLLECTIONS.SELECT_ONE_OR_MORE_COLLECTION)} and ${t(COLLECTIONS.CLIPS_LENGTH_LIMIT)}`)
            }else if(isClipTooLong ||isClipTooLongVar) {
                setTooltipText(t(COLLECTIONS.CLIPS_LENGTH_LIMIT) as string)
            }else{
                setTooltipText(t(COLLECTIONS.SELECT_ONE_OR_MORE_COLLECTION) as string)
            }

            if(collectionsSelected || isClipTooLongVar) return true
        }
    }, [
        addToSelectedCollections.length,
        collectionName,
        isCreateNewSelected,
        postRollInput,
        preRollInput, isClipTooLong, t])

    useEffect(() => {
        if (!isCreateCollectionModalOpen) {
            setCollectionName("")
            setPreRollInput("")
            setPostRollInput("")
            setIsClipTooLong(false)
            setAddToSelectedCollections([])
            setIsCreateNewSelected(true)
            setIsAddToExistingSelected(false)
        }
    }, [isCreateCollectionModalOpen]);

    return (
        <section className={'flex horizontal grid-footer footer'}>
            <HubDialogComponent
                dialogTheme={"dark"}
                dialogSize={"small"}
                dialogHeader={t(FINDER.CREATE_A_COLLECTION) as string}
                isModalOpen={isCreateCollectionModalOpen}
                setIsModalOpen={setIsCreateCollectionModalOpen}
                modalContent={
                <HubApolloProvider>
                    <CreateCollectionModalContent
                        setCollectionName={setCollectionName}
                        setPreRollInput={setPreRollInput}
                        setPostRollInput={setPostRollInput}
                        preRollInput={preRollInput}
                        postRollInput={postRollInput}
                        isClipTooLong={isClipTooLong}
                        setAddToSelectedCollections={setAddToSelectedCollections}
                        addToSelectedCollections={addToSelectedCollections}
                        isCreateNewSelected={isCreateNewSelected}
                        setIsCreateNewSelected={setIsCreateNewSelected}
                        isAddToExistingSelected={isAddToExistingSelected}
                        setIsAddToExistingSelected={setIsAddToExistingSelected}
                    />
                </HubApolloProvider>
                }
                displayDialogFooterButtons={true}
                hideCloseButton={false}
                footerPrimaryButtonText={isCreateNewSelected? GENERAL.CREATE : COLLECTIONS.ADD_TO_EXISTING}
                footerPrimaryButtonAction={isCreateNewSelected ? createCollectionFunction : addToExistingFunction}
                footerPrimaryButtonDisabled={footerPrimaryButtonDisabled}
                dialogId={"create-collection"}
                tooltipText={tooltipText}
            />
            <Text className={'selected-count'} variant={'button'}>{`${checkedIds ? checkedIds.length : 0} ${t(GENERAL.OF)} ${numberOfRecords} ${t(GRID_FOOTER.SELECTED)}`}</Text>
            <section className={'flex horizontal footer-options'}>
                { options && options.map((option, index) => {
                    const button = <Button
                        style={option.disabled ? { pointerEvents: 'none' } : {}}
                        testId={`footer-option-${index}`}
                        key={`${option.label}-${index}`}
                        className={'footer-option'}
                        disabled={option.disabled}
                        variant={'outline'}
                        onClick={() => {
                            mixpanel.track(`Hub-Portal: Grid footer option clicked.`, { option: option.label });
                            option.action();
                        }}>
                        { option.label}
                    </Button>;
                    if (option.tooManyForMatchTracker) {
                        return <Tooltip className={'tooltip-adjustments'} testId={'limit-tooltip'} text={t(FINDER.MATCHTRACKER_LIMIT_25)}>
                            <span tabIndex={0} data-testid={'tooltip-wrapper'} className={'tooltip-wrapper'}>
                                { button }
                            </span>
                        </Tooltip>;
                    }
                    return button;
                })}
            </section>
        </section>
    )
};
