export enum QualifierValueType {
    Text = 'text',
    Numeric = 'numeric',
    Integer = 'integer',
    List = 'list',
    Date = 'date',
    Boolean = 'boolean',
    Team = 'team',
    Player = 'player'
}

export interface QualifierType {
    id: string
    name: string
    valueType: QualifierValueType
    formatType: string
    unit?: {
        displayName: string
        shortName: string
    }
    values?: string | number[] | number
    limits?: {
        min: number,
        max: number
    }
    selectedValues?: string[] | number[] | number
}

export const categories = Array.from(Array(10).keys()).map(key => ({ id: `id ${key}`, label: `category ${key}`}));

export const filterOptions = Array.from(Array(100).keys()).map(key =>
    GetRandomObject(key));

function GetRandomObject (key: number) {
    const objects = [
        SelectObject,
        ToggleObject,
        NumericObject,
        NumericRangeObject,
    ];

    const randomIndex = Math.floor(Math.random() * objects.length);
    const randomObject = objects[randomIndex];
    return randomObject(key.toString());
}

function SelectObject(key: string) { return {
    id: key,
    name: `Select Filter ${key}`,
    valueType: QualifierValueType.List,
    formatType: 'fixed',
    values: ['Option 1', 'Option 2', 'Option 3']
}}

function ToggleObject(key: string) { return {
    id: key,
    name: `Verrrrrrrrrrrry Long Toggle Filter Name ${key}`,
    valueType: QualifierValueType.Boolean,
    formatType: 'fixed'
}}

function NumericObject(key: string) { return {
    id: key,
    name: `Numeric Filter ${key}`,
    valueType: QualifierValueType.Numeric,
    formatType: 'fixed',
    values: 5,
    limits: {
        min: 0,
        max: 100
    }
}}

function NumericRangeObject (key: string) { return {
    id: key,
    name: `Range Filter ${key}`,
    valueType: QualifierValueType.Numeric,
    formatType: 'range',
    limits: {
        min: 0,
        max: 100
    }
}}