import React, {useEffect} from "react";
import {FixtureFilter, GamesResults} from "./gamesResults";
import {EventsResults} from "./eventsResults";
import {useSearch} from "@tanstack/react-location";
import {useDatePresets} from "../../../utils/dateUtils";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {
    defineQueryFilters,
    SearchLocationTypes,
    usePortalNavigation
} from "../../../utils/routerUtils";
import {ResultType} from "../../../api/MatchTracker/enum/Common";
import useSeasons from "../../../api/MatchTracker/seasons/useSeasons";
import {HUB} from "../../../services/i18n/i18n-constants";
import {useAlerts} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {useUpdateFilter} from "../../../state/stateUtil";

export const ResultsSection: React.FC<any> = () => {
    const { filters } = useSearch<SearchLocationTypes>();
    const datePresets = useDatePresets();
    const {navigate} = usePortalNavigation();
    const { showError } = useAlerts();
    const {t} = useTranslation();


    const resultsType = filters?.resultsType || ResultType.Matches;
    const {state: {seasons, seasonsLoading, seasonsPageSize}} =
        useSeasons(undefined, undefined, undefined, undefined, () => showError(t(HUB.SEASONS_ERROR)));

    const [competitionId, videoChecked, eventsChecked, perfectMatchChecked, dateRange, seasonIds, teamIds, playerIds, includeFutureFixtures] = defineQueryFilters(filters, datePresets)
    const fixtureFilters = useSessionStore<FixtureFilter[]>(state => state.search.fixtureFilters, shallow);
    const updateFilter = useUpdateFilter({location: location, navigate: navigate});

    return (
        <>
            {resultsType == ResultType.Matches
                ? <GamesResults
                    dateRange={dateRange}
                    competitionId={competitionId}
                    getVideo={videoChecked}
                    getEvents={eventsChecked}
                    usePerfectMatch={perfectMatchChecked}
                    searchFilters={fixtureFilters}
                    switchType={(results) => {
                        updateFilter({
                            resultsType: ResultType.Events,
                            filterIds: [],
                            teamIds: [],
                            playerIds: [],
                            gameEvents: results.map((result) => { return  {id: result.id, name: result.homeTeam.name + ' vs ' + result.awayTeam.name}}),
                            seasonIds: seasonIds,
                            competitionId: competitionId,
                            video: false,
                            events: false
                        });
                    }
                    }
                    seasonIds={seasonIds}
                    teamIds={teamIds}
                    playerIds={playerIds}
                    includeFutureFixtures={includeFutureFixtures}
                />
                : <EventsResults
                    dateRange={dateRange}
                    competitionId={competitionId}
                    getVideo={videoChecked}
                    usePerfectMatch={perfectMatchChecked}
                    searchFilters={fixtureFilters}
                    seasonIds={seasonIds}
                    teamIds={teamIds}
                    playerIds={playerIds}
                    includeFutureFixtures={includeFutureFixtures}
                />}
        </>
    )
};
