import { create } from "zustand";
import {devtools, persist} from 'zustand/middleware';

import { createUserSlice } from './slices/user';
import {PersistedState} from "./slices/StoreSlice";
import {immer} from "zustand/middleware/immer";

const createSlices = () => create<PersistedState>()(
	immer(persist(
		devtools((...a) => {
			return {
				...createUserSlice(...a),
			};
		}, {name:'persisted-storage'}), { name: 'temp-persistent-storage'}
	))
)

export const clearPersistedState = () => {
	usePersistedStore.persist.clearStorage();
	usePersistedStore = createSlices();
}

// @ts-ignore
export let usePersistedStore = createSlices();
