import { gql } from "@apollo/client";

const REFRESH_TOKEN = gql`
    mutation RefreshToken($input: Object!) {
        refreshToken(input: $input) @rest(type: "auth", path: "/auth/refresh", method: "POST") {
            expire
            token
        }
    }
`;

export { REFRESH_TOKEN };
