import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FilterItemType, PlaylistType, SortBy} from "../../../api/Hub/enum/Enum";
import {getPathBackToPage, usePortalNavigation} from "../../../utils/routerUtils";
import {Routes} from "../../../enums/Routes";
import {useLocation} from "@tanstack/react-location";
import {CollectionList} from "../../../components/collectionList/collectionList";
import {
    Checkbox,
    noop,
    Spinner,
    Tooltip,
    Typography
} from "@catapultsports/referee-react";
import {COLLECTIONS, HUB} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import './overview.sass';
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import useMeasure from "react-use-measure";
import {IMetaDataGroupEdges} from "../../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {CollectionFiltersHide} from "./filterConstants";
import {FreeTextSearch} from "../../../components/freeTextSearch/freeTextSearch";
import {
    IconCancelSmall,
    IconDeleteSmall,
    IconMoreSmall
} from "@catapultsports/referee-react/icons";
import {CollectionDelete} from "../../../components/collection/collectionDelete";
import {
    MetadataGroupNestedSelect
} from "../../../components/metadataGroupNestedSelect/MetadataGroupNestedSelect";

const MEASURE_DEBOUNCE = 100;
const ITEM_HEIGHT = 170;

export const Overview: React.FC<any> = () => {
    const location = useLocation();
    const {navigate} = usePortalNavigation();
    const { t } = useTranslation();
    const setMetadataFilters = useSessionStore(state => state.setCollectionsMetadataIds, shallow);
    const setSortBy = useSessionStore(state => state.setCollectionsSortBy, shallow);
    const [collectionRef, collectionBounds] = useMeasure({ debounce: MEASURE_DEBOUNCE });
    const [presentationRef, presentationBounds] = useMeasure({ debounce: MEASURE_DEBOUNCE });
    const [areActionsForAllOpen, setAreActionsForAllOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState<boolean>(false);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);

    useEffect(() => {
        setMetadataFilters([]);
        setSortBy(SortBy.CreationTimestamp);
    }, []);

    const getPlaylistCount = (containerHeight: number) => {
        let count = 0;
        if (collectionBounds.height) {
            count = Math.floor(containerHeight / ITEM_HEIGHT);
        }
        return count;
    };

    const collectionCount = useMemo(() => {
        return getPlaylistCount(collectionBounds.height);
    }, [collectionBounds.height]);
    const presentationCount = useMemo(() => {
        return getPlaylistCount(presentationBounds.height);
    }, [collectionBounds.height]);

    const handleSelectAll = useCallback(() => {
        setSelectAll(!selectAll);
    }, [selectAll]);


    const handleSelectRow = useCallback((id: string) => {
        const index = selectedRows.indexOf(id);
        if (index > -1) {
            setSelectedRows(selectedRows.filter(row => row !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    }, [selectedRows]);

    const areAllRowsSelected = useMemo(() => {
        return selectedRows.length > 0
    }, [selectedRows]);

    const handleDeleteClick = useCallback(() => {
        if(areAllRowsSelected){
            setIsDeleteAllModalOpen(!isDeleteAllModalOpen)
        }else {
            noop()
        }
    }, [areAllRowsSelected, isDeleteAllModalOpen]);

    const handleMenuActionsClick = useCallback(() => {
        setAreActionsForAllOpen(!areActionsForAllOpen);
        if(!areActionsForAllOpen) {
            setSelectedRows([])
        }
    }, [areActionsForAllOpen]);

    const groupFilterFunc = useCallback((edge: IMetaDataGroupEdges) => {
        return !edge.hidden && !edge.hideInFilterView && !CollectionFiltersHide.includes(edge.name.toLowerCase())
    }, []);

    const collectionListSelectFunc = useCallback((collection: { id: any; }) => {
         navigate({
            to: getPathBackToPage(
                `${Routes.HUB_PAGE}/${Routes.COLLECTIONS}/${collection.id}`,
                location.current.pathname,
                location.current.pathname != '/'),
            replace: true
        })
    }, [location, navigate]);

    const metadataFilterIds = useMemo(() => {
        let locationSearch = location.current.search?.filters
        if(locationSearch?.filterIds){
            return locationSearch.filterIds
        }
        return []
    }, [location, location.current.search]);

    return (
        <div className={'overview-wrapper'}>
            <Typography variant={'heading-2'}>{t(HUB.OVERVIEW)}</Typography>
            <section className={'collections-overview'}>
                <section className={'title-bar'}>
                    <div className={"title-div"}>
                        <Typography className={'playlist-title'} variant={'heading-2'}>{t(HUB.COLLECTIONS)}</Typography>
                        <Tooltip text={!areActionsForAllOpen? t(COLLECTIONS.OPEN_ACTIONS_MENU): t(COLLECTIONS.CLOSE_ACTIONS_MENU)}>
                            <span tabIndex={0} className={"icon-span"}>
                                {
                                    areActionsForAllOpen ?
                                        <IconCancelSmall
                                            className={"clickable"}
                                            onClick={handleMenuActionsClick}
                                        /> :
                                        <IconMoreSmall
                                            className={"rotate-90 clickable"}
                                            onClick={handleMenuActionsClick}
                                        />
                                }
                            </span>
                        </Tooltip>
                    </div>
                    <div className={"title-div-actions"}>
                        <FreeTextSearch/>
                        <MetadataGroupNestedSelect
                            itemType={FilterItemType.Playlist}
                            groupFilter={groupFilterFunc}
                            id={"overview-page"}
                            metadataFilterIds={metadataFilterIds}
                        />
                    </div>
                    {
                        areActionsForAllOpen?
                            <div className={"all-actions-div"}>
                                <Checkbox
                                    label={""}
                                    onChange={handleSelectAll}
                                    checked={isCheckboxSelected}
                                    id={"overview-select-all"}
                                    testId={"overview-select-all"}
                                />
                                <div>
                                    <Tooltip
                                        text={
                                            areAllRowsSelected ? t(COLLECTIONS.DELETE_SELECTED_COLLECTIONS) :
                                                t(COLLECTIONS.MUST_SELECT_COLLECTIONS)
                                        }
                                    >
                                    <span tabIndex={0} className={"tooltip-span-style"}>
                                        <IconDeleteSmall
                                            className={`clickable ${areAllRowsSelected? "" : "disabled"}`}
                                            onClick={handleDeleteClick}
                                            testId={"overview-actions-delete-icon"}
                                            id={"overview-actions-delete-icon"}
                                        />
                                    </span>
                                    </Tooltip>
                                    {
                                        isDeleteAllModalOpen?
                                            <CollectionDelete
                                                displayDeleteModal={isDeleteAllModalOpen}
                                                setDisplayDeleteModal={setIsDeleteAllModalOpen}
                                                setDisplaySelectOptions={setAreActionsForAllOpen}
                                                collectionIds={selectedRows}
                                                setSelectedRows={setSelectedRows}
                                            />
                                            : null
                                    }
                                </div>
                            </div> :
                            null
                    }
                </section>
                <section ref={collectionRef} className={'collection-wrapper'}>
                    { collectionCount ?
                        <CollectionList
                            playlistType={PlaylistType.Collection}
                            pageSize={collectionCount}
                            onCollectionSelect={collectionListSelectFunc}
                            blockLoading={true}
                            displayLimit={collectionCount}
                            areActionsForAllOpen={areActionsForAllOpen}
                            handleSelectRow={handleSelectRow}
                            selectAll={selectAll}
                            setSelectedRows={setSelectedRows}
                            setSelectAll={setSelectAll}
                            selectedRows={selectedRows}
                            setIsCheckboxSelected={setIsCheckboxSelected}
                            metadataFilterIds={metadataFilterIds}
                        /> :
                        <Spinner/>
                    }
                </section>
                <Typography className={'playlist-title no-display'} variant={'heading-2'}>{t(HUB.PRESENTATIONS)}</Typography>
                <section ref={presentationRef} className={'collection-wrapper no-display'}>
                    { presentationCount ?
                        <CollectionList playlistType={PlaylistType.Presentation}
                             pageSize={presentationCount}
                             onCollectionSelect={collection => navigate({
                                 to: getPathBackToPage(
                                     `${Routes.HUB_PAGE}/${Routes.PRESENTATIONS}/${collection.id}`,
                                     location.current.pathname,
                                     location.current.pathname != '/'),
                                 replace: true
                             })}
                             blockLoading={true}
                             displayLimit={presentationCount}
                            areActionsForAllOpen={areActionsForAllOpen}
                        /> :
                        <Spinner/>
                    }
                </section>
            </section>
        </div>
    )
};
