/* eslint-disable jsx-a11y/media-has-caption */
import {FC} from "react";
import Plyr from "@sbgsportssoftware/plyr";
import usePlayer from "./usePlayer";

import "./player.module.scss";

export interface PlayerProps {
    id: string;
    options?: Plyr.Options;
    source: Plyr.SourceInfo | Plyr.SourceInfo[] | Plyr.SourcePlaylist;
    previewThumbnails?: Plyr.PreviewThumbnailsOptions;
    loadedCallback?: () => void;
    endedCallback?: () => void;
    updateTimeCallback?: (currentTime: number) => void;
    onPlayCallback?: () => void;
    onPauseCallback?: () => void;
}

const Player: FC<PlayerProps> = ({ id, ...rest }) => {
    const {
        actions: { setCurrentMatchTime, setCurrentSource, setCurrentTime, setStartTime, setDuration}
    } = usePlayer({ id, ...rest });

    return <video autoPlay id={id} />;
};

export default Player;
