import {IProvidersResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {PROVIDERS} from "./providers.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

const useProviders = (pageSize: number = -1, pageNumber: number = 0, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data } = useQuery<IProvidersResponse>(PROVIDERS, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    });


    useEffect(() => {
        handleErrorRaised(error, PROVIDERS, ApiErrors.Providers, onError);
    }, [error, PROVIDERS]);

    return {
        state: {
            providers: data?.providers?.edges,
            providersPageSize: pageSize,
            providersLoading: loading
        }
    };
};

export default useProviders;