import {ApolloError, useQuery} from "@apollo/client";
import {PLAYLIST_METADATA} from "./metadata.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {PlaylistMetadata} from "./__types__/metadata";
import {ApiErrors} from "../../enums/apiErrors";
import {useEffect} from "react";

export const usePlaylistMetadata = (id: string, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data } = useQuery<PlaylistMetadata, {id: string}>(PLAYLIST_METADATA, {
        variables: {
            id: id
        }
    });

    useEffect(() => {
        handleErrorRaised(error, PLAYLIST_METADATA, ApiErrors.PlaylistMetadata, onError);
    }, [error, PLAYLIST_METADATA]);

    return {
        metadata: data?.playlist.metadata,
        metadataGroups: data?.playlist.metadataGroups,
        loading,
        error
    };
};
