import { gql } from "@apollo/client";

const LOGIN = gql`
    fragment LogInInput on REST {
        username: String
        password: String
        customer: String
    }

    mutation LogIn($input: LogInInput!) {
        logIn(input: $input) @rest(type: "auth", path: "/auth/login", method: "POST") {
            customers
            products
            exp
            customerId
            s3Bucket
            hubApiUrl
            mtApiUrl
            region
        }
    }
`;

export { LOGIN };
