import React from 'react';
import {Button, Input, Tabs, useAlerts} from "@catapultsports/referee-react";
import {IconMagnifier} from "@catapultsports/referee-react/icons";
import './licensingManagement.sass';
import {LICENSING} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import {LicenceUsersTable} from "../../../components/licensing/users/licensingUsersTable";
import {LicenseTable, LicenseAction} from "../../../components/licensing/licenses/licenseTable";

export const LicenseManagement: React.FC = () => {
    const {t} = useTranslation();
    const { showInfo } = useAlerts();

    return (
        <div className={'license-management'}>
            <div className={'tabs-container'}>
                <Tabs testId={'licensing-management-tabs'}>
                    <Tabs.Panel className={'licensing-tab'} header={t(LICENSING.ALL_LICENSES)}>
                        <div className={'topLicensing-container'}>
                            <Input
                                placeholder={t(LICENSING.FILTER)}
                                data-testid="filter-box"
                                onChange={(event) => {

                                }}
                                endAdornmentSlot={
                                    <IconMagnifier/>
                                }
                            />
                            <Button variant="outline">{t(LICENSING.EDIT_SUBSCRIPTIONS)}</Button>
                        </div>
                        <LicenseTable onAction={((licence, action) => {
                            // @ts-ignore
                            showInfo(t(LICENSING.LICENSE_ACTION, {licenceAction: LicenseAction[action].toString(), licenceId: licence['base.id']}));
                        })}  />
                    </Tabs.Panel>
                    <Tabs.Panel className={'users-tab'} header={t(LICENSING.ALL_USERS)}>
                        <div className={'topUsers-container'}>
                            <Button variant="outline">{t(LICENSING.CREATE_NEW_USER)}</Button>
                        </div>
                        <LicenceUsersTable />
                    </Tabs.Panel>
                </Tabs>
            </div>
        </div>
    );
};
