import {Filters} from "../utils/routerUtils";

export function useUpdateFilter({location, navigate}: { location: any, navigate: any }) {
    return (newFilters: Partial<Filters>) => {
        let searchParameters = location?.current?.search

        let filterObject = {
            ...searchParameters?.filters,
            ...newFilters
        }

        for (const key in filterObject) {
            let values = filterObject[key]
            if ((values.length === 0 || values === "" || values === false || values === ",")) {
                delete filterObject[key];
            }
        }

        navigate({
            search: () => {
                if (Object.keys(filterObject).length !== 0) {
                    return {
                        filters: filterObject
                    };
                }
            },
            replace: true,
        })
    };
}
