import { Variables } from '@catapultsports/referee-react';
import { Styles } from 'react-joyride';

export const JoyrideStyles: Styles = {
	tooltip: {
		width: '506px',
		borderRadius: '4px',
		padding: '24px',
		backgroundColor: Variables.css?.grey1
	},
	tooltipTitle: {
		textAlign: 'left'
	},
	tooltipContent: {
		paddingLeft: 0
	},
	tooltipContainer: {
		textAlign: 'left'
	},
	tooltipFooterSpacer: {
		flex: 'inherit'
	},
	buttonNext: {
		backgroundColor: Variables.css?.catapultBlack
	},
	options: {
		backgroundColor: '#31376F',
		arrowColor: '#31376F',
		textColor: Variables.css?.catapultWhite
	}
};

export const JoyrideIcons = {
	pencil: '<svg data-testid=\"content-icon\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"white\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_3724_53)\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.8774 0.212938L15.7865 3.12206C15.9229 3.25844 15.9995 3.4434 15.9995 3.63624C15.9995 3.82909 15.9229 4.01405 15.7865 4.15043L13.7552 6.18172L9.81775 2.24423L11.849 0.212938C11.9854 0.0765938 12.1704 0 12.3632 0C12.5561 0 12.741 0.0765938 12.8774 0.212938ZM1.6671 10.3952L8.78935 3.27295L12.7268 7.21044L5.60459 14.3327C5.52469 14.4124 5.42733 14.4724 5.32023 14.508L0.956548 15.9625C0.882511 15.9876 0.804883 16.0003 0.726728 16.0003C0.61155 16.0003 0.498041 15.9728 0.395543 15.9203C0.293045 15.8677 0.204491 15.7916 0.137169 15.6982C0.0698483 15.6047 0.0256855 15.4966 0.00831692 15.3827C-0.00905171 15.2689 0.000870472 15.1525 0.0372666 15.0432L1.49183 10.6796C1.5274 10.5725 1.5874 10.4751 1.6671 10.3952Z\" fill=\"white\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_3724_53\">\n<rect width=\"16\" height=\"16\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>',
	help: '<svg data-testid=\"content-icon\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"white\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 11.5C9 11.7761 8.77614 12 8.5 12H7.5C7.22386 12 7 11.7761 7 11.5V7.5C7 7.22386 7.22386 7 7.5 7H8.5C8.77614 7 9 7.22386 9 7.5V11.5ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5C9 5.6 8.6 6 8 6Z\" fill=\"white\"/>\n</svg>'
}
