export enum ApiErrors {
    Installers= "Could not retrieve installers",
    Unexpected= "An unexpected error occurred",
    Qualifiers = "Could not retrieve qualifiers",
    Fixtures= "Could not retrieve fixtures",
    CompetitionSeasons= "Could not retrieve competition season data",
    Providers= "Could not retrieve providers",
    Players= "Could not retrieve players",
    Competitions= "Could not retrieve competitions",
    OfficialMatchInvolvements= "Could not retrieve OfficialMatchInvolvements",
    UseFixtureMedia= "Could not retrieve fixture media",
    Teams= "Could not retrieve teams",
    MatchEvents= "Could not retrieve match events",
    PhaseTypes= "Could not retrieve phase types",
    CommentsError= "Could not retrieve comments",
    VenueError= "Could not retrieve venues",
    MetadataGroups= "Could not retrieve filter data",
    Memberships= "Could not retrieve memberships",
    UpdateMembership= "Could not update membership",
    LicensesStatus= "Could not retrieve licenses status",
    TagError = "Could not retrieve tag",
    ClipComments= "Could not retrieve clip comments",
    PlaylistComments= "Could not retrieve playlist comments",
    TagMetadata= "Could not retrieve tag metadata",
    PlaylistMetadata= "Could not retrieve playlist metadata",
    Sessions= "Could not retrieve sessions",
    TagData= "Could not retrieve tag data",
    UserForCustomer= "Could not retrieve users for the customer",
    Seasons= "Could not retrieve seasons",
    CreatePlaylist = "Could not create the playlist",
    CreateMetadata = "Could not update the labels",
    UpdateCollection = "Could not update the collection",
}