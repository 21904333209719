import {MutableRefObject, useCallback, useEffect, useMemo} from "react";
import { isDesktop } from "react-device-detect";
import Plyr from "@sbgsportssoftware/plyr";
import { isEqual } from "lodash";
import * as constants from "./player.constants";
import { usePlayerContext } from "./usePlayerContext";
import {useMixPanel} from "../../providers/Mixpanel";

export interface UsePlayer {
    state: {
        options: Plyr.Options;
    };
    actions: {
        setCurrentTime: (time: number) => void;
        setDuration: (time: number) => void;
        setCurrentMatchTime: (time: string) => void;
        setCurrentSource: (source: Plyr.SourceInfo | Plyr.SourceInfo[] | null) => void;
        setStartTime: (time: number) => void;
    };
    ref: MutableRefObject<Plyr | undefined>;
}

const usePlayer = ({
    id,
    source,
    options,
    previewThumbnails,
    loadedCallback,
    endedCallback,
    updateTimeCallback,
    onPlayCallback,
    onPauseCallback
}: {
    id: string;
    options?: Plyr.Options;
    source: Plyr.SourceInfo | Plyr.SourceInfo[] | Plyr.SourcePlaylist;
    previewThumbnails?: Plyr.PreviewThumbnailsOptions;
    loadedCallback?: () => void;
    endedCallback?: () => void;
    updateTimeCallback?: (currentTime: number) => void;
    onPlayCallback?: () => void;
    onPauseCallback?: () => void;
}): UsePlayer => {
    const {
        playerRef,
        setCurrentTime,
        setCurrentMatchTime,
        setDuration,
        setFullscreen,
        setLoaded,
        setEnded,
        setCurrentSource,
        setStartTime
    } = usePlayerContext();

    const playerOptions: Plyr.Options = useMemo(
        () => ({
            controls: isDesktop ? constants.CONTROLS : constants.LIMITED_CONTROLS,
            ...constants.options,
            ...options,
            ...(previewThumbnails && { ...previewThumbnails })
        }),
        [options, previewThumbnails]
    );


    const previousId = useMemo(() => {id}, [id]);
    const previousSource = useMemo(() => {source}, [source]);
    const previousPlayerOptions = useMemo(() => {playerOptions}, [playerOptions]);

    const mixpanel = useMixPanel();

    const createPlayer = useCallback(() => {
        playerRef.current = new Plyr(`#${id}`, playerOptions);
        // @ts-ignore
        playerRef.current.source = source;

        playerRef.current.on("timeupdate", ({ detail }: any) => {
            const {
                plyr: { currentTime }
            } = detail;

            setCurrentTime(currentTime);
            setCurrentMatchTime(playerRef.current?.currentMatchTime || "");

            if(updateTimeCallback)
                updateTimeCallback(currentTime);
        });

        playerRef.current.on("seeking", ({ detail }: any) => {
            const {
                plyr: { currentTime }
            } = detail;

            setCurrentTime(currentTime);
            setCurrentMatchTime(playerRef.current?.currentMatchTime || "");
        });

        playerRef.current.on("seeked", ({ detail }: any) => {
            const {
                plyr: { currentTime }
            } = detail;

            setCurrentTime(currentTime);
            setCurrentMatchTime(playerRef.current?.currentMatchTime || "");
        });

        playerRef.current.on("durationchange", ({ detail }: any) => {
            const {
                plyr: { duration }
            } = detail;

            setDuration(duration);
        });

        playerRef.current.on("mediachange", ({ detail }: any) => {
            const {
                plyr: { source: currentSource }
            } = detail;

            mixpanel.track('Hub-Portal: Video Source Changed', {VideoSource: currentSource})
            setCurrentSource(currentSource);
        });

        playerRef.current.on("enterfullscreen", () => {
            setFullscreen(true);
        });

        playerRef.current.on("exitfullscreen", () => {
            setFullscreen(false);
        });

        playerRef.current.on("destroyed", () => {
            setEnded(false);
        });

        playerRef.current.on("play", () => {
            if (onPlayCallback) {
                onPlayCallback();
            }
        });

        playerRef.current.on("pause", () => {
            if (onPauseCallback) {
                onPauseCallback();
            }
        });

        playerRef.current.on("ended", ({ detail }: any) => {
            setEnded(true);

            const {
                plyr: { source: currentSource }
            } = detail;

            mixpanel.track('Hub-Portal: Video Finished', {VideoSource: currentSource})
            if(endedCallback)
                endedCallback();
        });

        playerRef.current.on("ready", ({ detail }: any) => {
            setLoaded(true);

            const {
                plyr: { source: currentSource }
            } = detail;

            mixpanel.track('Hub-Portal: Video Ready To Play', {VideoSource: currentSource});

            if(loadedCallback)
                loadedCallback();
        });

    }, [
        id,
        source,
        playerOptions,
        playerRef,
        setLoaded,
        setCurrentTime,
        setCurrentMatchTime,
        setCurrentSource,
        setDuration,
        setFullscreen,
        setEnded,
        setStartTime
    ]);

    const destroyPlayer = useCallback(() => {
        setLoaded(false);
        playerRef.current?.destroy();
    }, [playerRef, setLoaded]);

    useEffect(() => {
        if (
            isEqual(previousId, id) &&
            isEqual(previousSource, source) &&
            isEqual(previousPlayerOptions, playerOptions)
        ) {
            return () => playerRef.current?.destroy();
        }
        destroyPlayer();
        createPlayer();
        return () => playerRef.current?.destroy();
    }, [
        previousId,
        id,
        previousSource,
        source,
        previousPlayerOptions,
        playerOptions,
        playerRef,
        createPlayer,
        destroyPlayer
    ]);

    return {
        ref: playerRef,
        state: { options: playerOptions },
        actions: {
            setCurrentTime,
            setDuration,
            setCurrentMatchTime,
            setCurrentSource,
            setStartTime
        }
    };
};

export default usePlayer;
