import React from "react";
import "../../screens/licensing/subPages/licensingHome.sass"
import {Button, Pill, Typography} from "@catapultsports/referee-react";
import {useTranslation} from 'react-i18next';
import {LICENSING} from '../../services/i18n/i18n-constants';
import { Routes } from "../../enums/Routes";
import {useLocation} from "@tanstack/react-location";
import {getPathBackToPage, usePortalNavigation} from "../../utils/routerUtils";
export interface ComponentProps {
    pendingLicences?: number;
}

export const PendingLicencesComponent: React.FC<ComponentProps> = (props) => {
    const { pendingLicences } = props
    const { t } = useTranslation();

    const location = useLocation();
    const {navigate} = usePortalNavigation();
    // @ts-ignore
    return (
        <div>
            <Typography variant={'heading-2'} testId={"pending-licenses-heading"} id={"pending-licenses-heading"}>{t(LICENSING.LICENSES_PENDING)}</Typography>
            {pendingLicences && pendingLicences > 0 && (
                <div>
                    <Typography testId={"pending-licenses-text-1"} id={"pending-licenses-text-1"}>
                        {t(LICENSING.PENDING_LICENSES_1, {pendingLicenses: pendingLicences})}
                        <Pill
                            shape="square"
                            value={t(LICENSING.REQUESTED)}
                            variant="information"
                            testId={"pending-licenses"}
                        />
                        {t(LICENSING.PENDING_LICENSES_2)}
                    </Typography>
                    <Button variant={'outline'} testId={"pending-licenses-review-button"} id={"pending-licenses-review-button"}
                            onClick={() => navigate({
                        to: getPathBackToPage(
                            `${Routes.LICENSING_PAGE}/${Routes.MANAGEMENT}`,
                            location.current.pathname,
                            location.current.pathname != '/'), replace: true
                    })}>
                        {t(LICENSING.REVIEW_LICENSES)}
                    </Button>
                </div>
            )}
        </div>
    )
};
