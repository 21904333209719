import {ApolloError, useQuery} from "@apollo/client";
import {ILicensingUsersPage} from "../__types__/licensingTypes";
import {USERSPAGE} from "./getUsersPage.gql";
import {ApiPageSize} from "../../enums/apiMagic";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {MEMBERSHIPS} from "../Licensing/LicensingMemberships/memberships.gql";
import {ApiErrors} from "../../enums/apiErrors";

export const useGetUsersPage = (email?: undefined, userIds?: undefined, customerIDs?: string[] | undefined, pageSize?: number, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data, fetchMore } = useQuery<ILicensingUsersPage>(USERSPAGE, {
        variables: {
            userFilter: {
                email: email,
                userIDs: userIds,
                customerIDs: customerIDs ?? []
            },
            pageNumber: 0,
            pageSize: pageSize?? ApiPageSize.Ten
        }
    });

    const loadNextPage = () => {
        if (data?.users.pageInfo?.pageNumber === undefined) return;
        fetchMore({
            variables: {
                pageNumber: data?.users.pageInfo?.pageNumber + 1,
                pageSize: pageSize ?? ApiPageSize.Ten
            }
        });
    };

    useEffect(() => {
        handleErrorRaised(error, USERSPAGE, ApiErrors.UserForCustomer, onError);
    }, [error, onError]);

    return {
        state: {
            usersHasNextPage: !!data?.users.pageInfo.hasNextPage,
            usersForCustomer: data?.users.edges,
            usersPageError: error,
            usersPageLoading: loading,
        },
        operations: {
            loadNextPage
        }
    };
};
