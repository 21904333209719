import React, {useMemo} from "react";
import "./licensingHome.sass";
import {InstallersComponent} from "../../../components/installers/component";
import {PendingLicencesComponent} from "../../../components/pendingLicenses/component";
import {
    LicenseUsageComponent,
    MembershipActions
} from "../../../components/licenseUsage/component";
import useLicensingInstallers from "../../../api/Customer/Licensing/LicensingInstallers/useLicensingInstallers"
import useMemberships
    from "../../../api/Customer/Licensing/LicensingMemberships/useMemberships";
import useLicenseStatus
    from "../../../api/Customer/Licensing/licenses/status/useLicenseStatus";
import {membershipStatusFilter} from "../../../api/enums/membershipStatusFilter";
import {ApiPageSize} from "../../../api/enums/apiMagic";
import {useAlerts} from "@catapultsports/referee-react";
import {GENERAL, LICENSING} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import useUpdateMemberships from "../../../api/Customer/Licensing/LicensingMemberships/useUpdateMemberships";
import {useMixPanel} from "../../../providers/Mixpanel";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {shallow} from "zustand/shallow";
import {ILicensingCustomer} from "../../../api/Customer/__types__/licensingTypes";
import {useSessionStore} from "../../../state/SessionState";
import {defineToastText} from "../../../utils/licenseUsageCommonFunctions";

export const LicensingHome: React.FC = () => {
    const { showError, showInfo } = useAlerts();


    const {currentCustomer} = useSessionStore<{currentCustomer: ILicensingCustomer}>(state => ({currentCustomer: state.currentCustomer}), shallow);

    const customerIDsFilter = useMemo(
        () => {
            if (currentCustomer) {
                const id = currentCustomer['customerId']
                return id ? [id] : []
            }
        },
        [currentCustomer]
    )

    const { t } = useTranslation();
    const mixpanel = useMixPanel();
    const {
        state: { userInstallerList },
    } = useLicensingInstallers(() => showError(t(LICENSING.INSTALLERS_ERROR)));

    const {
        state: { memberships, membershipsLoading, membershipsHasNextPage },
        operations: { loadNextPage }
    } = useMemberships(customerIDsFilter, undefined, () => showError(t(LICENSING.MEMBERSHIPS_ERROR)));

    const { updateAutoApprove } = useUpdateMemberships(
        (data) => {
            showInfo(t(LICENSING.AUTO_APPROVE_UPDATED, {membershipId: data.updateMembership.base.id, value: data.updateMembership.autoApprove ? t(GENERAL.ENABLED) : t(GENERAL.DISABLED)}));
        },
        () => {
            showError(t(LICENSING.AUTO_APPROVE_UPDATE_ERROR));
        });

    const {
        state: { pendingLicenses },
    } = useLicenseStatus(membershipStatusFilter.Requested, ApiPageSize.All, () => showError(t(LICENSING.LICENSE_STATUS_ERROR)));


    return (
        <article className={"licensing-home"} data-testid={"licensing-home"}>
            <section className={"body"}>
                <InstallersComponent installers={userInstallerList}/>
                <PendingLicencesComponent pendingLicences={pendingLicenses} />
            </section>
            <section className={"footer"}>
                <LicenseUsageComponent memberships={memberships} membershipsLoading={membershipsLoading} membershipsHasNextPage={membershipsHasNextPage} loadNextPage={loadNextPage} onAction={
                    ((membership, action) => {
                        if (action === MembershipActions.AutoApprove) {
                            mixpanel.track(MixPanelEvents.MEMBERSHIP_AUTO_APPROVE, { membershipId: membership['base.id'], autoApprove: membership["autoApprove"] });
                            updateAutoApprove(membership['base.id'], membership["autoApprove"]);
                        }
                        else {
                            // @ts-ignore
                            showInfo(defineToastText(MembershipActions[action], membership['base.id'], membership["autoApprove"], t(LICENSING.AUTO_APPROVE_STATUS, {membershipAction: membershipAction, membershipId: membershipID}), t(LICENSING.AUTO_APPROVE_ENABLE, {mainText: mainText}), t(LICENSING.AUTO_APPROVE_DISABLE, {mainText: mainText})));
                        }
                    })} displayHeader={true}/>
            </section>
        </article>
    );
};