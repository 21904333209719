import {HttpLink, InMemoryCache, makeVar} from "@apollo/client";
import { REFRESH_TOKEN } from "../Util/auth/refreshToken.rest.gql";
import {
  createClientLinks,
  createErrorLink,
  createApolloClient, createApolloLinks,
} from "../commonApolloClientFunctions";
import {LICENSING} from "../../services/i18n/i18n-constants";


const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        redirect: {
          read() {
            return makeVar({ value: false });
          },
        },
        tags: {
          keyArgs: ["filter"],
          merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges],
            };
          },
        },
        playlists: {
          keyArgs: ["filter", "sort", "playlistType", "pageSize"],
          merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges],
            };
          },
        },
      },
    },
    Tag: {
      keyFields: ["id"],
      fields:{
        notes: {
            keyArgs: ["id"],
            merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges],
            };
          },
        },
          comments: {
              keyArgs: ["id"],
              merge(existing = { edges: [] }, incoming = { edges: [] }) {
                  if (!incoming.edges) return existing;
                  return {
                      ...incoming,
                      edges: [...existing?.edges, ...incoming?.edges],
                  };
              },
          }
      }
    },
    Playlist: {
      keyFields: ["id"]
    },
      UserComment: {
          keyFields: ["id"]
      },
    MetadataGroup: {
        keyFields: ["id"],
        fields: {
            metadata: {
              keyArgs: ["filter", "sort", "pageSize"],
              merge(existing = { edges: [] }, incoming = { edges: [] }) {
                if (!incoming.edges) return existing;
                return {
                  ...incoming,
                  edges: [...existing?.edges, ...incoming?.edges],
                };
              },
            }
        }
    }
  },
});

const restLink = createClientLinks(
    LICENSING.RESET_LINK,
  "",
  process.env.HUB_API_URL_REST
);

const refreshToken = () => {
  return client.mutate({ mutation: REFRESH_TOKEN, variables: { input: {} } });
};

const errorLink = createErrorLink(refreshToken);

const client = createApolloClient(errorLink, cache, restLink, true );

const updateLink = (newLink: HttpLink) => {
  client.setLink(
      createApolloLinks(
          errorLink,
          restLink,
          true,
          null,
          newLink));
};

export { client, updateLink };
