import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {ICollectionTile} from "../../../api/Hub/collections/__types__/Collections";
import {FilterItemType, PlaylistType, SortBy} from "../../../api/Hub/enum/Enum";
import {
    Button, Checkbox, GroupedSelectOptions, noop,
    SelectOption,
    SelectOptions, Tooltip,
    Typography,
    usePositioner
} from "@catapultsports/referee-react";
import {
    IconCancelSmall, IconDeleteSmall,
    IconFilter,
    IconMoreSmall
} from "@catapultsports/referee-react/icons";
import {useMixPanel} from "../../../providers/Mixpanel";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import './collections.sass';
import {useTranslation} from "react-i18next";
import {COLLECTIONS, HUB} from "../../../services/i18n/i18n-constants";
import {CollectionList} from "../../../components/collectionList/collectionList";
import {IMetaDataGroupEdges} from "../../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {CollectionFiltersHide, CollectionTopLevelFilters} from "./filterConstants";
import {FreeTextSearch} from "../../../components/freeTextSearch/freeTextSearch";
import {
    MetadataGroupNestedSelect
} from "../../../components/metadataGroupNestedSelect/MetadataGroupNestedSelect";
import {CollectionDelete} from "../../../components/collection/collectionDelete";
import {useLocation} from "@tanstack/react-location";

interface CollectionScreenProps {
    playlistType: PlaylistType
    headerMargin?: string
    pageSize?: number
    allowSort?: boolean
    onCollectionSelect?: (collection: ICollectionTile) => void,
    blockLoading?: boolean,
    displayLimit?: number
}

export const CollectionsScreen: React.FC<CollectionScreenProps> = (props) => {
    const dropdownRef = useRef(null) as unknown as MutableRefObject<HTMLElement>;
    const { getPopperProps, getReferenceProps } = usePositioner({ placement: 'bottom-end' });
    const [ sortOpen, setSortOpen ] = useState(false);
    const setMetadataFilters = useSessionStore(state => state.setCollectionsMetadataIds, shallow);
    const sortBy = useSessionStore(state => state.collections.sortBy, shallow);
    const setSortBy = useSessionStore(state => state.setCollectionsSortBy, shallow);
    const mixpanel = useMixPanel();
    const { t } = useTranslation();
    const location = useLocation();

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [areActionsForAllOpen, setAreActionsForAllOpen] = useState(false);
    const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState<boolean>(false);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);

    useEffect(() => {
        setMetadataFilters([]);
        setSortBy(SortBy.CreationTimestamp);
    }, [props.playlistType]);

    const groupFilterFunc = useCallback((edge: IMetaDataGroupEdges) => {
        return !edge.hidden && !edge.hideInFilterView && !CollectionFiltersHide.includes(edge.name.toLowerCase())
    }, []);

    const onSelectItemFunc = useCallback((selectedValue: SelectOption | GroupedSelectOptions) => {
        if ("value" in selectedValue && selectedValue.value === 'Modified Date') {
            setSortBy(SortBy.ModifiedTimestamp)
        } else {
            setSortBy(SortBy.CreationTimestamp)
        }

        if ("value" in selectedValue) {
            mixpanel.track(props.playlistType === PlaylistType.Collection ? MixPanelEvents.COLLECTION_SORT : MixPanelEvents.PRESENTATION_SORT, {sortBy: selectedValue.value})
        }
    }, [mixpanel, props.playlistType, setSortBy]);

    const handleSelectAll = useCallback(() => {
        setSelectAll(!selectAll);
    }, [selectAll]);


    const handleSelectRow = useCallback((id: string) => {
        const index = selectedRows.indexOf(id);
        if (index > -1) {
            setSelectedRows(selectedRows.filter(row => row !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    }, [selectedRows]);

    const areAllRowsSelected = useMemo(() => {
        return selectedRows.length > 0
    }, [selectedRows]);

    const handleDeleteClick = useCallback(() => {
        if(areAllRowsSelected){
            setIsDeleteAllModalOpen(!isDeleteAllModalOpen)
        }else {
            noop()
        }
    }, [areAllRowsSelected, isDeleteAllModalOpen]);

    const handleMenuActionsClick = useCallback(() => {
        setAreActionsForAllOpen(!areActionsForAllOpen);
        if(!areActionsForAllOpen) {
            setSelectedRows([])
        }
    }, [areActionsForAllOpen]);

    const metadataFilterIds = useMemo(() => {
        let locationSearch = location.current.search?.filters
        if(locationSearch?.filterIds){
            return locationSearch.filterIds
        }
        return []
    }, [location, location.current.search]);

    return (
        <section className={'collections-screen'} data-testid={"collections-screen"}>
            <nav className={'collections-nav'}>
                <div className={"title-div-actions"}>
                    <Typography variant={'heading-2'}>{props.playlistType === PlaylistType.Collection ? t(HUB.COLLECTIONS) : t(HUB.PRESENTATIONS)}</Typography>
                    <Tooltip text={!areActionsForAllOpen? t(COLLECTIONS.OPEN_ACTIONS_MENU): t(COLLECTIONS.CLOSE_ACTIONS_MENU)}>
                        <span tabIndex={0} className={"icon-span"}>
                            {
                                areActionsForAllOpen ?
                                    <IconCancelSmall
                                        className={"clickable"}
                                        onClick={handleMenuActionsClick}
                                    /> :
                                    <IconMoreSmall
                                        className={"rotate-90 clickable"}
                                        onClick={handleMenuActionsClick}
                                    />
                            }
                        </span>
                    </Tooltip>
                </div>
                <section className={'collections-screen-title-bar'}>
                    <div className={"collections-screen-title-bar-actions"}>
                        <FreeTextSearch />
                        <MetadataGroupNestedSelect
                            itemType={FilterItemType.Playlist}
                            groupFilter={groupFilterFunc}
                            id={"collections-page"}
                            metadataFilterIds={metadataFilterIds}
                        />
                    </div>
                    {(props.allowSort ?? true) && (
                        <Button className={'sort-button'} variant={'outline'} {...getReferenceProps()} onClick={() => setSortOpen(!sortOpen)}>
                            <IconFilter /> {t(HUB.SORT_BY)}
                        </Button>
                    )}
                </section>
                {
                    areActionsForAllOpen?
                        <div className={"collections-page-all-actions-div"}>
                            <Checkbox
                                label={""}
                                onChange={handleSelectAll}
                                checked={isCheckboxSelected}
                                id={"overview-select-all"}
                                testId={"overview-select-all"}
                            />
                            <div>
                                <Tooltip
                                    text={
                                        areAllRowsSelected ? t(COLLECTIONS.DELETE_SELECTED_COLLECTIONS) :
                                            t(COLLECTIONS.MUST_SELECT_COLLECTIONS)
                                    }
                                >
                                    <span tabIndex={0} className={"tooltip-span-style"}>
                                        <IconDeleteSmall
                                            className={`clickable ${areAllRowsSelected? "" : "disabled"}`}
                                            onClick={handleDeleteClick}
                                            testId={"overview-actions-delete-icon"}
                                            id={"overview-actions-delete-icon"}
                                        />
                                    </span>
                                </Tooltip>
                                {
                                    isDeleteAllModalOpen?
                                        <CollectionDelete
                                            displayDeleteModal={isDeleteAllModalOpen}
                                            setDisplayDeleteModal={setIsDeleteAllModalOpen}
                                            setDisplaySelectOptions={setAreActionsForAllOpen}
                                            collectionIds={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                        />
                                        : null
                                }
                            </div>
                        </div> :
                        null
                }
            </nav>
            <section className={'screen-content'}>
                <CollectionList
                    playlistType={props.playlistType}
                    pageSize={props.pageSize}
                    areActionsForAllOpen={areActionsForAllOpen}
                    handleSelectRow={handleSelectRow}
                    selectAll={selectAll}
                    setSelectedRows={setSelectedRows}
                    setSelectAll={setSelectAll}
                    selectedRows={selectedRows}
                    setIsCheckboxSelected={setIsCheckboxSelected}
                    metadataFilterIds={metadataFilterIds}
                />
            </section>
            <SelectOptions
                popperProps={getPopperProps}
                parentRef={dropdownRef}
                setIsVisible={setSortOpen}
                isVisible={sortOpen}
                onSelectItem={onSelectItemFunc}
                menuItems={[
                    {
                        id: 0,
                        isSelected: sortBy === SortBy.ModifiedTimestamp,
                        value: 'Modified Date',
                    },
                    {
                        id: 1,
                        isSelected: sortBy === SortBy.CreationTimestamp,
                        value: 'Created Date'
                    }
                ]}
            />
        </section>
    )
}