import {ApolloError, DocumentNode} from "@apollo/client";
import * as Sentry from "@sentry/react";
import {ApolloErrorDetail} from "./apolloUtils";

export const logApiError =
    (error: ApolloErrorDetail | string, apolloError: ApolloError, query: { loc: { source: unknown; }; } | DocumentNode | undefined) => {
    Sentry.configureScope((scope) => scope.clear());

    if (typeof error === 'string') {
        // It's a string error
        Sentry.setExtra('userErrorMessage', error as string)
    }
    else {
        // It's an error detail object
        const errorDetail = error as ApolloErrorDetail;
        Sentry.setExtra('response', errorDetail.parsedError)
        Sentry.setExtra('stringOfErrors', errorDetail.stringOfErrors)
        Sentry.setExtra('userErrorMessage', errorDetail.errorMessage)
    }

    if(query !== undefined){
        Sentry.setExtra("query", query?.loc?.source)
    }


    Sentry.captureException(error, {level: "error"})
}