import { ApolloError, useQuery } from "@apollo/client";
import {TAGS} from "./tags.gql";
import {SortType} from "../__types__/globalTypes";
import {ITagItem, ITagResponse, TagsVariables} from "./__types__/Tags";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";
import {useEffect} from "react";
import {generateFreeTextSearchItems} from "../utils/stringUtils";

export interface UseTags {
    state: {
        tagsHasNextPage: boolean;
        tagsLoading: boolean;
        tagsPageSize: number;
        tagsError: ApolloError | undefined;
        tags: ITagItem[] | null | undefined;
        tagsMetadataIds: string[]
    };
    operations: {
        loadNextPage: () => void;
    };
}

const useTags = (
    pageSize: number,
    metadataIds: string[],
    sessionIds: string[],
    onError?: (error: ApolloError, errorMessage: string) => void,
    includeAssets = false,
    includeMetadata = false,
    freeTextSearch?: string | undefined
): UseTags => {
    const { loading, error, data, fetchMore } = useQuery<ITagResponse, TagsVariables>(TAGS, {
        variables: {
            pageNumber: 0,
            pageSize,
            sort: { modifiedTimestamp: SortType.DESC },
            filter: {
                name: freeTextSearch ? generateFreeTextSearchItems(freeTextSearch) : [],
                metadataIds: metadataIds,
                sessionIds: sessionIds,
            },
            includeAssets,
            includeMetadata
        }
    });

    const loadNextPage = () => {
        if (data?.tags?.pageInfo?.pageNumber === undefined) return;
        fetchMore({
            variables: {
                pageNumber: data?.tags?.pageInfo?.pageNumber + 1,
                pageSize
            }
        });
    };

    useEffect(() => {
        handleErrorRaised(error, TAGS, ApiErrors.TagData, onError);
    }, [error, TAGS]);

    return {
        state: {
            tagsHasNextPage: !!data?.tags?.pageInfo.hasNextPage,
            tags: data?.tags?.edges,
            tagsPageSize: pageSize,
            tagsLoading: loading,
            tagsError: error,
            tagsMetadataIds: metadataIds
        },
        operations: { loadNextPage }
    };
};

export default useTags;
