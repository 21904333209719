import {IVenuesResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {VENUES} from "./venues.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

const useVenues = (pageSize: number = -1, pageNumber: number = 0, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data } = useQuery<IVenuesResponse>(VENUES, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    });


    useEffect(() => {
        handleErrorRaised(error, VENUES, ApiErrors.VenueError, onError);
    }, [error, VENUES]);

    return {
        state: {
            venues: data?.venues?.edges,
            venuesPageSize: pageSize,
            venuesLoading: loading
        }
    };
};

export default useVenues;