import {ApolloError, useQuery} from "@apollo/client";
import {ILicenseStatusResponse} from "../../../__types__/Licenses";
import {handleErrorRaised} from "../../../../../utils/apolloUtils";
import {LICENSES} from "../licenses.gql";
import {ApiPageSize} from "../../../../enums/apiMagic";
import {useEffect} from "react";
import {ApiErrors} from "../../../../enums/apiErrors";

const useLicenseStatus = (statusFilter?: string, pageSize?: number, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const {error, data} = useQuery<ILicenseStatusResponse>(LICENSES, {
        variables: {
            pageSize: pageSize ?? ApiPageSize.All,
            filter: { status: statusFilter || ""}
        }
    });


    useEffect(() => {
        handleErrorRaised(error, LICENSES, ApiErrors.LicensesStatus, onError);
    }, [error, LICENSES]);

    return {
        state: {
            pendingLicenses: data?.licenses?.edges.length
        }
    }
}

export default useLicenseStatus;