import { gql } from "@apollo/client";

const LOGOUT = gql`
    mutation LogOut($input: Object!) {
        logOut(input: $input) @rest(type: "auth", path: "/auth/logout", method: "POST") {
            message
        }
    }
`;

export { LOGOUT };
