import * as React from 'react';
import {IconCancel} from "@catapultsports/referee-react/icons";
import {useTheme, Variables} from "@catapultsports/referee-react";
import './pill.sass'

interface PillCommonProps extends React.HTMLAttributes<HTMLElement> {
    variant?:
        | 'neutral'
        | 'alert'
        | 'warning'
        | 'success'
        | 'information'
        | 'onboarding'
        | 'clearable'
        | 'alertText'
        | 'warningText'
        | 'successText'
        | 'informationText';
    shape?: 'round' | 'square';
    size?: 'small' | 'medium';
    value: React.ReactElement | string;
    withIcon?: boolean;
    startIcon?: React.ReactElement;
    testId?: string;
}

interface PillNonClearable extends PillCommonProps {
    onClick?: never;
    onClose?: never;
    disabled?: never;
}

interface PillClearable extends PillCommonProps {
    onClick?: () => void;
    onClose?: () => void;
    disabled?: boolean;
}

export type PillProps = PillNonClearable | PillClearable;

export const Pill = React.forwardRef<HTMLDivElement | HTMLButtonElement, PillProps>((props, ref) => {
    const {
        variant = 'information',
        shape = 'round',
        size = 'medium',
        testId,
        value,
        disabled,
        withIcon,
        startIcon,
        onClick,
        onClose,
        ...passThroughProps
    } = props;

    const theme = useTheme();
    const isDark = theme === 'dark' || ['warning', 'neutral', 'clearable'].includes(variant) ? 'dark' : null;
    const fontType = shape === 'round' ? 'label-2' : 'label-1';
    const hasAdornmentIcon = withIcon || !!startIcon;

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (onClose) {
            onClose();
        }
    };

    return (
        <div
            {...passThroughProps}
            data-testid={testId}
            ref={ref as React.ForwardedRef<HTMLDivElement>}
            className={'primary-pill'}
        >
            {startIcon && (startIcon)}
            {value}
            {onClose && !disabled ? (
                <div
                    className={'pill-close-button'}
                    onClick={handleClose}
                >
                    <IconCancel
                        className={'pill-close-icon'}
                        data-testid="pill-close"
                        color={isDark ? Variables.catapultBlack : Variables.catapultWhite}
                        onClick={handleClose}
                    />
                </div>
            ) : null}
        </div>
    );
});

Pill.displayName = 'Pill';
