export const RandomNumber = (max?: number) => max && max > 0 ? Math.floor(Math.random() * max) : Math.floor(Math.random() * 100);

export const secondsToHms = (d: number) => {
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : "00:";
    const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m + ':' : "00:";
    const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : "00";
    return hDisplay + mDisplay + sDisplay;
}

export const secondsToMmss = (d: number, useLeadingZero: boolean = true) => {
    const minutes = Math.floor(d / 60);
    const remainingSeconds = d % 60;

    const formattedMinutes = minutes < 10 && useLeadingZero ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
}