import {ApolloError, useQuery} from "@apollo/client";
import {ClipResponse, UseItemComments} from "./__types__/Comments";
import {CLIP_COMMENTS} from "./comments.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

const useClipComments = (id: string, onError?: (error: ApolloError, errorMessage: string) => void): UseItemComments => {
    const { loading, error, data } = useQuery<ClipResponse, {tagId: string}>(CLIP_COMMENTS, {
        variables: {
            tagId: id
        }
    });

    useEffect(() => {
        handleErrorRaised(error, CLIP_COMMENTS, ApiErrors.ClipComments, onError);
    }, [error, CLIP_COMMENTS]);

    return {
        value: data?.tag,
        loading,
        error
    };
};

export default useClipComments;
