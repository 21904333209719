import {useEffect} from "react";
import {IPhaseTypesResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {PHASE_TYPES} from "./phaseTypes.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

const usePhaseTypes = (pageSize: number = -1, pageNumber: number = 0, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data } = useQuery<IPhaseTypesResponse>(PHASE_TYPES, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    });

    useEffect(() => {
        handleErrorRaised(error, PHASE_TYPES, ApiErrors.PhaseTypes, onError);
    }, [error, PHASE_TYPES]);

    return {
        state: {
            phaseTypes: data?.phaseTypes?.edges,
            phaseTypesPageSize: pageSize,
            phaseTypesLoading: loading
        }
    };
};

export default usePhaseTypes;