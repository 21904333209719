import {gql} from "@apollo/client";

export const SEASONS = gql`
    query seasons($filter: SeasonFilter, $pageSize: Int, $pageNumber: Int), {
        seasons(filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
            edges{
                base {
                    id
                }
                startYear
                endYear
                isCurrentSeason
                isPreviousSeason
            }
        }
    }
`;