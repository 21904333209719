import React, {useCallback, useEffect, useState} from "react";
import './playlist.sass';
import {Outlet, useMatch} from "@tanstack/react-location";
import {PlaylistType} from "../../../api/Hub/enum/Enum";
import {PlaylistControls} from "../../../components/playlistControls/playlistControls";
import {Spinner} from "@catapultsports/referee-react";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";

interface PlaylistScreenProps {
    playlistType?: PlaylistType
}

export const PlaylistScreen: React.FC<PlaylistScreenProps> = (props) => {
    const {
        data: { playlistPathId },
    } = useMatch<any>();

    const [controlsHaveLoaded, setControlsHaveLoaded] = useState(false);
    const updateControlsHaveLoaded = useCallback(() => setControlsHaveLoaded(true), []);
    const setVideoAngle = useSessionStore(state => state.setSelectedHubVideoAngle, shallow);

    useEffect(() => {
        setVideoAngle(undefined);
    }, []);

    return (
        <article className={'playlist'}>
            {!controlsHaveLoaded &&
                <section className={'playlistControls-loading'}>
                    <Spinner />
                </section>
            }
                <section className='clips-container'>
                    <PlaylistControls playlistType={props.playlistType} hasLoadedCallback={updateControlsHaveLoaded} playlistId={playlistPathId} />
                    <Outlet/>
                </section>
        </article>
    )
}