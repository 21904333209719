import {ApolloError, useMutation} from "@apollo/client";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ADD_PLAYLIST_COMMENT} from "./comments.gql";
import {ApiErrors} from "../enum/Enum";

export interface UpdatePlaylistComments {
    addPlaylistComment: (tagId: string, contents: string, commentParentId?: string) => void;
    playlistCommentsLoading: boolean;
}

export interface UpdatePlaylistCommentsResponse {
    base : { id: string }
}

const useUpdatePlaylistComments = (onSuccess?: (response: UpdatePlaylistCommentsResponse) => void, onError?: (error: ApolloError, errorMessage: string) => void): UpdatePlaylistComments => {
    const [updateTagComments, { loading, error}] = useMutation(ADD_PLAYLIST_COMMENT, {
        onCompleted: (data: UpdatePlaylistCommentsResponse) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });

    useEffect(() => {
        handleErrorRaised(error, ADD_PLAYLIST_COMMENT, ApiErrors.AddPlaylistComment, onError);
    }, [error, ADD_PLAYLIST_COMMENT]);

    return {
        addPlaylistComment: (tagId: string, contents: string, commentParentId?: string) => {
            return updateTagComments({
                variables: {
                    input: [{
                        id: tagId,
                        createComments: [{
                            commentJSON: `{"SQComment" : ${JSON.stringify(contents)}, "SQCommentVersion" : 1}`,
                            replyId: commentParentId
                        }]
                    }]
                }
            })
        },
        playlistCommentsLoading: loading
    }
};

export default useUpdatePlaylistComments;