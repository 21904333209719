import { create } from "zustand";
import { immerProxy } from './ImmerUtils';
import {createHubSlice} from "./slices/hub";
import {createFinderSlice} from "./slices/matches";
import {SessionState} from "./slices/StoreSlice";
import {createLicensingSlice} from "./slices/licensing";

// type safe slices, modify StoreState to add more slices
const createSlices = () => create<SessionState>(immerProxy((set: any, get: any) => ({
	...createHubSlice(set, get),
	...createFinderSlice(set, get),
	...createLicensingSlice(set, get)
})))

export const clearSessionState = () => {
	// @ts-ignore
	useSessionStore = createSlices();
}
// @ts-ignore
export let useSessionStore = createSlices();
