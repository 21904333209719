import {ITagItem} from "../api/Hub/tags/__types__/Tags";
import {CalculateHubSyncPoints} from "./timeUtils";
import {IAsset} from "../api/Hub/common/Common";
import {AssetType} from "../api/Hub/enum/Enum";

export function CleanEmail (email: string) {
    const stripped = email
        .substring(0, email.lastIndexOf("@"))
        .split('.')

    return stripped.join(' ')
}

// Simple hashing for distinct ids in MixPanel
//  See https://stackoverflow.com/a/52171480
export const HashString = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};


export function dateStringToShortFormat(dateString: string) {
    const date = new Date(dateString);

    const monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];

    const dayNumber = date.getDate();

    let day = '';

    if(dayNumber == 1 || dayNumber == 21 || dayNumber == 31){
        day = `${dayNumber}st`;
    } else if(dayNumber == 2 || dayNumber == 22) {
        day = `${dayNumber}nd`;
    } else if(dayNumber == 3 || dayNumber == 23) {
        day = `${dayNumber}rd`;
    } else {
        day = `${dayNumber}th`;
    }

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day} ${monthName} ${year}`;
}
export const ZeroPadNumber = (num: number, places?: number) => String(num).padStart(places || 2, '0')

// Similar to numberUtils->secondsToHms but this function drops hours if not required
export const SecondsToTime = (e: number | string) => {
    const h = Math.floor(+e / 3600).toString().padStart(2, '0'),
        m = Math.floor(+e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(+e % 60).toString().padStart(2, '0');

    if ((+e / 3600) >= 1) return `${h}:${m}:${s}`

    return `${m}:${s}`;
}

export const CleanupTypeName = (typeName: string) : string => {
    try {
        let name = typeName;
        if (!name.match(/[_\s]/) && name.endsWith("ID")) {
            name = name.replace("ID", " ID");
        }

        let words = name.replaceAll("_", " ")
        let parts = (words.toLowerCase().trim()).split(' ');

        for (let i = 0; i < parts.length; i++) {
            parts[i] = parts[i][0].toUpperCase() + parts[i].toLowerCase().slice(1);
        }

        return parts.join(' ').trim();
    } catch {
        return typeName
    }
}

export const RemoveExcessWhitespace = (text: string) => {
    return text.replace(/\s+/g, ' ').trim();
};

export const HubTagToStreamUrl = (tag: ITagItem, asset?: IAsset) => {
    if (!tag) return undefined;

    const angle = asset === undefined
        ? tag.assets?.find(a => a.assetType == AssetType.VIDEO)
        : asset;
    const syncPoint = CalculateHubSyncPoints(
        tag.startTime, tag.endTime, tag.poiTime,
        angle?.startTime || 0, angle?.endTime || 0, angle?.poiTime || 0
    )

    return angle ? `${angle.file.s3Link}#t=${syncPoint.startSecond},${syncPoint.endSecond}` : undefined
}
