
import React, {useCallback, useMemo, useState} from "react";
import {Avatar, Button, Input, Tooltip, Typography, useAlerts} from "@catapultsports/referee-react";
import {IPlaylistResponse} from "../../api/Hub/collections/__types__/Playlist";
import {CleanEmail} from "../../utils/stringUtils";
import './playlistControlsHeaderComponent.sass';
import {GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import "./playlistControls.sass"
import {useMixPanel} from "../../providers/Mixpanel";
import {PlaylistControlsHeaderDelete} from "./headerActions/playlistControlsHeaderDelete";
import {PlaylistControlsHeaderRename} from "./headerActions/playlistControlsHeaderRename";
import {PlaylistControlsHeaderComponentEditLabels} from "./headerActions/playlistHeaderControlsEditLabels";
import {PlaylistControlsHeaderCollectionActions} from "./headerActions/playlistControlsHeaderCollectionActions";
import {PlaylistType} from "../../api/Hub/enum/Enum";
import {IconHubClient} from "../../../icons";
import {PlaylistControlsHeaderTrim} from "./headerActions/playlistControlsHeaderTrim";
import {
    PlaylistControlsHeaderChangeDefaultAngle
} from "./headerActions/playlistControlsHeaderChangeDefaultAngle";

interface PlaylistControlsHeaderComponentProps {
    data: IPlaylistResponse
    areCollectionActionsOpen: boolean
    setAreCollectionActionsOpen: (value: boolean) => void
    selectedTagIds?: string[]
    setSelectedTagIds?: (value: string[]) => void
    selectAll?: () => void
    updatePlaylistCallback?: () => void
    playlistType: PlaylistType
}

export const PlaylistControlsHeaderComponent: React.FC<PlaylistControlsHeaderComponentProps> = (props) => {
    const {
        data: playlistData,
        areCollectionActionsOpen,
        setAreCollectionActionsOpen,
        selectedTagIds,
        setSelectedTagIds,
        playlistType,
        selectAll,
        updatePlaylistCallback
    } = props;
    const mixpanel = useMixPanel();
    const {showInfo} = useAlerts();
    const {t} = useTranslation();
    const collectionId = useSessionStore(state => state.playlist.id, shallow);

    const [playListName, setPlayListName] = useState<string | undefined>(playlistData?.playlist.name);
    const [editCollectionName, setEditCollectionName] = useState<boolean>(false);

    const handleOnNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPlayListName(event.target.value);
    }, [])


     const handleSendToHub = useCallback(() => {
         mixpanel.track(`Hub-Portal: Deeplink Collection to Hub Client.`, {
             collectionId: collectionId,
             playlistType: playlistType
         });
         showInfo(t(HUB.OPENING_IN_HUB));
         window.location.assign(`hub://playlist?id=${collectionId}&type=${playlistType}`);
     }, [collectionId, playlistType]);


    const tagsExist = useMemo(() => {
        return playlistData?.playlist.tags.length > 0;
    }, [playlistData?.playlist.tags.length])

    return (
        <div data-testid={'playlist-controls-header'}>
            <div className={"playlist-controls-header-title"}>
                {editCollectionName ?
                    <Input as={"input"} size={"small"} variant={"primary"} value={playListName}
                           onInput={handleOnNameChange}/> :
                    <Typography
                        variant={'heading-3'}
                        numberOfLines={1}
                        testId={"playlist-title"}
                        className={"playlist-name-style"}
                    >
                        {playListName}
                    </Typography>
                }
                <div className={'playlist-controls-header-title-icons'}>
                    {/*//TODO: add back in once the functionality has been implemented*/}
                    {/*<IconSend/>*/}
                    <PlaylistControlsHeaderCollectionActions
                        areCollectionActionsOpen={areCollectionActionsOpen}
                        setAreCollectionActionsOpen={setAreCollectionActionsOpen}
                        setSelectedTagIds={setSelectedTagIds}
                    />
                </div>
            </div>
            <div className='flex horizontal clips-avatars'>
                {playlistData?.playlist.users.map((user, index) => (
                    <Avatar key={`${user.id}-${index}`} name={CleanEmail(user.email)} size="extra-small"/>
                ))}
            </div>
            {areCollectionActionsOpen ?
                <div className={"collection-actions"}>
                    <Typography variant={"label-2"}>Collection Actions</Typography>
                    <div className={"collection-actions-icons"}>
                        {tagsExist ? (
                            <div className={'button-container'}>
                                <Button size={'small'} onClick={selectAll} variant={'outline'} testId={'selectAllButton'}>
                                    {selectedTagIds?.length == playlistData?.playlist.tags.length ? t(GENERAL.DESELECT_ALL) : t(GENERAL.SELECT_ALL)}
                                </Button>
                            </div>
                            ) : null
                        }
                        <PlaylistControlsHeaderRename
                            data={playlistData}
                            playListName={playListName}
                            setEditCollectionName={setEditCollectionName}
                            editCollectionName={editCollectionName}
                        />
                        <PlaylistControlsHeaderComponentEditLabels
                            playListName={playListName}
                            collectionId={collectionId}
                        />
                        {tagsExist?
                            <>
                                <PlaylistControlsHeaderDelete
                                    data={playlistData}
                                    setSelectedTagIds={setSelectedTagIds}
                                    selectedTagIds={selectedTagIds}
                                />
                                <PlaylistControlsHeaderTrim
                                    data={playlistData}
                                    setSelectedTagIds={setSelectedTagIds}
                                    selectedTagIds={selectedTagIds}
                                    successCallback={updatePlaylistCallback}
                                />
                                <PlaylistControlsHeaderChangeDefaultAngle
                                    data={playlistData}
                                    selectedTagIds={selectedTagIds}
                                    setSelectedTagIds={setSelectedTagIds}
                                />
                            </>
                        : null}
                        <Tooltip
                            text={t(HUB.OPEN_IN_HUB)}
                        >
                            <span tabIndex={0} className={"tooltip-span-style"}>
                                <IconHubClient
                                    className={"icon"}
                                    onClick={handleSendToHub}
                                    data-testid={"send-to-hub"}
                                />
                            </span>
                        </Tooltip>
                    </div>
                </div>
                : null}
                <a className={"line"}></a>
        </div>
    )
}