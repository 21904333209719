import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Spinner, Typography, useAlerts} from "@catapultsports/referee-react";
import {VideoPlayer} from "../../../components/videoPlayer/videoPlayer";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {useMixPanel} from "../../../providers/Mixpanel";
import {useMatch} from "@tanstack/react-location";
import './clipPlayer.sass';
import {useTranslation} from "react-i18next";
import {HUB} from "../../../services/i18n/i18n-constants";
import {AngleOptions, AngleSelectedEvent} from "./angleOptions";
import useTag from "../../../api/Hub/tags/useTag";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {CalculateHubSyncPoints} from "../../../utils/timeUtils";
import {Tag, TagAsset} from "../../../api/Hub/tags/__types__/Tags";

let isPlaying = false;

export const ClipPlayer: React.FC<any> = () => {
    const mixpanel = useMixPanel();
    const [currentStream, setCurrentStream] = useState<string | undefined | null>(null);
    const selectedVideoAngle = useSessionStore(state => state.selectedHubVideoAngle, shallow);
    const setVideoAngle = useSessionStore(state => state.setSelectedHubVideoAngle, shallow);
    const { t } = useTranslation();
    const { showError } = useAlerts();
    const {
        data: { clipPathId },
    } = useMatch<any>();
    const { tag, loading } = useTag({ id: clipPathId }, () => showError(t(HUB.CLIP_ERROR)));

    const getStream = (tag: Tag, asset?: TagAsset) => {
        const syncPoint = CalculateHubSyncPoints(
            tag.startTime || 0,
            tag.endTime || 0,
            tag.poiTime || 0,
            asset?.startTime || 0,
            asset?.endTime || 0,
            asset?.poiTime || 0
        );
        return `${asset?.file.s3Link}#t=${syncPoint.startSecond},${syncPoint.endSecond}`
    }

    useEffect(() => {
        if (tag) {
            let defaultAsset = tag.assets.find((asset) => asset.isPreferred) || tag.assets.find((asset) => asset);
            if (selectedVideoAngle) {
                const matchingAngle = tag.assets.find((asset) => asset.name === selectedVideoAngle);
                if (matchingAngle)
                    defaultAsset = matchingAngle;
            }
            setCurrentStream(getStream(tag, defaultAsset));
        }
    }, [clipPathId, tag]);

    const playState = useMemo(() => {
        return isPlaying;
    }, [currentStream]);

    const handleAngleSelect = useCallback((angleSelected: AngleSelectedEvent) => {
        if (tag) {
            const asset = tag?.assets.find(asset => angleSelected.s3Link?.includes(asset.file.s3Link));
            setCurrentStream(getStream(tag, asset));
            setVideoAngle(angleSelected.name);
        }
    }, [tag, setVideoAngle]);

    if (loading)
        return (
            <div  className={"align-content-middle"}>
                <Spinner testId={'loading-spinner'} size={'large'}/>
            </div>
    );
    if (!loading && !tag)
        return(
            <div className={"align-content-middle"}>
            <Typography>{t(HUB.UNKNOWN_CLIP)}</Typography>
            </div>
        );

    return (
        <section className={'video-section'}>
            { tag && <AngleOptions tag={tag} onStreamChange={handleAngleSelect} currentStream={currentStream}/> }
            <section className='video-player'>
                {tag && currentStream && (
                    <VideoPlayer
                        onPlayPause={(playing: boolean) => isPlaying = playing}
                        sourceUrl={[{src: currentStream}]}
                        loadedCallback={() => mixpanel.time_event(MixPanelEvents.CLIP_PLAYING)}
                        endedCallback={() => mixpanel.track(MixPanelEvents.CLIP_PLAYING, {
                             videoSrc: currentStream
                         })}
                        autoPlay={playState}/>
                )}
            </section>
        </section>
    )
};
