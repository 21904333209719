import {ApolloError, useMutation} from "@apollo/client";
import {UPDATE_TAGS_METADATA_GROUPS} from "./updateTag.gql";
import {ApiErrors} from "../../../enums/apiErrors";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {CreateMetadataGroupInput, UpdateMetadataGroupInput} from "../../gql/schema";
import {PLAYLIST_METADATA, TAG_METADATA} from "../../metadata/metadata.gql";

export const useUpdateTagsMetadataGroups = (onError?: (error: ApolloError, errorMessage: string) => void, onSuccess?: () => void) => {
    const [updateTagsMetadataGroups, { error }] = useMutation(UPDATE_TAGS_METADATA_GROUPS, {
        refetchQueries: [TAG_METADATA, PLAYLIST_METADATA],
        onCompleted: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    useEffect(() => {
        handleErrorRaised(error, UPDATE_TAGS_METADATA_GROUPS, ApiErrors.CreateMetadata, onError);
    }, [error, onError]);

    return {
        updateTagMetadataGroups: (updates: {
            tagId: string,
            createMetadataGroups?: Array<CreateMetadataGroupInput>,
            updateMetadataGroups?: Array<UpdateMetadataGroupInput>
        }[]) => updateTagsMetadataGroups({
            variables: {
                input: updates.map((update) => ({
                    id: update.tagId,
                    createMetadataGroups: update.createMetadataGroups,
                    updateMetadataGroups: update.updateMetadataGroups
                }))
            },
        })
    };
};
