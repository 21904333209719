import {IEventsResponseElement} from "../../../api/MatchTracker/events/__types__/Events";
import {CleanupTypeName} from "../../../utils/stringUtils";

enum InvolvementType {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY"
}
export const getEventTitle = (event: IEventsResponseElement) : string => {
    let involvement = event.playerMatchInvolvements?.find((player) => player.involvementType === InvolvementType.PRIMARY);
    if(!involvement) {
        involvement = event.playerMatchInvolvements?.find((player) => player.involvementType === InvolvementType.SECONDARY);
    }
    return involvement && (involvement.player.firstName.length > 0 || involvement.player.lastName.length > 0) ?
        `${CleanupTypeName(event.eventType.name)}: ${involvement.player.firstName} ${involvement.player.lastName}` :
        CleanupTypeName(event.eventType.name);
};

export const getPlayerInEvent = (event: IEventsResponseElement) => {
    if(event.playerMatchInvolvements){
        let primaryPlayer = event.playerMatchInvolvements.find((involvement) => involvement.involvementType === InvolvementType.PRIMARY)
        let secondaryPlayer = event.playerMatchInvolvements.find((involvement) => involvement.involvementType === InvolvementType.SECONDARY)

        if(primaryPlayer){
            if(primaryPlayer.player.firstName !== "" && primaryPlayer.player.lastName !== "") {
                return {value: `${primaryPlayer.player.firstName} ${primaryPlayer.player.lastName}`, id: primaryPlayer.player.base.id}
            }else {
                return {value: "", id: ""}
            }
        }else {
            if(secondaryPlayer){
                if(secondaryPlayer.player.firstName !== "" && secondaryPlayer.player.lastName !== "") {
                    return {value: `${secondaryPlayer.player.firstName} ${secondaryPlayer.player.lastName}`, id: secondaryPlayer.player.base.id}
                }else{
                    return {value: "", id: ""}
                }
            }
        }
    }
}