import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Typography} from "@catapultsports/referee-react";
import {VideoPlayer} from "../../../components/videoPlayer/videoPlayer";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {useMixPanel} from "../../../providers/Mixpanel";
import {useMatch} from "@tanstack/react-location";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {ITagItem} from "../../../api/Hub/tags/__types__/Tags";
import './playlistClipPlayer.sass';
import {useTranslation} from "react-i18next";
import {FINDER, HUB} from "../../../services/i18n/i18n-constants";
import {AngleOptions, AngleSelectedEvent} from "./angleOptions";
import {HubTagToStreamUrl} from "../../../utils/stringUtils";

let isPlaying = false;

export const PlaylistClipPlayer: React.FC<any> = () => {
    const [ selectedTag, setSelectedTag ] = useState<ITagItem | undefined>(undefined);
    const [ unknownClipId, setUnknownClipId ] = useState(false);
    const mixpanel = useMixPanel();
    const playlistTags = useSessionStore<ITagItem[]>(state => state.playlist.tags, shallow);
    const playlistId = useSessionStore<string>(state => state.playlist.id, shallow);
    const setStateStream = useSessionStore(state => state.setCurrentStream, shallow);
    const currentStream = useSessionStore<string | undefined>(state => state.playlist.currentStream, shallow);
    const autoPlay = useSessionStore<boolean>(state => state.playlist.autoPlay, shallow);
    const setTagId = useSessionStore(state => state.setCurrentPlaylistTagId, shallow);
    const selectedVideoAngle = useSessionStore(state => state.selectedHubVideoAngle, shallow);
    const setVideoAngle = useSessionStore(state => state.setSelectedHubVideoAngle, shallow);

    const playingState = useMemo(() => {
        return isPlaying;
    }, [currentStream]);
    const { t } = useTranslation();
    const {
        data: { clipPathId },
    } = useMatch<any>();

    useEffect(() => {
        const matches = playlistTags.filter((tag) => tag.id === clipPathId);
        setTagId(clipPathId);
        if (matches.length > 0) {
            setUnknownClipId(false);
            setSelectedTag(matches[0]);
            let defaultAngle = matches[0]?.assets?.find(asset => asset.isPreferred) || matches[0]?.assets[0];
            if (matches[0].assets && selectedVideoAngle) {
                const matchingVideo = matches[0].assets.find(asset => asset.name === selectedVideoAngle);
                if (matchingVideo)
                    defaultAngle = matchingVideo;
            }
            if (currentStream !== defaultAngle?.file.s3Link) {
                setStateStream(HubTagToStreamUrl(matches[0], defaultAngle));
            }
        } else if (playlistTags.length > 0) {
            setUnknownClipId(true);
        }
    }, [clipPathId, playlistTags]);

    const handleAngleSelected = useCallback((angleSelected: AngleSelectedEvent) => {
        setStateStream(angleSelected.s3Link);
        setVideoAngle(angleSelected.name);
    }, [selectedTag]);

    return (
        unknownClipId ?
            <Typography>{t(HUB.CLIP_NOT_FOUND)}</Typography> :
            <section className={'video-section'}>
                {selectedTag?.assets?.length && selectedTag?.assets?.length > 0 ? (
                    <AngleOptions tag={selectedTag} onStreamChange={handleAngleSelected} currentStream={currentStream} />
                ) : null}
                <section className='video-player'>
                    {selectedTag && currentStream ? (
                        <VideoPlayer
                            onPlayPause={(playing: boolean) => isPlaying = playing}
                            sourceUrl={[{src: currentStream}]}
                            loadedCallback={() => mixpanel.time_event(MixPanelEvents.COLLECTION_CLIP_PLAYING)}
                             endedCallback={() => {
                                mixpanel.track(MixPanelEvents.COLLECTION_CLIP_PLAYING, {
                                    playlistId: playlistId,
                                    videoSrc: currentStream});
                                setStateStream(undefined);
                             }}
                             autoPlay={autoPlay || playingState}
                        />
                    ):
                        <Typography>{t(FINDER.EVENT_NO_VIDEO)}</Typography>
                    }
                    {!selectedTag && !currentStream && playlistTags.length > 0 && (
                        <Typography>{t(HUB.SELECT_STREAM)}</Typography>
                    )}
                </section>
            </section>
    )
};
