import React, {MutableRefObject, useCallback, useRef, useState} from "react";
import {
    Button, noop,
    SelectOptions,
    usePositioner,

} from "@catapultsports/referee-react";
import './videoAngleSelector.sass';
import {IconChevronDownSmall, IconCameraSmall} from "@catapultsports/referee-react/icons";
import {ContentFile} from "../../api/MatchTracker/__types__/Common";
import {FileMedia} from "../../api/MatchTracker/fixtures/__types__/Fixtures";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";

interface VideoAngleSelectorProps {
    angleList?: ContentFile[],
    setActiveFile?: (file?: ContentFile | FileMedia) => void,
    activeFileSource?: string;
}

export const VideoAngleSelector: React.FC<VideoAngleSelectorProps> = (props) => {
    const {angleList, activeFileSource, setActiveFile} = props;
    const [broadcastAngleClicked, setBroadcastAngleClicked] = useState(false);
    const setSelectedVideoAngle = useSessionStore(state => state.setSelectedFinderVideoAngle, shallow);

    const {getReferenceProps, getPopperProps} = usePositioner({
        placement: 'bottom-start',
    });
    const dropdownRef = useRef(null) as unknown as MutableRefObject<HTMLElement>;

    const toggleBroadcastAngleClicked = useCallback(() => {
        setBroadcastAngleClicked(!broadcastAngleClicked)
    }, [broadcastAngleClicked, setBroadcastAngleClicked])

    const onBroadcastAngleClick = useCallback(
        (selectedAngleFile: ContentFile ) => {
            toggleBroadcastAngleClicked();
            setActiveFile && setActiveFile(selectedAngleFile);
            setSelectedVideoAngle(selectedAngleFile.name);
        },
        [setActiveFile, toggleBroadcastAngleClicked]
    );

    const common = "video-angle-"

    return (
        <div data-testid={`${common}component`} id={`${common}component`}>
            {angleList && angleList.length > 0 ? (
                <div ref={dropdownRef} className={`video-angle-button`} data-testid={`${common}button-selections`} id={`${common}button-selections`}>
                    <Button  variant="text" size={"small"} onClick={toggleBroadcastAngleClicked} {...getReferenceProps()} testId={`${common}outside-button`}>
                        <IconCameraSmall className={"margin"} data-testid={`${common}camera-icon`} id={`${common}camera-icon`} />
                        <IconChevronDownSmall data-testid={`${common}chevron-down-icon`} id={`${common}chevron-down-icon`}/>
                    </Button>
                </div>
            ): null}
            <SelectOptions
                popperProps={getPopperProps}
                parentRef={dropdownRef}
                setIsVisible={setBroadcastAngleClicked}
                isVisible={broadcastAngleClicked}
                onSelectItem={noop}
                menuItems={angleList?.map((video, index) => ({
                    id: index,
                    key: index,
                    isSelected: activeFileSource ? activeFileSource.startsWith(video.contentURL) : false,
                    value: video.name,
                    onClick: () => onBroadcastAngleClick(video)
                }))}
            />
        </div>
    )
};
