import React, {
    RefObject,
    useCallback,
    useMemo, useRef,
    useState
} from "react";
import {Spinner, Typography, useAlerts} from "@catapultsports/referee-react";
import {IconChevronLeft} from "@catapultsports/referee-react/icons";
import './comments.sass';
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import usePlaylistComments from "../../api/Hub/comments/usePlaylistComments";
import useClipComments from "../../api/Hub/comments/useClipComments";
import {Comment} from "./comment";
import {useTranslation} from "react-i18next";
import {HUB} from "../../services/i18n/i18n-constants";
import {Labels} from "../labels/labels";
import {CommentEntry} from "./commentEntry";
import {COMMENT_REPLY_DEPTH_MAX} from "../../api/enums/clientMagic";
import {CommentsReplyEvent} from "./__types__/coments";
import {CleanEmail} from "../../utils/stringUtils";
import {CommentEntityType} from "./__types__/enums";
import {scrollToReference} from "../../utils/scrollingUtils";

export interface CommentsProps {
    onClipBackClick?: () => void
}

export const Comments: React.FC<CommentsProps> = (props) => {
    const { onClipBackClick } = props;
    const playlistId = useSessionStore(state => state.playlist.id, shallow);
    const tagId = useSessionStore(state => state.comments.tagId, shallow);
    const tagName = useSessionStore(state => state.comments.tagName, shallow);
    const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);

    const [replyState, setReplyState] = useState<CommentsReplyEvent | undefined>(undefined);
    const currentReply = useMemo(() => {
        return replyState;
    }, [replyState]);

    const { t } = useTranslation();
    const { showError } = useAlerts();
    const { value, loading } = tagId ?
        useClipComments(tagId, () => showError(t(HUB.CLIP_COMMENTS_UNAVAILABLE))) :
        usePlaylistComments(playlistId, () => showError(t(HUB.COLLECTION_COMMENTS_UNAVAILABLE)));

    const scrollerRef = useRef<HTMLElement>(null);

    let loadedCount = 0;

    const handleOnLoaded = useCallback((replyRef: RefObject<Element>) => {
        loadedCount+=1;
        const baseCommentCount = value?.comments?.filter((comment) => !comment.parentId).length;
        if (!initialLoadCompleted && baseCommentCount && loadedCount >= baseCommentCount) {
            scrollToReference(scrollerRef?.current?.children[scrollerRef?.current?.children.length-1]);
            setInitialLoadCompleted(true);
        } else if(initialLoadCompleted) {
            scrollToReference(replyRef?.current);
        }
    }, [value?.comments]);

    const handleOnReply = useCallback((commentEvent: CommentsReplyEvent) => {
        if (commentEvent.depth > COMMENT_REPLY_DEPTH_MAX) {
            showError(t(HUB.COMMENT_DEPTH_EXCEEDED));
            return;
        }
        setReplyState(commentEvent);
    }, []);

    const handleReplyCancel = useCallback(() => {
        setReplyState(undefined);
    }, []);

    return (
        <div className={'flex vertical comments-wrapper'}>
            {tagId &&
                <>
                    <div className={'flex horizontal title'}>
                        <div data-testid={'back-button'} onClick={onClipBackClick} className={'back-button'}><IconChevronLeft/></div>
                        <div className={'title-text'}><Typography numberOfLines={1}>{tagName}</Typography></div>
                    </div>
                    <Labels />
                </>
            }
            <div className={'comments flex vertical'} >
                <div className={'comment-count'}>
                    <Typography>{`${t(HUB.COMMENTS)} (${value?.commentCount ?? 0})`}</Typography>
                </div>
                <div ref={scrollerRef} data-testid={'comment-list'} className={`comment-list`}>
                    {
                        loading ?
                            <Spinner /> :
                            value && value.comments &&
                            value.comments
                                .filter((comment) => !comment.parentId)
                                .sort((a, b) => a.commentTimestamp - b.commentTimestamp)
                                .map((comment) =>
                                    <Comment
                                        depth={0}
                                        key={comment.id}
                                        commentId={comment.id}
                                        onLoaded={handleOnLoaded}
                                        onReplyClicked={handleOnReply}
                                    />
                                )
                    }
                </div>
                <div className={'comment-entry'}>
                    <CommentEntry
                        entityId={tagId ?? playlistId}
                        entityType={tagId ? CommentEntityType.tag : CommentEntityType.playlist}
                        parentCommentId={currentReply?.id}
                        replyName={currentReply?.email ? CleanEmail(currentReply?.email) : undefined}
                        onCancelReply={handleReplyCancel}
                    />
                </div>
            </div>
        </div>
    );
};