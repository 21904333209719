import React, {useCallback, useMemo, useState} from "react";
import {
    Typography,
    Input,
    FormControl,
    Checkbox,
    SearchInput, useDebounce, Spinner
} from "@catapultsports/referee-react";
import { useTranslation} from 'react-i18next';
import {COLLECTIONS} from '../../services/i18n/i18n-constants';
import "./createCollectionModalContent.sass"
import {PlaylistType, SortBy} from "../../api/Hub/enum/Enum";
import {useCollections} from "../../api/Hub/collections/useCollections";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import InfiniteScroll from "react-infinite-scroller";
import {ApiPageSize} from "../../api/enums/apiMagic";
import * as lodash from "lodash";
import {SelectedCollection} from "../gridFooter/gridFooter";
import {
    createdPlaylistId
} from "../../api/MatchTracker/playlists/createPlaylist/__mocks__/createPlaylistsMock";

export interface CreateCollectionModalContentProps {
    setCollectionName?: (collectionName: string) => void
    setPreRollInput?: (preRollInput: string) => void
    setPostRollInput?: (postRollInput: string) => void
    preRollInput?: string
    postRollInput?: string
    isClipTooLong?: boolean
    setAddToSelectedCollections?: (addToSelectedCollections: SelectedCollection[]) => void
    addToSelectedCollections?: {}[]
    isCreateNewSelected?: boolean
    setIsCreateNewSelected?: (isCreateNewSelected: boolean) => void
    isAddToExistingSelected?: boolean
    setIsAddToExistingSelected?: (isAddToExistingSelected: boolean) => void
}

export enum eventTypes {
    preRoll = "preRoll",
    postRoll = "postRoll",
    collectionName = "collectionName"
}

export const CreateCollectionId = "create-collection-modal-content";

export const CreateCollectionModalContent: React.FC<CreateCollectionModalContentProps> = (props) => {
    const {
        setCollectionName,
        setPreRollInput,
        setPostRollInput,
        preRollInput,
        postRollInput,
        isClipTooLong,
        setAddToSelectedCollections,
        addToSelectedCollections,
        isCreateNewSelected,
        setIsCreateNewSelected,
        isAddToExistingSelected,
        setIsAddToExistingSelected
    } = props
    const { t } = useTranslation();

    const [collectionSearchTerm, setCollectionSearchTerm] = useState<string>("");

    const handleOnInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, eventType: string) => {
        let inputVal = event.target.value
        if(eventType === "preRoll") {
            setPreRollInput && setPreRollInput(inputVal)
        }else if (eventType === "postRoll") {
            setPostRollInput && setPostRollInput(inputVal)
        }else if (eventType === "collectionName") {
            setCollectionName && setCollectionName(event.target.value)
        }
    }, [setCollectionName, setPostRollInput, setPreRollInput])


    const sortBy = useSessionStore<SortBy>(state => state.collections.sortBy, shallow);
    const {
        state: { collections, loading, hasNextPage , error},
        operations: { loadNextPage }
    } = useCollections(
        ApiPageSize.Fifteen,
        sortBy,
        PlaylistType.Collection,
        [],
        collectionSearchTerm,
    )

    const debouncedText = useDebounce((value: string) => {
        setCollectionSearchTerm(value);
    }, 500);

    const handleOnChangeSearchInput = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let searchValue = event.currentTarget.value
        debouncedText(searchValue);
    }, [debouncedText])

    const optionActions = useCallback((option: string) => {
        if(option === COLLECTIONS.CREATE_NEW && !isCreateNewSelected) {
            setIsCreateNewSelected && setIsCreateNewSelected(true)
            setIsAddToExistingSelected && setIsAddToExistingSelected(false)
        }else if(option === COLLECTIONS.ADD_TO_EXISTING && !isAddToExistingSelected) {
            setIsCreateNewSelected && setIsCreateNewSelected(false)
            setIsAddToExistingSelected && setIsAddToExistingSelected(true)
        }
        setPreRollInput && setPreRollInput("")
        setPostRollInput && setPostRollInput("")
    }, [isAddToExistingSelected, isCreateNewSelected, setIsAddToExistingSelected, setIsCreateNewSelected, setPostRollInput, setPreRollInput])

    const options = useMemo(() => {
        return [{
            label: t(COLLECTIONS.CREATE_NEW),
            action: () => {
                optionActions(COLLECTIONS.CREATE_NEW)
            },
            isChecked: isCreateNewSelected,
            testId: "create-new"
        },
            {
                label: t(COLLECTIONS.ADD_TO_EXISTING),
                action: () => {
                    optionActions(COLLECTIONS.ADD_TO_EXISTING)
                },
                isChecked: isAddToExistingSelected,
                testId: "add-to-existing"
            }
        ]
    }, [optionActions, isAddToExistingSelected, isCreateNewSelected, t])

    const handleCollectionCheckboxClick = useCallback((option: SelectedCollection) => {
        let doesIdExist = !!lodash.find(addToSelectedCollections, option)

        if(doesIdExist) {
            let filteredCollections = addToSelectedCollections?.filter(collection => collection.id !== option.id)
            setAddToSelectedCollections && setAddToSelectedCollections(filteredCollections)
        }else {
            setAddToSelectedCollections && setAddToSelectedCollections([...addToSelectedCollections, option])
        }
    }, [addToSelectedCollections, setAddToSelectedCollections])

    const determineIfOptionIsChecked = useCallback((option: SelectedCollection) => {
        let isPresent = lodash.find(addToSelectedCollections, option)
        return !!isPresent;
    }, [addToSelectedCollections])

    return (
        <div data-testid={`${CreateCollectionId}-div`} id={`${CreateCollectionId}-div`}>
            <Typography testId={`${CreateCollectionId}-sub-header`} id={`${CreateCollectionId}-sub-header`} variant={"body-1"}>{t(COLLECTIONS.CREATE_COLLECTION_MODAL_SUB_HEADER)}</Typography>
            <div className={"collection-options"}>
                {options.map((option, index) => (
                    <Typography className='options-input' key={`options-input-${index}`}>
                        <input
                            checked={option.isChecked}
                            onChange={option.action}
                            data-testid={`${CreateCollectionId}-radio-button-${option.testId}`}
                            type='radio'
                            value={option.label}
                        />
                        {option.label}
                    </Typography>
                ))}
            </div>
            {isCreateNewSelected? (
                <div className={"create-new-add-to-div"}>
                <FormControl testId={`${CreateCollectionId}-create-new-form`} label={t(COLLECTIONS.CREATE_COLLECTION_MODAL_INPUT_LABEL) as string} >
                    <Input
                        id={`${CreateCollectionId}-input`}
                        data-testid={`${CreateCollectionId}-input`}
                        fullWidth={true}
                        onChange={(event) => handleOnInputChange(event, eventTypes.collectionName)}
                    />
                </FormControl>
                </div>
            ) : null}
            {isAddToExistingSelected? (
                <>
                    <div className={"top-header"}>
                        <Typography testId={`${CreateCollectionId}-sub-header-add-to`} id={`${CreateCollectionId}-sub-header`} variant={"body-1"}>{t(COLLECTIONS.SELECT_A_COLLECTION)}</Typography>
                        <SearchInput
                            data-testid={`${CreateCollectionId}-add-to-existing-search`}
                            size={"small"}
                            fullWidth={true}
                            onChange={handleOnChangeSearchInput}
                            placeholder={t(COLLECTIONS.SEARCH_FOR_COLLECTIONS) as string}
                        />
                    </div>
                    <div className={"select-collection-options-list"} data-testid={`${CreateCollectionId}-collection-list`}>
                        <InfiniteScroll
                            element={'ul'}
                            threshold={10}
                            pageStart={0}
                            loadMore={loadNextPage}
                            hasMore={hasNextPage}
                            className={"list-ul"}
                            loader={<Spinner key='spinner' size="medium"/>}
                            useWindow={false}>
                            {loading && isAddToExistingSelected? <Spinner key='spinner' size="medium"/> :
                                (
                                    collections && collections.length > 0 ? (
                                        collections?.map((collection, index) => (
                                            <Checkbox
                                                key={`collection-option-checkbox-${index}`}
                                                testId={`collection-option-checkbox-${index}`}
                                                label={collection.name}
                                                checked={determineIfOptionIsChecked(lodash.pick(collection, ['id', 'name']))}
                                                onChange={() => handleCollectionCheckboxClick(lodash.pick(collection, ['id', 'name']))}
                                            />
                                        ))
                                    ):null
                                )
                            }
                        </InfiniteScroll>
                    </div>
                </>
            ) : null}
            <div className={"pre-post-roll-outer-div"}>
                <div className={"pre-post-roll-forms"}>
                    <FormControl
                        testId={`${CreateCollectionId}-pre-roll-form`}
                        label={t(COLLECTIONS.PRE_ROLL) as string}
                    >
                        <Input
                            type={"number"}
                            min={"0"}
                            size={"small"}
                            max={"60"}
                            error={isClipTooLong}
                            id={`${CreateCollectionId}-pre-roll-input`}
                            data-testid={`${CreateCollectionId}-pre-roll-input`}
                            value={preRollInput}
                            fullWidth={false} onChange={(event) => handleOnInputChange(event, eventTypes.preRoll)}
                        />
                    </FormControl>
                    <FormControl testId={`${CreateCollectionId}-post-roll-form`} label={t(COLLECTIONS.POST_ROLL) as string}>
                        <Input
                            type={"number"}
                            min={"0"}
                            max={"60"}
                            error={isClipTooLong}
                            size={"small"}
                            id={`${CreateCollectionId}-post-roll-input`}
                            data-testid={`${CreateCollectionId}-post-roll-input`}
                            fullWidth={false}
                            value={postRollInput}
                            onChange={(event) => handleOnInputChange(event, eventTypes.postRoll)
                        }/>
                    </FormControl>
                </div>
                {isClipTooLong?
                    <Typography
                        className={"error-message"}
                        variant={"body-1"}
                        testId={`${createdPlaylistId}-error`}
                    >
                        {t(COLLECTIONS.CLIPS_LENGTH_LIMIT)}
                    </Typography>
                : null}
            </div>
        </div>
    )
};
