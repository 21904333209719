import {QualifierValueType} from "../../../components/searchFilter/__mocks__/data";

export interface Query {
    filters: QueryGroup[],
    qualifierTypes?: number[],
    paging: { pageSize: number, pageNumber: number },
    orderBy: { field: string, order: "asc" | "desc" }[]
}

export interface QueryGroup {
    qualifierTypeId: string
    qualifierTypeName: string
    valueType: QualifierValueType
    formatType: string
    conditions?: QueryCondition[]   // May be empty if this is simply a nested container
    nested?: QueryGroup[]           // Any additional (child) queries, think of multiple parenthesis
    conjunction?: Conjunction       // Server to assume AND as default
    selectableOptions?: string[]
}

export interface QueryCondition {
    value: string | number | boolean
    operator: QueryOperator
    limits?: {
        min: number,
        max: number
    }
}

export interface QualifierTypeFacet {
    id: number
    ranges?: { from: number | Date, to: number | Date }[]
}

enum Conjunction {
    And = "AND",
    Or = "OR"
}

// See https://docs.google.com/spreadsheets/d/1fUwFX1rAdY2XwemEBHoQlLBbVPoN1rKt6zIaBOBgU5g/edit#gid=995377205
export enum QueryOperator {
    EqualIn = '[]',
    EqualExact = '=',
    EqualPartial = '~',
    EqualStartsWith = '|~',
    EqualEndsWith = '~|',
    NotEqual = '≠',
    LessThan = '<',
    LessThanEqualTo = '<=',
    GreaterThan = '>',
    GreaterThanEqualTo = '>=',
    Between = '∈',
    For = 'For',
    Against = 'Against',
    Home = 'Home',
    Away = 'Away'
}

export enum ResultType {
    Matches = 'Matches',
    Events = 'Events'
}
