import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FixtureFilter} from "../../screens/finder/subPages/gamesResults";
import {
    Button,
    Pill,
    SelectOption,
    SelectOptions,
    Spinner,
    states,
    statesSelected,
    Table,
    Typography,
    useAlerts,
    usePositioner
} from "@catapultsports/referee-react";
import {Column, HeaderGroup, Row} from "react-table";
import {EventResult} from "../../screens/finder/subPages/eventsResults";
import {FINDER, HUB} from "../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import './gameViewer.sass';
import {useMixPanel} from "../../providers/Mixpanel";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import useEvents from "../../api/MatchTracker/events/useEvents";
import {FixtureFilterType} from "../../api/MatchTracker/enum/Common";
import {CalculateSyncPoint, FormatTimeStamp} from "../../utils/timeUtils";
import {IconFilter} from "@catapultsports/referee-react/icons";
import useEventTypes from "../../api/MatchTracker/eventTypes/useEventTypes";
import {CleanupTypeName} from "../../utils/stringUtils";
import {GameResult} from "../../__types__/globalTypes";
import {ContentFile} from "../../api/MatchTracker/__types__/Common";
import {getPlayerInEvent} from "../../screens/finder/subPages/eventUtils";
import usePlayers from "../../api/MatchTracker/players/usePlayers";

interface GameViewerEventsTableProps {
    result: GameResult,
    activeFile?: ContentFile
    setSelectedEvent: (event: GameEvent) => void
    selectedEvent: GameEvent | undefined
    eventsLoaded?: (hasData: boolean) => void
}

export interface GameEvent {
    event: string,
    timeString: string,
    timestamp: number,
    team: string,
    player: string,
    id: string,
    startTime: number,
    epochStartTime: number
}

export const GameViewerEventsTable: React.FC<GameViewerEventsTableProps> = (props) => {
    const {
        result,
        activeFile,
        setSelectedEvent,
        selectedEvent,
        eventsLoaded
    } = props;

    const { showError } = useAlerts();
    const mixpanel = useMixPanel();
    const [selectedEventsFilters, setSelectedEventsFilters] = useState<FixtureFilter[]>([])
    const [selectedTeamsFilters, setSelectedTeamsFilters] = useState<FixtureFilter[]>([])
    const [selectedPlayersFilters, setSelectedPlayersFilters] = useState<FixtureFilter[]>([])

    const matchEvents = useEvents(100, 0, '', [
        {
            filterType: FixtureFilterType.FixtureIDs,
            id: result?.id,
            name: result?.id,
        },
        ...selectedEventsFilters,
        ...selectedTeamsFilters,
        ...selectedPlayersFilters
    ]);

    const {state: {players, playersLoading}} = usePlayers(
        [],
        undefined,
        undefined,
        () => showError(t(HUB.PLAYERS_ERROR)),
        [result?.id]
    );


    const {state: {eventTypes, eventTypesLoading, eventTypesError}} = useEventTypes();
    const [showEventsFilter, setShowEventsFilter] = useState<boolean>(false)
    const [showTeamsFilter, setShowTeamsFilter] = useState<boolean>(false)
    const [showPlayersFilter, setShowPlayersFilter] = useState<boolean>(false)

    const eventTypesList = useMemo(() => {
        return eventTypes?.map(item => ({
            id: item.base.id,
            value: CleanupTypeName(item.name),
            isSelected: selectedEventsFilters.some(x => x.id == item.base.id)
        }))
    }, [selectedEventsFilters, eventTypesLoading])

    const teamList = useMemo(() => {
        return [{
            value: result?.homeTeam.name,
            id: result?.homeTeam.base.id,
            isSelected: selectedTeamsFilters.some(x => x.id == result?.homeTeam.base.id)
        }, {
            value: result?.awayTeam.name,
            id: result?.awayTeam.base.id,
            isSelected: selectedTeamsFilters.some(x => x.id == result?.awayTeam.base.id)
        }
        ]
    }, [selectedTeamsFilters, result])

    const {t} = useTranslation();

    const eventDropdownRef = useRef(null);
    const teamDropdownRef = useRef(null);
    const playerDropdownRef = useRef(null);


    const matchColumns: Column<EventResult>[] = [
        {
            Header: () => {
                const {getReferenceProps, getPopperProps} = usePositioner({
                    placement: 'top-end',
                });
                return (
                    <div className={'filterHeader'}>
                        {t(FINDER.EVENT)}
                        <div className={'filterButton'} ref={eventDropdownRef}>
                            <Button testId={'eventTypeFilter'} size="small" variant="text" {...getReferenceProps()}
                                    onClick={() => setShowEventsFilter(!showEventsFilter)}>
                                <IconFilter/>
                            </Button>
                            <SelectOptions
                                popperProps={getPopperProps}
                                parentRef={eventDropdownRef}
                                setIsVisible={setShowEventsFilter}
                                isVisible={showEventsFilter}
                                onSelectItem={(item: SelectOption) => {
                                    if (!item.isSelected) {
                                        const newSelectedEvents = [...selectedEventsFilters, {
                                            filterType: FixtureFilterType.EventTypeIDs,
                                            id: item?.id,
                                            name: item?.value,
                                        } as FixtureFilter];

                                        mixpanel.track(MixPanelEvents.FINDER_GAME_PLAYER_EVENT_FILTERS_UPDATED, newSelectedEvents);
                                        setSelectedEventsFilters(newSelectedEvents);
                                    } else {
                                        const newSelectedEvents = selectedEventsFilters.filter(x => x.id != item.id);

                                        mixpanel.track(MixPanelEvents.FINDER_GAME_PLAYER_EVENT_FILTERS_UPDATED, newSelectedEvents);
                                        setSelectedEventsFilters(newSelectedEvents);
                                    }
                                }}
                                isMultiselect
                                isSearchable
                                menuItems={eventTypesList}
                            />
                            {selectedEventsFilters.length > 0 && (
                                <Pill testId={'filterCount'} onClick={() => setSelectedEventsFilters([])}
                                      value={!matchEvents.state.loading ? selectedEventsFilters.length.toString() :
                                          <Spinner/>} variant={'clearable'}
                                />
                            )}
                        </div>
                    </div>
                )
            },
            accessor: 'event',
            disableSortBy: true,
        },
        {
            Header: () => {
                return (
                    <Typography className={'timeColumn'}>
                        {t(FINDER.TIME)}
                    </Typography>
                );
            },
            Cell: ({row}: { row: Row<EventResult> }) => {
                return (
                    <Typography className={'timeColumn'}>
                        {row.values['timeString']}
                    </Typography>
                );
            },
            accessor: 'timeString',
            disableSortBy: true,
        },
        {
            Header: t(FINDER.TIMESTAMP),
            accessor: 'timestamp',
            disableSortBy: true,
        },
        {
            Header: () => {
                const {getReferenceProps, getPopperProps} = usePositioner({
                    placement: 'top-start',
                });

                return (
                    <div className={'filterHeader'}>
                        {t(FINDER.PLAYER)}
                        <div className={'filterButton'} ref={playerDropdownRef}>
                            <Button size="small" variant="text" {...getReferenceProps()}
                                    onClick={() => setShowPlayersFilter(!showPlayersFilter)}>
                                <IconFilter/>
                            </Button>
                            <SelectOptions
                                popperProps={getPopperProps}
                                parentRef={playerDropdownRef}
                                setIsVisible={setShowPlayersFilter}
                                isVisible={showPlayersFilter}
                                onSelectItem={(item: SelectOption) => {
                                    if (!item.isSelected) {
                                        const newSelectedEvents = [...selectedPlayersFilters, {
                                            filterType: FixtureFilterType.PlayerIDs,
                                            id: item?.id,
                                            name: item?.value,
                                        } as FixtureFilter];
                                        mixpanel.track(MixPanelEvents.FINDER_GAME_PLAYER_TEAM_FILTERS_UPDATED, newSelectedEvents);
                                        setSelectedPlayersFilters(newSelectedEvents);
                                    } else {

                                        const newSelectedEvents = selectedPlayersFilters.filter(x => x.id != item.id);
                                        mixpanel.track(MixPanelEvents.FINDER_GAME_PLAYER_TEAM_FILTERS_UPDATED, newSelectedEvents);
                                        setSelectedPlayersFilters(selectedPlayersFilters.filter(x => x.id != item.id));
                                    }
                                }}
                                isMultiselect
                                isSearchable
                                menuItems={playersListToDisplay}
                            />
                            {selectedPlayersFilters.length > 0 && (
                                <Pill testId={'filterCount'}
                                      onClick={() => setSelectedPlayersFilters([])}
                                      value={!matchEvents.state.loading ? selectedPlayersFilters.length.toString() :
                                          <Spinner/>}
                                      variant={'clearable'}
                                />
                            )}
                        </div>
                    </div>
                );
            },
            accessor: 'player',
            disableSortBy: true,
        },
        {
            Header: () => {
                const {getReferenceProps, getPopperProps} = usePositioner({
                    placement: 'top-start',
                });

                return (
                    <div className={'filterHeader'}>
                        {t(FINDER.TEAM)}
                        <div className={'filterButton'} ref={teamDropdownRef}>
                            <Button size="small" variant="text" {...getReferenceProps()}
                                    onClick={() => setShowTeamsFilter(!showTeamsFilter)}>
                                <IconFilter/>
                            </Button>
                            <SelectOptions
                                popperProps={getPopperProps}
                                parentRef={teamDropdownRef}
                                setIsVisible={setShowTeamsFilter}
                                isVisible={showTeamsFilter}
                                onSelectItem={(item: SelectOption) => {
                                    if (!item.isSelected) {
                                        const newSelectedEvents = [...selectedTeamsFilters, {
                                            filterType: FixtureFilterType.TeamIDs,
                                            id: item?.id,
                                            name: item?.value,
                                            isSelected: true
                                        } as FixtureFilter];

                                        mixpanel.track(MixPanelEvents.FINDER_GAME_PLAYER_TEAM_FILTERS_UPDATED, newSelectedEvents);
                                        setSelectedTeamsFilters(newSelectedEvents);
                                    } else {

                                        const newSelectedEvents = selectedTeamsFilters.filter(x => x.id != item.id);
                                        mixpanel.track(MixPanelEvents.FINDER_GAME_PLAYER_TEAM_FILTERS_UPDATED, newSelectedEvents);
                                        setSelectedTeamsFilters(selectedTeamsFilters.filter(x => x.id != item.id));
                                    }
                                }}
                                isMultiselect
                                menuItems={teamList}
                            />
                            {selectedTeamsFilters.length > 0 && (
                                <Pill testId={'filterCount'}
                                      onClick={() => setSelectedTeamsFilters([])}
                                      value={!matchEvents.state.loading ? selectedTeamsFilters.length.toString() :
                                          <Spinner/>}
                                      variant={'clearable'}
                                />
                            )}
                        </div>
                    </div>
                );
            },
            accessor: 'team',
            disableSortBy: true,
        },
        {
            Header: 'startTime',
            accessor: 'startTime',
            disableSortBy: true,
        },
        {
            Header: 'epochStartTime',
            accessor: 'epochStartTime',
            disableSortBy: true,
        },
        {
            Header: 'id',
            accessor: 'id',
            disableSortBy: true,
        }
    ];

    const playersListToDisplay = useMemo(() => {
        if(players && players.length > 0){
            return players?.map(item => ({
                id: item.base.id,
                value: `${item.firstName} ${item.lastName}`,
                isSelected: selectedPlayersFilters.some(x => x.id == item.base.id)
            }))
        }
        return []
    }, [players, selectedPlayersFilters])

    const onRowCLick = useCallback((row: GameEvent) => {
        setSelectedEvent(row);
        mixpanel.track(MixPanelEvents.FINDER_GAME_MATCH_EVENT_SELECTED, row);
    }, [mixpanel, setSelectedEvent])


    const matchEventsList = useMemo(() => {
        if (matchEvents.state.events && matchEvents.state.events.length > 0) {
            return matchEvents?.state.events?.map(x => ({
                event: x.eventType.name,
                timeString: FormatTimeStamp(x),
                timestamp: CalculateSyncPoint(
                    'CollectedFile',
                    activeFile ? (activeFile.videoAsset ? activeFile.videoAsset?.epochStartTime : activeFile.epochStartTime) : 0,
                    activeFile ? (activeFile.videoAsset ? activeFile.videoAsset?.defaultStartTime : activeFile.defaultStartTime) : 0,
                    x.epochStartTime || x.startTime
                ),
                team: x.team.name,
                id: x.base.id,
                startTime: x.startTime,
                epochStartTime: x.epochStartTime,
                player: getPlayerInEvent(x)?.value
            } as GameEvent))
        }

        return []
    }, [activeFile, matchEvents.state.events])

    useEffect(() => {
        if(!matchEvents.state.loading && eventsLoaded)
            eventsLoaded(matchEvents.state.events?.length > 0);
        },
        [matchEvents.state.loading])


    if (matchEvents.state.loading && selectedEventsFilters.length === 0 && selectedTeamsFilters.length === 0 && selectedPlayersFilters.length === 0)
        return <Spinner testId={'loading-spinner'} size={'large'}/>

    return (
        <section data-testid={'game-list-table'} className={'events-table'}>
            <Table
                columns={matchColumns}
                data={matchEventsList}
                initialState={{
                    hiddenColumns: ['timestamp', 'id', 'startTime', "epochStartTime"],
                }}
                mapColumnIdToName={(column: HeaderGroup) => {
                    return column.Header;
                }}
                getCustomRowProps={(row) => ({
                    onClick: () => {
                        onRowCLick(row.values as GameEvent)
                    },
                    style: {
                        cursor: 'pointer',
                    },
                    className: row.values.id === selectedEvent?.id ? statesSelected : states
                })}
                requestMoreData={() => {
                    matchEvents.operations.loadNextPage();
                }}
                hasMoreData={matchEvents.state.pageInfo?.hasNextPage ?? false}
                hasCustomRowProps
            />
        </section>
    )
};
