import { gql } from "@apollo/client";

export const MATCH_EVENT = gql`
    fragment EventFields on MatchEvent {
        base {
            id
        }
        startTime
        endTime
        epochStartTime
        epochEndTime
        matchPhase {
            phaseType {
                name
                mtValue
            }
        }
        eventType {
            base {
                id
            }
            name
            mtValue
        }
        team {
            base {
                id
            }
            name
        }
        fixture {
            base {
                id
            }
            homeTeam {
                name
            }
            awayTeam {
                name
            }
            competition {
                base {
                    id
                }
                name
            }
             delivery {
                 deliveredFiles {
                     fileDetails {
                         fileType
                     }
                 }
             }
            collectedFiles {
                videoAsset {
                    base {
                        id
                    }
                    # s3link
                }
            }
            providers(name: "MATCH_TRACKER") {
                values {
                    id
                }
            }
        }
        qualifiers {
            qualifierType {
                name
            }
            value
        }
        playerMatchInvolvements {
            base {
                id
            }
            involvementType
            player {
                firstName
                lastName
                base{
                    id
                }
            }
        }
    }
`;

export const MATCH_EVENTS = gql`
    ${MATCH_EVENT}
    query Events($filter: MatchEventFilter, $pageSize: Int, $pageNumber: Int) {
        matchEvents(filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
            edges {
                ...EventFields
            }
            pageInfo {
                pageSize
                pageNumber
                hasNextPage
                total
            }
        }
    }
`;
