import {ApolloError, useMutation} from "@apollo/client";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {client as hubClient} from "../../../Hub/client";
import {UPDATE_PLAYLIST} from "./updatePlaylist.gql"
import {HUB} from "../../../../services/i18n/i18n-constants";
import {clearSpecificCacheForAGivenClient} from "../../../commonApolloClientFunctions";

const useUpdatePlaylist = ( onError?: (error: ApolloError, errorMessage: string) => void, onSuccess?: () => void) => {
    const { t } = useTranslation();

    const [updatePlaylist, { error }] = useMutation(UPDATE_PLAYLIST, {
        onCompleted: () => {
            clearSpecificCacheForAGivenClient(hubClient, "playlists")
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    useEffect(() => {
        let errorMessage = t(HUB.UPDATE_COLLECTION_ERROR)
        handleErrorRaised(error, UPDATE_PLAYLIST, errorMessage, onError);
    }, [error, onError, t]);

    return {
        updatePlaylist: (updatePlaylistObject: {id: string, name?: string}[]) =>
            updatePlaylist({
                variables: {
                    "input": updatePlaylistObject
                },
            })
    };
};

export default useUpdatePlaylist;