import { gql } from "@apollo/client";

export const FIXTURE_HEADING = gql`
    fragment FixtureHeading on Fixture {
        base {
            id
        }

        date

        homeTeam {
            name
        }

        awayTeam {
            name
        }
    }
`;
