import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {Spinner, Typography} from "@catapultsports/referee-react";
import {PlaylistType} from "../../api/Hub/enum/Enum";
import {IPlaylistResponse} from "../../api/Hub/collections/__types__/Playlist";
import {GET_PLAYLIST} from "../../api/Hub/collections/collections.gql";
import {useMixPanel} from "../../providers/Mixpanel";
import {usePortalNavigation} from "../../utils/routerUtils";
import {useTranslation} from "react-i18next";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {HubTagToStreamUrl} from "../../utils/stringUtils";
import {Clip} from "../clip/clip";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import {Comments} from "../comments/comments";
import Expandable from "../expandableWrapper/expandable";
import {PlaylistControlsHeaderComponent} from "./playlistControlsHeaderComponent";
import {ITagItem} from "../../api/Hub/tags/__types__/Tags";

interface PlaylistScreenProps {
    playlistId: string,
    playlistType?: PlaylistType
    hasLoadedCallback?: () => void
}

export const PlaylistControls: React.FC<PlaylistScreenProps> = (props) => {
    const {playlistId, hasLoadedCallback, playlistType} = props;
    const [currentTab, setCurrentTab] = useState('clips');
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])

    const {loading, error, data, refetch} = useQuery<IPlaylistResponse>(GET_PLAYLIST, {
        variables: {id: playlistId, filters: {metadataIds: selectedFilters}},
    });

    const mixpanel = useMixPanel();
    const {navigate} = usePortalNavigation();
    const setPlaylistTags = useSessionStore(state => state.setPlaylistTags, shallow);
    const setPlaylistId = useSessionStore(state => state.setPlaylistId, shallow);
    const clearCommentTag = useSessionStore(state => state.clearCommentTag, shallow);
    const commentTagId = useSessionStore(state => state.comments.tagId, shallow);
    const setCommentTag = useSessionStore(state => state.setCommentTag, shallow);
    const tags = useSessionStore(state => state.playlist.tags, shallow);
    const currentStream = useSessionStore<string | undefined>(state => state.playlist.currentStream, shallow);
    const setStateStream = useSessionStore(state => state.setCurrentStream, shallow);
    const currentStreamIndex = useSessionStore<number>(state => state.playlist.currentStreamIndex, shallow);
    const setCurrentStreamIndex = useSessionStore(state => state.setStreamIndex, shallow);
    const setAutoPlay = useSessionStore(state => state.setAutoPlayStream, shallow);
    const currentTagId = useSessionStore(state => state.playlist.currentTagId, shallow);
    const [selectedTagIds, setSelectedTagIds] = useState<string[] | undefined>([]);
    const [areCollectionActionsOpen, setAreCollectionActionsOpen] = useState(false);
    const [isInitialRender, setIsInitialRender] = useState(true);

    const {t} = useTranslation();

    const selectAll = useCallback(() => {
        //deselect
        if (selectedTagIds?.length == data?.playlist.tags.length) {
            setSelectedTagIds([]);
        } else {
            setSelectedTagIds(data?.playlist.tags.map(tag => tag.id));
        }
    }, [data, selectedTagIds])

    useEffect(() => {
        clearCommentTag();
    }, []);

    useEffect(() => {
        if (data?.playlist.tags && data?.playlist.tags.length > 0) {
            if(isInitialRender) {
                const tag = data?.playlist?.tags[0];
                setPlaylistTags(data?.playlist.tags);
                const stream = HubTagToStreamUrl(tag)
                setStateStream(stream);
                setCurrentStreamIndex(0);
                setAutoPlay(false);
                navigate({to: `${tag.id}`, replace: true});
            }
        }
        if (hasLoadedCallback) {
            hasLoadedCallback();
        }
    }, [data?.playlist.tags, isInitialRender]);

    useEffect(() => {
        setPlaylistId(playlistId);
    }, [playlistId]);

    useEffect(() => {
        if (currentStream == undefined && tags.length > 0 && data?.playlist.tags && data?.playlist.tags.length > 0) {
            let nextTagIndex;

            if (currentStreamIndex >= data?.playlist.tags.length - 1) {
                nextTagIndex = 0;
                setAutoPlay(false);
            } else {
                nextTagIndex = currentStreamIndex + 1;
                setAutoPlay(true);
            }

            const nextTag = data?.playlist.tags[nextTagIndex]
            const stream = HubTagToStreamUrl(nextTag)
            setStateStream(stream);
            setCurrentStreamIndex(nextTagIndex);
            navigate({to: `${nextTag?.id}`, replace: true});
            setIsInitialRender(false)
        }
    }, [currentStream, data?.playlist.tags]);

    const clipsClicked = useCallback(() => {
        setCurrentTab('clips');
        clearCommentTag();
        setIsInitialRender(false)
    }, []);

    const commentsClicked = useCallback(() => {
        setCurrentTab('comments');
        clearCommentTag();
        setIsInitialRender(false)
    }, []);

    const handleClipClick = useCallback((tag: ITagItem, index: number) => {
        setIsInitialRender(false)
        if (tag.id === currentTagId)
            return;
        const stream = HubTagToStreamUrl(tag)
        setStateStream(stream);
        setCurrentStreamIndex(index);
        setAutoPlay(true);
        mixpanel.track(MixPanelEvents.COLLECTION_CLIP_CLICK, {
            tagId: tag.id,
            tagName: tag.name
        });
        navigate({to: `${tag.id}`, replace: true});
    }, [currentTagId, mixpanel, navigate, setAutoPlay, setCurrentStreamIndex, setStateStream]);

    const handleArrowClick = useCallback((id: string, name: string) => {
        setCommentTag(id, name);
        setCurrentTab('comments');
        setIsInitialRender(false)
    }, [setCommentTag, setCurrentTab]);

    if (error) {
        return error.message === t(HUB.NOT_FOUND) ?
            <Typography>{t(HUB.PLAYLIST_NOT_FOUND)}</Typography> :
            <Typography>{t(GENERAL.UNEXPECTED_ERROR) + error.message}</Typography>
    }

    return (
        <Expandable>
            <section data-testid={'clip-controls'} className='clips-controls'>
                {loading ? <Spinner/> :
                    <>
                        <PlaylistControlsHeaderComponent
                            data={data}
                            areCollectionActionsOpen={areCollectionActionsOpen}
                            setAreCollectionActionsOpen={setAreCollectionActionsOpen}
                            selectedTagIds={selectedTagIds}
                            setSelectedTagIds={setSelectedTagIds}
                            playlistType={playlistType}
                            selectAll={selectAll}
                            updatePlaylistCallback={refetch}
                        />
                        <section className='flex horizontal clips-section clips-comments'>
                            <div
                                className={`clips-comments-box${(currentTab === 'clips' || commentTagId) ? ' clips-comments-selected' : ''}`}
                                onClick={clipsClicked}>
                                <Typography variant={'heading-5'}>Clips</Typography>
                            </div>
                            <div
                                className={`clips-comments-box${(currentTab === 'comments' && !commentTagId) ? ' clips-comments-selected' : ''}`}
                                onClick={commentsClicked}>
                                <Typography variant={'heading-5'}>Comments</Typography>
                            </div>
                        </section>
                        {currentTab === 'clips' ?
                            <div className={"clipsList-container"}>
                                <div className={'clips-scroller'}>
                                    {data?.playlist.tags
                                        .map((tag, index) => (
                                            <Clip id={tag.id}
                                                  key={tag.id}
                                                  name={tag.name}
                                                  commentCount={tag.commentCount}
                                                  colour={tag.colour}
                                                  assets={tag.assets}
                                                  isActive={tag.id === currentTagId}
                                                  onClick={() => handleClipClick(tag, index)}
                                                  onArrowClick={(id: string, name: string) => handleArrowClick(id, name)}
                                                  displayCheckbox={areCollectionActionsOpen}
                                                  selectedTagIds={selectedTagIds}
                                                  setSelectedTagIds={setSelectedTagIds}
                                            />
                                        ))
                                    }
                                </div>
                            </div> :
                            <Comments
                                onClipBackClick={() => {
                                    setCurrentTab('clips');
                                    clearCommentTag();
                                }
                                }
                            />
                        }
                    </>
                }
            </section>
        </Expandable>
    )
}