import {clearApolloClientsCache} from "./apolloUtils";
import {clearPersistedState} from "../state/PersistedState";
import {clearSessionState} from "../state/SessionState";
import {client} from "../api/Hub/client";
import {LOGOUT} from "../api/authService/logout.rest.gql";

export const logoutFunction = () => {
    return new Promise((resolve, reject) => {
        try {
            // run all logout tasks in parallel using promises (assuming no dependencies between them)
            Promise.all([
                client.mutate({ mutation: LOGOUT, variables: { input: {} } }),
                clearApolloClientsCache(),
                new Promise((resolve) => {
                    clearPersistedState();
                    clearSessionState();
                    resolve(true) ;
                })
            ]).finally(() => resolve(true))
        } catch (ex) {
            reject(ex)
        }
    })
}