import {
  InMemoryCache,
} from "@apollo/client";
import { REFRESH_TOKEN } from "../Util/auth/refreshToken.rest.gql";
import {
  createErrorLink,
  createApolloClient,
} from "../commonApolloClientFunctions";
import { LICENSING } from "../../services/i18n/i18n-constants";

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userCustomerList: {
          merge(existing = [], incoming = []) {
            if (!incoming) return existing;
            return [...existing, ...incoming];
          },
        },
        userInstallerList: {
          merge(existing = [], incoming = []) {
            if (!incoming) return existing;
            return [...existing, ...incoming];
          },
        },
        licenses: {
          keyArgs: ["filter"],
          merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges],
            };
          },
        },
        users: {
          keyArgs: ["userFilter", ["customerIDs"]],
          merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges],
            };
          },
        },
        memberships: {
          keyArgs: ["filter", ["customerIDs"], "pageSize"],
          merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges],
            };
          },
        },
        pendingLicenses: {
          keyArgs: ["licensesStatusFilter, pageSize"],
          merge(existing = { edges: [] }, incoming = { edges: [] }) {
            if (!incoming.edges) return existing.length;
            return {
              ...incoming,
              edges: [...existing?.edges, ...incoming?.edges].length,
            };
          },
        },
      },
    },
    Membership: {
      keyFields: ["base", ["id"]]
    }
  }
});

const refreshToken = () => {
  return client.mutate({ mutation: REFRESH_TOKEN, variables: { input: {} } });
};

const errorLink = createErrorLink(refreshToken);

const urlSettings = {  linkType: LICENSING.HTTP_LINK,
  clientSpecificURI: process.env.CUSTOMER_API_URL,
  clientSpecificRestURI: ""}
const client = createApolloClient(errorLink, cache, null,  false, urlSettings );

export { client };
