export enum Routes {
    ROOT_PATH = '/',
    LOGIN_PAGE = 'login',
    LOGOUT_PAGE = 'logout',
    LICENSING_PAGE = 'licensing',
    MATCH_PAGE = 'matches',
    NEXT_GEN_PAGE = 'next-gen',
    HUB_PAGE = 'hub',
    ONE_LEVEL_BACK = '..',
    WILDCARD = '*',
    OVERVIEW = 'overview',
    COLLECTIONS = 'co',
    PRESENTATIONS = 'pr',
    CLIPS = 'clips',
    PIT_WALL = 'pitwall',
    MANAGEMENT = 'management',
    HOME = 'home'
}
