import React, {useCallback} from "react";
import "./nav.sass";
import { GlobalHeader } from "@catapultsports/referee-react";
import {
  IconFavourite,
  IconHelp,
  IconLogout,
  IconNotifications,
  IconUsers,
} from "@catapultsports/referee-react/icons";
import { Routes } from "../../enums/Routes";
import { usePortalNavigation } from "../../utils/routerUtils";
import { useLocation } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import {ACCOUNT, NAV} from "../../services/i18n/i18n-constants";
import {usePersistedStore} from "../../state/PersistedState";
import { shallow } from "zustand/shallow";
import { ReactComponent as F1Logo } from "../../../public/f1logo.svg";
import { Products } from "../../enums/Products";

const getRoute = (route: string) => {
  return `${Routes.ROOT_PATH}${route}`;
};

const F1LogoPaths: string[] = [getRoute(Routes.PIT_WALL)];

const DefaultRegion = 'eu-west-1';

export const Navigation: React.FC<{ onClick?: (selected: string) => void }> = (
  props
) => {
  const { onClick } = props;
  const { navigate } = usePortalNavigation();
  const clickHandler = onClick
    ? (selected: string) => onClick(selected)
    : () => {};
  const location = useLocation();
  const { t } = useTranslation();
  const onLogout = useCallback(
      () => navigate({ to: `/${Routes.LOGOUT_PAGE}`, replace: true }),
      [navigate]
  )
  const user = usePersistedStore((state) => state.user, shallow);
  const navItems = [];

  if (user.products.includes(Products.Hub)) {
    navItems.push({
      active: location.current.pathname.includes(getRoute(Routes.HUB_PAGE)),
      content: t(NAV.HUB),
      key: "nav-hub",
      as: "a",
      href: `#/${Routes.HUB_PAGE + "/" + Routes.OVERVIEW}`,
      onAuxClick: () => {
        clickHandler(Routes.HUB_PAGE + "/" + Routes.OVERVIEW);
      },
      onClick: () => {
        clickHandler(Routes.HUB_PAGE + "/" + Routes.OVERVIEW);
        navigate({
          to: Routes.HUB_PAGE + "/" + Routes.OVERVIEW,
          replace: true,
        });
      },
    });
  }

  if (user.products.includes(Products.Finder)) {
    navItems.push({
      active: location.current.pathname.includes(getRoute(Routes.MATCH_PAGE)),
      content: t(NAV.MATCHES),
      key: "nav-matches",
      as: "a",
      href: `#/${Routes.MATCH_PAGE}`,
      onAuxClick: () => {
        clickHandler(Routes.MATCH_PAGE);
      },
      onClick: () => {
        clickHandler(Routes.MATCH_PAGE);
        navigate({ to: Routes.MATCH_PAGE });
      },
    });
  }
if(process.env.DISPLAY_NEXTGEN_TAB == 'true') {
  navItems.push({
    active: location.current.pathname.includes(getRoute(Routes.NEXT_GEN_PAGE)),
    content: t(NAV.NEXT_GEN),
    key: "nav-next-gen",
    as: "a",
    href: `#/${Routes.NEXT_GEN_PAGE}`,
    onAuxClick: () => {
      clickHandler(Routes.NEXT_GEN_PAGE);
    },
    onClick: () => {
      clickHandler(Routes.NEXT_GEN_PAGE);
      navigate({to: Routes.NEXT_GEN_PAGE});
    },
  });
}

  // TODO: Removing PitWall to avoid displaying for any accounts with RaceWatch license
  // if (user.products.includes(Products.RaceWatch)) {
  //   navItems.push({
  //     active: location.current.pathname.includes(getRoute(Routes.PIT_WALL)),
  //     content: t(NAV.PIT_WALL),
  //     key: "nav-pitwall",
  //     as: "a",
  //     href: `#/${Routes.PIT_WALL}`,
  //     onAuxClick: () => {
  //       clickHandler(Routes.PIT_WALL);
  //     },
  //     onClick: () => {
  //       clickHandler(Routes.PIT_WALL);
  //       navigate({ to: Routes.PIT_WALL });
  //     },
  //   });
  // }

  // Remember to re-enable the test checking for this when adding back in
  // if (user.allowLicensing) {
  //   navItems.push({
  //     active: location.current.pathname.includes(getRoute(Routes.LICENSING_PAGE)),
  //     content: t(NAV.LICENSING),
  //     key: "nav-licensing",
  //     as: "a",
  //     href: `#/${Routes.LICENSING_PAGE}`,
  //     onAuxClick: () => {
  //       clickHandler(Routes.LICENSING_PAGE);
  //     },
  //     onClick: () => {
  //       clickHandler(Routes.LICENSING_PAGE);
  //       navigate({to: Routes.LICENSING_PAGE});
  //     },
  //   });
  // }

  const openURLInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <GlobalHeader
      // customLogo={
      //   F1LogoPaths.includes(location.current.pathname) ? <F1Logo /> : undefined
      // }
      key={location.current.pathname}
      testId={"main-nav"}
      primaryNavigationItems={navItems}
      secondaryNavigationItems={[
        // TODO: add back in once the functionality has been implemented, remember to re-add test assertions!
        // {
        //   active: false,
        //   content: <IconNotifications />,
        //   key: "nav-secondary-notification",
        //   label: t(NAV.NOTIFICATIONS)!,
        //   onClick: () => clickHandler("notifications"),
        // },
        {
          active: false,
          content: <IconHelp />,
          key: "nav-secondary-help",
          label: t(NAV.HELP)!,
          onClick: () => {
            clickHandler("help");
            openURLInNewTab(process.env.REACT_APP_SUPPORT_URL);
          },
        },
        // TODO: add back in once the functionality has been implemented, remember to re-add test assertions!
        // {
        //   active: false,
        //   content: <IconFavourite />,
        //   key: "nav-secondary-fav",
        //   label: t(NAV.FAVOURITES)!,
        //   onClick: () => clickHandler("favs"),
        // },
      ]}
      accountDetails={{
        avatarShape: "square",
        displayName: "Sample User",
        dropdownItems: [
          {
            startIcon: <IconUsers />,
            id: 0,
            isSelected: false,
            value: t(ACCOUNT.MY_ACCOUNT),
            onClick: () => clickHandler("account"),
          },
          {
            startIcon: <IconLogout />,
            id: 1,
            isSelected: false,
            value: t(ACCOUNT.LOG_OUT),
            onClick: onLogout,
          },
          {
            id: 'app-version',
            isSelected: false,
            value: `${user.region ?? DefaultRegion} v${process.env.APP_VERSION}`
          }
        ],
      }}
    />
  );
};
