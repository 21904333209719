import { gql } from "@apollo/client";

export const FIXTURE = gql`
    fragment FixtureFields on Fixture {
        base {
            id
        }
        date
        homeTeam {
            base {
                id
            }
            name
        }
        awayTeam {
            base {
                id
            }
            name
        }
        competition {
            base {
                id
            }
            name
        }
        collectedFiles {
            base {
                id
            }
            fileDetails {
                fileType
            }
        }
        delivery {
            deliveredFiles {
                base {
                    id
                }
                fileDetails {
                    fileType
                }
            }
        }
        providers(name: "MATCH_TRACKER") {
            values {
                id
            }
        }
    }
`;

export const FIXTURES = gql`
    ${FIXTURE}
    query Fixtures($filter: FixtureFilter, $pageSize: Int, $pageNumber: Int) {
        fixtures(filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
            edges {
                ...FixtureFields
            }
            pageInfo {
                pageSize
                pageNumber
                hasNextPage
                total
            }
        }
    }
`;

export const FIXTURE_BY_ID = gql`
    query Fixture($id: String!) {
        fixture(id: $id) {
            base {
                id
            }
            collectedFiles {
                base {
                    id
                }
                fileDetails {
                    fileType
                    s3Link
                    friendlyName
                }
                videoAsset {
                    base {
                        id
                    }
                    epochStartTime
                    defaultStartTime
                }
                __typename
            }
            delivery {
                deliveredFiles {
                    base {
                        id
                    }
                    fileDetails {
                        fileType
                        s3Link
                        friendlyName
                    }
                    epochStartTime
                    __typename
                }
            }
        }
    }
`;
