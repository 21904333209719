/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnnotationItemType {
  ARROW = "ARROW",
  ELLIPSE = "ELLIPSE",
  FREEHAND = "FREEHAND",
  MAGNIFY = "MAGNIFY",
  NONE = "NONE",
  SPOTLIGHT = "SPOTLIGHT",
  STRAIGHT_ARROW = "STRAIGHT_ARROW",
  TEXT_BOX = "TEXT_BOX",
}

export enum AssetType {
  AUDIO = "AUDIO",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  NONE = "NONE",
  OTHER = "OTHER",
  PDF = "PDF",
  UNAVAILABLE = "UNAVAILABLE",
  VIDEO = "VIDEO",
  WEBLINK = "WEBLINK",
}

export enum LayoutType {
  Grid = "Grid",
  List = "List",
}

export enum PlaylistType {
  Collection = "Collection",
  Playlist = "Playlist",
  Presentation = "Presentation",
  Session = "Session",
}

export enum SortType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TagType {
  CLIP = "CLIP",
  EXTERNAL = "EXTERNAL",
  IMAGE = "IMAGE",
  SLIDE = "SLIDE",
  UNKNOWN = "UNKNOWN",
}

export interface MetadataGroupFilter {
  metadataIds?: string[] | null;
  playlistType?: PlaylistType | null;
}

export interface PlaylistFilter {
  name?: string | null;
  playlistType?: PlaylistType | null;
  metadataIds?: string[] | null;
}

export interface PlaylistSort {
  modifiedTimestamp?: SortType | null;
  creationTimestamp?: SortType | null;
  playlistTimestamp?: SortType | null;
}

export interface SessionFilter {
  name?: string | null;
  metadataIds?: string[] | null;
}

export interface SessionSort {
  modifiedTimestamp?: SortType | null;
  creationTimestamp?: SortType | null;
  sessionTimestamp?: SortType | null;
}

export interface TagFilter {
  name?: string | null;
  metadataIds?: string[] | null;
  tagIds?: string[] | null;
}

export interface TagSort {
  modifiedTimestamp?: SortType | null;
  creationTimestamp?: SortType | null;
  tagTimestamp?: SortType | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
