import {gql} from "@apollo/client";

export const USERSPAGE = gql`
  query($userFilter: UserFilter, $pageNumber: Int, $pageSize: Int) {
    users(filter:$userFilter, pageNumber: $pageNumber, pageSize: $pageSize){
      edges {
          base{id}
            firstName
            lastName
            email
            accountStatus
            roleDetail{
              mainRole
          }
      }
        pageInfo {
            total
            pageSize
            pageNumber
            hasNextPage
        }
    }
}
`;
