import React, {useCallback, useMemo, useState} from "react";
import {INote} from "../../api/Hub/tags/__types__/Tags";
import {Button, Input, Typography as Text} from "@catapultsports/referee-react";
import {IconAdd, IconDelete, IconPencil, IconSave} from "@catapultsports/referee-react/icons";
import './notes.sass'
import {useTranslation} from "react-i18next";
import {NOTES} from "../../services/i18n/i18n-constants";
import {compareTwoArrayOfObjects} from "../labels/__commons__/commonFunctions";

interface NotesProps {
    notes: INote[],
    onSave?: (defaultNote: INote | null, notes: INote[]) => void
}

export const Notes: React.FC<NotesProps> = (props) => {
    const { notes, onSave } = props
    const {t} = useTranslation();
    const [internalNotes, setInternalNotes] = useState(GetInternalNotes(notes, t(NOTES.DEFAULT_NOTE) || ''))
    const defaultNote = useMemo(
        () => {
            return internalNotes && internalNotes.length > 0
                ? internalNotes.find(note => note.id.toLowerCase() == 'default')
                : undefined
        },
        [internalNotes]
    )
    const notesFromTheServer = useMemo(() => {
        return notes && notes.length > 0
            ? notes.map(note => ({"id": note.id, "heading": note.heading, "value": note.value}))
            : []
    }, [notes])

    let isSaveButtonDisabled = useMemo(() => {
        if(notesFromTheServer && notesFromTheServer.length > 0 && internalNotes && internalNotes.length > 0){
            return compareTwoArrayOfObjects(notesFromTheServer, internalNotes, "value", "id")
        }
    }, [internalNotes, notesFromTheServer]);

    const onNoteAdd = useCallback(
        () => {
            const numbers = internalNotes
                .filter(n => n.heading.startsWith('Note'))
                .map(n => n.heading.replace(/[^0-9]/g, ''))
                .filter(n => !isNaN(parseInt(n)))
                .map(n => parseInt(n) + 1)
            const maxNoteNumber = Math.max(...numbers, 1)
            const newNote = { id: `new-${maxNoteNumber}`, heading: `Note #${maxNoteNumber}`, value: '', isEdit: true }
            setInternalNotes([...internalNotes, newNote])
        },
        [internalNotes]
    )

    const onNotesSave = useCallback(
        () => {
            const validNotes = [...internalNotes.filter(n => n.value !== '').map(n => ({...n, isEdit: false}))]
            setInternalNotes(validNotes)
            const areNotesEqual = compareTwoArrayOfObjects(notesFromTheServer, validNotes, "value", "id")
            if(!areNotesEqual) onSave && onSave(defaultNote ? defaultNote : null, validNotes)
        },
        [internalNotes, notesFromTheServer, onSave, defaultNote]
    )
    const onNoteDelete = useCallback((noteId: string) => {
        let updatedNotes = [...internalNotes.filter(n => n.id !== noteId)]
        const validNotes = [...updatedNotes.filter(n => n.value !== '').map(n => ({...n, isEdit: false}))]

        const areNotesEqual = compareTwoArrayOfObjects(notesFromTheServer, validNotes, "value", "id")

        if(!areNotesEqual) onSave && onSave(defaultNote ? defaultNote : null, validNotes)
        setInternalNotes(updatedNotes)

    }, [defaultNote, internalNotes, notesFromTheServer, onSave])

    const onNoteEdit = useCallback((noteId: string) => {
        const target = internalNotes.find(n => n.id === noteId)
        if (target) {
            setInternalNotes([{...target, isEdit: !target.isEdit}, ...internalNotes.filter(n => n.id !== noteId)])
        }
    }, [internalNotes])

    const updateNote = useCallback((noteId: string, value: string) => {
        const target = internalNotes.find(n => n.id === noteId)
        if (target) {
            setInternalNotes([{...target, value}, ...internalNotes.filter(n => n.id !== noteId)])
        }
    }, [internalNotes])

    return (
        <section data-testid={'clip-notes'} className={'clip-notes'} style={{ maxHeight: internalNotes.length > 1 ? '150px' : '100px' }}>
            {defaultNote && (
                <div className={'default-note'}>
                    <div className={'note-heading'}>
                        <Text>{defaultNote.heading}</Text>
                        <div>
                            {internalNotes.some(n => n.isEdit) && (
                                <Button testId={'btn-save'} size={'small'} variant={'text'} onClick={onNotesSave} title={t(NOTES.SAVE_CHANGES) || ''} disabled={isSaveButtonDisabled}>
                                    <IconSave />
                                </Button>
                            )}
                            <Button testId={'btn-add'} size={'small'} variant={'text'} onClick={onNoteAdd} title={t(NOTES.ADD_NOTE) || ''}>
                                <IconAdd />
                            </Button>
                            {defaultNote && !defaultNote.isEdit && (
                                <Button testId={'btn-default-edit'} size={'small'} variant={'text'} onClick={() => onNoteEdit('default')} title={t(NOTES.EDIT_NOTE) || ''}>
                                    <IconPencil />
                                </Button>
                            )}
                        </div>
                    </div>
                    {defaultNote.isEdit
                        ? <Input
                                as={'textarea'}
                                onChange={event => {
                                    // @ts-ignore
                                    updateNote('default', event.target.value)
                                }}
                                value={defaultNote.value}
                                fullWidth
                            />
                        : defaultNote.value !== ''
                            ? <Text
                                testId={`note-${defaultNote.id}`}
                                className={"default-note-value"}
                            >
                                {defaultNote.value}
                            </Text>
                            : <span/>
                    }
                </div>
            )}
            {internalNotes && internalNotes.filter(n => n.id !== 'default').length > 0 && (
                <section data-testid={'internal-notes'} className={'internal-notes'}>
                    <div key={'internal-scroller'}>
                        {internalNotes.filter(n => n.id !== 'default').map(note => (
                            <div key={note.id} data-testid={`internal-note-${note.id}`} className={'internal-note'}>
                                <div className={'note-heading'}>
                                    <Text>{note.heading}</Text>
                                    <div>
                                        {!note.isEdit && (
                                            <Button testId={`btn-${note.id}-edit`} size={'small'} variant={'text'} onClick={() => onNoteEdit(note.id)} title={t(NOTES.EDIT_NOTE) || ''}>
                                                <IconPencil />
                                            </Button>
                                        )}
                                        <Button testId={`btn-${note.id}-remove`} size={'small'} variant={'text'} onClick={() => onNoteDelete(note.id)} title={t(NOTES.REMOVE_NOTE) || ''}>
                                            <IconDelete />
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    {note.isEdit
                                        ? <Input as={'textarea'} onChange={event => {
                                            // @ts-ignore
                                            updateNote(note.id, event.target.value)
                                        }} fullWidth value={note.value} />
                                        : <Text
                                            testId={`note-${note.id}`}
                                            className={"default-note-value"}
                                        >
                                            {note.value}
                                        </Text>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            )}
        </section>
    )
}

const GetInternalNotes = (notes: INote[], defaultHeading: string) => {
    const internalNotes = notes.map(note => ({...note, isEdit: false}))
    const hasDefault = notes.some(note => note.id.toLowerCase() == 'default')

    if (!hasDefault) {
        internalNotes.push({ id: 'default', heading: defaultHeading, value: '', isEdit: false })
    }

    return internalNotes
}