import {useEffect} from "react";
import {ISeasonResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {SEASONS} from "./seasons.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";
import {ApiPageSize} from "../../enums/apiMagic";

const useSeasons = (
    competitionIDs?: [],
    seasonIDs?: [],
    pageSize: number = -1,
    pageNumber: number = 0,
    onError?: (error: ApolloError, errorMessage: string) => void
) => {
    const { loading, error, data } = useQuery<ISeasonResponse>(SEASONS, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize ?? ApiPageSize.All,
            filter: {
                competitionIDs: competitionIDs ?? [],
                seasonIDs: seasonIDs ?? [],
            }
        }
    });

    useEffect(() => {
        handleErrorRaised(error, SEASONS, ApiErrors.Seasons, onError);
    }, [error, onError]);


    return {
        state: {
            seasons: data?.seasons?.edges,
            seasonsPageSize: pageSize,
            seasonsLoading: loading,
        }
    };
};

export default useSeasons;