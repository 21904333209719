export enum MixPanelEvents {
    // Login Screen Events
    LOGIN_SUCCESS = 'Hub-Portal: Login - Login Successful',
    LOGIN_FAILED = 'Hub-Portal: Login - Login Failed',
    LOGIN_SUBMITTED = 'Hub-Portal: Login - Form Submitted',
    LOGOUT = 'Hub-Portal: Logout - Manual',
    LOGOUT_FORCED = 'Hub-Portal: Logout - Forced',

    //Hub Navigation Events
    NAVIGATION_ENTERED_SCREEN = 'Hub-Portal: Entered Screen',
    NAVIGATION_OPENED_NEW_SCREEN = 'Hub-Portal: Opened New Winder',
    NAVIGATION_OPTION_SELECTED = 'Hub-Portal: Navigation option selected',


    // Hub Collection Events
    COLLECTION_SELECT = 'Hub-Portal: Collection Selected',
    COLLECTION_SORT = 'Hub-Portal: Collection Sorted',
    COLLECTION_CLIP_CLICK = 'Hub-Portal: Collection Clip (Selected)',
    CLIP_CLICK = 'Hub-Portal: Clip (Selected)',
    COLLECTION_CLIP_ANGLE_CHANGE = 'Hub-Portal: Collection Clip (Angle Change)',
    COLLECTION_CLIP_PLAYING = 'Hub-Portal: Collection Clip (Playing)',
    CLIP_PLAYING = 'Hub-Portal: Clip (Playing)',
    PRESENTATION_SELECT = 'Hub-Portal: Presentation Selected',
    PRESENTATION_SORT = 'Hub-Portal: Presentation Sorted',
    LABELS_UPDATED = 'Labels Updated: New labels for tag',

    // F1 Video Events
    F1_VIDEO_PLAY = 'Pit Wall: Video - Play',
    F1_VIDEO_PAUSE = 'Pit Wall: Video - Pause',
    F1_VIDEO_EJECT = 'Pit Wall: Video - Eject',
    F1_VIDEO_SEEK = 'Pit Wall: Video - Seek',
    F1_VIDEO_SCRUB = 'Pit Wall: Video - Scrub',
    F1_VIDEO_PLAYBACK = 'Pit Wall: Video - Change Speed',
    F1_VIDEO_MUTE = 'Pit Wall: Video - Mute',
    F1_VIDEO_UNMUTE = 'Pit Wall: Video - Unmute',
    F1_VIDEO_OVERLAY = 'Pit Wall: Video - Toggle Overlay',
    F1_VIDEO_FULLSCREEN = 'Pit Wall: Video - Toggle Fullscreen',
    F1_VIDEO_DROP = 'Pit Wall: Video - Drag and Drop',

    // F1 Tag Display Events
    F1_LAYOUT_CHANGE = 'Pit Wall: Layout - Change',

    // NextGenFinder Events
    FINDER_CLIP_PLAYING = 'Matches-Screen: Finder Clip (Playing)',
    FINDER_GAME_NEW_FILE_SELECTED = 'Matches-Screen: Game Viewer New File Selected',
    FINDER_GAME_MATCH_EVENT_SELECTED = 'Matches-Screen: Game Viewer Match Event Selected',
    FINDER_GAME_PLAYER_EVENT_SELECTED = 'Matches-Screen: Game Viewer Player Event Selected',
    FINDER_GAME_PLAYER_EVENT_FILTERS_UPDATED = 'Matches-Screen: Game Viewer Player Event Filters Updated',
    FINDER_GAME_PLAYER_TEAM_FILTERS_UPDATED = 'Matches-Screen: Game Viewer Player TEAM Filters Updated',
    FINDER_GAME_RESULT_SELECTED = 'Matches-Screen:  Game Result Selected',

    // Licensing Events
    LICENSING_MANAGE_DOWNLOAD = 'Licensing: Management - Download License',
    LICENSING_MANAGE_PIN = 'Licensing: Management - Pin License',
    LICENSING_MANAGE_EDIT = 'Licensing: Management - Edit License',
    LICENSING_MANAGE_APPROVE = 'Licensing: Management - Approve License',
    LICENSING_MANAGE_REJECT = 'Licensing: Management - Reject License',
    LICENSING_MANAGE_REVOKE = 'Licensing: Management - Revoke License',
    LICENSING_MANAGE_DELETE = 'Licensing: Management - Delete License',
    LICENSING_REQUEST_NEW_PASSWORD = 'Licensing: Management - Password Reset Requested',
    LICENSING_DELETE_USER = 'Licensing: Management - Delete User',
    MEMBERSHIP_AUTO_APPROVE = "Membership: Auto Approve"
}