import React, {useEffect} from "react";
import {usePortalNavigation} from "../../utils/routerUtils";
import {Spinner} from "@catapultsports/referee-react";
import {logoutFunction} from "../../utils/logoutUtils";
import {useMixPanel} from "../../providers/Mixpanel";
import './logout.sass'
import {LOGIN} from "../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import {Routes} from "../../enums/Routes";
import {MixPanelEvents} from "../../enums/MixPanelEvents";

export const Logout: React.FC<any> = () => {
    const {navigate} = usePortalNavigation();
    const mixpanel = useMixPanel();
    const {t} = useTranslation();
    const isForcedLogout = window.location.hash.includes('?forced=true');

    useEffect(
        () => {
            // All logout roads should lead here, execute clearState() and the app will redirect to this screen
            if (mixpanel) {
                logoutFunction()
                    .finally(() => {
                        mixpanel.track(isForcedLogout ? MixPanelEvents.LOGOUT_FORCED : MixPanelEvents.LOGOUT)
                        navigate({
                            to: '/' + Routes.LOGIN_PAGE,
                            replace: true
                        })
                    })
            }
        },
        [mixpanel, isForcedLogout]
    )

    return (
        <article className={'logout'}>
            <Spinner size={'large'} variant={'muted'} /><br />
            {t(isForcedLogout ? LOGIN.EXPIRED_REDIRECT : LOGIN.LOGOUT)}
        </article>
    );
};
