import {HttpLink, InMemoryCache} from "@apollo/client";
import {REFRESH_TOKEN} from "../Util/auth/refreshToken.rest.gql";
import {createPersistedQueryLink} from "@apollo/client/link/persisted-queries";
import {sha256} from "crypto-hash";
import {createErrorLink, createApolloClient, createApolloLinks, hubAPILink} from "../commonApolloClientFunctions";
import { LICENSING } from "../../services/i18n/i18n-constants";
import {client as hubClient } from "../Hub/client"


const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                fixtures: {
                    keyArgs: ["filter", "dates", "competitionIDs", "eventTypeIDs", "qualifierTypeIDs", "phaseTypeIDs", "teamIDs", "providerIDs", "playerIDs", "hasVideo"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                matchEvents: {
                    keyArgs: ["filter", "dates", "competitionIDs", "eventTypeIDs", "qualifierTypeIDs", "phaseTypeIDs", "teamIDs", "providerIDs", "playerIDs", "officialIDs", "hasVideo"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                eventTypes: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                qualifierTypes: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                phaseTypes: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                players: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                providers: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                teams: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                competitions: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                competitionSeasons: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                venues: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                },
                officialMatchInvolvements: {
                    keyArgs: ["filter"],
                    merge(existing = { edges: [] }, incoming = { edges: [] }) {
                        if (!incoming.edges) return existing;

                        return { ...incoming, edges: [...existing?.edges, ...incoming?.edges] };
                    }
                }
            }
        },
        VideoAsset: {
            keyFields: ["base", ["id"]],
            fields: {
                defaultStartTime(value = 0) {
                    return value / 1000;
                }
            }
        },
        EventType: {
            fields: {
                name(value) {
                    return value[0] + value.slice(1).replaceAll("_", " ").toLowerCase();
                }
            }
        },
        QualifierType: {
            fields: {
                name(value) {
                    return value[0] + value.slice(1).replaceAll("_", " ").toLowerCase();
                }
            }
        },
        PhaseType: {
            fields: {
                name(value) {
                    return value[0] + value.slice(1).replaceAll("_", " ").toLowerCase();
                }
            }
        },
        Provider: {
            keyFields: ["base", ["id"]],
            fields: {
                name(value) {
                    if (!value.includes("_")) {
                        return value;
                    }
                    return value[0] + value.slice(1).replaceAll("_", " ").toLowerCase();
                }
            }
        }
    }
});

const persistedQueryLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

const refreshToken = () => {
    return hubClient.mutate({ mutation: REFRESH_TOKEN, variables: { input: {} }});
};

const errorLink = createErrorLink(refreshToken);

const getDefaultMatchtrackerLink = (): string | undefined => {
    const persistentStorage = localStorage.getItem('temp-persistent-storage');
    if (persistentStorage) {
        const storageObject = JSON.parse(persistentStorage);
        if (storageObject?.state?.user?.matchtrackerUrl)
            return storageObject.state.user.matchtrackerUrl;
    }

    return process.env.MATCHTRACKER_API_URL;
};

const urlSettings = {  linkType: LICENSING.HTTP_LINK,
    clientSpecificURI: getDefaultMatchtrackerLink(),
    clientSpecificRestURI: ""}

const client = createApolloClient(errorLink, cache, persistedQueryLink, false, urlSettings );

const updateLink = (newLink: HttpLink) => {
    client.setLink(
        createApolloLinks(
            errorLink,
            persistedQueryLink,
            true,
            urlSettings,
            newLink));
};

export {client, updateLink};