import {PersistedState} from '../StoreSlice';
import {Products} from "../../../enums/Products";
import {PitWallLayout} from "../../../screens/pitwall/__types__/F1Enums";
import {StateCreator} from "zustand";

export interface UserSliceProps {
	user: {
		userId: string | undefined,
		token: string | undefined,
		expiry: number | undefined,
		rememberMe: boolean,
		products: Products[],
		email: string | undefined,
		allowLicensing: boolean,
		hubUrl: string | undefined,
		matchtrackerUrl: string | undefined,
		region: string | undefined
	},
	settings: {
		f1Layout: PitWallLayout
	}
	persistUser: (userId: string, token: string, expiry: number, products: Products[], email: string) => void
	clearUser: () => void,
	persistRememberMe: (value: boolean) => void,
	changeF1Layout: (layout: PitWallLayout) => void,
	setAllowLicensing: (allowLicensing: boolean) => void
	persistHubUrl: (hubUrl: string | undefined) => void
	persistMatchtrackerUrl: (matchtrackerUrl: string | undefined) => void
	persistRegion: (region: string | undefined) => void
}

export const createUserSlice: StateCreator<PersistedState,  [["zustand/persist", unknown], ["zustand/devtools", never]], [], UserSliceProps> =
	(set) => ({
		user: {
			userId: undefined,
			token: undefined,
			expiry: undefined,
			rememberMe: true,
			products: [],
			email: undefined,
			allowLicensing: false,
			hubUrl: undefined,
			matchtrackerUrl: undefined,
			region: undefined
		},
		settings: {
			f1Layout: PitWallLayout.Quad
		},
		persistUser: (userId: string, token: string, expiry: number, products: Products[], email: string) => set((state: UserSliceProps) => {
			state.user.userId = userId;
			state.user.token = token;
			state.user.expiry = expiry;
			state.user.products = products;
			state.user.email = email;
			return state;
		}),
		clearUser: () => set((state: UserSliceProps) => {
			state.user.userId = undefined;
			state.user.token = undefined;
			state.user.expiry = undefined;
			state.user.products = [];
			state.user.email = undefined;
			return state;
		}),
		persistRememberMe: (value: boolean) => set((state: UserSliceProps) => {
			state.user.rememberMe = value;
			return state;
		}),
		changeF1Layout: (layout: PitWallLayout) => set((state: UserSliceProps) => {
			state.settings.f1Layout = layout;
			return state;
		}),
		setAllowLicensing: (allowLicensing: boolean) => set((state: UserSliceProps) => {
			state.user.allowLicensing = allowLicensing;
			return state;
		}),
		persistHubUrl: (hubUrl: string | undefined) => set((state: UserSliceProps) => {
			state.user.hubUrl = hubUrl;
			return state;
		}),
		persistMatchtrackerUrl: (matchtrackerUrl: string | undefined) => set((state: UserSliceProps) => {
			state.user.matchtrackerUrl = matchtrackerUrl;
			return state;
		}),
		persistRegion: (region: string | undefined) => set((state: UserSliceProps) => {
			state.user.region = region;
			return state;
		})
})