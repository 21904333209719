import {
    ICollectedFiles,
    IDeliveredFiles
} from "../api/MatchTracker/fixtureContent/__types__/FixtureContent";

export const fileObjectToReturn = (fileObject: ICollectedFiles | IDeliveredFiles) => {
    return {
        name: fileObject.fileDetails.friendlyName,
        contentURL: fileObject.fileDetails.s3Link,
        downloadable: false,
        epochStartTime: 'videoAsset' in fileObject
            ? fileObject.videoAsset?.epochStartTime
            : fileObject.epochStartTime,
        defaultStartTime: 'videoAsset' in fileObject
            ? fileObject.videoAsset?.defaultStartTime
            : undefined
    }
}