import React, {useCallback} from "react";
import {Input, Select, SelectOption, Spinner, Typography} from "@catapultsports/referee-react";
import './filterChipList.sass';
import '../NDSPill/pill.sass';
import {
    IconCalendar,
    IconCoach,
    IconCrosshair,
    IconDataDownload,
    IconErrorWarning,
    IconEvent,
    IconPeople,
    IconPerson,
    IconStadium,
    IconStopwatch1,
    IconTrophy,
    IconWhistle
} from "@catapultsports/referee-react/icons";
import {FilterChipIcon} from "../../enums/FilterChipIcon";
import {Pill} from "../NDSPill/pill";
import {QueryGroup, QueryOperator} from "../../screens/nextGenFinder/__types__";
import {QualifierValueType} from "../searchFilter/__mocks__/data";
import {OperatorMenu} from "../searchFilter/subComponents/operatorMenu";

interface FilterChipListProps {
    activeFilters?: QueryGroup[] | undefined,
    onRemove?: (id: string) => void
    onFilterUpdated: (filter: QueryGroup) => void
}

export interface FilterChip
{
    name: string,
    qualifierTypeId: number,
    operator: QueryOperator,
    value: string | string[],
    valueType: QualifierValueType,
    formatType: string
}

export const FilterChipList: React.FC<FilterChipListProps> = (props) => {
    const {activeFilters, onRemove, onFilterUpdated} = props;

    const filterChipIconToSVG = (icon: FilterChipIcon) => {
        switch (icon) {
            case FilterChipIcon.Trophy:
                return <IconTrophy/>;
            case FilterChipIcon.Event:
                return <IconEvent/>;
            case FilterChipIcon.Stopwatch:
                return <IconStopwatch1/>;
            case FilterChipIcon.Person:
                return <IconPerson/>;
            case FilterChipIcon.Crosshair:
                return <IconCrosshair/>;
            case FilterChipIcon.Download:
                return <IconDataDownload/>;
            case FilterChipIcon.People:
                return <IconPeople/>;
            case FilterChipIcon.Stadium:
                return <IconStadium/>;
            case FilterChipIcon.Calendar:
                return <IconCalendar/>;
            case FilterChipIcon.Coach:
                return <IconCoach/>;
            case FilterChipIcon.Referee:
                return <IconWhistle/>;
            default:
                return <IconErrorWarning/>;
        }
    }

    const onOperatorUpdated = useCallback((filterId: string, operator: QueryOperator) => {
        let updatedFilter = activeFilters?.find(x => x.qualifierTypeId === filterId);

        if (updatedFilter && updatedFilter.conditions) {
            const { conditions, valueType } = updatedFilter;

            if (conditions.length > 1 && operator !== QueryOperator.Between && valueType === QualifierValueType.Numeric) {
                updatedFilter.conditions[1].operator = operator;
                updatedFilter.conditions = [updatedFilter.conditions[1]];
            } else if (operator === QueryOperator.Between) {
                updatedFilter.conditions = [
                    {
                        value: conditions[0].limits?.min ?? 0,
                        operator: QueryOperator.GreaterThanEqualTo
                    },
                    {
                        value: conditions[0].value,
                        operator: QueryOperator.LessThanEqualTo
                    }
                ];
            } else {
                updatedFilter.conditions = conditions.map(x => ({ ...x, operator }));
            }

            onFilterUpdated(updatedFilter);
        }
    }, [activeFilters, onFilterUpdated]);

    const onInputChange = useCallback((filterId: string, value: number, index: number) => {
        const updatedFilter = activeFilters?.find(x => x.qualifierTypeId === filterId);

        if (updatedFilter && updatedFilter.conditions) {
            const condition = updatedFilter.conditions[index];
            const { valueType, conditions } = updatedFilter;

            if (valueType === QualifierValueType.Numeric) {
                let { max = 100, min = 0 } = condition.limits || {};

                if (conditions.length > 1) {
                    if (index === 0) {
                        max = conditions[1].value;
                    } else if (index === 1) {
                        min = conditions[0].value;
                    }
                }

                if (value >= max) {
                    condition.value = max;
                } else if (value <= min) {
                    condition.value = min;
                } else {
                    condition.value = value;
                }
            } else {
                condition.value = value;
            }

            onFilterUpdated(updatedFilter);
        }
    }, [activeFilters, onFilterUpdated]);


    const onSelectUpdate = useCallback((filterId: string, items: SelectOption[]) => {
        const updatedFilter = activeFilters?.find(x => x.qualifierTypeId === filterId);

        if (updatedFilter && updatedFilter.conditions) {
            const defaultOperator = updatedFilter.conditions[0].operator ?? QueryOperator.EqualExact;

            updatedFilter.conditions = items.map(x => ({
                value: x.value,
                operator: defaultOperator
            }));

            onFilterUpdated(updatedFilter);
        }
    }, [activeFilters, onFilterUpdated]);

    function RenderFilterChip(chip: QueryGroup) {
        // Event handler for updating operator
        const handleOperatorUpdate = (operator: QueryOperator) => {
            onOperatorUpdated(chip.qualifierTypeId, operator);
        };

        // Event handler for updating input values
        const handleInputChange = (value: number, index: number) => {
            onInputChange(chip.qualifierTypeId, +value, index);
        };

        // Event handler for updating select options
        const handleSelectUpdate = (items: SelectOption[]) => {
            onSelectUpdate(chip.qualifierTypeId, items);
        };

        // Ranged Numeric filter
        if (chip.valueType === QualifierValueType.Numeric && chip.conditions && chip.conditions.length > 1) {
            return (
                <div className="pill-content">
                    <Typography variant="body-1" className="pill-text">{chip.qualifierTypeName}:</Typography>
                    <div className="pill-input">
                        <Input
                            onInput={(e) => handleInputChange(e.target.value, 0)}
                            size="small"
                            type="number"
                            value={+chip.conditions[0].value ?? 0}
                        />
                    </div>
                    <OperatorMenu
                        activeOperator={QueryOperator.Between}
                        filterType={chip.valueType}
                        operatorUpdated={handleOperatorUpdate}
                    />
                    <div className="pill-input">
                        <Input
                            onInput={(e) => handleInputChange(e.target.value, 1)}
                            className="pill-input"
                            size="small"
                            type="number"
                            value={+chip.conditions[1]?.value ?? 0}
                        />
                    </div>
                </div>
            );
        }

        // Switch filter
        if (chip.valueType === QualifierValueType.Boolean) {
            return (
                <div className="pill-content">
                    <Typography variant="body-1" className="pill-text">{chip.qualifierTypeName}</Typography>
                </div>
            );
        }

        // Select filter
        if (chip.valueType === QualifierValueType.List) {
            const selectableValues = chip.selectableOptions?.map(value => ({
                id: value.toString(),
                value: value.toString(),
                isSelected: chip.conditions && chip.conditions.some(x => x.value == value)
            }));

            return (
                <div className="pill-content">
                    <Typography variant="body-1" className="pill-text">{chip.qualifierTypeName} </Typography>
                    <OperatorMenu
                        activeOperator={chip.conditions[0]?.operator ?? QueryOperator.EqualExact}
                        filterType={chip.valueType}
                        operatorUpdated={handleOperatorUpdate}
                    />
                    <Select
                        isMultiselect
                        isExternalUpdated
                        onSelectItem={handleSelectUpdate}
                        size="small"
                        testId="pill-select"
                        menuItems={selectableValues}
                    />
                </div>
            );
        }
        if (chip.valueType === QualifierValueType.Team || chip.valueType === QualifierValueType.Player) {
            return (
                <div className="pill-content">
                    <OperatorMenu
                        activeOperator={chip.conditions[0]?.operator ?? QueryOperator.EqualExact}
                        filterType={chip.valueType}
                        operatorUpdated={handleOperatorUpdate}
                    />
                    <Typography variant="body-1" className="pill-text">{chip.qualifierTypeName}</Typography>
                </div>
            );
        }

        // Basic Numberic Input
        return (
            <div className="pill-content">
                <Typography variant="body-1" className="pill-text">{chip.qualifierTypeName}</Typography>
                <OperatorMenu
                    activeOperator={chip.conditions[0]?.operator ?? QueryOperator.EqualExact}
                    filterType={chip.valueType}
                    operatorUpdated={handleOperatorUpdate}
                />
                <div className="pill-input">
                    <Input
                        onInput={(e) => handleInputChange(e.target.value, 0)}
                        size="small"
                        type="number"
                        value={+chip.conditions[0]?.value ?? 0}
                    />
                </div>
            </div>
        );
    }

// Render the filter chips
    return (
        <section data-testid="filter-chip">
            {/* Check if there are active filters */}
            {activeFilters && activeFilters.length > 0 && activeFilters.map((chip, index) => (
                <Pill
                    key={chip.qualifierTypeId}
                    testId={`filter-pill-${index}`}
                    value={RenderFilterChip(chip)}
                    onClose={() => onRemove && onRemove(chip.qualifierTypeId)}
                    startIcon={
                        chip.conditions?.length > 0
                            ? (chip.valueType !== QualifierValueType.Team && chip.valueType !== QualifierValueType.Player ? filterChipIconToSVG(FilterChipIcon.Trophy): null)
                            : <Spinner />
                    }
                    shape="square"
                />
            ))}
        </section>
    );

};
