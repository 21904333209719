import { createContext, useContext, ReactNode } from 'react';
import { init, Mixpanel } from 'mixpanel-browser';
import React from 'react';

const MixPanelContext = createContext({} as Mixpanel);
const useMixPanel = () => useContext(MixPanelContext);

const MixPanelProvider = ({children}: {children : ReactNode}) => {
    const isLocal = process.env.LOCAL == 'true';
    const instance = init(process.env.MIXPANEL_TOKEN!, { test: isLocal, debug: isLocal }, 'hub-portal');
    return (
        <MixPanelContext.Provider value={ instance }>
            {children}
        </MixPanelContext.Provider>
    );
};

export { useMixPanel, MixPanelContext };
export default MixPanelProvider;
