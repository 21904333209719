import React, { useEffect } from 'react';

export function useOutsideClick(
    ref: React.MutableRefObject<any>,
    callback: () => void,
    parentRef?: React.MutableRefObject<any>
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent | TouchEvent) {
            if (
                ref &&
                ref.current &&
                !ref.current.contains(event.target) &&
                (!parentRef || (parentRef && parentRef.current && !parentRef.current.contains(event.target)))
            ) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);
        };
    }, [ref, ref.current, callback, parentRef, parentRef?.current]);
}

