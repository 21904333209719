import {Routes} from "../enums/Routes";
import {Login} from "../screens/login/login";
import {RequireAuth} from "../screens/requireAuth";
import {Hub} from "../screens/hub/hub";
import {Finder} from "../screens/finder/finder";
import {Licensing} from "../screens/licensing/licensing";
import React from "react";
import {CollectionsScreen} from "../screens/hub/subPages/collections";
import {PlaylistType} from "../api/Hub/enum/Enum";
import {Clips} from "../screens/hub/subPages/clips";
import {Lost} from "../screens/lost/lost";
import {Navigate, Outlet} from "@tanstack/react-location";
import {PlaylistScreen} from "../screens/hub/subPages/playlist";
import {PitWall} from "../screens/pitwall/pitWall";
import {Overview} from "../screens/hub/subPages/overview";
import {RequireProduct} from "../screens/requireProduct";
import {Products} from "../enums/Products";
import {PlaylistClipPlayer} from "../screens/hub/subPages/playlistClipPlayer";
import {ClipPlayer} from "../screens/hub/subPages/clipPlayer";
import {LicenseManagement} from "../screens/licensing/subPages/licensingManagement";
import {LicensingHome} from "../screens/licensing/subPages/licensingHome";
import {Logout} from "../screens/logout/logout";
import {NextGenFinder} from "../screens/nextGenFinder/nextGenFinder";

const licensingSubRoutes = [
    {
        path: Routes.MANAGEMENT,
        element: <LicenseManagement />
    },
    {
        element: <LicensingHome />,
    }
];

const hubSubRoutes = [
    {
        path: Routes.OVERVIEW,
        element: <Overview />
    },
    {
        path: Routes.COLLECTIONS,
        element: <Outlet />,
        children: [
            {
                path: ":collectionId",
                element: <PlaylistScreen playlistType={PlaylistType.Collection} />,
                loader: ({params}: any) => ({
                    playlistPathId: params.collectionId,
                }),
                children: [
                    {
                        path: ":clipId",
                        element: <PlaylistClipPlayer />,
                        loader: ({params}: any) => ({
                            clipPathId: params.clipId,
                        }),
                    }
                ]
            },
            {
                element: <CollectionsScreen pageSize={6} playlistType={PlaylistType.Collection} />
            },
        ]
    },
    // {
    //     path: Routes.PRESENTATIONS,
    //     element: <Outlet/>,
    //     children: [
    //         {
    //             path: ":playlistId",
    //             element: <PlaylistScreen playlistType={PlaylistType.Presentation} />,
    //             loader: ({params}: any) => ({
    //                 playlistPathId: params.playlistId,
    //             }),
    //             children: [
    //                 {
    //                     path: ":clipId",
    //                     element: <PlaylistClipPlayer />,
    //                     loader: ({params}: any) => ({
    //                         clipPathId: params.clipId,
    //                     }),
    //                 }
    //             ]
    //         },
    //         {
    //             element: <CollectionsScreen pageSize={6} playlistType={PlaylistType.Presentation} />
    //         },
    //     ]
    // },
    {
        path: Routes.CLIPS,
        element: <Clips />,
        children: [
            {
                path: ":clipId",
                element: <ClipPlayer />,
                loader: ({params}: any) => ({
                    clipPathId: params.clipId,
                }),
            }
        ]
    },
    {
        path: '/',
        element: <Navigate to={Routes.OVERVIEW}/>
    },
];

export const routes = [
    {
        path: Routes.LOGIN_PAGE,
        element: <Login />,
        meta: { title: () => 'Login' }
    },
    {
        path: Routes.LOGOUT_PAGE,
        element: <Logout />,
        meta: { title: () => 'Logout' }
    },
    {
        path: Routes.ROOT_PATH,
        element: <RequireAuth><Navigate to={Routes.HUB_PAGE} /></RequireAuth>,
        meta: { title: () => 'Home' },
    },
    {
        path: Routes.HUB_PAGE,
        element: <RequireAuth><RequireProduct product={Products.Hub}><Hub /></RequireProduct></RequireAuth>,
        meta: { title: () => 'Hub' },
        children: hubSubRoutes
    },
    {
        path: Routes.MATCH_PAGE,
        element: <RequireAuth><RequireProduct product={Products.Finder}><Finder /></RequireProduct></RequireAuth>,
        meta: { title: () => 'Game NextGenFinder' }
    },
    {
        path: Routes.NEXT_GEN_PAGE,
        element: <RequireAuth><RequireProduct product={Products.Finder}><NextGenFinder /></RequireProduct></RequireAuth>,
        meta: { title: () => 'Game NextGenFinder' }
    },
    // {
    //     path: Routes.LICENSING_PAGE,
    //     element: <RequireAuth><Licensing /></RequireAuth>,
    //     meta: { title: () => 'Licensing' },
    //     children: licensingSubRoutes
    // },
    // {
    //     path: Routes.PIT_WALL,
    //     element: <RequireAuth><RequireProduct product={Products.RaceWatch}><PitWall /></RequireProduct></RequireAuth>,
    //     meta: { title: () => 'Pit Wall' }
    // },
    {
        path: Routes.WILDCARD,
        element: <Lost />,
        meta: { title: () => 'Not Found' }
    }
];

export const getFirstProductRoute = (products: Products[]) : string | undefined => {
    if (products.length > 0) {
        if (products.includes(Products.Finder)) {
            return Routes.MATCH_PAGE;
        }
        if (products.includes(Products.Hub)) {
            return Routes.HUB_PAGE;
        }
        // if (products.includes(Products.RaceWatch)) {
        //     return Routes.PIT_WALL;
        // }
    }

    return undefined;
};
