import React, {useCallback, useEffect, useMemo, useState} from "react";
import {shallow} from "zustand/shallow";
import {useSessionStore} from "../../state/SessionState";
import {VideoSection} from "../videoSection/videoSection";
import {getEventTitle} from "../../screens/finder/subPages/eventUtils";
import {ResultType} from "../../screens/finder/finder";
import './eventViewer.sass';
import useFixtureMedia from "../../api/MatchTracker/fixtures/useFixtureMedia";
import {useAlerts} from "@catapultsports/referee-react";
import {CalculateSyncPoint} from "../../utils/timeUtils";
import {FileMedia} from "../../api/MatchTracker/fixtures/__types__/Fixtures";
import {IEventsResponseElement} from "../../api/MatchTracker/events/__types__/Events";
import {useTranslation} from "react-i18next";
import {HUB} from "../../services/i18n/i18n-constants";
import {ContentFile} from "../../api/MatchTracker/__types__/Common";

export interface EventViewerProps {}

export const EventViewer: React.FC<EventViewerProps> = (props) => {
    const selectedResult = useSessionStore<IEventsResponseElement>(state => state.events.selectedResult, shallow);
    const setSelectedResult = useSessionStore(state => state.setSelectedEventResult, shallow);
    const { t } = useTranslation();
    const { showError } = useAlerts();
    const { state: { loading, fixture }} = useFixtureMedia(selectedResult.fixture.base.id,  () => showError(t(HUB.EVENT_FIXTURE_MEDIA_ERROR)));
    const [selectedVideoSource, setSelectedVideoSource] = useState<string | undefined>(undefined);
    const selectedVideoAngle = useSessionStore(state => state.selectedFinderVideoAngle, shallow);

    const videoFiles = useMemo(()=> {
        return  [...fixture?.collectedFiles || [], ...fixture?.delivery.deliveredFiles || []];
    }, [fixture?.collectedFiles, fixture?.delivery.deliveredFiles])

    const setVideoSource = useCallback((file?: FileMedia | ContentFile) => {
        if (file) {
            const typeName = '__typename' in file ? file.__typename : 'CollectedFile';
            const source = 'fileDetails' in file ? file.fileDetails.s3Link : file.contentURL
            const syncPoint = CalculateSyncPoint(
                typeName,
                file.videoAsset ? file.videoAsset.epochStartTime : 'epochStartTime' in file ? +file.epochStartTime : undefined,
                file.videoAsset ? file.videoAsset.defaultStartTime : 'defaultStartTime' in file ? +file.defaultStartTime : undefined,
                selectedResult.epochStartTime
            );
            setSelectedVideoSource(`${source}#t=${syncPoint}`);
        }
    }, [selectedResult.epochStartTime]);

    const files = useMemo(
        () => videoFiles.filter((file) => file.fileDetails && file.fileDetails.fileType.toLowerCase().includes('video') && file.fileDetails.s3Link),
        [videoFiles]
    )

    useEffect(() => {
        const videoOptions = videoFiles
            .filter((file) => file.fileDetails && file.fileDetails.fileType.toLowerCase().includes('video') && file.fileDetails.s3Link);
        let defaultVideo = videoOptions.find((file) => file);
        if (selectedVideoAngle)
            defaultVideo = videoOptions.find((file) => file.fileDetails.friendlyName === selectedVideoAngle) ?? defaultVideo;
        if (defaultVideo) {
            setVideoSource(defaultVideo);
        }
    }, [setVideoSource, videoFiles])

    return (
        <div className={'event-viewer-wrapper'} key={'event-viewer-wrapper'}>
            <VideoSection
                title={getEventTitle(selectedResult)}
                finderResult={ResultType.Events}
                fixtureId={selectedResult.fixture.base.id}
                selectedVideoSource={selectedVideoSource}
                onClose={() => {
                    setSelectedResult(undefined)
                }}
                autoplay
                angleList={files}
                setActiveFile={setVideoSource}
                loading={loading}
            />
        </div>
    );
};
