import { ILicensingInstallersList } from "../../__types__/installersTypes";
import {ApolloError, useQuery} from "@apollo/client";
import { GETINSTALLERS } from "./installers.gql";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {ApiErrors} from "../../../enums/apiErrors";

const useLicensingInstallers = (onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { error, data } = useQuery<ILicensingInstallersList>(GETINSTALLERS, {
    });

    useEffect(() => {
        handleErrorRaised(error, GETINSTALLERS, ApiErrors.Installers, onError);
    }, [error, GETINSTALLERS]);

    return {
        state: {
            userInstallerList: data?.getInstaller || [],
        },
    };
};

export default useLicensingInstallers;