import React, {createRef, useEffect, useState} from "react";
import './commentEntry.sass';
import {Button, noop, Textarea, Typography as Text, useAlerts} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {HUB} from "../../services/i18n/i18n-constants";
import {IconCancelSmall} from '@catapultsports/referee-react/icons';
import {ApolloError} from "@apollo/client";
import useUpdateTagComments from "../../api/Hub/comments/useUpdateTagComments";
import useUpdatePlaylistComments from "../../api/Hub/comments/useUpdatePlaylistComments";
import {CommentEntityType} from "./__types__/enums";
import {COMMENT_MAX_LENGTH} from "../../api/enums/clientMagic";

export interface CommentEntryProps {
    entityId: string;
    entityType: CommentEntityType;
    parentCommentId?: string;
    replyName?: string;
    onCancelReply?: () => void;
    onSuccess?: () => void;
}

export const CommentEntry: React.FC<CommentEntryProps> = (props) => {
    const {entityId, entityType, parentCommentId, replyName, onCancelReply} = props;
    const { t } = useTranslation();
    const [commentValue, setCommentValue] = useState<string | undefined>();
    const { showError, showWarning } = useAlerts();
    const textAreaRef = createRef<HTMLDivElement>();
    const errorAlert = (error: ApolloError, errorMessage: string) => {
        showError(t(HUB.COMMENT_POST_FAILURE));
    };
    const success = () => {
        setCommentValue('');
    }
    const {addTagComment, tagCommentsLoading} = useUpdateTagComments(success, errorAlert);
    const {addPlaylistComment, playlistCommentsLoading} = useUpdatePlaylistComments(success, errorAlert);
    const submitComment = () => {
        if (commentValue === undefined || commentValue.length === 0) {
            showWarning(t(HUB.COMMENT_EMPTY_CONTENTS));
            return;
        }
        if (entityType === CommentEntityType.tag) {
            addTagComment(entityId, commentValue, parentCommentId);
        }
        else {
            addPlaylistComment(entityId, commentValue, parentCommentId);
        }
    };

    useEffect(() => {
        if (onCancelReply && entityId) {
            onCancelReply();
        }
    }, [entityId]);

    return (
        <section className={'flex vertical entry-section'}>
            <section className={'flex horizontal reply-section'}>
                {
                    parentCommentId && replyName ?
                        <>
                            <Text variant={'label-1'}>{t(HUB.REPLY_TO, {name: replyName})}</Text>
                            <IconCancelSmall onClick={() => onCancelReply ? onCancelReply() : noop()}/>
                        </> :
                        <Text variant={'label-1'}>{t(HUB.ADD_COMMENT)}</Text>
                }
            </section>
            <div className={"write-comment-div"}>
                <div ref={textAreaRef} className={'text-container'} data-testid={'text-container'}>
                    <Textarea
                        value={commentValue}
                        onChange={(event) => setCommentValue((event.target as HTMLTextAreaElement).value)}
                        noResize
                        placeholder={t(HUB.COMMENT_PLACEHOLDER) as string}
                        error={commentValue !== undefined && commentValue.length > COMMENT_MAX_LENGTH}/>
                </div>
                <Text color={'secondary'} className={'character-counter'}>{`${commentValue ? commentValue.length : 0}/${COMMENT_MAX_LENGTH}`}</Text>
                <Button
                    testId={'post-button'}
                    disabled={commentValue === undefined || commentValue.length === 0 || commentValue.length > COMMENT_MAX_LENGTH || tagCommentsLoading || playlistCommentsLoading}
                    onClick={() => submitComment()}
                    size={'small'}
                    variant={'outline'}>
                    { parentCommentId && replyName ? t(HUB.POST_REPLY) : t(HUB.POST_COMMENT)}
                </Button>
            </div>

        </section>
    );
};