import {ApolloError, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {
    FixtureMedia,
    FixtureMediaDetail,
    FixtureMediaVariables,
} from "./__types__/Fixtures";
import {FIXTURE_BY_ID} from "./fixtures.gql";
import {client} from "../matchTrackerClient";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseFixtureMedia {
    state: {
        loading: boolean;
        fixture: FixtureMediaDetail | null;
    };
}

const useFixtureMedia = (id: string, onError?: (error: ApolloError, errorMessage: string) => void): UseFixtureMedia => {
    const [elements, setElements] = useState<FixtureMediaDetail | null>(null);

    const { loading, error, data } = useQuery<FixtureMedia, FixtureMediaVariables>(FIXTURE_BY_ID, {
        variables: {
            id: id
        }
    });

    useEffect(() => {
        if (data != null) {
            setElements(data.fixture);
        }
    }, [loading, data]);


    useEffect(() => {
        handleErrorRaised(error, FIXTURE_BY_ID, ApiErrors.UseFixtureMedia, onError);
    }, [error, FIXTURE_BY_ID]);

    return {
        state: {
            fixture: elements,
            loading: loading
        }
    };
};

export default useFixtureMedia;
