import { gql } from "@apollo/client";

export const FIXTURE_COLLECTED_UNSUPPORTED = gql`
    fragment FixtureCollectedUnsupported on CollectedFile {
        fileDetails {
            friendlyName
            fileType
            s3Link
        }
    }
`;

export const FIXTURE_INGESTED_UNSUPPORTED = gql`
    fragment FixtureIngestedUnsupported on IngestedFile {
        fileDetails {
            friendlyName
            fileType
            s3Link
        }
    }
`;

export const FIXTURE_DELIVERED_UNSUPPORTED = gql`
    fragment FixtureDeliveredUnsupported on DeliveredFile {
        fileDetails {
            friendlyName
            fileType
            s3Link
        }
    }
`;
