import { useCallback } from "react";
import Plyr from "@sbgsportssoftware/plyr";
import * as types from "./player.types";
import { usePlayerDispatch, usePlayerState } from "./player.context";

export interface UsePlayerContext extends types.State {
    setLoaded(loaded: boolean): void;
    setCurrentTime(time: number): void;
    setCurrentMatchTime(time: string): void;
    setDuration(time: number): void;
    setFullscreen(fullscreen: boolean): void;
    setEnded(ended: boolean): void;
    setCurrentSource(source: Plyr.SourceInfo | Plyr.SourceInfo[] | null): void;
    setStartTime(time: number): void;
}

const usePlayerContext = (): UsePlayerContext => {
    const dispatch = usePlayerDispatch();
    const { playerRef, currentTime, currentMatchTime, duration, fullscreen, loaded, ended, currentSource } =
        usePlayerState();

    const setLoaded = useCallback(
        (value: boolean) => {
            dispatch({ type: types.SET_PLAYER_LOADED, loaded: value });
        },
        [dispatch]
    );

    const setCurrentTime = useCallback(
        (time: number) => {
            dispatch({ type: types.SET_PLAYER_CURRENT_TIME, currentTime: time });
        },
        [dispatch]
    );

    const setCurrentMatchTime = useCallback(
        (time: string) => {
            dispatch({ type: types.SET_PLAYER_CURRENT_MATCH_TIME, currentMatchTime: time });
        },
        [dispatch]
    );

    const setDuration = useCallback(
        (time: number) => {
            dispatch({ type: types.SET_PLAYER_DURATION, duration: time });
        },
        [dispatch]
    );

    const setFullscreen = useCallback(
        (value: boolean) => {
            dispatch({ type: types.SET_PLAYER_FULLSCREEN, fullscreen: value });
        },
        [dispatch]
    );

    const setEnded = useCallback(
        (value: boolean) => {
            dispatch({ type: types.SET_PLAYER_ENDED, ended: value });
        },
        [dispatch]
    );

    const setCurrentSource = useCallback(
        (source: Plyr.SourceInfo | Plyr.SourceInfo[] | null) => {
            dispatch({ type: types.SET_PLAYER_CURRENT_SOURCE, currentSource: source });
        },
        [dispatch]
    );

    const setStartTime = useCallback(
        (newStartTime: number) => {
            dispatch({ type: types.SET_PLAYER_START_TIME, startTime: newStartTime });
        },
        [dispatch]
    );

    return {
        playerRef,
        loaded,
        currentTime,
        currentMatchTime,
        duration,
        fullscreen,
        ended,
        currentSource,
        setLoaded,
        setDuration,
        setCurrentTime,
        setFullscreen,
        setCurrentMatchTime,
        setCurrentSource,
        setEnded,
        setStartTime
    };
};

export { usePlayerContext };
