import React, {useCallback, useMemo, useState} from "react";
import { useTranslation} from 'react-i18next';
import {GENERAL, HUB} from '../../../services/i18n/i18n-constants';
import {HubDialogComponent} from "../../hubDialog/hubDialog";
import {LabelsDialogContentComponent} from "./labelsDialogContent";
import {useAlerts} from "@catapultsports/referee-react";
import {useMixPanel} from "../../../providers/Mixpanel";
import {IPlaylistResponse} from "../../../api/Hub/collections/__types__/Playlist";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {Metadata} from "../../../api/Hub/metadata/__types__/metadata";
import * as _ from 'lodash';
import {shallow} from "zustand/shallow";
import {useSessionStore} from "../../../state/SessionState";
import {useUpdateTagsMetadataGroups} from "../../../api/Hub/tags/updateTags/useUpdateTagMetadataGroups";
import {CreateMetadataGroupInput, UpdateMetadataGroupInput} from "../../../api/Hub/gql/schema";

export interface LabelsDialogComponentProps {
    isModalOpen?: boolean
    setIsModalOpen?: (isModalOpen: boolean) => void
    metadata?: Metadata[]
    metadataGroups?: {id: string, name: string}[]
    modalType?: "collection" | "tag"
    inputValue?: string,
    collectionOrTagId?: string,
    playlistData?: IPlaylistResponse
}

export interface MetadataGroup {
    id: string | undefined;
    name: string;
    values: {
        id: string | undefined;
        value: string;
    }[];
}

export let labelsDialogId = "clip-edit-labels"

export const LabelsDialogComponent: React.FC<LabelsDialogComponentProps> = (props) => {
    const { isModalOpen, setIsModalOpen , metadata, metadataGroups, modalType, inputValue, collectionOrTagId, playlistData} = props
    const mixpanel = useMixPanel();
    const { t } = useTranslation();
    const playlistTags = useSessionStore(state => state.playlist.tags, shallow);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const mapMetadataGroups = (metadata: Metadata[], metadataGroups: {id: string, name: string}[]): MetadataGroup[] => {
        const groupedValues = metadata?.reduce((result: Map<string, Metadata[]>, value) => {
            if (result.has(value.metadataGroup.id)) {
                result.set(value.metadataGroup.id, [...result.get(value.metadataGroup.id) ?? [], value]);
                return result;
            }
            result.set(value.metadataGroup.id, [value]);
            return result;
        }, new Map<string, Metadata[]>());
        const groups: MetadataGroup[] | undefined = metadataGroups?.map((metadataGroup) => {
            return {
                id: metadataGroup.id,
                name: metadataGroup.name,
                values: groupedValues?.get(metadataGroup.id) ?? []
            };
        });
        return groups || [];
    };
    const currentMetadataGroups: MetadataGroup[] = useMemo(() => {
        if (metadata && metadataGroups) {
            return mapMetadataGroups(metadata, metadataGroups);
        }
        return [];
    }, [metadata, metadataGroups]);

    const { showError, showInfo } = useAlerts();
    const [metadataGroupState, setMetadataGroupState] = useState<MetadataGroup[] | undefined>(undefined);

    const onSuccess = useCallback(() => {
        showInfo(t(HUB.LABELS_UPDATED))
        setTimeout(() => {
            if (setIsModalOpen) {
                setIsModalOpen(false)
                setIsSaving(false);
            }
        }, 1000);
        setIsSaving(false);
    }, [setIsModalOpen, showInfo, t, setIsSaving])

    const errorAlert = useCallback(() => {
        showError(t(HUB.LABELS_UPDATE_ERROR))
    }, [showError, t])

    const {updateTagMetadataGroups} = useUpdateTagsMetadataGroups(errorAlert);

    const handleFooterPrimaryButtonClick = React.useCallback(() => {
        setIsSaving(true);
        const metadataGroupToCreateRequest = (metadata: MetadataGroup): CreateMetadataGroupInput => ({
            id: metadata.id,
            name: metadata.name,
            createMetadata: metadata.values.map(value => ({
                id: value.id,
                value: value.value
            }))
        });
        const getTagUpdate = (tagId: string): {
            tagId: string,
            createMetadataGroups?: Array<CreateMetadataGroupInput>,
            updateMetadataGroups?: Array<UpdateMetadataGroupInput>
        } => {
            return {
                tagId: tagId,
                createMetadataGroups: metadataGroupState && metadataGroupState.length > 0 ? metadataGroupState.map(metadataGroupToCreateRequest) : undefined
            };
        };
        if(collectionOrTagId && modalType === "tag" && metadataGroupState) {
            updateTagMetadataGroups(
                [getTagUpdate(collectionOrTagId)])
                .then(() => {
                    onSuccess();
                }).catch((err) => {
                    setIsSaving(false);
                    throw new Error(err);
                });
            mixpanel.track(MixPanelEvents.LABELS_UPDATED, {updatedLabelGroups: metadataGroupState, TagId: collectionOrTagId});
        } else if(collectionOrTagId && modalType === "collection" && metadataGroupState) {
            updateTagMetadataGroups(
                playlistTags.map(tag => getTagUpdate(tag.id)))
                .then(() => {
                onSuccess();
            }).catch((err) => {
                setIsSaving(false);
                throw new Error(err);
            });
            mixpanel.track(MixPanelEvents.LABELS_UPDATED, {updatedLabelGroups: metadataGroupState, TagIds: playlistTags.map(tag => tag.id)});
        }
    }, [collectionOrTagId, modalType, metadataGroupState, playlistTags, updateTagMetadataGroups, mixpanel, onSuccess]);

    let isSaveButtonDisabled = useMemo(
        () => metadataGroupState === undefined || _.isEqual(currentMetadataGroups, metadataGroupState),
        [currentMetadataGroups, metadataGroupState]);

    const onMetadataGroupStateUpdate = useCallback((groups: MetadataGroup[]) => {
        setMetadataGroupState(groups);
    }, [setMetadataGroupState]);

    return (
        <HubDialogComponent
            dialogTheme={"dark"}
            dialogSize={'small'}
            dialogHeader={t(HUB.EDIT_LABELS) as string}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalContent={
                <LabelsDialogContentComponent
                    currentMetadataGroups={currentMetadataGroups}
                    modalType={modalType}
                    inputValue={inputValue}
                    onMetadataGroupStateUpdate={onMetadataGroupStateUpdate}
                />
            }
            displayDialogFooterButtons={true}
            hideCloseButton={false}
            footerPrimaryButtonText={t(GENERAL.SAVE) as string}
            footerPrimaryButtonAction={handleFooterPrimaryButtonClick}
            footerPrimaryButtonDisabled={isSaveButtonDisabled || isSaving}
            dialogId={labelsDialogId}
            tooltipText={t(HUB.EDIT_LABELS_TOOLTIP) as string}
        />
    )
};
