import {PreRollTime, videoDuration} from "../api/MatchTracker/util/EnumUtil";
import {SyncPoint} from "../__types__/globalTypes";
import {IEventsResponseElement} from "../api/MatchTracker/events/__types__/Events";
import {secondsToMmss} from "./numberUtils";

export const CalculateSyncPoint = (
    typeName: string,
    assetEpochStartTime: number | null | undefined,
    assetDefaultStartTime: number | undefined,
    eventEpochStartTime?: number | undefined
) => {
    let timeToReturn = 0

    if (['CollectedFile', 'VideoAsset'].includes(typeName)) {
        if (eventEpochStartTime && eventEpochStartTime > 0) {
            if (assetEpochStartTime) {
                timeToReturn = Math.floor((eventEpochStartTime - assetEpochStartTime) / 1000) - PreRollTime.FIVE_SECONDS
            } else {
                timeToReturn =  Math.floor(eventEpochStartTime / 1000) - PreRollTime.FIVE_SECONDS
            }
        } else if (assetDefaultStartTime && assetDefaultStartTime > 0) {
            timeToReturn =  assetDefaultStartTime - PreRollTime.FIVE_SECONDS
            if (timeToReturn < 0) timeToReturn = assetDefaultStartTime
        }
    } else if (['DeliveredFile'].includes(typeName)) {
        if (eventEpochStartTime && eventEpochStartTime > 0) {
            timeToReturn = Math.floor((eventEpochStartTime / (assetEpochStartTime || 1)) / 1000) - PreRollTime.FIVE_SECONDS
        }
    }

    if((timeToReturn > videoDuration.TWO_DAYS || timeToReturn < 0)){
        return 0
    }

    return timeToReturn
}

export const CalculateHubSyncPoints = (
    tagStartTime: number, tagEndTime: number, tagPoiTime: number,
    assetStartTime: number, assetEndTime: number, assetPoiTime: number
): SyncPoint => {
    let assetStart = assetStartTime / 1000;
    let assetEnd = assetEndTime / 1000;
    let assetPoi = assetPoiTime / 1000;

    let tagStart = tagStartTime / 1000;
    let tagEnd = tagEndTime / 1000;
    let tagPoi = tagPoiTime / 1000;
    let preRoll = 0;

    if (tagStart !== 0) {
        preRoll = tagPoi - tagStart;
        if (preRoll >= 0) {
            assetStart = assetPoi - preRoll;
        }
    }

    if (assetStart < 0 && assetEnd < 0) {
        assetStart = assetPoi;
        assetEnd = assetPoi + (tagEnd - tagStart)
    }

    let duration = assetStart >= 0 && assetEnd >= 0
        ? assetEnd - assetStart
        : tagEnd - tagStart;

    return {
        startEpoch: tagStartTime, startSecond: Math.max(assetStart, 0),
        endEpoch: tagEndTime, endSecond: Math.max(assetEnd, (assetStart + duration)),
        duration: duration
    };
}

export const FormatTimeStamp = (x: IEventsResponseElement) => {
    if(x.matchPhase.phaseType.name == 'First half' && x.startTime > 2700)
        return '45:00 + ' + secondsToMmss(x.startTime - 2700, false)
    else if(x.matchPhase.phaseType.name == 'Second half' && x.startTime > 2700)
        return '90:00 + ' + secondsToMmss(x.startTime - 2700, false)


    return secondsToMmss(x.startTime + (x.matchPhase.phaseType.name == 'First half' ? 0 : 2700));
}