import {useEffect} from "react";
import {IPlayersResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {PLAYERS} from "./players.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

const usePlayers = (teamIds?: string[], pageSize: number = -1, pageNumber: number = 0, onError?: (error: ApolloError, errorMessage: string) => void, fixtureIds?: string[]) => {
    const { loading, error, data } = useQuery<IPlayersResponse>(PLAYERS, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            filter: {
                teamIDs: teamIds ?? [],
                fixtureIDs: fixtureIds ?? [],
            }
        }
    });

    useEffect(() => {
        handleErrorRaised(error, PLAYERS, ApiErrors.Players, onError);
    }, [error, PLAYERS]);

    return {
        state: {
            players: data?.players?.edges,
            playersPageSize: pageSize,
            playersLoading: loading
        }
    };
};

export default usePlayers;