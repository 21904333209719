export interface MetadataInput {
    value: string;
    id: string;
    metadataGroup: {
        name: string;
        hidden: boolean;
        id: string;
        metadata: {
            id: string;
            value: string;
        }
    }
}

export interface MetadataItem {
    value: string,
    id: string,
    metadataGroupName:string
    groupId: string
}

export interface GroupItem {
    name: string,
    id: string
}

export interface labelObjectBasicFields {
    value: string,
    groupId?: string,
    tagId?: string,
    id?: string
}

export interface labelObjectExtraFields {
    value: string,
    id: string,
    groupId: string,
    metadataGroupName: string
    status?: string
}

export enum collectionLabelsActions {
    ADDED = "added",
    DELETED = "deleted",
}