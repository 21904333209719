import React from "react";
import "../../screens/licensing/subPages/licensingHome.sass"
import {Typography} from "@catapultsports/referee-react";
import {Installer} from "../../reference/installer";
import {useTranslation} from 'react-i18next';
import {LICENSING} from "../../services/i18n/i18n-constants";

export interface InstallersComponentProps {
    installers: Installer[];
}

export const InstallersComponent: React.FC<InstallersComponentProps> = (props) => {
    const { installers } = props
    const { t } = useTranslation();

    return (
        <div>
            <Typography variant={'heading-2'} testId={"installer-component-header"} id={"installer-component-header"}>{t(LICENSING.INSTALLERS)}</Typography>
            <table>
                <tbody>
                {installers.map((ins, index) => (
                    <tr key={`installer-${index}`}>
                        <td><Typography testId={`installer-${index}-product-name`} id={`installer-${index}-product-name`}>{ins.productName}</Typography></td>
                        <td><Typography testId={`installer-${index}-product-version`} id={`installer-${index}-product-version`}>{ins.productVersion}</Typography></td>
                        <td><Typography testId={`installer-${index}-release-notes`} id={`installer-${index}-release-notes`}><a href={ins.changelogUrl} target={'_blank'} data-testid={`installer-${index}-change-log-url`} id={`installer-${index}-change-log-url`}>{t(LICENSING.RELEASE_NOTES)}</a></Typography></td>
                        <td><Typography testId={`installer-${index}-win-text`} id={`installer-${index}-win-text`}><a href={ins.urlWin} target={'_blank'} data-testid={`installer-${index}-url-win`} id={`installer-${index}-url-win`}>{t(LICENSING.DOWNLOAD_EXE)}</a></Typography></td>
                        <td><Typography testId={`installer-${index}-mac-text`} id={`installer-${index}-mac-text`}><a href={ins.urlMac} target={'_blank'} data-testid={`installer-${index}-url-mac`} id={`installer-${index}-url-mac`}>{t(LICENSING.DOWNLOAD_DMG)}</a></Typography></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
};
