import {gql} from "@apollo/client";

export const PLAYLIST_METADATA = gql`
    query PlaylistMetadata($id: String!) {
      playlist(id: $id){
        id
        metadata {
            id
            value
            metadataGroup {
                name
                hidden
                id
            }
        }
        metadataGroups {
            id
            name
        }
      }
    }
`;

export const TAG_METADATA = gql`
    query TagMetadata($id: String!) {
      tag(id: $id){
        id
        description
        metadata {
            id
            value
            metadataGroup {
                name
                hidden
                id
            }
        }
        metadataGroups {
            id
            name
        }
        notes {
          id
          heading
          value
        }
      }
    }
`;
