import {ApolloError, useQuery} from "@apollo/client";
import {COMMENT} from "./comments.gql";
import {UseBase} from "../__types__/Common";
import {Comment} from "./__types__/Comments";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseComment extends UseBase{
    comment: Comment | null | undefined;
}

const useComment = (commentId: string | undefined, onError?: (error: ApolloError, errorMessage: string) => void): UseComment => {
    const { loading, error, data } = useQuery<{ comment: Comment | null }, {commentId: string | undefined}>(COMMENT, {
        variables: {
            commentId: commentId
        }
    });

    useEffect(() => {
        handleErrorRaised(error, COMMENT, ApiErrors.CommentsError, onError);
    }, [error, COMMENT]);

    return {
        comment: data?.comment,
        loading,
        error
    };
};

export default useComment;
