import {ApolloError, DocumentNode} from "@apollo/client";
import {ApiErrors} from "../api/enums/apiErrors";
import {logApiError} from "./sentryUtils";
import {client as hubClient} from "../api/Hub/client";
import {client as customerClient} from "../api/Customer/client";
import {client as matchTrackerClient} from "../api/MatchTracker/matchTrackerClient";

export interface ApolloErrorDetail {
    errorMessage: string;
    parsedError: NetworkError;
    stringOfErrors: string;
}

export interface NetworkError {
    networkError: {
        result: {
            errors: { message: string }[]
        }
    }
}

export function getErrorMessage(error: ApolloErrorDetail | string): string {
    return typeof error === 'string' ? error : error.errorMessage;
}

export function ApolloExtractError(error: ApolloError, fallbackError?: string ): ApolloErrorDetail | string {
    let unexpectedError = ApiErrors.Unexpected

    if (error) {
        let errorObject = JSON.parse(JSON.stringify(error))

        if (errorObject.networkError && errorObject.networkError.result && errorObject.networkError.result.errors && errorObject.networkError.result.errors.length > 0) {
            let networkError = errorObject.networkError
            let errorArray = networkError.result.errors
            let stringOfErrors = errorArray.map((element: string | {message: any; }) => typeof element === 'string' ? element : element.message.replace(".", "")).join(', ')
            let errorToReturn = `Status Code: ${networkError.statusCode}; Error(s): ${stringOfErrors}`

            return {
                errorMessage: errorToReturn,
                parsedError: errorObject,
                stringOfErrors,
            }
        } else {
            if (fallbackError) {
                return fallbackError
            }
        }
    }
    return unexpectedError
}

export function handleErrorRaised(
    error : ApolloError | undefined,
    query: { loc: { source: unknown; }; } | DocumentNode | undefined,
    fallbackError: string | undefined,
    onError?: (error: ApolloError, errorMessage: string) => void) {
    if(error !== undefined) {
        const extractedError = ApolloExtractError(error, fallbackError);
        logApiError(extractedError, error, query);
        if(onError) {
            onError(error, typeof extractedError === 'string' ? extractedError : (extractedError as ApolloErrorDetail).errorMessage);
        }
    }
}

export const clearApolloClientsCache = async () => {
    try{
        await Promise.all([
            hubClient.cache.reset(),
            customerClient.cache.reset(),
            matchTrackerClient.cache.reset()
        ])
        return true
    }catch (e) {
        return false
    }
}
