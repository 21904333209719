import {PermanentState} from '../StoreSlice';
import {StateCreator} from "zustand";

export interface JoyrideSliceProps {
    showInitialIntro: boolean,
    persistShowInitialIntro: (value: boolean) => void
}

export const createJoyrideSlice: StateCreator<PermanentState,  [["zustand/persist", unknown], ["zustand/devtools", never]], [], JoyrideSliceProps> =
    (set) => {
        return ({
            showInitialIntro: true,
            persistShowInitialIntro: (value: boolean) => set((state: JoyrideSliceProps) => {
                state.showInitialIntro = value;
                return state;
            })
        })
    };
