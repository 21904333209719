import {IOfficialMatchInvolvementsResponse} from "../__types__/Common";
import {useQuery} from "@apollo/client";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {OFFICIALMATCHINVOLVEMENTS} from "./officialMatchInvolvements.gql";
import {ApiErrors} from "../../enums/apiErrors";

const useOfficialMatchInvolvements = (isTeamStaff: boolean = false, isUniqueType: boolean = true, teamIDs: string[] = [],  pageSize: number = -1, pageNumber: number = 0) => {
    const { loading, error, data } = useQuery<IOfficialMatchInvolvementsResponse>(OFFICIALMATCHINVOLVEMENTS, {
        variables: {
            filter: {
                isTeamStaff: isTeamStaff,
                isUniqueType: isUniqueType,
                teamIDs: teamIDs
            },
            pageNumber: pageNumber,
            pageSize: pageSize,
        },
    });

    if (error) {
        handleErrorRaised(error, OFFICIALMATCHINVOLVEMENTS, ApiErrors.OfficialMatchInvolvements);
    }

    return {
        state: {
            officialMatchInvolvements: data?.officialMatchInvolvements?.edges,
            officialMatchInvolvementsPageSize: pageSize,
            officialMatchInvolvementsLoading: loading,
            officialMatchInvolvementsError: error
        }
    };
};

export default useOfficialMatchInvolvements;