import React, {useCallback} from "react";
import {Tooltip} from "@catapultsports/referee-react";
import {IconMoreSmall, IconCancelSmall} from "@catapultsports/referee-react/icons";
import '../playlistControlsHeaderComponent.sass';
import {COLLECTIONS} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import "../playlistControls.sass"

interface PlaylistControlsHeaderComponentCollectionActionsProps {
    areCollectionActionsOpen: boolean
    setAreCollectionActionsOpen: (value: boolean) => void
    setSelectedTagIds?: (value: string[]) => void
}

export const PlaylistControlsHeaderCollectionActions: React.FC<PlaylistControlsHeaderComponentCollectionActionsProps> = (props) => {
    const {
        areCollectionActionsOpen,
        setAreCollectionActionsOpen,
        setSelectedTagIds
    } = props;
    const {t} = useTranslation();

    const handleMoreActionsClick = useCallback(() => {
        setAreCollectionActionsOpen(!areCollectionActionsOpen)
        if(setSelectedTagIds) setSelectedTagIds([])

    }, [areCollectionActionsOpen, setAreCollectionActionsOpen, setSelectedTagIds])

    return (
            !areCollectionActionsOpen ? (
                <Tooltip text={t(COLLECTIONS.EDIT_COLLECTION)}>
                    <span tabIndex={0}>
                        <IconMoreSmall
                            className={"icon"}
                            onClick={handleMoreActionsClick}
                            data-testid={'collection-actions-more-icon'}
                        />
                    </span>
                </Tooltip>
            ): (
                <Tooltip text={t(COLLECTIONS.CLOSE_EDIT_COLLECTION)}>
                    <span tabIndex={0}>
                        <IconCancelSmall
                            className={"icon"}
                            onClick={handleMoreActionsClick}
                            data-testid={'collection-actions-close-icon'}
                        />
                    </span>
                </Tooltip>
            )
    )
}