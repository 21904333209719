import React from "react";
import ReactDOM from "react-dom/client";
import "@catapultsports/referee-react/reset.css";
import "@catapultsports/referee-react/styles.css";
import "./styles/index.sass";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import MixPanelProvider from "./providers/Mixpanel";
import "./services/i18n/TranslationService";
import HubApolloProvider from "./providers/ApolloProviders/HubApolloProvider";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "./screens/errorFallback/errorFallback";
import { SentryConfig } from "./configs/SentryConfig";

Sentry.init(SentryConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HubApolloProvider>
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <MixPanelProvider>
          <App />
        </MixPanelProvider>
      </Sentry.ErrorBoundary>
    </HubApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
