import React from "react";
import {SideMenu, SideMenuItem} from "@catapultsports/referee-react";
import { useTranslation } from 'react-i18next';
import {HUB} from '../../services/i18n/i18n-constants';
import {usePortalNavigation} from "../../utils/routerUtils";
import {Routes} from "../../enums/Routes";
import './menu.sass'

export enum MenuItems {
    OVERVIEW = 'overview',
    COLLECTIONS = 'collections',
    PRESENTATIONS = 'presentations',
    CLIPS = 'clips'
}

export const HubMenu: React.FC<{selected?: MenuItems}> = (props) => {
    const { selected } = props;
    const { t } = useTranslation();
    const { navigate } = usePortalNavigation();

    return (
        <SideMenu defaultSelected={selected ?? '1'} testId={'side-nav'}>
            <SideMenuItem
                label={t(HUB.OVERVIEW)}
                value={MenuItems.OVERVIEW}
                onClick={() => navigate({ to: Routes.OVERVIEW, replace: true })}
            />
            <SideMenuItem
                label={t(HUB.COLLECTIONS)}
                value={MenuItems.COLLECTIONS}
                onClick={() => navigate({ to: Routes.COLLECTIONS, replace: true })}
            />
            {/*<SideMenuItem*/}
            {/*    label={t(HUB.PRESENTATIONS)}*/}
            {/*    value={MenuItems.PRESENTATIONS}*/}
            {/*    onClick={() => navigate({ to: Routes.PRESENTATIONS, replace: true })}*/}
            {/*/>*/}
            <SideMenuItem
                label={t(HUB.CLIPS)}
                value={MenuItems.CLIPS}
                onClick={() => navigate({ to: Routes.CLIPS, replace: true })}
            />
        </SideMenu>
    )
}
