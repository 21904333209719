import './licenseTable.sass'
import React, {useCallback} from 'react';
import {ILicense} from "../../../api/Customer/__types__/Licenses";
import {Button, Pill, Spinner, Table, Tooltip} from "@catapultsports/referee-react";
import {
    IconBan,
    IconCancel,
    IconCheck,
    IconImport,
    IconPencil,
    IconPin, IconRemove
} from "@catapultsports/referee-react/icons";
import {HeaderGroup} from "react-table";
import ReactTimeago from "react-timeago";
import useLicenses from "../../../api/Customer/Licensing/licenses/useLicenses";
import {getDateString} from "../../../utils/dateUtils";
import {useTranslation} from "react-i18next";
import {LICENSING} from "../../../services/i18n/i18n-constants";
import {useMixPanel} from "../../../providers/Mixpanel";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {usePersistedStore} from "../../../state/PersistedState";
import {shallow} from "zustand/shallow";

export enum LicenseAction {
    Download,
    Pin,
    Edit,
    Approve,
    Reject,
    Revoke,
    Delete
}

interface LicenseTableProps {
    onAction?: (license: ILicense, action: LicenseAction) => void
}

export const LicenseTable = (props: LicenseTableProps) => {
    const { onAction } = props
    const {
        state: { licenses, licensesLoading, licensesHasNextPage },
        operations: { loadNextPage }
    } = useLicenses()
    const email = usePersistedStore(state => state.user.email, shallow)
    const onActionTrigger = useCallback(
        (license: ILicense, action: LicenseAction) => {
            switch (action) {
                case LicenseAction.Download:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_DOWNLOAD, { user: email, license: license })
                    break
                case LicenseAction.Pin:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_PIN, { user: email, license: license })
                    break
                case LicenseAction.Edit:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_EDIT, { user: email, license: license })
                    break
                case LicenseAction.Approve:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_APPROVE, { user: email, license: license })
                    break
                case LicenseAction.Reject:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_REJECT, { user: email, license: license })
                    break
                case LicenseAction.Revoke:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_REVOKE, { user: email, license: license })
                    break
                case LicenseAction.Delete:
                    mixpanel.track(MixPanelEvents.LICENSING_MANAGE_DELETE, { user: email, license: license })
                    break
            }

            onAction && onAction(license, action)
        },
        [onAction]
    )
    const { t } = useTranslation();
    const mixpanel = useMixPanel();

    return (
        <section data-testid={'license-table'} className={'license-table'}>
            {licensesLoading && <Spinner size={'large'} variant={'muted'} />}
            {!licensesLoading && licenses && licenses.length > 0 && (
                <Table<ILicense>
                    initialState={{
                        hiddenColumns: ['base.id'],
                    }}
                    columns={[
                        {
                            Header: t(LICENSING.USER),
                            disableSortBy: true,
                            accessor: 'user.email'
                        },
                        {
                            Header: 'LicenseKey',
                            accessor: 'base.id',
                        },
                        {
                            Header: t(LICENSING.COMPUTER),
                            accessor: "computer",
                            disableSortBy: true,
                        },
                        {
                            Header: t(LICENSING.PRODUCT),
                            accessor: "productName",
                            disableSortBy: true,
                        },
                        {
                            Header: t(LICENSING.RELEASE),
                            disableSortBy: true,
                            Cell: (props) => "N/A"
                        },
                        {
                            Header: t(LICENSING.LAST_LOGIN),
                            accessor: "lastLoggedIn",
                            Cell: (props) => getDateString(new Date(props.row.values.lastLoggedIn)),
                            disableSortBy: true,
                        },
                        {
                            Header: t(LICENSING.SUBSCRIPTION),
                            disableSortBy: true,
                            Cell: (props) => "N/A",
                            disableSortBy: true,
                        },
                        {
                            Header: t(LICENSING.EXPIRY),
                            accessor: "expiryDate",
                            disableSortBy: true,
                            Cell: (props) => getDateString(new Date(props.row.values.expiryDate))
                        },
                        {
                            Header: t(LICENSING.REMAINING),
                            disableSortBy: true,
                            Cell: (props) => {
                                const expiry = new Date(props.row.values.expiryDate)
                                return (
                                    <ReactTimeago date={expiry} formatter={((value, unit, suffix) => `${value} ${unit}${value > 1 ? 's' : ''}`)} />
                                )
                            }
                        },
                        {
                            Header: t(LICENSING.STATUS),
                            accessor: "status",
                            disableSortBy: true,
                            Cell: (props) => {
                                const status = props.row.values.status.toLowerCase()
                                return (
                                    <Pill
                                        value={status.toUpperCase()}
                                        variant={
                                            ['active', 'valid'].includes(status)
                                                ? 'success'
                                                : status === 'expired'
                                                    ? 'warning'
                                                    : status == 'revoked' ? 'alert' : 'information'
                                        }
                                        shape={"square"}
                                    />
                                )
                            }
                        },
                        {
                            Header: ' ',
                            disableSortBy: true,
                            Cell: (props) => {
                                const key = props.row.values['base.id']
                                const status = props.row.values.status.toLowerCase()
                                const isRemoved = ['expired', 'revoked'].includes(status)

                                if (status === 'requested') {
                                    return (
                                        <div className={'actions'}>
                                            <Tooltip text={t(LICENSING.TOOLTIP_APPROVE)}>
                                                <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Approve)} testId={`btn-approve-${key}`}><IconCheck /></Button>
                                            </Tooltip>
                                            <Tooltip text={t(LICENSING.TOOLTIP_EDIT)}>
                                                <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Edit)} testId={`btn-edit-${key}`}><IconPencil /></Button>
                                            </Tooltip>
                                            <Tooltip text={t(LICENSING.TOOLTIP_REJECT)}>
                                                <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Reject)} testId={`btn-reject-${key}`}><IconCancel /></Button>
                                            </Tooltip>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={'actions'}>
                                            {!isRemoved && (
                                                <>
                                                    <Tooltip text={t(LICENSING.TOOLTIP_DOWNLOAD)}>
                                                        <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Download)} testId={`btn-download-${key}`}><IconImport /></Button>
                                                    </Tooltip>
                                                    <Tooltip text={t(LICENSING.TOOLTIP_PIN)}>
                                                        <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Pin)} testId={`btn-pin-${key}`}><IconPin /></Button>
                                                    </Tooltip>
                                                </>
                                            )}
                                            <Tooltip text={t(LICENSING.TOOLTIP_EDIT)}>
                                                <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Edit)} testId={`btn-edit-${key}`}><IconPencil /></Button>
                                            </Tooltip>
                                            {isRemoved && (
                                                <Tooltip text={t(LICENSING.TOOLTIP_DELETE)}>
                                                    <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Delete)} testId={`btn-delete-${key}`}><IconRemove /></Button>
                                                </Tooltip>
                                            )}
                                            {!isRemoved && (
                                                <Tooltip text={t(LICENSING.TOOLTIP_REVOKE)}>
                                                    <Button variant={'text'} onClick={() => onActionTrigger({...props.row.values}, LicenseAction.Revoke)} testId={`btn-revoke-${key}`}><IconBan /></Button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )
                                }
                            },
                        },
                    ]}
                    data={licenses}
                    mapColumnIdToName={(column: HeaderGroup) => {
                        return column.Header;
                    }}
                    requestMoreData={loadNextPage}
                    hasMoreData={licensesHasNextPage}
                />
            )}
        </section>
    )
};
