import * as types from "./player.types";

const playerReducer = (state: types.State, action: types.Action): types.State => {
    switch (action.type) {
        case "SET_PLAYER_LOADED": {
            return { ...state, loaded: action.loaded };
        }
        case "SET_PLAYER_CURRENT_TIME": {
            return { ...state, currentTime: action.currentTime };
        }
        case "SET_PLAYER_CURRENT_MATCH_TIME": {
            return { ...state, currentMatchTime: action.currentMatchTime };
        }
        case "SET_PLAYER_DURATION": {
            return { ...state, duration: action.duration };
        }
        case "SET_PLAYER_FULLSCREEN": {
            return { ...state, fullscreen: action.fullscreen };
        }
        case "SET_PLAYER_ENDED": {
            return { ...state, ended: action.ended };
        }
        case "SET_PLAYER_CURRENT_SOURCE": {
            return { ...state, currentSource: action.currentSource };
        }
        default: {
            return state;
        }
    }
};

export default playerReducer;
