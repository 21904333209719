export enum PitWallLayout {
    Single = 'wrapper-single',
    DualHorizontal = 'wrapper-dual-horizontal',
    DualVertical = 'wrapper-dual-vertical',
    Quad = 'wrapper-quad',
    GridHorizontal = 'wrapper-grid-horizontal',
    GridVertical = 'wrapper-grid-vertical'
}

export enum F1Event {
    OnMount = 'onMount',
    OnUnmount = 'onUnmount',
    OnPlay = 'onPlay',
    OnPause = 'onPause',
    OnSeek = 'onSeek',
    OnSpeedChange = 'onSpeedChange',
    OnMute = 'onMute',
    OnChangeMaster = 'onChangeMaster'
}
