import {ITeamsResponse} from "../__types__/Common";
import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {TEAMS} from "./teams.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

const useTeams = (
    competitionIds: string[] = [],
    seasonIDs: string[] = [],
    pageSize: number = -1,
    pageNumber: number = 0,
    onError?: (error: ApolloError, errorMessage: string) => void
) => {
    const { loading, error, data } = useQuery<ITeamsResponse>(TEAMS, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            filter: {
                competitionIDs: competitionIds,
                seasonIDs: seasonIDs
            }
        },
    });

    useEffect(() => {
        handleErrorRaised(error, TEAMS, ApiErrors.Teams, onError);
    }, [error, onError]);

    return {
        state: {
            teams: data?.teams?.edges,
            teamsPageSize: pageSize,
            teamsLoading: loading
        }
    };
};

export default useTeams;