import { gql } from "@apollo/client";
import {FIXTURE_HEADING} from "./fixtureHeading.gql";
import {FIXTURE_COLLECTED_MEDIA, FIXTURE_DELIVERED_MEDIA, FIXTURE_INGESTED_MEDIA} from "./fixtureMedia.gql";
import {FIXTURE_FILES} from "./fixtureFiles.gql";

const FIXTURE_CONTENT = gql`
    ${FIXTURE_HEADING}
    ${FIXTURE_FILES}
    ${FIXTURE_COLLECTED_MEDIA}
    ${FIXTURE_INGESTED_MEDIA}
    ${FIXTURE_DELIVERED_MEDIA}
    query FixtureContent($fixtureId: String!) {
        fixture(id: $fixtureId) {
            base {
                id
            }

            collectedFiles {
                ...FixtureCollectedMedia
            }

            ingestedFiles {
                ...FixtureIngestedMedia
            }

            delivery {
                deliveredFiles {
                    ...FixtureDeliveredMedia
                }
            }

            ...FixtureHeading
            ...FixtureFiles
        }
    }
`;

export { FIXTURE_CONTENT };
