import React, {useCallback, useState, useEffect} from "react";
import "../../screens/licensing/subPages/licensingHome.sass"
import {Typography, Table, Switch, Spinner} from "@catapultsports/referee-react";
import {IMembership} from "../../api/Customer/__types__/Memberships";
import {HeaderGroup} from "react-table";
import { useTranslation} from 'react-i18next';
import {LICENSING} from '../../services/i18n/i18n-constants';
import './licenseUsage.sass'
import {convertMembershipTypeToString, calculateMembershipsAvailable } from "../../utils/licenseUtils"
import {getDateString} from "../../utils/dateUtils";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import {useMixPanel} from "../../providers/Mixpanel";
import {usePersistedStore} from "../../state/PersistedState";
import {shallow} from "zustand/shallow";

export enum MembershipActions {
    AutoApprove,
}
export interface ComponentProps {
    memberships: IMembership,
    membershipsLoading: boolean,
    membershipsHasNextPage: boolean,
    loadNextPage: () => void,
    onAction?: (membership: IMembership, action: MembershipActions) => void
    displayHeader?: boolean
}

export const LicenseUsageComponent: React.FC<ComponentProps> = (props) => {
    const { memberships, membershipsLoading, membershipsHasNextPage, loadNextPage, onAction , displayHeader} = props
    const { t } = useTranslation();
    const mixpanel = useMixPanel();
    const email = usePersistedStore(state => state.user.email, shallow)

    const onActionTrigger = useCallback(
        (membership: IMembership, action: MembershipActions, membershipAutoMergeValueAfterChange: boolean) => {
            const membershipCopy = JSON.parse(JSON.stringify(membership));

            switch (action) {
                case MembershipActions.AutoApprove:
                    membershipCopy['autoApprove'] = membershipAutoMergeValueAfterChange;
                    mixpanel.track(MixPanelEvents.MEMBERSHIP_AUTO_APPROVE, { user: email, membership: membershipCopy })
                    break
            }

            onAction && onAction(membershipCopy, action)
        },
        [onAction, email, mixpanel]
    )

    // @ts-ignore
    return (
        <>
            {displayHeader? (
                <Typography testId={"license-usage-heading"} id={"license-usage-heading"} variant={'heading-2'}>{t(LICENSING.LICENSE_USAGE)}</Typography>
                ): null}
            <div data-testid={"license-usage-component"} id={"license-usage-component"}>
                {membershipsLoading && <Spinner size={'large'} variant={'muted'} />}
                {!membershipsLoading && memberships && memberships.length > 0 && (
                    <Table<IMembership>
                        initialState={{
                            hiddenColumns: ['base.id'],
                        }}
                        columns={[
                            {
                                Header: 'MembershipID',
                                accessor: 'base.id',
                                disableSortBy: true,
                            },
                            {
                                Header: t(LICENSING.SUBSCRIPTION),
                                accessor: 'membershipType',
                                disableSortBy: true,
                                Cell: (props) => convertMembershipTypeToString(props.row.values.membershipType)
                            },
                            {
                                Header: t(LICENSING.PRODUCT),
                                accessor: 'name',
                                disableSortBy: true,
                            },
                            {
                                Header: t(LICENSING.PURCHASED),
                                accessor: 'maxMems',
                                disableSortBy: true,
                            },
                            {
                                Header: t(LICENSING.USED),
                                accessor: 'currentMems',
                                disableSortBy: true,
                            },
                            {
                                Header: t(LICENSING.AVAILABLE),
                                accessor: 'avilableMems',
                                disableSortBy: true,
                                Cell: (props) => calculateMembershipsAvailable(props.row.values.maxMems, props.row.values.currentMems)
                            },
                            {
                                Header: t(LICENSING.SUBSCRIPTION_DATE),
                                accessor: 'membershipDate',
                                disableSortBy: true,
                                Cell: (props) => getDateString(new Date(props.row.values.membershipDate))
                            },
                            {
                                Header: t(LICENSING.AUTO_APPROVE),
                                accessor: 'autoApprove',
                                disableSortBy: true,
                                Cell: (props) => <Switch defaultValue={props.row.values.autoApprove} onChange={() => onActionTrigger({...props.row.values}, MembershipActions.AutoApprove, !props.row.values.autoApprove
                                )}  />
                            },
                        ]}
                        data={memberships}
                        mapColumnIdToName={(column: HeaderGroup) => { return column.Header }}
                        requestMoreData={loadNextPage}
                        hasMoreData={membershipsHasNextPage}
                    />
                )}
            </div>
    </>
    )
};
