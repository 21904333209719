import {gql} from "@apollo/client";

export const PLAYERS = gql`
    query players($filter: PlayerFilter, $pageSize: Int = -1, $pageNumber: Int = 0) {
        players (filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
            pageInfo {
                total
                pageSize
                pageNumber
            }
            edges {
                base {
                    id
                }
                firstName
                lastName
            }
        }
    }
`;