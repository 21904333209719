import {FixtureFilterType} from "../enum/Common";
import {FilterChipIcon} from "../../../enums/FilterChipIcon";

export const FixtureFilterTypeToGroupData = (type: FixtureFilterType): {name: string, icon: FilterChipIcon} => {
    let groupName = 'Group';
    let groupIcon = FilterChipIcon.Crosshair;

    switch (type) {
        case FixtureFilterType.CompetitionID:
            groupName = 'Competition';
            groupIcon = FilterChipIcon.Trophy;
            break;
        case FixtureFilterType.EventTypeIDs:
            groupName = 'Event';
            groupIcon = FilterChipIcon.Event;
            break;
        case FixtureFilterType.PhaseTypeIDs:
            groupName = 'Period'
            groupIcon = FilterChipIcon.Stopwatch;
            break;
        case FixtureFilterType.PlayerIDs:
            groupName = 'Player';
            groupIcon = FilterChipIcon.Person;
            break;
        case FixtureFilterType.ProviderIDs:
            groupName = 'Provider';
            groupIcon = FilterChipIcon.Download;
            break;
        case FixtureFilterType.TeamIDs:
            groupName = 'Team';
            groupIcon = FilterChipIcon.People;
            break;
        case FixtureFilterType.QualifierTypeIDs:
            groupName = 'Qualifier';
            groupIcon = FilterChipIcon.Crosshair;
            break;
        case FixtureFilterType.VenueIDs:
            groupName = 'Venue';
            groupIcon = FilterChipIcon.Stadium;
            break;
        case FixtureFilterType.CoachIDs:
            groupName = 'Coach';
            groupIcon = FilterChipIcon.Coach;
            break;
        case FixtureFilterType.RefereeIDs:
            groupName = 'Referee';
            groupIcon = FilterChipIcon.Referee;
            break;
        case FixtureFilterType.CompetitionSeasonIDs:
            groupName = 'Season';
            groupIcon = FilterChipIcon.Calendar;
            break;
    }

    return { name: groupName, icon: groupIcon };
}

export const FilterTypeFromGroupName = (type: string) => {
    switch (type) {
        case 'Competition':
            return FixtureFilterType.CompetitionID;
        case 'Event':
            return FixtureFilterType.EventTypeIDs
        case 'Period':
            return FixtureFilterType.PhaseTypeIDs
        case 'Player':
            return FixtureFilterType.PlayerIDs
        case 'Provider':
            return FixtureFilterType.ProviderIDs
        case 'Team':
            return FixtureFilterType.TeamIDs
        case 'Qualifier':
            return FixtureFilterType.QualifierTypeIDs
        case 'Venue':
            return FixtureFilterType.VenueIDs
        case 'Season':
            return FixtureFilterType.CompetitionSeasonIDs
        case 'Coach':
            return FixtureFilterType.CoachIDs
        case 'Referee':
            return FixtureFilterType.RefereeIDs
        default:
            break;
    }
}

export const PreRollTime = {
    FIVE_SECONDS: 5
}

export const videoDuration = {
    TWO_DAYS: 172800
}