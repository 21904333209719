import * as React from 'react';
import {IconChevronDown, IconChevronUp, IconPencilSmall} from "@catapultsports/referee-react/icons";
import {
    Button, Input,
    SelectOption,
    SelectOptions,
    Tooltip,
    Typography,
    UnstyledButton,
    usePositioner
} from "@catapultsports/referee-react";
import './expandableMenuItem.sass';
import {useCallback, useState} from "react";
import {GENERAL} from "../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";

interface ExpandableMenuItemProps {
    label: string;
    value: string;
    onClick?: (value: string, event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => boolean | void;
    testId?: string;
    reverseButtonOrder?: boolean;
    labelOptions?: {id: string | number, value: string}[],
    labelOptionIcon?: SVGElement
    onLabelOptionSelected?: (id: string | number) => void,
    enableEdit?: boolean,
    onLabelEdit?: (value: string) => void,
    onLabelEditCancel?: () => void
}

export const ExpandableMenuItem: React.FunctionComponent<React.PropsWithChildren<ExpandableMenuItemProps>> = (props) => {
    const {
        testId,
        children,
        label,
        reverseButtonOrder,
        labelOptions,
        labelOptionIcon,
        onLabelOptionSelected,
        enableEdit,
        onLabelEdit,
        onLabelEditCancel
    } = props;

    const contentRef = React.useRef<HTMLDivElement>();
    const { t } = useTranslation();

    const [contentHeight, setContentHeight] = React.useState('auto');
    const [isExpanded, setExpanded] = React.useState<boolean>(false);

    const handleExpand = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setContentHeight(`${contentRef?.current?.scrollHeight}px`);
        if (isExpanded)
            setOptionsVisible(false);
        setTimeout(() => setExpanded(!isExpanded));
        setTimeout(() => setContentHeight('auto'), parseInt('150ms'));
    };

    const { getPopperProps, getReferenceProps } = usePositioner({
        placement: 'bottom-start',
    });
    const [editedValue, setEditedValue] = useState<string | undefined>(undefined);
    const onEditedValueChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditedValue(event.target.value);
    }, [setEditedValue]);
    const dropdownRef = React.useRef(null);
    const optionsRef = React.useRef(null);
    const [optionsVisible, setOptionsVisible] = React.useState(false);
    const flipOptionsVisibleButton = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setOptionsVisible(!optionsVisible);
    }, [optionsVisible, setOptionsVisible]);
    const flipOptionsVisible = useCallback((isVisible: boolean) => {
        setOptionsVisible(!optionsVisible);
    }, [optionsVisible, setOptionsVisible]);

    const onEditCancel = useCallback(() => {
        setEditedValue(undefined);
        if (onLabelEditCancel)
            onLabelEditCancel();
    }, [setEditedValue, onLabelEditCancel]);

    const onEditSave = useCallback(() => {
        if (onLabelEdit && editedValue)
            onLabelEdit(editedValue);
        setEditedValue(undefined);
    }, [editedValue, setEditedValue, onLabelEdit]);

    let startIconElement;
    let contentElement;
    if (children) {
        startIconElement = (
            <button
                className={'itemIcon'}
                onClick={handleExpand}
                aria-label={isExpanded ? 'Collapse item' : 'Expand item'}
            >
                <div className={'itemChevron'}>{(isExpanded ? <IconChevronUp/> : <IconChevronDown/>)}</div>
            </button>
        );
        contentElement = (
            <div
                className={'itemContent'}
                style={
                    {
                        '--expanded-height': isExpanded ? contentHeight : '0px',
                    } as React.CSSProperties
                }
            >
                <div ref={contentRef}>{children}</div>
            </div>
        );
    }

    const buttonText = (
        <Typography className={'itemLabelText'} variant="body-1">
            <Tooltip ellipsify={label} noArrow/>
        </Typography>
    );

    const buttonElement = (
        <div onClick={handleExpand} className={'itemLabelClickArea'}>
            <UnstyledButton>
                {buttonText}
            </UnstyledButton>
            {
                labelOptions && isExpanded &&
                <span ref={dropdownRef}>
                    <Button variant={"text"} size={"small"} {...getReferenceProps()} onClick={flipOptionsVisibleButton}>
                        {labelOptionIcon ? labelOptionIcon : <IconPencilSmall/>}
                    </Button>
                </span>
            }
        </div>
    );

    const editableControls = (
        <div className={'flex horizontal editControls'}>
            <Input
                size={"small"}
                placeholder={label}
                onChange={onEditedValueChange}/>
            <Button size={"small"} variant={"outline"} onClick={onEditCancel}>{t(GENERAL.CANCEL)}</Button>
            <Button size={"small"} onClick={onEditSave}>{t(GENERAL.SAVE)}</Button>
        </div>
    );

    return (
        <>
            <div data-testid={testId}>
                <div className={'itemLabel'}>
                    {
                        enableEdit ?
                            editableControls :
                            !reverseButtonOrder ?
                                <>
                                    {buttonElement}
                                    {startIconElement}
                                </>

                                :
                                <>
                                    {startIconElement}
                                    {buttonElement}
                                </>
                    }
                    {labelOptions &&
                        <div ref={optionsRef}>
                            <SelectOptions
                                popperProps={getPopperProps}
                                parentRef={dropdownRef}
                                setIsVisible={flipOptionsVisible}
                                isVisible={optionsVisible}
                                menuItems={labelOptions}
                                onSelectItem={(selectedOption) => {
                                    if (onLabelOptionSelected)
                                        onLabelOptionSelected((selectedOption as SelectOption).id);
                                    flipOptionsVisible(false);
                                }}
                                themeOverride={'light'}
                            />
                        </div>
                    }
                </div>
                {contentElement}
            </div>
        </>
    );
};
