import React from "react";
import {getPathBackToPage, usePortalNavigation} from "../../utils/routerUtils";
import {Button, Typography} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {LOST} from "../../services/i18n/i18n-constants";
import {Routes} from "../../enums/Routes";
import {useLocation} from "@tanstack/react-location";
import "./lost.sass";

export const Lost: React.FC<any> = () => {
    const {navigate} = usePortalNavigation();
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <article className={'lost'}>
            <Typography>{t(LOST.MESSAGE)}</Typography>
            <br/>
            <Button
                onClick={() =>
                    navigate({ to: getPathBackToPage(Routes.ROOT_PATH, location.current.pathname, location.current.pathname != '/'), replace: true })}>
                {t(LOST.HOME_BUTTON)}
            </Button>
        </article>
    );
};
