import {
    DefaultGenerics,
    Location, MakeGenerics,
    PartialGenerics,
    Search,
    Updater,
    useLocation,
    useNavigate
} from "@tanstack/react-location";
import {useMixPanel} from "../providers/Mixpanel";
import {MixPanelEvents} from "../enums/MixPanelEvents";
import {decompressDateRangeSeconds} from "./dateUtils";
import {ResultType} from "../api/MatchTracker/enum/Common";
import {shallow} from "zustand/shallow";
import {usePermanentStore} from "../state/PerminantState";

export function getPathBackToPage(rootPage: string, currentPath: string, requiredCondition: boolean, backPath: string = '../'): string {
    let path = rootPage;
    if (requiredCondition) {
        const count = currentPath.split("/").length;
        path = backPath.repeat(count) + rootPage;
    }
    return './'+path;
}

export function usePortalNavigation<TGenerics extends PartialGenerics = DefaultGenerics> (){
    let navigator = useNavigate();
    const mixpanel = useMixPanel();
    const location = useLocation();
    const setNewActiveUrl = usePermanentStore((state) => state.setActiveUrl, shallow);

    const navigate = (obj: {
        search?: true | Updater<Partial<Search<unknown>>> | undefined,
        hash?: true | Updater<string> | undefined,
        replace?: boolean | undefined,
        from?: Partial<Location<TGenerics>>,
        to?: string | number | null | undefined,
        fromCurrent?: boolean | undefined,
    }) => {

        mixpanel.track(MixPanelEvents.NAVIGATION_ENTERED_SCREEN, {'Screen Name': mapRouteToName(location.current.pathname), 'Route': location.current.pathname});
        mixpanel.time_event(MixPanelEvents.NAVIGATION_ENTERED_SCREEN);

        navigator({
            to: obj.to,
            replace: obj.replace,
            search: obj.search,
            hash: obj.hash,
            from: obj.from,
            fromCurrent: obj.fromCurrent
        })

        const ignoreThisPath = ['/login', '/logout'].some(path => window.location.hash.includes(path))
        if(!ignoreThisPath) {
            setNewActiveUrl(window.location.href);
        }
    };

    const auxNavigate = (obj: {
        to?: string | undefined,
    }) => {
        mixpanel.track(MixPanelEvents.NAVIGATION_OPENED_NEW_SCREEN, {'Screen Name': mapRouteToName(location.current.pathname), 'Route': location.current.pathname});
        mixpanel.time_event(MixPanelEvents.NAVIGATION_OPENED_NEW_SCREEN);

        const url = location.history.createHref({ pathname: obj.to });

        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return {navigate, auxNavigate};
}

export function mapRouteToName(route: string) {
    const slicedRoute = route.split('/').slice(0, 2).join('/');
    // @ts-ignore
    const niceName = routeNamePairings[slicedRoute];
    if(niceName)
        return niceName;
    else
        return route;
}

const routeNamePairings = {
    '/': 'Hub',
    "/hub": 'Hub',
    '/licensing': 'Licensing',
    '/finder': 'Finder',
    '/login': 'Login',
};

export function buildPath(...segments: string[]): string {
    return segments.join('/');
}

export type Filters = {
    dateRange: string,
    resultsType: ResultType,
    competitionId: string,
    video: boolean,
    events: boolean,
    perfectMatch: boolean,
    filterIds: string[],
    gameEvents: GameEvent[]
    seasonIds: string[],
    teamIds: string[],
    playerIds: string[],
    includeFutureFixtures: boolean
}

export type GameEvent = {
    id: string | undefined,
    name: string | undefined
}

export type SearchLocationTypes = MakeGenerics<{
    Search: {
        filters: Filters
    };
}>;

export const defineQueryFilters = (filters, datePresets) => {
    const competitionId = filters?.competitionId || '';
    const videoChecked = filters?.video || false;
    const eventsChecked = filters?.events || false;
    const perfectMatchChecked = filters?.perfectMatch || false;
    const dateRange =
        filters?.dateRange ?
            decompressDateRangeSeconds(filters?.dateRange) : {
                from: datePresets.ALL.from,
                to: datePresets.ALL.to
            };
    const seasonIds = filters?.seasonIds || []
    const teamIds = filters?.teamIds || []
    const playerIds = filters?.playerIds || []
    const includeFutureFixtures = filters?.includeFutureFixtures || false

    return [competitionId, videoChecked, eventsChecked, perfectMatchChecked, dateRange, seasonIds, teamIds, playerIds, includeFutureFixtures]
}
