import {useCallback, useMemo, useState} from "react";
import {
    FormControl,
    Input,
    noop,
    Tooltip, Typography,
    useAlerts,
} from "@catapultsports/referee-react";
import {IPlaylistResponse} from "../../../api/Hub/collections/__types__/Playlist";
import {COLLECTIONS, GRID_FOOTER, HUB} from "../../../services/i18n/i18n-constants";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import {useMixPanel} from "../../../providers/Mixpanel";
import {usePortalNavigation} from "../../../utils/routerUtils";
import {HubDialogComponent} from "../../hubDialog/hubDialog";
import {IconScissors} from "../../../../icons";
import {useUpdateTagNotes} from "../../../api/Hub/tags/updateTags/useUpdateTagNotes";
import "../playlistControls.sass"
import '../playlistControlsHeaderComponent.sass';
import './playlistControlsHeaderTrim.sass'
import {useUpdateTagAsset} from "../../../api/Hub/tags/updateTagAsset/useUpdateTagAsset";

interface PlaylistControlsHeaderComponentTrimProps {
    data: IPlaylistResponse
    selectedTagIds?: string[]
    setSelectedTagIds?: (value: string[]) => void
    successCallback?: () => void
}

export const PlaylistControlsHeaderTrim: React.FC<PlaylistControlsHeaderComponentTrimProps> = (props) => {
    const {
        data: playlistData,
        selectedTagIds,
        setSelectedTagIds,
        successCallback
    } = props;

    const mixpanel = useMixPanel();
    const { showError, showInfo } = useAlerts();
    const {navigate} = usePortalNavigation()
    const {t} = useTranslation();

    const collectionId = useSessionStore(state => state.playlist.id, shallow);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [preRollValue, setPreRollValue] = useState<number | null>(null);
    const [postRollValue, setPostRollValue] = useState<number | null>(null);

    const errorAlert = useCallback(() => {
        let message = t(COLLECTIONS.PRE_AND_POST_ROLL_FAILED)
        if(selectedTagIds && selectedTagIds?.length > 1) message = t(COLLECTIONS.PRE_AND_POST_ROLL_FAILED_MULTIPLE)
        showError(message)
    }, [selectedTagIds, showError, t])

    const onSuccess = useCallback(() => {
        let message = t(COLLECTIONS.PRE_AND_POST_ROLL_UPDATED)
        if(selectedTagIds && selectedTagIds?.length > 1) message = t(COLLECTIONS.PRE_AND_POST_ROLL_UPDATED_MULTIPLE)
        showInfo(message)
        mixpanel.track(`Clips Pre/Post Roll Updated`, { collectionID: collectionId, clipsId: selectedTagIds });
        setSelectedTagIds && setSelectedTagIds([])
    }, [collectionId, mixpanel, navigate, playlistData?.playlist?.tags, selectedTagIds, setSelectedTagIds, showInfo, t])

    let  selectedTags = useMemo(() => {

        const selectedTags = playlistData?.playlist?.tags?.filter(x => selectedTagIds?.some(y => y == x.id))

        if(selectedTags?.length > 0) {
            setPreRollValue(selectedTags?.length > 1 ? null : ((selectedTags[0].poiTime - selectedTags[0].startTime) / 1000));
            setPostRollValue(selectedTags?.length > 1 ? null : ((selectedTags[0].endTime - selectedTags[0].poiTime) / 1000));
        }
        return selectedTags;

    }, [selectedTagIds, isModalOpen])

    //update tags
    const {updateTag} = useUpdateTagNotes(errorAlert)
    const {updateTagAsset} = useUpdateTagAsset(errorAlert)

    //Set PreAndPost
    const handleUpdateConfirmation = useCallback(() => {
        setIsModalOpen(!isModalOpen)

        const updatePromises = selectedTags.map(tag => {
            const tagToUpdate = {
                id: tag.id,
                startTime: tag.poiTime - (preRollValue * 1000),
                endTime: tag.poiTime + (postRollValue * 1000)
            };

            tag.assets?.forEach((asset) => {
                updateTagAsset({
                    id: asset.id,
                    startTime: asset.poiTime - (preRollValue * 1000),
                    endTime: asset.poiTime + (postRollValue * 1000)
                })
            })

            return updateTag(tagToUpdate);
        });

        Promise.all(updatePromises)
            .then(() => {
                onSuccess();

                if(successCallback)
                    successCallback();
            })
            .catch(error => {
                throw new Error(error);
            });
    }, [
        updateTag,
        isModalOpen,
        onSuccess,
        selectedTags,
        preRollValue,
        postRollValue
    ])

    const areTagsSelected = useMemo(() => {
        return selectedTagIds && selectedTagIds?.length > 0
    } , [selectedTagIds])


   const updateRollValue = useCallback(
       (
           input: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
           setRollValue: (value: (((prevState: (number | null)) => (number | null)) | number | null)) => void) => {

        const newValue = +input.target.value
        if (!isNaN(newValue))
            setRollValue(newValue)
        else
            setRollValue(0)
    }, []);

    return (
        <>
            <Tooltip
                text={
                    areTagsSelected ? t(COLLECTIONS.UPDATE_PRE_AND_POST_ROLL) :
                        t(COLLECTIONS.SELECT_CLIPS_TO_UPDATE_PRE_AND_POST_ROLL)
                }
            >
                    <span tabIndex={0} className={"tooltip-span-style"}>
                         <IconScissors
                             className={`icon ${areTagsSelected ? "" : "disabled-icon"}`}
                             onClick={areTagsSelected ? () => setIsModalOpen(true) : noop}
                             data-testid={"collection-actions-trim-icon"}
                         />
                    </span>
            </Tooltip>
            <div className={"outer-dialog-component"}>
                {isModalOpen?
                    <HubDialogComponent
                        dialogTheme={"dark"}
                        dialogSize={'small'}
                        dialogHeader={t(COLLECTIONS.ADJUST_PRE_AND_POST_ROLL)}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        modalContent={
                            <div
                                data-testid={`trim-clips-content`}
                                className={`trim-clips-content`}
                            >
                                <Typography variant={"body-1"}>
                                    {t(COLLECTIONS.ADD_TIME_TO_CLIPS)}
                                </Typography>
                                <Typography color={"catapult-orange"} variant={"body-1"}>
                                    {selectedTagIds?.length} {selectedTagIds.length > 1 ? t(HUB.CLIPS) : t(HUB.CLIP)} {t(GRID_FOOTER.SELECTED)}
                                </Typography>
                                <div className={'trim-clips-form'}>
                                    <FormControl
                                        label={t(COLLECTIONS.PRE_ROLL)}
                                        error={preRollValue + postRollValue > 60 ? t(COLLECTIONS.CLIPS_LENGTH_LIMIT) : ''}
                                    >
                                        <Input
                                            value={preRollValue}
                                            size={'small'}
                                            data-testid={`pre-roll-input`}
                                            onChange={(input) => {
                                                updateRollValue(input, setPreRollValue);
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        label={t(COLLECTIONS.POST_ROLL)}
                                        error={preRollValue + postRollValue > 60 ? " ": ''}
                                    >
                                        <Input
                                            size={'small'}
                                            value={postRollValue}
                                            data-testid={`post-roll-input`}
                                            onChange={(input) => {
                                                updateRollValue(input, setPostRollValue);
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        }
                        displayDialogFooterButtons={true}
                        hideCloseButton={true}
                        footerPrimaryButtonText={t(HUB.ADJUST)}
                        footerPrimaryButtonAction={handleUpdateConfirmation}
                        footerPrimaryButtonDisabled={preRollValue == null || postRollValue == null || preRollValue + postRollValue > 60}
                        tooltipText={t(COLLECTIONS.CLIPS_LENGTH_LIMIT)}
                        dialogId={'trim-clips'}
                        allowDialogClosure={false}
                    />
                    : null
                }
            </div>
        </>
    )
}