import React, {useMemo} from "react";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import {VideoPlayer} from "../videoPlayer/videoPlayer";
import {useMixPanel} from "../../providers/Mixpanel";
import {ResultType} from "../../screens/finder/finder";
import './videoSection.sass';
import {Button, Spinner, Typography} from "@catapultsports/referee-react";
import {IconCancelSmall} from "@catapultsports/referee-react/icons";
import {useTranslation} from "react-i18next";
import {FINDER} from "../../services/i18n/i18n-constants";
import {fileObjectToReturn} from "../../utils/objectUtils";
import {
    VideoAngleSelector
} from "../videoAngleSelector/videoAngleSelector";
import {ContentFile} from "../../api/MatchTracker/__types__/Common";
import {FileMedia} from "../../api/MatchTracker/fixtures/__types__/Fixtures";

export interface VideoSectionProps {
    title: string,
    finderResult: ResultType;
    fixtureId: string;
    selectedVideoSource: string | undefined;
    onClose: () => void;
    autoplay?: boolean;
    updateTimeCallback?: (currentTime: number) => void;
    loading?: boolean;
    angleList?: {};
    setActiveFile?: (file?: ContentFile | FileMedia) => void
}

export const VideoSection: React.FC<React.PropsWithChildren<VideoSectionProps>> = (props) => {
    const { title, finderResult, onClose, selectedVideoSource, autoplay, updateTimeCallback , loading, angleList, setActiveFile} = props;
    const mixpanel = useMixPanel();
    const {t} = useTranslation();

    const anglesToDisplay = useMemo(() => {
        let videoFiles = []
        if(angleList){
            angleList
                .filter(file => file.videoAsset != null || file.fileDetails.fileType.toLowerCase().startsWith('video'))
                .forEach(file => {
                    videoFiles.push(fileObjectToReturn(file))
                })
        }
        return videoFiles
    }, [angleList])

    if (loading) return <Spinner />;

    return (
        <section className={'video-section-wrapper'}>
            <section className={'video-wrapper'}>
                <section className={'title-section'}>
                    <Typography variant={"heading-4"}>{title}</Typography>
                    <div>
                        <VideoAngleSelector angleList={anglesToDisplay} setActiveFile={setActiveFile} activeFileSource={selectedVideoSource} />
                        <Button size="small" variant="text" onClick={onClose}>
                            <IconCancelSmall/>
                        </Button>
                    </div>
                </section>
                { selectedVideoSource ?
                    <section className={'video-section'}>
                        <VideoPlayer
                            updateTimeCallback={updateTimeCallback}
                            autoPlay={autoplay}
                            sourceUrl={[{src: selectedVideoSource}]}
                            loadedCallback={() => mixpanel.time_event(MixPanelEvents.FINDER_CLIP_PLAYING)}
                            endedCallback={() => mixpanel.track(MixPanelEvents.FINDER_CLIP_PLAYING, {
                                finderResult,
                                videoSrc: selectedVideoSource
                            })}/>
                    </section> :
                    <>{finderResult === ResultType.Events && <Typography>{t(FINDER.EVENT_NO_VIDEO)}</Typography>}</>
                }
            </section>
        </section>
    )
};
