import React, {useCallback, useState} from "react";
import "./login.sass";
import {Button, Checkbox, FormControl, Input, Select, Typography,} from "@catapultsports/referee-react";
import {ApolloError, useMutation} from "@apollo/client";
import {LOGIN} from "../../api/authService/login.rest.gql";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {getPathBackToPage, usePortalNavigation,} from "../../utils/routerUtils";
import {useTranslation} from "react-i18next";
import {GENERAL, LOGIN as TSLOGIN} from "../../services/i18n/i18n-constants";
import {useMixPanel} from "../../providers/Mixpanel";
import {usePersistedStore} from "../../state/PersistedState";
import {shallow} from "zustand/shallow";
import {CleanEmail, HashString} from "../../utils/stringUtils";
import {Products} from "../../enums/Products";
import {getFirstProductRoute} from "../../utils/routes";
import {useLocation} from "@tanstack/react-location";
import {ApolloExtractError, getErrorMessage} from "../../utils/apolloUtils";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import {CUSTOMERLOGIN} from "../../api/Customer/authService/login.gql";
import {IValueAndIdPair} from "../../api/Hub/common/Common";
import {usePermanentStore} from "../../state/PerminantState";
import {
  updateLink as updateHubApiLink
} from "../../api/Hub/client";
import {
  updateLink as updateMatchtrackerApiLink
} from "../../api/MatchTracker/matchTrackerClient";
import {getNewGraphLink, getNewGraphString} from "../../api/commonApolloClientFunctions";

interface LoginRequest {
  username?: string;
  password?: string;
  customer?: string;
}

export interface Login {
  logIn: {
    exp: number;
    customerId: string;
    products?: Array<IValueAndIdPair>;
    customers: Array<IValueAndIdPair>;
    s3Bucket: string;
    hubApiUrl?: string;
    mtApiUrl?: string;
  };
}

export const Login: React.FC<{ onLogin?: () => void }> = (props) => {
  const [isReset, setIsReset] = useState(false);
  const [username, setUsername] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [customerSelected, setCustomerSelected] = useState<string | undefined>(
    undefined
  );
  const [customerSelection, setCustomerSelection] = useState<IValueAndIdPair[]>([]);
  const [customerAPILoginSuccessful, setCustomerAPILoginSuccessful] = useState<
    boolean | undefined
  >(false);

  const [hubAPILoginSuccessful, setHubAPILoginSuccessful] = useState<
      boolean | undefined
  >(false);

  const { navigate } = usePortalNavigation();
  const { t } = useTranslation();
  const mixpanel = useMixPanel();
  const userRememberMe = usePersistedStore((state) => state.user.rememberMe, shallow);
  const setRememberMe = usePersistedStore((state) => state.persistRememberMe, shallow);
  const setUser = usePersistedStore((state) => state.persistUser, shallow);
  const setAllowLicensing = usePersistedStore((state) => state.setAllowLicensing, shallow);
  const persistHubUrl = usePersistedStore((state) => state.persistHubUrl, shallow);
  const persistMatchtrackerUrl = usePersistedStore((state) => state.persistMatchtrackerUrl, shallow);
  const persistRegion = usePersistedStore((state) => state.persistRegion, shallow);
  const location = useLocation();

  const activeUrl = usePermanentStore((state) => state.activeUrl, shallow);
  let requiredField = t(TSLOGIN.REQUIRED_FIELD)
  let invalidEmail = t(TSLOGIN.INVALID_EMAIL)

  const schema = yup
    .object()
    .shape({
      username: yup.string().email(invalidEmail).required(requiredField),
      password: yup.string().required(requiredField),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    defaultValues: {
      username: "",
      password: "",
      customer: customerSelected,
    },
    resolver: yupResolver(schema),
  });

  const onLoginSuccess = ({ logIn }: Login) => {
    const userProducts = logIn.products ? logIn.products.map((product) => product.id) as Products[] : [];
    mixpanel.identify(HashString(username).toString());
    mixpanel.people.set({
      customerId: logIn.customerId,
      $name: CleanEmail(username),
      products: userProducts,
      $email: username,
    });
    mixpanel.track(MixPanelEvents.LOGIN_SUCCESS, {
      method: "Credentials",
      username: CleanEmail(username),
      expiry: logIn.exp,
      rememberMe: userRememberMe,
    });
    setUser(logIn.customerId, "", logIn.exp, userProducts, username);
  }

  const defineErrorResponse = (error: ApolloError) => {
    const errorDetail = ApolloExtractError(error);
    setError(getErrorMessage(errorDetail).includes('401') ? t(TSLOGIN.INCORRECT_LOGIN) : t(TSLOGIN.SERVER_ERROR));
    mixpanel.track(MixPanelEvents.LOGIN_FAILED, { error: errorDetail });
  };

  const [login] = useMutation(LOGIN, {
    onCompleted: (data) => {
      const { logIn } = data;
      if (logIn) {
        setHubAPILoginSuccessful(true)

        if (logIn.hubApiUrl) {
          updateHubApiLink(getNewGraphLink(logIn.hubApiUrl));
          persistHubUrl(getNewGraphString(logIn.hubApiUrl));
        }
        if (logIn.mtApiUrl) {
          updateMatchtrackerApiLink(getNewGraphLink(logIn.mtApiUrl));
          persistMatchtrackerUrl(getNewGraphString(logIn.mtApiUrl));
        }
        if (logIn.region)
          persistRegion(logIn.region);

        // if user has multiple customers associated, prompt to select profile (if not selected)
        if (!customerSelected && logIn.customers.length > 1) {
          setCustomerSelection(logIn.customers);
          return logIn.customers.length
        } else {
          onLoginSuccess(data);
          if(activeUrl){
            window.location.replace(activeUrl);
          } else {
            navigate({
              to: getPathBackToPage(
                  getFirstProductRoute(logIn.products.map((p: any) => p.id)) || '/',
                  location.current.pathname,
                  location.current.pathname != "/"
              ),
              replace: true,
            })
          }
        }
      }
    },
    onError: (e) => defineErrorResponse(e)
  });

  const [customerAPILogin] = useMutation(CUSTOMERLOGIN, {
    onCompleted: (x) => {
      let successfulLogin = x?.login?.message.toLowerCase().startsWith("successful")
      if (successfulLogin) {
        setCustomerAPILoginSuccessful(true);
        setAllowLicensing(true);
      }
    }
  });

  const onFormSubmit = useCallback(
      (values: LoginRequest) => {
          setError(null)
          setUsername(values.username!)
          mixpanel.track(MixPanelEvents.LOGIN_SUBMITTED, { email: values.username });

          login({
            variables: {
              input: {
                ...values,
                customer: customerSelected,
              },
            },
          });
      },
      [customerAPILoginSuccessful, hubAPILoginSuccessful, customerSelected]
  )

  return (
    <article className={"login"}>
      <div className={'app-details'}>
        <img src={"/logo.png"} alt={"Catapult Portal Logo"} />
        <span>{`v${process.env.APP_VERSION}`}</span>
      </div>
      {!isReset && (
        <section data-testid={"login"}>
          {error && (
            <div className={"alert-box error"}>
              <Typography>{error}</Typography>
            </div>
          )}

          <form onSubmit={handleSubmit(onFormSubmit)}>
            {customerSelection.length === 0 && (
              <>
                <FormControl
                  label={t(TSLOGIN.EMAIL)!}
                  error={errors.username ? errors.username?.message : ""}
                  fullWidth
                >
                  <Input
                    id="username"
                    data-testid="username"
                    {...register("username")}
                    placeholder={t(TSLOGIN.EMAIL)!}
                    autoComplete={"off"}
                  />
                </FormControl>
                <FormControl
                  label={t(TSLOGIN.PASSWORD)!}
                  error={errors.password ? errors.password?.message : ""}
                  fullWidth
                >
                  <Input
                    placeholder={t(TSLOGIN.PASSWORD)!}
                    type="password"
                    data-testid="password"
                    {...register("password")}
                    autoComplete={"off"}
                  />
                </FormControl>
                <Button stretched type="submit">
                  Sign In
                </Button>
                <div className={"split"}>
                  <Checkbox
                    label={t(TSLOGIN.REMEMBER_ME)}
                    name="name"
                    onChange={() => setRememberMe(!userRememberMe)}
                    checked={userRememberMe}
                  />
                  <Button variant="text" onClick={() => setIsReset(!isReset)}>
                    <Typography>{t(TSLOGIN.FORGOT_PASSWORD)}</Typography>
                  </Button>
                </div>
              </>
            )}
            {customerSelection.length > 0 && (
              <div>
                <FormControl label={t(TSLOGIN.PROFILE)!} fullWidth>
                  <Select
                    {...register("customer")}
                      menuItems={customerSelection.map((profile) => ({
                        value: profile.value,
                        id: profile.id,
                        isSelected: customerSelected === profile.id
                      }))}
                      onSelectItem={(selected) => selected.length > 0 && setCustomerSelected(selected[0].id as string)}
                      isSearchable
                  />
                </FormControl>
                <Button stretched type="submit" disabled={!customerSelected}>
                  <Typography>{t(GENERAL.CONTINUE)}</Typography>
                </Button>
              </div>
            )}
          </form>
        </section>
      )}
      {isReset && (
        <section data-testid={"reset"}>
          <FormControl label={t(TSLOGIN.EMAIL)!}>
            <Input placeholder={t(TSLOGIN.EMAIL)!} autoComplete={"off"} />
          </FormControl>
          <Button stretched onClick={() => setIsReset(false)}>
            {t(GENERAL.SUBMIT)}
          </Button>
        </section>
      )}
    </article>
  );
};
