import {ApolloError, useQuery} from "@apollo/client";
import {PlaylistResponse, UseItemComments} from "./__types__/Comments";
import {PLAYLIST_COMMENTS} from "./comments.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../enums/apiErrors";

const usePlaylistComments = (playlistId: string | undefined, onError?: (error: ApolloError, errorMessage: string) => void): UseItemComments => {
    const { loading, error, data } = useQuery<PlaylistResponse, {playlistId: string | undefined}>(PLAYLIST_COMMENTS, {
        variables: {
            playlistId: playlistId
        }
    });


    useEffect(() => {
        handleErrorRaised(error, PLAYLIST_COMMENTS, ApiErrors.PlaylistComments, onError);
    }, [error, PLAYLIST_COMMENTS]);

    return {
        value: data?.playlist,
        loading,
        error
    };
};

export default usePlaylistComments;
