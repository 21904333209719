import {ApolloError, useMutation} from "@apollo/client";
import {UPDATE_MEMBERSHIPS} from "./memberships.gql";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {ApiErrors} from "../../../enums/apiErrors";
import {LICENSING} from "../../../../services/i18n/i18n-constants";
import {useEffect} from "react";

export interface UpdateMemberships {
    updateAutoApprove: (membershipId: string, autoApprove: boolean) => void
}

export interface AutoApproveResponse {
    updateMembership: {
        base: { id: string },
        autoApprove: boolean
    }
}

const useUpdateMemberships = (onSuccess?: (response: AutoApproveResponse) => void, onError?: (error: ApolloError, errorMessage: string) => void): UpdateMemberships => {
    const [updateAutoApprove, { loading, error}] = useMutation(UPDATE_MEMBERSHIPS, {
        onCompleted: (data: AutoApproveResponse) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });

    useEffect(() => {
        handleErrorRaised(error, UPDATE_MEMBERSHIPS, ApiErrors.UpdateMembership, onError);
    }, [error, UPDATE_MEMBERSHIPS]);

    return {
        updateAutoApprove: (membershipId: string, autoApprove: boolean) => updateAutoApprove({
            variables: {
                membershipID: membershipId,
                membershipUpdate: {
                    autoApprove: autoApprove
                }
            },
            context: { endpoint: LICENSING.CUSTOMER_API },
        })
    }
};

export default useUpdateMemberships;