import React from "react";
import {Column, HeaderGroup} from "react-table";
import './emptyTable.sass'
import {Table} from "@catapultsports/referee-react";

interface EmptyTableProps {
    headings: Column<any>[],
    emptyComponent: React.ReactNode,
    tableClass?: string
}

export const EmptyTable: React.FC<EmptyTableProps> = (props) => {
    const { headings, emptyComponent, tableClass } = props
    return (
        <div className={['empty-table', tableClass].join(' ')}  data-testid={'empty-table'}>
            <Table
                columns={headings}
                data={[]}
                initialState={{
                    hiddenColumns: ['id'],
                }}
                mapColumnIdToName={(column: HeaderGroup) => {
                    return column.Header;
                }}
            />
                <div className={"empty-table-text"}>
                    {emptyComponent}
                </div>
        </div>
    )
}