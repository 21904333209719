import React from 'react';
import Plyr from "@sbgsportssoftware/plyr";
import {PlayerProvider} from "../player/player.context";
import Player, {PlayerProps} from "../player";
import "./videoPlayer.sass"
import Source = Plyr.Source;

interface VideoPlayerProps {
    sourceUrl: Source | Source[];
    loadedCallback?: () => void;
    endedCallback?: () => void;
    updateTimeCallback?: (currentTime: number) => void;
    options?: Plyr.Options;
    autoPlay?: boolean;
    onPlayPause?: (playing: boolean) => void;
}

export const VideoPlayer = (props: VideoPlayerProps) => {
    const {
        sourceUrl,
        loadedCallback,
        endedCallback,
        updateTimeCallback,
        options,
        autoPlay ,
        onPlayPause } = props;

    const source = {
        type: 'video',
        sources: sourceUrl,
        autoplay: autoPlay,
    } as PlayerProps["source"];

    function getPlyrProgressContainerWidth() {
        const progressContainer = document.querySelector('.plyr') as HTMLElement;
        return progressContainer?.offsetWidth;
    }

    function setPlyrProgressContainerWidth() {
        const width = getPlyrProgressContainerWidth();
        if(width < 525) {
            document.querySelector('.plyr__progress__container')?.setAttribute('style', `visibility: hidden`);
            document.querySelector('.plyr__time--current')?.setAttribute('style', `visibility: hidden`);
            document.querySelector('.plyr__time--duration')?.setAttribute('style', `visibility: hidden`);
        } else {
            document.querySelector('.plyr__progress__container')?.setAttribute('style', `visibility: visible`);
            document.querySelector('.plyr__time--current')?.setAttribute('style', `visibility: visible`);
            document.querySelector('.plyr__time--duration')?.setAttribute('style', `visibility: visible`);
        }
    }

    setPlyrProgressContainerWidth();
    window.addEventListener('resize', setPlyrProgressContainerWidth);

    return (
        <div data-testid={'videoPlayer'}>
            <PlayerProvider>
                <Player
                    id={'player'}
                    onPlayCallback={() => onPlayPause ? onPlayPause(true) : false}
                    onPauseCallback={() => onPlayPause ? onPlayPause(false) : false}
                    source={source || []}
                    loadedCallback={() => {
                        setPlyrProgressContainerWidth();
                        if (loadedCallback) {
                            loadedCallback()
                    }}}
                    endedCallback={endedCallback}
                    updateTimeCallback={updateTimeCallback}
                    options={options} />
            </PlayerProvider>
        </div>
    );
};

