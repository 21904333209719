import {
  ApolloClient,
  from,
  fromPromise,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import {RestLink} from "apollo-link-rest";
import {onError} from "@apollo/client/link/error";
import {urlSettingsObject} from "./__types__/common";
import {LICENSING} from "../services/i18n/i18n-constants";
import {logoutFunction} from "../utils/logoutUtils";

export const hubAPILink = new HttpLink({
  uri: process.env.HUB_API_URL,
  credentials: "include",
});
const customerAPILink = new HttpLink({
  uri: process.env.CUSTOMER_API_URL,
  credentials: "include",
});

export const createClientLinks = (
    linkType: string,
    clientSpecificURI?: string,
    clientSpecificRestURI?: string
) => {
  if (linkType === LICENSING.HTTP_LINK) {
    return new HttpLink({
      uri: clientSpecificURI,
      credentials: "include",
    });
  } else {
    return new RestLink({
      uri: clientSpecificRestURI,
      credentials: "include",
    });
  }
};

const forceLogout = () => {
  const skipClearState = ['/login', '/logout'].some(path => window.location.hash.includes(path))
  if (!skipClearState) {
    logoutFunction().finally(() => window.location.replace("/#/logout"));
  }
}

export const createErrorLink = (refreshToken: any) => {
  return onError(({networkError, operation, forward}) => {
    if (networkError && "statusCode" in networkError) {
      let networkStatusCode = networkError.statusCode;
      let operationName = operation.operationName;
      if (
          (networkStatusCode === 401 || networkStatusCode === 403 || networkStatusCode === 500) &&
          operationName !== "LogIn" &&
          operationName !== "RefreshToken"
      ) {
        return fromPromise(
            refreshToken().catch(() => forceLogout())
        ).flatMap(() => forward(operation));
      } else if (
          (networkStatusCode === 401 || networkStatusCode === 403 || networkStatusCode === 500) &&
          operationName === "RefreshToken"
      ) {
        forceLogout()
      }
    }
  });
};

const getDefaultHubLink = (): HttpLink => {
  const persistentStorage = localStorage.getItem('temp-persistent-storage');
  if (persistentStorage) {
    const storageObject = JSON.parse(persistentStorage);
    if (storageObject?.state?.user?.hubUrl)
      return new HttpLink({
        uri: storageObject.state.user.hubUrl,
        credentials: "include",
      });
  }

  return hubAPILink;
};

export const createApolloLinks = (
    errorLink: ApolloLink,
    restLink?: RestLink | null,
    createApolloLink?: boolean,
    urlSettings?: typeof urlSettingsObject,
    customLink?: HttpLink
) => {
  let httpLink;
  if (createApolloLink) {
    httpLink = ApolloLink.split(
        (operation) => operation.getContext().endpoint === LICENSING.CUSTOMER_API,
        customerAPILink, //if above
        customLink ? customLink : getDefaultHubLink()
    );
  } else {
    httpLink = createClientLinks(
        urlSettings?.linkType,
        urlSettings?.clientSpecificURI,
        urlSettings?.clientSpecificRestURI,
    );
  }

  let link;
  if (restLink !== null) {
    link = from([errorLink, restLink, httpLink])
  } else {
    link = from([errorLink, httpLink])
  }

  return link;
};


export const createApolloClient = (
    errorLink: ApolloLink,
    cache: InMemoryCache,
    restLink?: RestLink | null,
    createApolloLink?: boolean,
    urlSettings?: typeof urlSettingsObject
) => {
  return new ApolloClient({
    link: createApolloLinks(errorLink, restLink, createApolloLink, urlSettings),
    cache: cache,
    connectToDevTools: true,
  });
};

export const clearSpecificCacheForAGivenClient = (specificClient: ApolloClient<NormalizedCacheObject>, fieldName: string) => {
  specificClient.cache.evict({ fieldName: fieldName });
}

const GraphEndpointSuffix = '/api/graphql';

export const getNewGraphString = (newUrl: string) => newUrl + GraphEndpointSuffix;
export const getNewGraphLink = (newUrl: string) => new HttpLink({uri: getNewGraphString(newUrl), credentials: "include"});
