import {IEventTypesResponse} from "../__types__/Common";
import {useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {EVENT_TYPES} from "./eventTypes.gql";

const useEventTypes = (pageSize: number = -1, pageNumber: number = 0) => {
    const { loading, error, data } = useQuery<IEventTypesResponse>(EVENT_TYPES, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    });

    return {
        state: {
            eventTypes: data?.eventTypes?.edges,
            eventTypesPageSize: pageSize,
            eventTypesLoading: loading,
            eventTypesError: error
        }
    };
};

export default useEventTypes;