import { StoreSlice } from '../StoreSlice';
import {FixtureFilter} from "../../../screens/finder/subPages/gamesResults";
import {FilterChip} from "../../../components/filterChipList/filterChipList";

export interface FinderSliceProps {
	search: {
		filterIds: string[]
		fixtureFilters: FixtureFilter[]
		filterChips: FilterChip[]
	},
	selectedFinderVideoAngle: string | undefined,
	setFilterIds: (filterIds: string[]) => void,
	setSearchItems: (fixtureFilters: FixtureFilter[], filterChips: FilterChip[]) => void,
	setSelectedFinderVideoAngle: (value: string | undefined) => void,
}

export const createFinderSlice: StoreSlice<FinderSliceProps> = (set, get) => ({
	search: {
		filterIds: [],
		fixtureFilters: [],
		filterChips: []
	},
	selectedFinderVideoAngle: undefined,
	setFilterIds: (filterIds: string[]) => set((state: FinderSliceProps) => {
		state.search.filterIds = filterIds;
		return state;
	}),
	setSearchItems: (fixtureFilters: FixtureFilter[], filterChips: FilterChip[]) => set((state: FinderSliceProps) => {
		state.search.fixtureFilters = fixtureFilters;
		state.search.filterChips = filterChips;
		return state;
	}),
	setSelectedFinderVideoAngle: (value: string | undefined) => set((state: FinderSliceProps) => {
		state.selectedFinderVideoAngle = value;
		return state;
	})
});
