import React, {useCallback, useEffect} from "react";
import {
    Checkbox,
    Pill,
    Spinner,
    states,
    statesSelected,
    Table,
    Typography,
    useAlerts
} from "@catapultsports/referee-react";
import {Column, HeaderGroup, Row} from "react-table";
import {FINDER, GENERAL, HUB} from "../../../services/i18n/i18n-constants";
import useFixtures from "../../../api/MatchTracker/fixtures/useFixtures";
import {useTranslation} from "react-i18next";
import {dateStringToShortFormat} from "../../../utils/stringUtils";
import {DateTime} from "luxon";
import {IFixturesEdges} from "../../../api/MatchTracker/fixtures/__types__/Fixtures";
import {FixtureFilterType} from "../../../api/MatchTracker/enum/Common";
import {useMixPanel} from "../../../providers/Mixpanel";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import './gamesResults.sass';
import {DateRange, EventResult, GameResult} from "../../../__types__/globalTypes";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {FINDER_PAGE_SIZE} from "../../../api/enums/clientMagic";
import {IconCameraSmall, IconPlaySmall} from "@catapultsports/referee-react/icons";
import {EmptyTable} from "../../../components/emptyTable/emptyTable";

export interface FixtureFilter {
    filterType: FixtureFilterType;
    id: string | undefined;
    name: string | undefined;
}

interface GamesTableProps {
    dateRange: DateRange,
    competitionId: string,
    getVideo: boolean,
    getEvents: boolean,
    usePerfectMatch: boolean,
    searchFilters: FixtureFilter[],
    seasonsIds: [],
    teamIds: [],
    playerIds: [],
    includeFutureFixtures: boolean
}

export const GamesTable: React.FC<GamesTableProps> = (props) => {
    const { dateRange, competitionId, getVideo, getEvents, usePerfectMatch, searchFilters, seasonsIds, teamIds, playerIds, includeFutureFixtures} = props;
    const {t} = useTranslation();
    const mixpanel = useMixPanel();
    const selectedResult = useSessionStore(state => state.games.selectedResult, shallow);
    const setSelectedResult = useSessionStore(state => state.setSelectedGameResult, shallow);
    const results = useSessionStore<GameResult[]>(state => state.games.results, shallow);
    const setResults = useSessionStore(state => state.setGameResults, shallow);
    const checkedIds = useSessionStore<string[]>(state => state.games.checkedIds, shallow);
    const setCheckedIds = useSessionStore(state => state.setCheckedGameIds, shallow);
    const setMatchtrackerIds = useSessionStore(state => state.setMatchtrackerIds, shallow);
    const setNumberOfRecords = useSessionStore(state => state.setNumberOfRecords, shallow);
    const { showError } = useAlerts();

    const {
        state: {fixtures, loading, error, hasNextPage, fixturesQuery, numberOfRecords},
        operations: {loadNextPage},
    } = useFixtures(FINDER_PAGE_SIZE, 0, competitionId, searchFilters, dateRange, getVideo, getEvents, () => showError(t(HUB.FIXTURES_ERROR)), seasonsIds, teamIds, playerIds, includeFutureFixtures);

    useEffect(() => {
        setSelectedResult(undefined);
        setCheckedIds([]);
    }, [dateRange.from, dateRange.to, competitionId, getVideo, getEvents, usePerfectMatch, searchFilters]);

    useEffect(() => {
        if(numberOfRecords){
            setNumberOfRecords(numberOfRecords)
        }else{
            setNumberOfRecords(0)
        }
    }, [numberOfRecords, setNumberOfRecords])

    useEffect(() => {
        if (fixtures) {
            setMatchtrackerIds(
                fixtures.filter((fixture) => checkedIds.includes(fixture.base.id))
                    .map(fixtures => fixtures.providers.find(provider => provider.values))
                    .map((provider) => provider?.values.id));
        }
    }, [checkedIds]);

    const selectRow = useCallback(
        (row: Row<GameResult>) => {
            setSelectedResult(row.values as GameResult);
            mixpanel.track(MixPanelEvents.FINDER_GAME_RESULT_SELECTED, { values: row.values });
        }, []);

    const SelectedCell = ({row}: { row: Row<GameResult> }) => {
        return (
            <Checkbox key={`checked-${row.values.id}`} label={''} checked={checkedIds.includes(row.values.id)} onChange={(x) => {
                if (checkedIds.includes(row.values.id)) {
                    setCheckedIds(checkedIds.filter((id) => id != row.values.id));
                }
                else {
                    setCheckedIds([...checkedIds, row.values.id]);
                }
            }}/>
        );
    }

    const DateCell = ({row}: { row: Row<GameResult> }) => {
        return (
            <div key={`date-${row.values.id}`} data-testid={'table-cell'} onClick={() => selectRow(row)}>
                <Typography variant='body-2'>{dateStringToShortFormat(row.values.date)}</Typography>
            </div>
        );
    }

    const RelevanceCell = ({row}: { row: Row<GameResult> }) => {
        return (<div key={`relevance-${row.values.id}`} data-testid={'table-cell'} onClick={() => selectRow(row)}>
                <Pill
                    shape="square"
                    value={row.values.relevance + ' HITS'}
                    variant="information"
                />
            </div>
        );
    }
    const TeamCell = (row: Row<GameResult>, key: string) => {
        return (
            <div key={`table-${row.values.id}-${key}`} data-testid={'table-cell'} onClick={() => selectRow(row)}>
                <Typography>{row.values[key].name}</Typography>
            </div>
        );
    }
    const TableCell = (row: Row<GameResult>, key: string) => {
        return (
            <div key={`table-${row.values.id}-${key}`} data-testid={'table-cell'} onClick={() => selectRow(row)}>
                <Typography>{row.values[key]}</Typography>
            </div>
        );
    }

    const GetResultRelevance = (edge: IFixturesEdges): number => {
        let relevance = 0;

        const date = DateTime.fromISO(edge.date);

        if (dateRange.from && dateRange.to &&
            date > DateTime.fromJSDate(dateRange.from) &&
            date < DateTime.fromJSDate(dateRange.to))
            relevance++;
        if (competitionId && edge.competition.base.id === competitionId)
            relevance++;
        if (getVideo && edge.collectedFiles.some((collectedFile) => collectedFile.fileDetails.fileType.includes('Video')))
            relevance++;

        return relevance;
    };

    const VideoCell = (row: Row<GameResult>) => {
        if(fixtures){
            let matchEventData = fixtures?.filter(element => element.base.id === row.values.id)[0]
            let hasVideo =
            (
                matchEventData?.collectedFiles.some(
                    (file) => file.videoAsset != null
                    || file.fileDetails.fileType.toLowerCase().startsWith('video')
                )
                ||
                matchEventData?.delivery?.deliveredFiles.some(
                    (file) => file.fileDetails.fileType.toLowerCase().startsWith('video')
                )
            )
            return (
                <div key={`table-${row.values.id}-video`} data-testid={'table-cell'} onClick={() => selectRow(row)}>
                    {hasVideo ? <IconPlaySmall/> : null}
                </div>
            )
        }
    }

    useEffect(() => {
        if (error) {
            setResults([]);
            return;
        }

        if (fixtures && fixtures.length > 0) {
            setResults(fixtures?.map(x => {
                return {
                    homeTeam: x.homeTeam,
                    awayTeam: x.awayTeam,
                    date: x.date,
                    relevance: GetResultRelevance(x),
                    competition: x.competition.name,
                    selected: false,
                    id: x.base.id
                } as GameResult
            }) as GameResult[]);
        } else if (fixtures && results.length > 0) {
            setResults([]);
        }
    }, [fixtures, error]);

    const columns: Column<GameResult>[] = [
        {
            Header: <Checkbox key={`checkbox`} label={''} onChange={(x) => {
                if (x.target.checked) {
                    setCheckedIds(results.map((result) => result.id));
                    return;
                }
                setCheckedIds([]);
            }}/>,
            Cell: SelectedCell,
            accessor: 'selected',
            disableSortBy: true
        },
        {
            Header: t(FINDER.HOME),
            Cell: ({row}: { row: Row<GameResult> }) => TeamCell(row, 'homeTeam'),
            accessor: 'homeTeam',
            disableSortBy: true
        },
        {
            Header: "",
            Cell:  <Typography>-</Typography>,
            accessor: 'slash',
            disableSortBy: true
        },
        {
            Header: t(FINDER.AWAY),
            Cell: ({row}: { row: Row<GameResult> }) => TeamCell(row, 'awayTeam'),
            accessor: 'awayTeam',
            disableSortBy: true
        },
        // TODO: The below is hidden until the AND/OR logic is available
        // {
        //     Header: t(FINDER.RELEVANCE),
        //     Cell: RelevanceCell,
        //     accessor: 'relevance'
        //     disableSortBy: true
        // },
        {
            Header: t(FINDER.LEAGUE),
            Cell: ({row}: { row: Row<GameResult> }) => TableCell(row, 'competition'),
            accessor: 'competition',
            disableSortBy: true
        },
        {
            Header: t(FINDER.MATCH_DATE),
            Cell: DateCell,
            accessor: 'date',
            disableSortBy: true
        },
        {
            Header: <IconCameraSmall/>,
            Cell: ({row}: { row: Row<GameResult> }) => VideoCell(row),
            accessor: 'hasVideo',
            disableSortBy: true,
        },
        {
            Header: 'id',
            accessor: 'id',
            disableSortBy: true
        }
    ];

    if (loading)
        return <Spinner testId={'loading-spinner'} size={'large'}/>

    return (
        <section className={'results-table'}>
            {results.length > 0 && (
                <Table
                    columns={columns}
                    data={results}
                    initialState={{
                        hiddenColumns: ['id'],
                    }}
                    mapColumnIdToName={(column: HeaderGroup) => {
                        return column.Header;
                    }}
                    getCustomRowProps={(row: Row<GameResult>) => ({
                        style: {
                            cursor: 'pointer',
                        },
                        className: row.values.id === selectedResult?.id ? statesSelected : states
                    })}
                    requestMoreData={loadNextPage}
                    hasMoreData={hasNextPage}
                    hasCustomRowProps
                />
            )}
            {results.length === 0 && (
                <EmptyTable headings={columns} tableClass={'results-table'} emptyComponent={<div>{t(GENERAL.NO_RESULTS)}</div>} />
            )}
        </section>
    )
};
