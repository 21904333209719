import React, {useCallback, useEffect, useRef} from "react";
import {
    Checkbox,
    Pill,
    Spinner,
    states,
    statesSelected,
    Table,
    Tooltip,
    Typography,
    useAlerts
} from "@catapultsports/referee-react";
import {Column, HeaderGroup, Row} from "react-table";
import {FINDER, GENERAL} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import {DateTime, Duration} from "luxon";
import {CleanupTypeName, dateStringToShortFormat} from "../../../utils/stringUtils";
import './eventsResults.sass';
import useEvents from "../../../api/MatchTracker/events/useEvents";
import {
    ICollectedFile,
    IEventQualifier,
    IEventsResponseElement
} from "../../../api/MatchTracker/events/__types__/Events";
import {FixtureFilter} from "./gamesResults";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {getPlayerInEvent} from "./eventUtils";
import {DateRange, EventResult} from "../../../__types__/globalTypes";
import {FINDER_PAGE_SIZE} from "../../../api/enums/clientMagic";
import {IconPlaySmall, IconCameraSmall} from "@catapultsports/referee-react/icons";
import {EmptyTable} from "../../../components/emptyTable/emptyTable";
import {FormatTimeStamp} from "../../../utils/timeUtils";

interface EventsTableProps {
    dateRange: DateRange,
    competitionId: string,
    getVideo: boolean,
    usePerfectMatch: boolean,
    searchFilters: FixtureFilter[],
    seasonIds: [],
    teamIds: [],
    playerIds: [],
    includeFutureFixtures: boolean
}



export const EventsTable: React.FC<EventsTableProps> = (props) => {
    const { dateRange, competitionId, getVideo, usePerfectMatch, searchFilters, seasonIds, teamIds, playerIds, includeFutureFixtures } = props;
    const {t} = useTranslation();
    const selectedResult = useSessionStore(state => state.events.selectedResult, shallow);
    const setSelectedResult = useSessionStore(state => state.setSelectedEventResult, shallow);
    const results = useSessionStore<EventResult[]>(state => state.events.results, shallow);
    const setResults = useSessionStore(state => state.setEventResults, shallow);
    const checkedIds = useSessionStore<string[]>(state => state.events.checkedIds, shallow);
    const setCheckedIds = useSessionStore(state => state.setCheckedEventIds, shallow);
    const setMatchtrackerIds = useSessionStore(state => state.setMatchtrackerIds, shallow);
    const setNumberOfRecords = useSessionStore(state => state.setNumberOfRecords, shallow);

    const { showError } = useAlerts();
    const ref = useRef()

    useEffect(() => {
        setSelectedResult(undefined);
        setCheckedIds([]);
    }, [dateRange.from, dateRange.to, competitionId, getVideo, usePerfectMatch, searchFilters]);

    const matchEvents = useEvents(
        FINDER_PAGE_SIZE,
        0,
        competitionId,
        searchFilters,
        dateRange,
        getVideo,
        () => showError(t(FINDER.FIXTURES_UNAVAILABLE)),
        seasonIds,
        teamIds,
        playerIds,
        includeFutureFixtures
    );

    useEffect(() => {
        if(matchEvents?.state?.numberOfRecords){
            let numberOfRecords = matchEvents?.state?.numberOfRecords
            setNumberOfRecords(numberOfRecords)
        }else{
            setNumberOfRecords(0)
        }
    }, [matchEvents?.state?.numberOfRecords, setNumberOfRecords])

    const handleOnRowClick = useCallback((row) =>{
        if(ref.current){
            let rowIndex = row.index
            let rowId = row.values.id
            let rowToScrollTo = ref?.current?.children[0]?.children[1]?.children[rowIndex]
            if (rowToScrollTo)
            {
                setTimeout(() => {
                    rowToScrollTo.scrollIntoView({ block: "center", inline: "center", behavior: "smooth"});
                }, 200)

            }
            if (matchEvents.state.events) {
                const match = matchEvents.state.events.find((event) => event.base.id === rowId);
                setSelectedResult(match);
            }
        }
    }, [matchEvents.state.events, setSelectedResult])

    useEffect(() => {
        setSelectedResult(undefined);
        setResults([]);
        setCheckedIds([]);
    }, [setCheckedIds, setResults, setSelectedResult]);


    useEffect(() => {
        if (matchEvents.state.events) {
            const values: string[] =
                matchEvents.state.events.filter((event) => checkedIds.includes(event.base.id))
                    .map((event) => event.fixture.providers.find(provider => provider.values))
                    .map((provider) => provider?.values.id);
            // @ts-ignore
            setMatchtrackerIds([...(new Set(values))]);
        }
    }, [checkedIds, matchEvents.state.events, setMatchtrackerIds]);


    useEffect(() => {
        if (matchEvents.state.events && matchEvents.state.events.length > 0) {
            setResults(matchEvents.state.events.map(x => {
                return {
                    event: {
                        name: CleanupTypeName(x.eventType.name),
                        timestamp: {
                            start: FormatTimeStamp(x),
                            duration: durationToReturn(x)
                        },
                        game: `${x.fixture.homeTeam.name} vs ${x.fixture.awayTeam.name}`,
                        home: x.fixture.homeTeam.name,
                        away: x.fixture.awayTeam.name,
                        date: x.epochStartTime,
                        relevance: GetResultRelevance(x),
                        competitionShortened: x.fixture.competition.name.split(' ').map((word) => word.length > 0 ? word[0] : '').join('').toUpperCase(),
                        competition: x.fixture.competition.name,
                        dateShortened: DateTime.fromMillis(x.epochStartTime).toFormat('dd/MM/yyyy'),
                        player: getPlayerInEvent(x)?.value,
                        teamAssociatedWithEvent: x.team.name
                    },
                    metaData: {
                        labels: x.qualifiers.map((qualifier: IEventQualifier) => CleanupTypeName(qualifier.qualifierType.name)).join(', '),
                        extended: x.qualifiers.map((qualifier: IEventQualifier) => `${CleanupTypeName(qualifier.qualifierType.name)}: ${qualifier.value}`).join(', '),
                    },
                    selected: false,
                    id: x.base.id
                } as EventResult
            }) as EventResult[]);
            return;
        }
        if (results.length > 0) {
            setResults([]);
        }
    }, [matchEvents.state.events]);

    const SelectedCell = ({row}: { row: Row<EventResult> }) => {
        return (
            <Checkbox label={''} checked={checkedIds.includes(row.values.id)} onChange={(x) => {
                if (checkedIds.includes(row.values.id)) {
                    setCheckedIds(checkedIds.filter((id) => id != row.values.id));
                }
                else {
                    setCheckedIds([...checkedIds, row.values.id]);
                }
            }}/>
        );
    }

    const DateCell = ({row}: { row: Row<EventResult> }) => {
        return (
            <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                <Typography variant='body-2'>{dateStringToShortFormat(DateTime.fromMillis(row.values['event.date']).toISODate())}</Typography>
            </div>
        );
    }

    const MetaDataCell = ({row}: { row: Row<EventResult> }) => {
        return (
            <div data-testid={"table-cell"} className={"wider-cell"} onClick={() => handleOnRowClick(row)}>
                <Tooltip className={"metadata-extended"} position={"bottom"} text={row.values.metaData.extended}>
                    <Typography numberOfLines={1}>{row.values.metaData.labels}</Typography>
                </Tooltip>
            </div>
        );
    }

    const RelevanceCell = ({row}: { row: Row<EventResult> }) => {
        return (
            <div data-testid={'table-cell'} >
                <Pill
                    shape="square"
                    value={row.values['event.relevance'] + ' HITS'}
                    variant="information"
                />
            </div>
        );
    }

    const VideoCell = ({row}: { row: Row<EventResult> }) => {
        if(matchEvents){
            let matchEventData = matchEvents.state.events?.filter(element => element.base.id === row.values.id)[0]
            let hasVideo = matchEventData?.fixture?.collectedFiles.length !== 0 || matchEventData?.fixture?.delivery?.deliveredFiles.some(
                (file) => file.fileDetails.fileType.toLowerCase().startsWith('video')
            )
            return (
                <div
                    key={`event-table-video-cell-${row.values.id}`}
                    data-testid={'table-cell'}
                    onClick={() => handleOnRowClick(row)}
                >
                    {hasVideo? <IconPlaySmall/>: null}
                </div>
            )
        }
    }

    const GetResultRelevance = (edge: IEventsResponseElement): number => {
        let relevance = 0;

        if (dateRange.from && dateRange.to &&
            DateTime.fromMillis(edge.epochStartTime) > DateTime.fromJSDate(dateRange.from) &&
            DateTime.fromMillis(edge.epochEndTime) < DateTime.fromJSDate(dateRange.to))
            relevance++;
        if (competitionId && edge.fixture.competition.base.id === competitionId)
            relevance++;
        if (getVideo &&
            (edge.fixture.collectedFiles.some((collectedFile: ICollectedFile) => collectedFile.videoAsset != null) ||
                edge.fixture.delivery && edge.fixture.delivery.deliveredFiles.some((deliveredFile) => deliveredFile.fileDetails.fileType.includes('Video'))))
            relevance++;

        return relevance;
    };

    const durationToReturn = (time: {startTime: number, endTime: number}) => {
        let startTime = time.startTime
        let endTime = time.endTime

        let duration = 0
        let durationText = ""
        let timeStampText = ""

        if((endTime !== undefined && startTime != undefined)){
            durationText = Duration.fromMillis((endTime - startTime) * 1000).toFormat('ss')
            duration = Number(durationText)
        }

        if (duration > 0){
            timeStampText = `(${durationText} seconds)`
        }

        return timeStampText
    }

    const columns: Column<EventResult>[] = selectedResult ?
        [
            {
                Header: <Checkbox label={''} onChange={(x) => {
                    if (x.target.checked) {
                        setCheckedIds(results.map((result) => result.id));
                        return;
                    }
                    setCheckedIds([]);
                }}/>,
                Cell: SelectedCell,
                accessor: 'selected',
                disableSortBy: true
            },
            {
                Header: t(FINDER.EVENT),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'}  className={"wider-cell"} onClick={() => handleOnRowClick(row)}>
                        <Typography variant={"heading-5"}>{row.values.event.name}</Typography>
                        <Typography variant={"caption-1"}>{`${row.values.event.game} (${row.values.event.timestamp.start})`}</Typography>
                        <Typography variant={"caption-1"}>{`${row.values.event.competitionShortened} - ${row.values.event.dateShortened}`}</Typography>
                    </div>,
                accessor: 'event',
                disableSortBy: true
            },
            {
                Header: t(FINDER.PLAYER),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} className={"wider-cell"} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.player']}</Typography>
                    </div>,
                accessor: 'event.player',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.TEAM),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} className={"wider-cell"} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.teamAssociatedWithEvent']}</Typography>
                    </div>,
                accessor: 'event.teamAssociatedWithEvent',
                disableSortBy: true,
            },
            // TODO: removing until AND/OR logic is available
            // {
            //     Header: t(FINDER.RELEVANCE),
            //     Cell: RelevanceCell,
            //     accessor: 'event.relevance'
            // },
            {
                Header: t(FINDER.QUALIFIERS),
                Cell: MetaDataCell,
                accessor: 'metaData',
                disableSortBy: true,
            },
            {
                Header: 'id',
                accessor: 'id',
                disableSortBy: true,
            }
        ] :
        [
            {
                Header: <Checkbox label={''} onChange={(x) => {
                    if (x.target.checked) {
                        setCheckedIds(results.map((result) => result.id));
                        return;
                    }
                    setCheckedIds([]);

                }}/>,
                Cell: SelectedCell,
                accessor: 'selected',
                disableSortBy: true
            },
            {
                Header: t(FINDER.EVENT),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.name']}</Typography></div>,
                accessor: 'event.name',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.PLAYER),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.player']}</Typography>
                    </div>,
                accessor: 'event.player',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.TEAM),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.teamAssociatedWithEvent']}</Typography>
                    </div>,
                accessor: 'event.teamAssociatedWithEvent',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.TIME),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography className={'timestamp-item'}>{`${row.values['event.timestamp'].start} ${row.values['event.timestamp'].duration}`}</Typography></div>,
                accessor: 'event.timestamp',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.QUALIFIERS),
                Cell: MetaDataCell,
                accessor: 'metaData',
                disableSortBy: true,
            },
            // TODO: removing until AND/OR logic is available
            // {
            //     Header: t(FINDER.RELEVANCE),
            //     Cell: RelevanceCell,
            //     accessor: 'event.relevance'
            // },
            {
                Header: t(FINDER.HOME),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.home']}</Typography></div>,
                accessor: 'event.home',
                disableSortBy: true,
            },
            {
                Header: "",
                Cell:  <Typography>-</Typography>,
                accessor: 'slash',
                disableSortBy: true
            },
            {
                Header: t(FINDER.AWAY),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.away']}</Typography></div>,
                accessor: 'event.away',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.MATCH_DATE),
                Cell: DateCell,
                accessor: 'event.date',
                disableSortBy: true,
            },
            {
                Header: t(FINDER.LEAGUE),
                Cell: ({row}: { row: Row<EventResult> }) =>
                    <div data-testid={'table-cell'} onClick={() => handleOnRowClick(row)}>
                        <Typography>{row.values['event.competition']}</Typography></div>,
                accessor: 'event.competition',
                disableSortBy: true,
            },
            {
                Header: <IconCameraSmall/>,
                Cell: VideoCell,
                accessor: 'event.video',
                disableSortBy: true,
            },
            {
                Header: 'id',
                accessor: 'id',
                disableSortBy: true,
            }
        ];

    if (matchEvents.state.loading)
        return <Spinner testId={'loading-spinner'} size={'large'}/>


    return (
        <section className={`results-table${selectedResult ? ' events-results-table-selected' : ' event-results-table'}`} ref={ref}>
            {results.length > 0 && (
                <Table
                    columns={columns}
                    data={results}
                    initialState={{
                        hiddenColumns: ['id'],
                    }}
                    mapColumnIdToName={(column: HeaderGroup) => {
                        return column.Header;
                    }}
                    getCustomRowProps={(row: Row<EventResult>) => ({
                        style: {
                            cursor: 'pointer'
                        },
                        className: row.values.id === selectedResult?.base.id ? statesSelected : states
                    })}
                    requestMoreData={matchEvents.operations.loadNextPage}
                    hasMoreData={matchEvents.state.pageInfo?.hasNextPage}
                    hasCustomRowProps
                />
            )}
            {results.length === 0 && (
                <EmptyTable headings={columns} tableClass={'event-results-table'} emptyComponent={<div>{t(GENERAL.NO_RESULTS)}</div>} />
            )}
        </section>
    )
};
