import React, {useCallback, useMemo} from "react";
import {
    Tooltip,
    useAlerts
} from "@catapultsports/referee-react";
import {IPlaylistResponse} from "../../../api/Hub/collections/__types__/Playlist";
import {IconPencil, IconSaveSmall} from "@catapultsports/referee-react/icons";
import '../playlistControlsHeaderComponent.sass';
import {COLLECTIONS, HUB} from "../../../services/i18n/i18n-constants";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import useUpdatePlaylist from "../../../api/Hub/playlist/updatePlaylist/useUpdatePlaylist";
import "../playlistControls.sass"
import {useMixPanel} from "../../../providers/Mixpanel";
interface PlaylistControlsHeaderComponentRenameProps {
    data: IPlaylistResponse
    playListName?: string
    setEditCollectionName?: (value: boolean) => void
    editCollectionName?: boolean
}

export const PlaylistControlsHeaderRename: React.FC<PlaylistControlsHeaderComponentRenameProps> = (props) => {
    const {
        data: playlistData,
        playListName,
        setEditCollectionName,
        editCollectionName
    } = props;
    const mixpanel = useMixPanel();
    const { showError, showInfo } = useAlerts();
    const {t} = useTranslation();
    const collectionId = useSessionStore(state => state.playlist.id, shallow);

    const hasPlaylistNameChanged = useMemo(() => {
        return playListName?.trim() !== playlistData?.playlist.name;

    }, [playListName, playlistData?.playlist.name])

    const errorAlert = useCallback(() => {
        showError(t(HUB.UPDATE_COLLECTION_ERROR));
    }, [showError, t])

    const onSuccess = useCallback(() => {
        mixpanel.track(`Collection Renamed`, { collectionID: collectionId, collectionName: playListName });
        let text = `${t(COLLECTIONS.COLLECTION_UPDATED_SINGLE)}`
        showInfo(text);
    }, [collectionId, mixpanel, showInfo, t, playListName])

    const {updatePlaylist} = useUpdatePlaylist(errorAlert, onSuccess);

    const handleOnSave = useCallback(() => {
        let updatePlaylistObject = [{
            id: collectionId,
            name: playListName,
        }]
        updatePlaylist(updatePlaylistObject)
        setEditCollectionName && setEditCollectionName(!editCollectionName)
    }, [collectionId, playListName, updatePlaylist, setEditCollectionName, editCollectionName])


    const handleEditCollectionNameClick = useCallback(() => {
        setEditCollectionName && setEditCollectionName(!editCollectionName)
    }, [editCollectionName, setEditCollectionName])

    return (
        <Tooltip
            text={
                hasPlaylistNameChanged? t(COLLECTIONS.SAVE_CHANGES):
                    t(COLLECTIONS.EDIT_COLLECTION_NAME)
            }
        >
            <span tabIndex={0}>
                {hasPlaylistNameChanged?
                    <IconSaveSmall className={"icon"} onClick={handleOnSave}  data-testid={"edit-collection-name-save-changes"}/>:
                    <IconPencil className={"icon"} onClick={handleEditCollectionNameClick} data-testid={"edit-collection-name"}/>
                }
            </span>
        </Tooltip>
    )
}