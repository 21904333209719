import {ApolloError, useQuery, DocumentNode} from "@apollo/client";
import {Fixtures, FixturesVariables, IFixturesEdges} from "./__types__/Fixtures";
import {FIXTURES} from "./fixtures.gql";
import {client} from "../matchTrackerClient";
import {FixtureFilter} from "../../../screens/finder/subPages/gamesResults";
import {FixtureFilterType} from "../enum/Common";
import {DateRange} from "../../../__types__/globalTypes";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseFixtures {
    state: {
        hasNextPage: boolean;
        loading: boolean;
        pageSize: number;
        error: ApolloError | undefined;
        fixtures: IFixturesEdges[] | null | undefined;
        numberOfRecords: number | undefined;
    };
    operations: {
        loadNextPage: () => void;
    };
}

const useFixtures = (
    pageSize: number,
    pageNumber: number,
    competitionId: string,
    filters?: FixtureFilter[],
    dateRange?: DateRange,
    hasVideo?: boolean,
    hasEvents?: boolean,
    onError?: (error: ApolloError, errorMessage: string) => void,
    seasonsIds?: [],
    teamIds?: [],
    playerIds?: [],
    includeFutureFixtures?: boolean
): {
    operations: { loadNextPage: () => void };
    state: {
        hasNextPage: boolean;
        fixturesQuery: DocumentNode;
        pageSize: number;
        loading: boolean;
        error: ApolloError | undefined;
        fixtures: IFixturesEdges[] | undefined
        numberOfRecords: number | undefined;
    }
} => {

    const officials = filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.CoachIDs || f.filterType === FixtureFilterType.RefereeIDs).map(f => f.id) || [];

    const officialIDs: string[] = [];
    const officialTypes: string[] = [];

    officials.forEach(x => {
        const values = x?.split('&')
        officialIDs.push(values[0]);

        if(!officialTypes.includes(values[1]))
            officialTypes.push(values[1]);
    })

    const { loading, error, data, fetchMore } = useQuery<Fixtures, FixturesVariables>(FIXTURES, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            filter: {
                dates: dateRange && dateRange.from && dateRange.to ? [dateRange.from.toISOString(), dateRange.to.toISOString()] : [],
                competitionIDs: competitionId ? [competitionId] : [],
                eventTypeIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.EventTypeIDs).map(f => f.id) || [],
                qualifierTypeIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.QualifierTypeIDs).map(f => f.id) || [],
                phaseTypeIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.PhaseTypeIDs).map(f => f.id) || [],
                teamIDs: teamIds || [],
                playerIDs: playerIds || [],
                providerIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.ProviderIDs).map(f => f.id) || [],
                venueIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.VenueIDs).map(f => f.id) || [],
                officialIDs: officialIDs,
                officialTypeIDs: officialTypes,
                hasVideo: hasVideo ?? false,
                hasEvents: hasEvents ?? false,
                seasonIDs: seasonsIds ?? [],
                includeFutureFixtures: includeFutureFixtures ?? false,
            }
        },
    });

    const loadNextPage = () => {
        if (data?.fixtures?.pageInfo?.pageNumber === undefined) return;

        fetchMore({
            variables: {
                pageNumber: data?.fixtures?.pageInfo?.pageNumber + 1,
                pageSize
            }
        });
    };

    useEffect(() => {
        handleErrorRaised(error, FIXTURES, ApiErrors.Fixtures, onError);
    }, [error, FIXTURES]);

    return {
        state: {
            hasNextPage: !!data?.fixtures?.pageInfo.hasNextPage,
            fixtures: data?.fixtures?.edges,
            pageSize,
            loading: loading,
            error: error,
            fixturesQuery: FIXTURES,
            numberOfRecords: data?.fixtures?.pageInfo?.total
        },
        operations: { loadNextPage }
    };
};

export default useFixtures;
