import { gql } from "@apollo/client";
import {
    FIXTURE_COLLECTED_FILE_MENU,
    FIXTURE_DELIVERED_FILE_MENU,
    FIXTURE_INGESTED_FILE_MENU
} from "./fixtureFileMenu.gql";

export const FIXTURE_COLLECTED_FILE = gql`
    ${FIXTURE_COLLECTED_FILE_MENU}
    fragment FixtureCollectedFile on CollectedFile {
        base {
            id
        }

        videoAsset {
            defaultStartTime
            matchTimeDetection
        }

        fileDetails {
            fileType
            friendlyName
        }
        ...FixtureCollectedFileMenu
    }
`;

export const FIXTURE_INGESTED_FILE = gql`
    ${FIXTURE_INGESTED_FILE_MENU}
    fragment FixtureIngestedFile on IngestedFile {
        base {
            id
        }

        fileDetails {
            fileType
            friendlyName
        }
        ...FixtureIngestedFileMenu
    }
`;

export const FIXTURE_DELIVERED_FILE = gql`
    ${FIXTURE_DELIVERED_FILE_MENU}
    fragment FixtureDeliveredFile on DeliveredFile {
        base {
            id
        }

        fileDetails {
            fileType
            friendlyName
        }
        ...FixtureDeliveredFileMenu
    }
`;
