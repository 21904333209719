import {ApolloError, useQuery} from "@apollo/client";
import {ICollectionTile, ICollectionTileResponse} from "./__types__/Collections";
import {GET_COLLECTIONS} from "./collections.gql";
import {SortBy} from "../enum/Enum";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiPageSize} from "../../enums/apiMagic";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {generateFreeTextSearchItems} from "../utils/stringUtils";

export interface UseCollections {
    state: {
        hasNextPage: boolean;
        loading: boolean;
        pageSize: number;
        collections: ICollectionTile[] | null | undefined;
        error: ApolloError | null | undefined
    };
    operations: {
        loadNextPage: () => void;
    };
}

export const useCollections = (
    pageSize?: number | undefined,
    sortBy?: string,
    playlistType?: string,
    metadataIds?: string[],
    freeTextSearch?: string | undefined,
    onError?: (error: ApolloError, errorMessage: string) => void): UseCollections => {

    const { loading, error, data, fetchMore } = useQuery<ICollectionTileResponse>(GET_COLLECTIONS, {
        variables: {
            pageNumber: 0,
            pageSize: pageSize ?? ApiPageSize.Five,
            sort: {
                ...(sortBy === SortBy.CreationTimestamp && {"creationTimestamp": "DESC"}),
                ...(sortBy === SortBy.ModifiedTimestamp && {"modifiedTimestamp": "DESC"})
            },
            filter: {
                playlistType: playlistType,
                metadataIds: metadataIds,
                name: freeTextSearch ? generateFreeTextSearchItems(freeTextSearch) : []
            }
        },
    });

    const loadNextPage = () => {
        if (data?.playlists.pageInfo?.pageNumber === undefined) return;
        fetchMore({
            variables: {
                pageNumber: data?.playlists.pageInfo?.pageNumber + 1,
                pageSize: pageSize ?? ApiPageSize.Five
            }
        });
    };

    const { t } = useTranslation();

    useEffect(() => {
        handleErrorRaised(error, GET_COLLECTIONS, `Could not retrieve ${playlistType}s`, onError);
    }, [error, GET_COLLECTIONS]);

    return {
        state: {
            hasNextPage: !!data?.playlists.pageInfo.hasNextPage,
            collections: data?.playlists.edges,
            pageSize: pageSize ?? ApiPageSize.Five,
            loading,
            error: error
        },
        operations: { loadNextPage }
    };
};