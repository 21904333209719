import {ApolloError, useMutation} from "@apollo/client";
import {CREATE_PLAYLIST} from "./createPlaylists.gql";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../../enums/apiErrors";
import {useTranslation} from "react-i18next";
import {client as hubClient} from "../../../Hub/client";
import {clearSpecificCacheForAGivenClient} from "../../../commonApolloClientFunctions";
import {ICreateCollectionResponse} from "../../__types__/Common";

const useCreatePlaylist = ( onError?: (error: ApolloError, errorMessage: string) => void, onSuccess?: (response: ICreateCollectionResponse) => void) => {
    const { t } = useTranslation();

    const [createAPlaylist, { error }] = useMutation(CREATE_PLAYLIST, {
        onCompleted: (response: ICreateCollectionResponse) => {
            clearSpecificCacheForAGivenClient(hubClient, "playlists");
            if (onSuccess) {
                onSuccess(response);
            }
        },
    });

    useEffect(() => {
        handleErrorRaised(error, CREATE_PLAYLIST, ApiErrors.CreatePlaylist, onError);
    }, [error]);

    return {
        createPlaylist: (
            createPlaylistInput: {
                name: string;
                createTags: {eventID: string}[];
                preRoll?: number;
                postRoll?: number;
            }
        ) => createAPlaylist({
            variables: {
                "playlists": [createPlaylistInput]
            },
        })
    };
};

export default useCreatePlaylist;