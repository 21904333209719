import { gql } from "@apollo/client";

export const FIXTURE_COLLECTED_VIDEO = gql`
    fragment FixtureCollectedVideo on CollectedFile {
        base {
            id
        }
        fileDetails {
            s3Link
        }
        videoAsset {
            base {
                id
            }
            epochStartTime
            defaultStartTime
        }
    }
`;

export const UPDATE_VIDEO_ASSET = gql`
    mutation UpdateVideoAsset($id: String!, $defaultStartTime: Int!) {
        updateVideoAsset(input: { id: $id, defaultStartTime: $defaultStartTime }) {
            videoAsset {
                base {
                    id
                }
                defaultStartTime
                matchTimeDetection
            }
        }
    }
`;
