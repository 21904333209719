import {gql} from "@apollo/client";
export const MEMBERSHIPS = gql`
    query($filter: MembershipFilter, $pageNumber: Int, $pageSize: Int) {
        memberships(filter:$filter, pageNumber: $pageNumber, pageSize: $pageSize){
            edges {
                base {
                    id
                }
                membershipType
                name
                maxMems
                currentMems
                membershipDate
                autoApprove
            }
            pageInfo {
                pageSize
                pageNumber
                hasNextPage
            }
        }
    }

`;

export const UPDATE_MEMBERSHIPS = gql`
    mutation UpdateMembershipAutoApprove($membershipID: String!, $membershipUpdate: MembershipUpdateInput) {
      updateMembership(membershipID: $membershipID, input: $membershipUpdate) {
          base {
              id
          }
          autoApprove
      }
    }
`;