import React, {useCallback, useMemo, useState} from "react";
import {Pill, Spinner, Typography as Text, useAlerts} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {HUB, NOTES} from "../../services/i18n/i18n-constants";
import "./labels.sass";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTagMetadata} from "../../api/Hub/metadata/useTagMetadata";
import {Notes} from "../notes/notes";
import {INote} from "../../api/Hub/tags/__types__/Tags";
import {IconPencil} from "@catapultsports/referee-react/icons";
import {LabelsDialogComponent} from "./labelsDialog/labelsDialog";
import {CleanupTypeName} from "../../utils/stringUtils";
import {useUpdateTagNotes} from "../../api/Hub/tags/updateTags/useUpdateTagNotes";

export const Labels: React.FC<any> = () => {
    const {t} = useTranslation();
    const tagId = useSessionStore(state => state.comments.tagId, shallow);
    const tagName = useSessionStore(state => state.comments.tagName, shallow);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isLabelsModalOpen, setIsLabelsModalOpen] = useState<boolean>(false);

    const { showError, showInfo } = useAlerts();
    const { loading, metadata, metadataGroups, notes } = useTagMetadata(tagId ?? '', () => showError(t(HUB.LABELS_ERROR)));

    const uniqueLabels = useMemo(
        () => {
            if (metadata && metadata.length > 0) {
                return Array.from(
                    new Set(metadata.filter(metadata => !metadata.metadataGroup.hidden)))
                        .map((metadata) => `${metadata.metadataGroup.name}: ${metadata.value}`);
            }
            return [];
        },
        [metadata]
    );

    const onSuccess = useCallback(
        () => {showInfo(t(NOTES.UPDATE_NOTES))
        },
        [showInfo, t]
    )

    const onError = useCallback(
        () => {
            showError(t(NOTES.UPDATE_NOTES_ERROR));
        },
        [showError, t]
    )

    const {updateTag} = useUpdateTagNotes(onError)

    const onNotesSave = useCallback(
        (defaultNote: INote | null, validNotes: INote[]) => {
        let notes = validNotes
            .filter(note => note.id !== 'default')
            .map(note => ({
                "heading": note.heading,
                "value": note.value,
             }))
        notes.push({"id": defaultNote.id, "heading": defaultNote.heading, "value": defaultNote.value})

        let updateObject = [
            {
                "id": tagId,
                "createNotes" : notes
            }
        ]
        updateTag(updateObject).then(() => onSuccess())
        },
        [onSuccess, tagId, updateTag]
    )



    const getDisplayContent = () => {
        if (uniqueLabels.length === 0) {
            return <Text color={"secondary"}>{t(HUB.NO_LABELS)}</Text>;
        }

        if (uniqueLabels.length > 8) {
            return expanded ?
                <>
                    {uniqueLabels.map(mapLabels)}
                    <Text onClick={() => setExpanded(false)} variant={"label-1"} className={'extended-count'}>{t(HUB.LESS)}</Text>
                </> :
                <>
                    {uniqueLabels.slice(0, 8).map(mapLabels)}
                    <Text onClick={() => setExpanded(true)} variant={"label-1"} className={'extended-count'}>{`+ ${uniqueLabels.length - 8} ${t(HUB.MORE)}`}</Text>
                </>;
        }

        return <>
            {uniqueLabels.map(mapLabels)}
        </>;
    }

    const mapLabels = (value: string) => {
        const newValue = CleanupTypeName(value);

        return <Pill key={newValue} value={newValue} variant="neutral" shape={"square"}/>
    };

    const handleOnEditLabelsClick = useCallback(() => {
        setIsLabelsModalOpen(true)
    }, [])

    return (
        <>
            { !loading && <Notes notes={notes || []} onSave={onNotesSave} />}
            <section className={'labels'}>
                <div className={"label-header"}>
                    <Text className={"label-text"}>{t(HUB.LABELS)}</Text>
                    { !loading && <IconPencil
                        className={"label-edit"}
                        data-testid={"edit-labels-button"}
                        id={"edit-labels-button"}
                        onClick={handleOnEditLabelsClick}
                    />}
                </div>
                <section className={'label-list'}>
                    {
                        loading
                            ? <Spinner/>
                            : getDisplayContent()
                    }
                </section>
                <LabelsDialogComponent
                    isModalOpen={isLabelsModalOpen}
                    setIsModalOpen={setIsLabelsModalOpen}
                    metadata={metadata}
                    metadataGroups={metadataGroups}
                    modalType={"tag"}
                    inputValue={tagName}
                    collectionOrTagId={tagId}
                />
            </section>
        </>
    )
};
