import React, {useEffect, useState} from 'react';
import {Navigation} from "./components/nav/nav";
import {Routes} from "./enums/Routes";
import {createHashHistory, Outlet, ReactLocation, Router, useLocation} from "@tanstack/react-location";
import {useMixPanel} from "./providers/Mixpanel";
import {AlertProvider, RefereeProvider} from "@catapultsports/referee-react";
import '@catapultsports/referee-react/reset.css';
import '@catapultsports/referee-react/styles.css';
import {clearPersistedState, usePersistedStore} from "./state/PersistedState";
import {shallow} from "zustand/shallow";
import {routes} from "./utils/routes";
import {Lost} from "./screens/lost/lost";
import {MixPanelEvents} from "./enums/MixPanelEvents";
import ReactAppzi from 'react-appzi';
import useLogout from "./api/Customer/authService/logout/useLogout";
import {Helmet} from "react-helmet";

if (process.env.APPZI_TOKEN) {
    ReactAppzi.initialize(process.env.APPZI_TOKEN);
}

// TODO: why does createBrowserHistory and history/browser break direct routing?
const hashHistory = createHashHistory();
const location = new ReactLocation({history: hashHistory});

const pathWithRoot = (path: string) => `${Routes.ROOT_PATH}${path}`;
const subPathWithRoot = (path: string) => `${pathWithRoot(path)}/*`;

function mapUrlToFriendlyName(url: string): string {
    if (url.includes('overview')) {
        return 'Overview';
    } else if (url.includes('co')) {
        if (url.endsWith('co')) {
            return 'Collections';
        } else {
            return 'Collection';
        }
    } else if (url.includes('clip')) {
        return 'Clips';
    } else if (url.includes('matches')) {
        return 'Matches';
    } else if (url.includes('next-gen')) {
        return 'Next Gen';
    } else if (url.includes('login')) {
        return 'Login';
    } else {
        // Handle other cases or return a default value if needed
        return 'Unknown';
    }
}


const PathsWithNavBar = [
    Routes.ROOT_PATH,
    subPathWithRoot(Routes.HUB_PAGE),
    subPathWithRoot(Routes.MATCH_PAGE),
    subPathWithRoot(Routes.NEXT_GEN_PAGE),
    subPathWithRoot(Routes.LICENSING_PAGE),
    subPathWithRoot(Routes.PIT_WALL)];

const Nav = () => {
    const [showNav, setShowNav] = useState(false);
    const pageLocation = useLocation();
    const mixpanel = useMixPanel();

    useEffect(() => {
        const show = PathsWithNavBar.find(element => {
            if (element[element.length - 1] === "*") {
                return pageLocation.current.pathname.includes(element.substr(0, element.length - 2));
            }
            return pageLocation.current.pathname === element
        });
        setShowNav(show != undefined);
    }, [pageLocation, pageLocation.current.pathname]);

    const friendlyTitle = mapUrlToFriendlyName(pageLocation.current.pathname);

    return showNav ? <div>
            <Helmet>
                <title>Hub Portal: {friendlyTitle}</title>
                <meta name="title" content={friendlyTitle} />
            </Helmet>
            <Navigation
                onClick={(selected: string) => {
                    mixpanel.track(MixPanelEvents.NAVIGATION_OPTION_SELECTED, {SecondaryNavOption: selected});
                }}/>
        </div> :
        <Helmet>
            <title>Hub Portal: Login</title>
        </Helmet>;
}

function App() {
    const userState = usePersistedStore(state => state.user, shallow);
    const clearUser = usePersistedStore(state => state.clearUser, shallow);
    const customerAPILogout = useLogout();

    useEffect(() => {
        const navEntries = window.performance.getEntriesByType('navigation');
        const navTypes = navEntries.map((nav: any) => nav.type);
        const pageAccessedByRefreshOrNav = navTypes.includes('reload') || navTypes.includes('back_forward');

        if (!pageAccessedByRefreshOrNav && !userState.rememberMe) {
            clearPersistedState()
        }
    }, [clearUser, customerAPILogout, userState.rememberMe]);

    return (
        <Router location={location} routes={routes} defaultElement={<Lost/>}>
            <RefereeProvider theme="dark">
                <AlertProvider itemOverrides={{theme: "light"}}>
                    <div className={'app-wrapper'}>
                        <Nav/>
                        <div data-testid={'app-test-id'} className="app">
                            <Outlet/>
                        </div>
                    </div>
                </AlertProvider>
            </RefereeProvider>
            {/* NOTE: React Location Devtools are only included in bundles when process.env.NODE_ENV === 'development' */}
            {/*<ReactLocationDevtools />*/}
        </Router>
    );
}

export default App;
