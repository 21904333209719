import { gql } from "@apollo/client";

const TAGS = gql`
    query Tags(
        $filter: TagFilter, 
        $sort: TagSort, 
        $pageSize: Int, 
        $pageNumber: Int, 
        $includeAssets: Boolean!,
        $includeMetadata: Boolean!
    ) {
        tags(filter: $filter, sort: $sort, pageSize: $pageSize, pageNumber: $pageNumber) {
            edges {
                id
                name
                description
                startTime
                endTime
                modifiedTimestamp
                commentCount
                colour {
                    red
                    green
                    blue
                }
                thumbnail @include(if: $includeAssets) {
                    file {
                        s3Link
                    }
                }
                assets @include(if: $includeAssets)  {
                    id
                    file {
                        base {
                            id
                        }
                    }
                }
                metadata(pageSize: 100) @include(if: $includeMetadata) {
                    id
                    value
                    epochTimestamp
                    metadataGroup {
                        id
                        name
                    }
                }
                metadataGroups {
                    id
                    name
                }
            }
            pageInfo {
                pageSize
                pageNumber
                hasNextPage
            }
        }
    }
`;

const TAG = gql`
    query Tag($id: String!) {
      tag(id: $id){
        id
        name
        startTime
        endTime
        poiTime
        assets {
            id
            name
            startTime
            endTime
            poiTime
            isPreferred
            file {
                s3Link
                base {
                    id
                }
            }
        }
      }
    }
`;

export { TAG, TAGS };
