import React, {useCallback, useMemo} from "react";
import {COLLECTIONS, GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {Typography, useAlerts} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import "./collection.sass"
import {useMixPanel} from "../../providers/Mixpanel";
import {HubDialogComponent} from "../hubDialog/hubDialog";
import {ApolloError} from "@apollo/client";
import useDeletePlaylist from "../../api/Hub/playlist/deletePlaylist/useDeletePlaylist";
interface CollectionDeleteProps {
    displayDeleteModal: boolean;
    setDisplayDeleteModal: (displayDeleteModal: boolean) => void;
    setDisplaySelectOptions?: (displaySelectOptions: boolean) => void;
    collectionIds?: string[];
    setSelectedRows?: (value: string[]) => void;
    setIsCheckboxSelected?: (value: boolean) => void;
}

export let deleteDialogId = "delete-collection-list-collection-modal"


export const CollectionDelete: React.FC<CollectionDeleteProps> = (props) => {
    const {
        displayDeleteModal,
        setDisplayDeleteModal,
        setDisplaySelectOptions,
        collectionIds,
        setSelectedRows,
        setIsCheckboxSelected
    } = props;

    const { t } = useTranslation();
    const { showError, showInfo } = useAlerts();
    const mixpanel = useMixPanel();

    const errorAlert = (error: ApolloError, errorMessage: string) => {
        showError(t(HUB.DELETE_PLAYLIST_ERROR));
    };

    const multipleCollections = useMemo(() => {
        return collectionIds && collectionIds?.length > 1
    }, [collectionIds])

    const text = useMemo(() => {
        return multipleCollections ? t(COLLECTIONS.COLLECTION_DELETE_MODAL_TEXT_MULTIPLE) : t(COLLECTIONS.COLLECTION_DELETE_MODAL_TEXT)
    }, [multipleCollections, t])

    const onSuccess = useCallback((deletedCollectionIds: string) => {
        mixpanel.track(`Deleted Playlist`, { collectionID: deletedCollectionIds });
        let text = multipleCollections ? `${t(COLLECTIONS.COLLECTION_DELETED_MULTIPLE)}` : `${t(COLLECTIONS.COLLECTION_DELETED)}`;
        showInfo(text);
    }, [mixpanel, showInfo, t])

    const {deletePlaylists} = useDeletePlaylist(errorAlert);

    let deleteCollectionFunctionMultiple = useCallback(() => {
        setDisplayDeleteModal(false)
        setDisplaySelectOptions && setDisplaySelectOptions(false)
        setSelectedRows && setSelectedRows([])
        setIsCheckboxSelected && setIsCheckboxSelected(false)
        if(collectionIds  && collectionIds?.length > 0){
            let deletePromises = collectionIds?.map((id) => {
                let updateObject = [{id: id}]
                return deletePlaylists(updateObject);
            })

            Promise.all(deletePromises).then(() => {
                onSuccess(collectionIds.map(collectionId => collectionId).join(", "))
            }).catch((error) => {
                throw new Error(error)
            })
        }
    }, [collectionIds, deletePlaylists, onSuccess, setDisplayDeleteModal, setDisplaySelectOptions, setSelectedRows])

    return (
        <HubDialogComponent
            dialogTheme={"dark"}
            dialogSize={'small'}
            dialogHeader={t(COLLECTIONS.COLLECTION_DELETE_MODAL_HEADER).toString()}
            isModalOpen={displayDeleteModal}
            setIsModalOpen={setDisplayDeleteModal}
            modalContent={
                <div
                    data-testid={`${deleteDialogId}-content-div`}
                    id={`${deleteDialogId}-content-div`}
                >
                    <Typography
                        testId={`${deleteDialogId}-content-sub-header`}
                        id={`${deleteDialogId}-content-sub-header`}
                        variant={"body-1"}>{text}
                    </Typography>
                </div>
            }
            displayDialogFooterButtons={true}
            hideCloseButton={true}
            footerPrimaryButtonText={t(GENERAL.DELETE).toString()}
            footerPrimaryButtonAction={deleteCollectionFunctionMultiple}
            footerPrimaryButtonDisabled={false}
            dialogId={deleteDialogId}
            allowDialogClosure={false}
        />
    )
}