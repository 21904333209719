import React from 'react';
import {CommonProps, Step, TooltipRenderProps} from 'react-joyride';
import { Button, Typography as Text, Variables } from '@catapultsports/referee-react';
import { useTranslation } from 'react-i18next';
import { JOYRIDE } from '../../services/i18n/i18n-constants';
import './CustomJoyrideContainer.sass';
import {IconCancel} from "@catapultsports/referee-react/icons";

export interface CustomJoyrideContainerProps extends TooltipRenderProps, CommonProps{
	continuous: boolean,
	index: number,
	isLastStep: boolean,
	// size here is the count of the steps
	size: number,
	step: Step
}

const ProgressTicksLimit = 2;

export const CustomJoyrideContainer: React.FC<CustomJoyrideContainerProps> = (props) => {
	const {
		closeProps,
		continuous,
		index,
		isLastStep,
		primaryProps,
		size,
		skipProps,
		step,
		tooltipProps,
		showSkipButton
	} = props;
	const {
		content,
		hideFooter,
		title,
		styles,
	} = step;
	const { t } = useTranslation();
	const output = {
		primary: t(JOYRIDE.DONE),
		skip: <button
				style={styles?.buttonSkip}
				type="button"
				data-test-id="button-skip"
				aria-live="off"
				{...skipProps}>
				<Text variant={'button'}>{t(JOYRIDE.GOT_IT)}</Text>
			</button>
	};

	skipProps.title = '';
	primaryProps.title = '';

	if (continuous) {
		output.primary = isLastStep ? t(JOYRIDE.DONE) : index === 0 ? t(JOYRIDE.LEARN_MORE) : t(JOYRIDE.NEXT);
	}

	const ticks = [];
	for (let i = 0; i < size; i++) {
		if (i === 0) continue;
		ticks.push(
			<span data-testid={'progress-tick'} style={{backgroundColor: i === index ? Variables.css?.catapultWhite : Variables.css?.grey3}}></span>)
	}

	return (
		<div
			data-testid={"joyride-tooltip"}
			key="JoyrideTooltip"
			className="react-joyride__tooltip"
			style={styles?.tooltip}
			{...tooltipProps}
		>
			<div style={styles?.tooltipContainer}>
				<div data-testid={'header-container'}>
					{title && (
						<Text variant={'heading-3'}>
							{title}
						</Text>
					)}
					<button
						data-test-id="button-close"
						{...closeProps}
						role="button"><IconCancel data-testid='cancel-icon'/></button>
				</div>
				<div data-testid={'contents'} style={styles?.tooltipContent} dangerouslySetInnerHTML={{ __html: content as string }}/>
			</div>
			{!hideFooter && (
				<div style={index === 0 ? styles?.tooltipFooter : { ...styles?.tooltipFooter, justifyContent: 'space-between', display: 'flex' } }>
					{ index === 0 ?
						(showSkipButton ? <div style={styles?.tooltipFooterSpacer}>{output.skip}</div> : <></>) :
						(size > ProgressTicksLimit ?
							<>
								<div data-testid={'tick-spacer'}></div>
								<div data-testid={'tick-container'}>{ticks}</div>
							</> :
							<></>)
					}
					<Button
						style={styles?.buttonNext}
						type="button"
						data-test-id="button-primary"
						{...primaryProps}>
						<div data-testid={'button-text'}><Text variant={'button'}>{output.primary}</Text></div>
					</Button>
				</div>
			)}
		</div>
	);
}
