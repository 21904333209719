import React, {useMemo} from "react";
import {GridFooter} from "../../../components/gridFooter/gridFooter";
import './eventsResults.sass';
import {FixtureFilter} from "./gamesResults";
import {EventsTable} from "./eventsTable";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {EventViewer} from "../../../components/eventViewer/eventViewer";
import {DateRange} from "../../../__types__/globalTypes";
import {Spinner} from "@catapultsports/referee-react";

export interface EventResult {
    event: { name: string, game: string, timestamp: { start: string, duration: string }, date: string, relevance: number, competitionShortened: string, competition: string, dateShortened: string }
    metaData: { labels: string, extended: string },
    selected: boolean
    id: string
}

interface EventsResultsProps {
    dateRange: DateRange,
    competitionId: string,
    getVideo: boolean,
    usePerfectMatch: boolean,
    searchFilters: FixtureFilter[],
    seasonIds: [],
    teamIds: [],
    playerIds: [],
    includeFutureFixtures: boolean
}

export const EventsResults: React.FC<EventsResultsProps> = (props) => {
    const { dateRange, competitionId, getVideo, usePerfectMatch, searchFilters, seasonIds, teamIds, playerIds, includeFutureFixtures } = props;
    const selectedResult = useSessionStore(state => state.events.selectedResult, shallow);

    const filtersLoaded = useMemo(() => {
        return searchFilters.every((filter) => filter.name != undefined);
    }, [searchFilters]);

    return (
        <>
            <section className={'flex horizontal event-results'}>
                {
                    filtersLoaded ?
                        <>
                            <EventsTable
                                searchFilters={searchFilters}
                                usePerfectMatch={usePerfectMatch}
                                getVideo={getVideo}
                                competitionId={competitionId}
                                dateRange={dateRange}
                                seasonIds={seasonIds}
                                teamIds={teamIds}
                                playerIds={playerIds}
                                includeFutureFixtures={includeFutureFixtures}
                            />
                            {selectedResult && <EventViewer/>}
                        </> :
                        <Spinner size={"large"}/>
                }
            </section>
            <GridFooter resultType={'event'}/>
        </>
    );
};
