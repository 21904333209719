import {ApolloError, useQuery} from "@apollo/client";
import {client} from "../matchTrackerClient";
import {MATCH_EVENTS} from "./events.gql";
import {EventsVariables, IEventsResponse, IEventsResponseElement} from "./__types__/Events";
import {FixtureFilter} from "../../../screens/finder/subPages/gamesResults";
import {FixtureFilterType} from "../enum/Common";
import {IPageInfo} from "../__types__/Common";
import {DateRange} from "../../../__types__/globalTypes";

export interface UseEvents {
    state: {
        loading: boolean;
        pageSize: number;
        error: ApolloError | undefined;
        events: IEventsResponseElement[] | null | undefined;
        pageInfo: IPageInfo | null | undefined;
        numberOfRecords: number | undefined;
    };
    operations: {
        loadNextPage: () => void;
    };
}

const useEvents = (
    pageSize: number,
    pageNumber: number,
    competitionId: string,
    filters: FixtureFilter[],
    dateRange?: DateRange,
    hasVideo?: boolean,
    onError?: (error: ApolloError) => void,
    seasonsIds?: [],
    teamIds?: [],
    playerIds?: [],
    includeFutureFixtures?: boolean
): UseEvents => {

    const officials = filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.CoachIDs || f.filterType === FixtureFilterType.RefereeIDs).map(f => f.id) || [];

    const officialIDs: string[] = [];
    const officialTypes: string[] = [];

    officials.forEach(x => {
        const values = x?.split('&')
        officialIDs.push(values[0]);

        if(!officialTypes.includes(values[1]))
            officialTypes.push(values[1]);
    })

    const { loading, error, data, fetchMore } = useQuery<IEventsResponse, EventsVariables>(MATCH_EVENTS, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            filter: {
                dates: dateRange && dateRange.from && dateRange.to ? [dateRange.from.toISOString(), dateRange.to.toISOString()] : [],
                competitionIDs: competitionId ? [competitionId] : [],
                eventTypeIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.EventTypeIDs).map(f => f.id) || [],
                qualifierTypeIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.QualifierTypeIDs).map(f => f.id) || [],
                phaseTypeIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.PhaseTypeIDs).map(f => f.id) || [],
                teamIDs: teamIds ? teamIds : (filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.TeamIDs).map(f => f.id) || []),
                providerIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.ProviderIDs).map(f => f.id) || [],
                playerIDs: playerIds || [],
                fixtureIDs: filters && filters.length > 0 && filters.filter(f => f.filterType === FixtureFilterType.FixtureIDs).map(f => f.id) || [],
                officialIDs: officialIDs,
                officialTypeIDs: officialTypes,
                hasVideo: hasVideo ?? false,
                seasonIDs: seasonsIds ?? [],
                includeFutureFixtures: includeFutureFixtures ?? false,
            }
        },
    });

    const loadNextPage = () => {
        if (data?.matchEvents?.pageInfo?.pageNumber === undefined) return;

        fetchMore({
            variables: {
                pageNumber: data?.matchEvents?.pageInfo?.pageNumber + 1,
                pageSize
            }
        });
    };

    if (error && onError) {
        onError(error);
    }

    return {
        state: {
            events: data?.matchEvents.edges,
            pageInfo: data?.matchEvents.pageInfo,
            pageSize,
            loading: loading,
            error: error,
            numberOfRecords: data?.matchEvents?.pageInfo?.total
        },
        operations: { loadNextPage }
    };
};

export default useEvents;
