import {gql} from "@apollo/client";

export const OFFICIALMATCHINVOLVEMENTS = gql`
    query officialMatchInvolvements($filter: OfficialMatchInvolvementFilter, $pageSize: Int = -1, $pageNumber: Int = 0) {
        officialMatchInvolvements (filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
            pageInfo {
                total
                pageSize
                pageNumber
            }
            edges {
                    base {
                        id
                    }
                official {
                    base {
                        id
                    }
                    firstName
                    lastName
                }
                officialType {
                    base {
                        id
                    }
                    name
                    shortName
                    longName
                }
                team {
                    base {
                        id
                    }
                    name
                }
            }
        }
    }
`;