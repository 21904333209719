import {gql} from "@apollo/client";

export const LICENSES = gql`
    query($filter: LicenseFilter, $pageNumber: Int, $pageSize: Int) {
        licenses(filter:$filter, pageNumber: $pageNumber, pageSize: $pageSize){
            edges {
                base{id}
                productName
                membershipID
                computer
                expiryMirrorMembership
                expiryDate
                status
                lastLoggedIn
                dateRequested
                user {
                    email
                }
            }
            pageInfo {
                pageSize
                pageNumber
                hasNextPage
            }
        }
    }
`;