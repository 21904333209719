import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './multiRangeSlider.sass'
import {Tooltip, Typography} from "@catapultsports/referee-react";

type Props = {
	id?: string;
	min?: number | string;
	max?: number | string;
	step?: number | string;
	minValue?: number | string;
	maxValue?: number | string;
	baseClassName?: string;
	className?: string;
	disabled?: boolean;
	style?: React.CSSProperties;
	ruler?: boolean | string;
	label?: boolean | string;
	subSteps?: boolean | string;
	stepOnly?: boolean | string;
	preventWheel?: boolean | string;
	labels?: string[];
	minCaption?: string;
	maxCaption?: string;
	barLeftColor?: string;
	barRightColor?: string;
	barInnerColor?: string;
	thumbLeftColor?: string;
	thumbRightColor?: string;
	onInput?: (e: ChangeResult) => void;
	onChange?: (e: ChangeResult) => void;
	singleSlider?: boolean
};
export type ChangeResult = {
	min: number;
	max: number;
	minValue: number;
	maxValue: number;
};
let _wheelTimeout: number | null = null;
let _triggerTimeout: number | null = null;
const MultiRangeSlider = (props: Props, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
	let ruler = props.ruler === undefined || props.ruler === null ? true : props.ruler;
	let label = props.label === undefined || props.label === null ? true : props.label;
	let subSteps = props.subSteps === undefined || props.subSteps === null ? false : props.subSteps;
	let stepOnly = props.stepOnly === undefined || props.stepOnly === null ? false : props.stepOnly;
	let preventWheel = props.preventWheel === undefined || props.preventWheel === null ? false : props.preventWheel;
	let refBar = useRef<HTMLDivElement>(null);
	let min = +(props.min || 0);
	let max = +(props.max || 100);
	let step = +(props.step || 5);
	let fixed = 0;
	let disabled = !!props.disabled;
	let singleSlider = props.singleSlider;
	let stepCount = Math.floor((+max - +min) / +step);
	let labels: string[] = props.labels || [];
	if (labels.length === 0) {
		labels = [];
		labels.push(min.toString());
		labels.push((Math.round(max/2)).toString());
		labels.push(max.toString());
	} else {
		stepCount = labels.length - 1;
	}

	if (typeof label === 'string') {
		label = label === 'true';
	}
	if (typeof ruler === 'string') {
		ruler = ruler === 'true';
	}
	if (typeof preventWheel === 'string') {
		preventWheel = preventWheel === 'true';
	}
	if (step.toString().includes('.')) {
		fixed = 2;
	}

	let calcMinValue = useMemo(() => {
		let tempMinValue = props.minValue;
		if (tempMinValue === null || tempMinValue === undefined) {
			tempMinValue = 25;
		}
			tempMinValue = +tempMinValue;

		if (tempMinValue < min) {
			tempMinValue = min;
		}
		if (tempMinValue > max) {
			tempMinValue = max;
		}

		return tempMinValue
	}, [min, max, props.minValue])

	let calcMaxValue = useMemo(() => {
		let tempMaxValue = props.maxValue;
		if (tempMaxValue === null || tempMaxValue === undefined) {
			tempMaxValue = 75;
		}
		tempMaxValue = +tempMaxValue;

		if (tempMaxValue < +calcMinValue) {
			tempMaxValue = +calcMinValue + +step;
		}
		if (tempMaxValue > max) {
			tempMaxValue = max;
		}
		if (tempMaxValue < min) {
			tempMaxValue = min;
		}
		return tempMaxValue;
	}, [props.maxValue, min, max, calcMinValue])


	const [minValue, setMinValue] = useState(+calcMinValue);
	const [maxValue, setMaxValue] = useState(+calcMaxValue);
	const [barMin, setBarMin] = useState(((minValue - min) / (max - min)) * 100);
	const [barMax, setBarMax] = useState(((max - maxValue) / (max - min)) * 100);
	const [isChange, setIsChange] = useState(true);

	function GetMovementValue(e: MouseEvent | TouchEvent, startX: number, barBox: DOMRect, barValue: number) {
		let clientX = null;
		if(e instanceof MouseEvent){
			clientX = e.clientX;
		} else {
			clientX = e.touches[0].clientX;
		}
		let dx = clientX - startX;
		let per = dx / barBox.width;
		let val = barValue + (max - min) * per;
		if (stepOnly) {
			val = Math.round(val / step) * step;
		}
		return parseFloat(val.toFixed(fixed));
	}

	const onLeftThumbMousedown = useCallback((e: React.MouseEvent) => {
		if (disabled) return;
		let startX = e.clientX;
		let thumb = e.target as HTMLDivElement;
		let bar = thumb.parentNode as HTMLDivElement;
		let barBox = bar.getBoundingClientRect();
		let barValue = minValue;
		setIsChange(false);
		let onLeftThumbMousemove: { (e: MouseEvent): void } = (e: MouseEvent) => {
			let val = GetMovementValue(e, startX, barBox, barValue);
			if (val < min) {
				val = min;
			} else if (val > maxValue) {
				val = maxValue;
			}
			setMinValue(val);
		};
		let onLeftThumbMouseup: { (e: MouseEvent): void } = (e: MouseEvent) => {
			setIsChange(true);
			document.removeEventListener('mousemove', onLeftThumbMousemove);
			document.removeEventListener('mouseup', onLeftThumbMouseup);
		};
		document.addEventListener('mousemove', onLeftThumbMousemove);
		document.addEventListener('mouseup', onLeftThumbMouseup);
	}, [minValue, maxValue, min]);

	const onLeftThumbTouchStart = useCallback((e: React.TouchEvent) => {
		if (disabled) return;
		let startX = e.touches[0].clientX;
		let thumb = e.target as HTMLDivElement;
		let bar = thumb.parentNode as HTMLDivElement;
		let barBox = bar.getBoundingClientRect();
		let barValue = minValue;
		setIsChange(false);
		let onLeftThumbToucheMove: { (e: TouchEvent): void } = (e: TouchEvent) => {
			let val = GetMovementValue(e, startX, barBox, barValue);
			if (val < min) {
				val = min;
			} else if (val > maxValue) {
				val = maxValue;
			}
			setMinValue(val);
		};
		let onLeftThumbTouchEnd: { (e: TouchEvent): void } = (e: TouchEvent) => {
			setIsChange(true);
			document.removeEventListener('touchmove', onLeftThumbToucheMove);
			document.removeEventListener('touchend', onLeftThumbTouchEnd);
		};

		document.addEventListener('touchmove', onLeftThumbToucheMove);
		document.addEventListener('touchend', onLeftThumbTouchEnd);
	}, [min, maxValue, minValue]);

	const onRightThumbMousedown: React.MouseEventHandler = useCallback((e: React.MouseEvent) => {
		if (disabled) return;
		e.stopPropagation();
		let startX = e.clientX;
		let thumb = e.target as HTMLDivElement;
		let bar = thumb.parentNode as HTMLDivElement;
		let barBox = bar.getBoundingClientRect();
		let barValue = maxValue;
		setIsChange(false);
		let onRightThumbMousemove: { (e: MouseEvent): void } = (e: MouseEvent) => {
			let val = GetMovementValue(e, startX, barBox, barValue);
			if (val < minValue) {
				val = minValue;
			} else if (val > max) {
				val = max;
			}
			setMaxValue(val);
		};
		let onRightThumbMouseup: { (e: MouseEvent): void } = (e: MouseEvent) => {
			setIsChange(true);
			document.removeEventListener('mousemove', onRightThumbMousemove);
			document.removeEventListener('mouseup', onRightThumbMouseup);
		};
		document.addEventListener('mousemove', onRightThumbMousemove);
		document.addEventListener('mouseup', onRightThumbMouseup);
	}, [minValue, maxValue, max]);

	const onRightThumbTouchStart = useCallback((e: React.TouchEvent) => {
		if (disabled) return;
		e.stopPropagation();
		let startX = e.touches[0].clientX;
		let thumb = e.target as HTMLDivElement;
		let bar = thumb.parentNode as HTMLDivElement;
		let barBox = bar.getBoundingClientRect();
		let barValue = maxValue;
		setIsChange(false);
		let onRightThumbTouchMove: { (e: TouchEvent): void } = (e: TouchEvent) => {
			let val = GetMovementValue(e, startX, barBox, barValue);
			if (val < minValue) {
				val = minValue;
			} else if (val > max) {
				val = max;
			}
			setMaxValue(val);
		};
		let onRightThumbTouchEnd: { (e: TouchEvent): void } = (e: TouchEvent) => {
			setIsChange(true);
			document.removeEventListener('touchmove', onRightThumbTouchMove);
			document.removeEventListener('touchend', onRightThumbTouchEnd);
		};
		document.addEventListener('touchmove', onRightThumbTouchMove);
		document.addEventListener('touchend', onRightThumbTouchEnd);
	}, [minValue, maxValue, max]);

	const onMouseWheel = useCallback((e: React.WheelEvent) => {
		if (disabled) return;
		if (preventWheel === true) {
			return;
		}
		if (!e.shiftKey && !e.ctrlKey) {
			return;
		}
		let val = (max - min) / 100;
		if (val > 1) {
			val = 1;
		}
		if (e.deltaY < 0) {
			val = -val;
		}

		let tempMinValue = minValue;
		let tempMaxValue = maxValue;
		if (e.shiftKey && e.ctrlKey) {
			if (tempMinValue + val >= min && tempMaxValue + val <= max) {
				tempMinValue = tempMinValue + val;
				tempMaxValue = tempMaxValue + val;
			}
		} else if (e.ctrlKey) {
			val = tempMaxValue + val;
			if (val < tempMinValue) {
				val = tempMinValue;
			} else if (val > max) {
				val = max;
			}
			tempMaxValue = val;
		} else if (e.shiftKey) {
			val = tempMinValue + val;
			if (val < min) {
				val = min;
			} else if (val > tempMaxValue) {
				val = tempMaxValue;
			}
			tempMinValue = val;
		}

		setIsChange(false);
		setMaxValue(tempMaxValue);
		setMinValue(tempMinValue);
		_wheelTimeout && window.clearTimeout(_wheelTimeout);
		_wheelTimeout = window.setTimeout(() => {
			setIsChange(true);
		}, 100);
	}, [min, max, minValue, maxValue]);

	useEffect(() => {
		if (refBar && refBar.current) {
			let bar = refBar.current as HTMLDivElement;
			let p_bar = bar.parentNode as HTMLDivElement;
			p_bar.addEventListener('wheel', (e) => {
				if (!e.shiftKey && !e.ctrlKey) {
					return;
				}
				e.preventDefault();
			});
		}
	}, [refBar]);

	useEffect(() => {
		if (maxValue < minValue) {
			throw new Error('maxValue is less than minValue');
		}
		const triggerChange = () => {
			let result: ChangeResult = { min, max, minValue, maxValue };
			isChange && props.onChange && props.onChange(result);
			props.onInput && props.onInput(result);
		};
		let _barMin = ((minValue - min) / (max - min)) * 100;
		setBarMin(_barMin);
		let _barMax = ((max - maxValue) / (max - min)) * 100;
		setBarMax(_barMax);
		_triggerTimeout && window.clearTimeout(_triggerTimeout);
		_triggerTimeout = window.setTimeout(triggerChange, 20);
	}, [minValue, maxValue, min, max, fixed, props, isChange]);

	useEffect(() => {
		let tempMinValue = props.minValue;
		if (tempMinValue === null || tempMinValue === undefined) {
			tempMinValue = 25;
		}
		tempMinValue = +tempMinValue;
		if (tempMinValue < min) {
			tempMinValue = min;
		}
		if (tempMinValue > max) {
			tempMinValue = max;
		}
		setIsChange(false);
		setMinValue(+tempMinValue);
	}, [props.minValue, min, max]);

	useEffect(() => {
		let tempMaxValue = props.maxValue;
		if (tempMaxValue === null || tempMaxValue === undefined) {
			tempMaxValue = 75;
		}
		tempMaxValue = +tempMaxValue;

		if (tempMaxValue > max) {
			tempMaxValue = max;
		}
		if (tempMaxValue < min) {
			tempMaxValue = min;
		}
		setIsChange(false);
		setMaxValue(+tempMaxValue);
	}, [props.maxValue, min, max, step]);

	const leftRange = useMemo(() => {
		return Math.round((minValue - min) / step);
	}, [minValue, min, step])

	const rightRange = useMemo(() => {
		return Math.round((maxValue - min) / step -1);
	}, [maxValue, min, step])

	const clickOnBar = useCallback((e) => {
		const sliderRect = e.currentTarget.getBoundingClientRect();
		const clickPosition = e.clientX - sliderRect.left;
		const sliderWidth = sliderRect.width;

		const percentage = (clickPosition / sliderWidth) * 100;
		const newValue = (percentage * (max - min)) / 100 + min;
		const minDiff = newValue - minValue;
		const maxDiff = maxValue - newValue;

		if(maxDiff < minDiff || singleSlider){
			setMaxValue(+newValue.toFixed(2));
		} else {
			setMinValue(+newValue.toFixed(2));
		}
	}, [min, max, maxValue, minValue])

	return (
		<div ref={ref} id={props.id} className={('multi-range-slider ') + (singleSlider ? 'hide-left-thumb' : '') + (disabled ? ' disabled' : '')} style={props.style} onWheel={onMouseWheel} >
			<div className='bar' ref={refBar}>
				<div data-testid={'bar-left'} className='bar-left' style={{ width: barMin + '%', backgroundColor: props.barLeftColor }}></div>
				<div data-testid={'thumb-left'} className='thumb thumb-left' style={{ backgroundColor: props.thumbLeftColor }} onMouseDown={onLeftThumbMousedown} onTouchStart={onLeftThumbTouchStart} />
				<div data-testid={'bar-inner'} className='bar-inner' style={{ backgroundColor: props.barInnerColor }}>
					<div className='bar-inner-left'></div>
					<div className='bar-inner-right'></div>
				</div>
				<div data-testid={'thumb-right'} className='thumb thumb-right' style={{ backgroundColor: props.thumbRightColor }} onMouseDown={onRightThumbMousedown} onTouchStart={onRightThumbTouchStart} />
				<div data-testid={'bar-right'} className='bar-right' style={{ width: barMax + '%', backgroundColor: props.barRightColor }} ></div>
				{ruler && (
					<div data-testid={'ruler'} className='ruler' onMouseDown={clickOnBar}>
						{[...Array(stepCount)].map((e, i) => (
							<div key={i} className={'ruler-rule' + (i >= leftRange && i <= rightRange ? ' orange-rule' : '')}>
								{subSteps && [...Array(10)].map((e, n) => <div key={n} className='ruler-sub-rule' />)}
							</div>
						))}
					</div>
				)}
			</div>
			{label && (
				<div className='labels'>
					{labels.map((label) => {
						return (
							<Typography key={label.toString()} className='label'>
								{label}
							</Typography>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default React.memo(forwardRef<HTMLDivElement, Props>(MultiRangeSlider));
