import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Checkbox, Portal, SearchInput, Spinner, Typography as Text, useTheme} from "@catapultsports/referee-react";
import './searchFilter.sass';
import {useTranslation} from "react-i18next";
import {SEARCH} from "../../services/i18n/i18n-constants";
import {
    IconCancelSmall,
    IconChevronLeft,
    IconChevronRight
} from "@catapultsports/referee-react/icons";
import {FILTER_CATEGORY_SCROLL_COUNT, SEARCH_DEBOUNCE_DEFAULT} from "../../api/enums/clientMagic";
import InfiniteScroll from "react-infinite-scroller";
import {QualifierType} from "./__mocks__/data";
import {FilterMenuRow} from "./subComponents/filterMenuRow";
import {scrollToReference} from "../../utils/scrollingUtils";
import {QueryGroup} from "../../screens/nextGenFinder/__types__";
import _ from 'lodash';

export interface SearchFilterProps {
    selectedTab: TabOption;
    onTabSelected: (selected: TabOption) => void;
    popperProps: (props?: HTMLElement) => HTMLElement;
    onClose: () => void;
    onSearch: (searchValue: string | undefined) => void;
    onPerfectMatchChanged: (checked: boolean) => void;
    perfectMatch: boolean;
    categories: { id: string, label: string }[];
    onCategorySelected: (value: string | undefined) => void;
    selectedCategoryIds: string[] | undefined,
    filterOptions: QualifierType[];
    hasMoreFilters: boolean;
    onLoadNextPage: () => void;
    activeOnly: boolean;
    onActiveOnlyChanged: (checked: boolean) => void;
    activeFilters: QueryGroup[];
    onUpdateActiveFilters: (activeFiltersUpdated: QueryGroup[]) => void;
    flattenedFilterIds: string[];
}

export enum TabOption {
    Basic,
    Advanced,
    Presets
}

export const SearchFilter: React.FC<SearchFilterProps> = (props) => {
    const {
        popperProps,
        selectedTab,
        onTabSelected,
        onClose,
        onSearch,
        onPerfectMatchChanged,
        perfectMatch,
        categories,
        onCategorySelected,
        selectedCategoryIds,
        filterOptions,
        hasMoreFilters,
        onLoadNextPage,
        activeOnly,
        onActiveOnlyChanged,
        activeFilters,
        onUpdateActiveFilters,
        flattenedFilterIds} = props;

    const theme = useTheme();

    const popper = useMemo(() => {
        return popperProps();
    }, [popperProps]);

    const wrapperRef = React.useRef(null);
    const [categoryScrollNumber, setCategoryScrollNumber] = useState(0);
    const categoryContainerRef = React.useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const debouncedText = _.debounce((value: string) => onSearch(value), SEARCH_DEBOUNCE_DEFAULT);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedText(event.target.value);
    }, [debouncedText]);

    const onPerfectMatchCheck = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onPerfectMatchChanged(event.target.checked), [onPerfectMatchChanged]);

    const onCategoriesLeftClick = useCallback(() => {
        setCategoryScrollNumber(
            Math.max(categoryScrollNumber - (categoryScrollNumber === categories.length - 1 ? (FILTER_CATEGORY_SCROLL_COUNT * 2) : FILTER_CATEGORY_SCROLL_COUNT), 0));
    }, [categoryScrollNumber, categoryContainerRef]);

    const onCategoriesRightClick = useCallback(() => {
        setCategoryScrollNumber(
            Math.min(categoryScrollNumber + (categoryScrollNumber === 0 ? (FILTER_CATEGORY_SCROLL_COUNT * 2) : FILTER_CATEGORY_SCROLL_COUNT), categories.length - 1));
    }, [categoryScrollNumber, categoryContainerRef]);

    const onActiveOnlyCheck = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onActiveOnlyChanged(event.target.checked), [onActiveOnlyChanged]);

    const maskClass = useMemo(() => {
        switch (categoryScrollNumber) {
            case 0:
                return 'mask-end';
            case categories.length -1:
                return 'mask-start';
            default:
                return 'mask-both';
        }
    }, [categoryScrollNumber]);

    useEffect(() => {
        if (categoryContainerRef.current) {
            scrollToReference(categoryContainerRef.current.children[categoryScrollNumber].firstElementChild);
        }
    }, [categoryScrollNumber]);

    const onFilterUpdated = useCallback((group: QueryGroup) => {
        const filters = _.cloneDeep(activeFilters);
        let updatedFilters = [];

        if (group.conditions && group.conditions.length == 0) {
            // remove
            updatedFilters = filters.filter(filter => filter.qualifierTypeId != group.qualifierTypeId);
            onUpdateActiveFilters(updatedFilters);
            return;
        }

        if (flattenedFilterIds.includes(group.qualifierTypeId)) {
            // update
            updatedFilters = filters.map(filter => {
                if(filter.qualifierTypeId != group.qualifierTypeId) {
                    return filter;
                } else {
                    return group;
                }
            }
            );
            onUpdateActiveFilters(updatedFilters);
            return;
        }

        // add
        if(group.conditions && group.conditions.length > 0) {
            onUpdateActiveFilters([...filters, group]);
        }
    }, [flattenedFilterIds]);

    return (
        <Portal {...popper} data-theme={theme}>
            <div ref={wrapperRef} className={'flex vertical search-filter'} data-testid={'filter-wrapper'}>
                <div className={'flex horizontal header'}>
                    <div className={'flex horizontal filter-tabs'}>
                        <div onClick={() => onTabSelected(TabOption.Basic)} className={`tab ${selectedTab === TabOption.Basic ? 'tab-selected' : 'tab-unselected'}`}>
                            <Text>{t(SEARCH.BASIC_FILTERS)}</Text>
                        </div>
                        <div onClick={() => onTabSelected(TabOption.Advanced)} className={`tab ${selectedTab === TabOption.Advanced ? 'tab-selected' : 'tab-unselected'}`}>
                            <Text>{t(SEARCH.ADVANCED_MODE)}</Text>
                        </div>
                        <div onClick={() => onTabSelected(TabOption.Presets)} className={`tab ${selectedTab === TabOption.Presets ? 'tab-selected' : 'tab-unselected'}`}>
                            <Text>{t(SEARCH.PRESETS)}</Text>
                        </div>
                    </div>
                    <Button size={"small"} variant={"text"} onClick={onClose}><IconCancelSmall/></Button>
                </div>
                <div className={'flex horizontal search-options'}>
                    <div className={'flex horizontal search-left'}>
                        <SearchInput data-testid={'search-input'} onChange={handleSearchChange}/>
                        <Checkbox testId={'active-only'} checked={activeOnly} onChange={onActiveOnlyCheck} label={t(SEARCH.ACTIVE_ONLY)}/>
                    </div>
                    <div className={'perfect-match'}>
                        <Checkbox testId={'perfect-match'} checked={perfectMatch} onChange={onPerfectMatchCheck} label={t(SEARCH.PERFECT_MATCH)}/>
                    </div>
                </div>
                <div className={'flex horizontal categories'}>
                    <Button
                        disabled={categoryScrollNumber === 0}
                        size={"small"}
                        variant={"text"}
                        onClick={onCategoriesLeftClick}>
                        <IconChevronLeft/>
                    </Button>
                    <div ref={categoryContainerRef} className={`flex horizontal categories-list ${maskClass}`}>
                        {
                            categories.map(category =>
                                <Button
                                    key={category.id}
                                    className={selectedCategoryIds?.includes(category.id) ? 'selected' : ''}
                                    size={"small"}
                                    variant={"outline"}
                                    onClick={() => onCategorySelected(category.id)}>{category.label}</Button>)
                        }
                    </div>
                    <Button
                        disabled={categoryScrollNumber === categories.length - 1}
                        size={"small"}
                        variant={"text"}
                        onClick={onCategoriesRightClick}>
                        <IconChevronRight/>
                    </Button>
                </div>
                <div className={'flex vertical filter-row-options'} data-testid={'filter-row-options'}>
                    <InfiniteScroll
                        className={'filterScroll'}
                        threshold={10}
                        pageStart={0}
                        loadMore={onLoadNextPage}
                        hasMore={hasMoreFilters}
                        loader={<Spinner key='spinner' size="medium"/>}
                        useWindow={false}>
                        {
                            filterOptions && filterOptions.length > 0 ?
                                filterOptions.map(filterOption =>
                                    <FilterMenuRow
                                        key={filterOption.id}
                                        filterOption={filterOption}
                                        onUpdated={onFilterUpdated}
                                        activeFilter={activeFilters.filter((x) =>
                                            x.qualifierTypeId === filterOption.id
                                        )[0]}/>) :
                                <div className={'no-filters'}><Text>{t(SEARCH.NO_FILTERS_FOR_CRITERIA)}</Text></div>
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </Portal>);
};