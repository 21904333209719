import React from "react";
import {HubMenu} from "./menu";
import {Outlet} from "@tanstack/react-location";

export const Hub: React.FC<any> = () => {

    return (
        <article className={'screen'}>
            <HubMenu />
            <Outlet />
        </article>
    )
};
