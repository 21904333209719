import { gql } from "@apollo/client";

export const COMPETITION = gql`
    fragment CompetitionFields on Competition {
        base {
            id
        }
        name
    }
`;

export const COMPETITIONS = gql`
    ${COMPETITION}
    query Competition($pageSize: Int, $pageNumber: Int), {
        competitions(pageSize: $pageSize, pageNumber: $pageNumber) {
            edges {
                ...CompetitionFields
            }
        }
    }
`;
