import { gql } from "@apollo/client";

const METADATA_GROUPS = gql`
    query MetadataGroups(
        $filter: MetadataGroupFilter
        $pageSize: Int
    ) {
        metadataGroups(filter: $filter, pageSize: $pageSize) {
            edges {
                id
                name
                type
                hidden
                hideInFilterView
                hideInTableView
                excludeFromSearch
                excludeFromCopyLabels
                metadata {
                    id
                    value
                    itemCount
                }
            }
        }
    }
`;

const METADATA_GROUP_VALUES = gql`
    query MetadataGroupValues(
        $filter: MetadataGroupFilter
        $pageSize: Int
    ) {
        metadataGroups(filter: $filter, pageSize: $pageSize) {
            edges {
                id
                name
                metadata {
                    id
                    value
                }
            }
        }
    }
`;

export { METADATA_GROUPS, METADATA_GROUP_VALUES };
