import {ApolloError, useQuery} from "@apollo/client";
import {PLAYLIST_METADATA, TAG_METADATA} from "./metadata.gql";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {TagMetadata} from "./__types__/metadata";
import {ApiErrors} from "../../enums/apiErrors";
import {useEffect} from "react";

export const useTagMetadata = (id: string, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data } = useQuery<TagMetadata, {id: string}>(TAG_METADATA, {
        variables: {
            id: id
        }
    });

    useEffect(() => {
        handleErrorRaised(error, PLAYLIST_METADATA, ApiErrors.PlaylistMetadata, onError);
    }, [error, PLAYLIST_METADATA]);

    return {
        metadata: data?.tag.metadata,
        metadataGroups: data?.tag.metadataGroups,
        notes: data?.tag.description
            ? data?.tag.notes && data.tag.notes.length > 0
                ? [...data.tag.notes, { id: 'default', heading: 'Default Note', value: data.tag.description }]
                : [{ id: 'default', heading: 'Default Note', value: data.tag.description }]
            : data?.tag.notes,
        loading,
        error
    };
};
