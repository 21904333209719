import {PermanentState} from '../StoreSlice';
import {StateCreator} from "zustand";

export interface URLSliceProps {
    activeUrl: string
    setActiveUrl: (newUrl: string) => void
}

export const createUrlSlice: StateCreator<PermanentState,  [["zustand/persist", unknown], ["zustand/devtools", never]], [], URLSliceProps> =
    (set) => {
        return ({
            activeUrl: '',
            setActiveUrl: (newUrl: string) => set((state: URLSliceProps) => {
                state.activeUrl = newUrl;
                return state;
            })
        })
};
