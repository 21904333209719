import {useQuery} from "@apollo/client";
import {LICENSES} from "./licenses.gql";
import {ILicensesResponse} from "../../__types__/Licenses";
import {ApiPageSize} from "../../../enums/apiMagic";

const useLicenses = (licenseIds?: string[], userIds?: string[], pageSize?: number) => {
    const { loading, error, data, fetchMore } = useQuery<ILicensesResponse>(LICENSES, {
        variables: {
            pageNumber: 0,
            pageSize: pageSize ?? ApiPageSize.Thirty,
            filter: { licenseIDs: licenseIds || [], userIDs: userIds || [] }
        }
    });

    const loadNextPage = () => {
        if (data?.licenses.pageInfo?.pageNumber === undefined) return;
        fetchMore({
            variables: {
                pageNumber: data?.licenses.pageInfo?.pageNumber + 1,
                pageSize: pageSize ?? ApiPageSize.Thirty
            }
        });
    };

    return {
        state: {
            licensesHasNextPage: !!data?.licenses.pageInfo.hasNextPage,
            licenses: data?.licenses?.edges,
            licensesPageSize: pageSize ?? ApiPageSize.Five,
            licensesLoading: loading,
            licensesError: error,
        },
        operations: { loadNextPage }
    }
};

export default useLicenses;