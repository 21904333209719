import { gql } from "@apollo/client";

export const GET_COLLECTIONS = gql`
    query Collections($filter: PlaylistFilter, $sort: PlaylistSort, $pageSize: Int, $pageNumber: Int) {
        playlists(filter: $filter, sort: $sort, pageSize: $pageSize, pageNumber: $pageNumber) {
            pageInfo {
                total
                pageSize
                pageNumber
                hasNextPage
                __typename
            }
            edges {
                id
                commentCount
                name
                description
                creationTimestamp
                modifiedTimestamp
                thumbnail {
                    file {
                        s3Link
                    }
                    offset
                }
                tags {
                    name
                    id
                }
                assetCount(assetTypes: [VIDEO, IMAGE, DOCUMENT, PDF]) {
                    assetType
                    count
                }
                users {
                    email
                }
                metadata {
                    id
                    value
                }
            }
        }
    }
`

export const GET_PLAYLIST = gql`
    query Playlist($id: String!) {
        playlist(id: $id) {
            id
            name
            role
            tags {
                id
                name
                description
                startTime
                endTime
                poiTime
                tagType
                commentCount
                presentationOrder
                colour {
                    red
                    green
                    blue
                }
                thumbnail {
                    file {
                        s3Link
                    }
                    offset
                }
                metadata {
                    id
                    value
                    metadataGroup {
                        id
                        name
                    }
                }
                assets {
                    id
                    name
                    description
                    assetType
                    startTime
                    endTime
                    poiTime
                    isPreferred
                    file {
                        s3Link
                    }
                }
                comments {
                    id
                    email
                    comment {
                        message
                        version
                    }
                    commentTimestamp
                    replies {
                        id
                        email
                        comment {
                            message
                            version
                        }
                        commentTimestamp
                    }
                }
            }
            users {
                email
                role
            }
        }
    }
`