import { gql } from "@apollo/client";
import {FIXTURE_COLLECTED_FILE, FIXTURE_DELIVERED_FILE, FIXTURE_INGESTED_FILE} from "./fixtureFile.gql";

export const FIXTURE_FILES = gql`
    ${FIXTURE_COLLECTED_FILE}
    ${FIXTURE_INGESTED_FILE}
    ${FIXTURE_DELIVERED_FILE}
    fragment FixtureFiles on Fixture {
        collectedFiles {
            ...FixtureCollectedFile
        }

        ingestedFiles {
            ...FixtureIngestedFile
        }

        delivery {
            base {
                id
            }
            deliveredFiles {
                ...FixtureDeliveredFile
            }
        }
    }
`;
