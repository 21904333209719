import {
    ApolloProvider,
} from '@apollo/client';
import React, {ReactNode} from "react";
import {client} from "../../api/MatchTracker/matchTrackerClient";

const MatchTrackerApolloProvider = ({children}: {children : ReactNode}) => {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
};

export default MatchTrackerApolloProvider;