import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Portal, Tooltip, Typography, usePositioner} from "@catapultsports/referee-react";
import {IconCheck} from "@catapultsports/referee-react/icons";
import {useOutsideClick} from "../../../utils/mouseUtils";
import './operatorMenu.sass';
import {QueryOperator} from "../../../screens/nextGenFinder/__types__";
import {QualifierValueType} from "../__mocks__/data";
import {useTranslation} from "react-i18next";
import {NAV, OPERATORS} from "../../../services/i18n/i18n-constants";
import { TFunction } from "i18next";

export interface OperatorMenuProps {
    operatorUpdated: (operator: QueryOperator) => void
    filterType: QualifierValueType
    activeOperator: QueryOperator
}

export const returnQueryOperatorIndex = (operatorValue: QueryOperator) => {
    return Object.keys(QueryOperator).indexOf(Object.keys(QueryOperator)[Object.values(QueryOperator).indexOf(operatorValue)])
}

function getAvailableOperators(filterType: QualifierValueType, activeOperator: QueryOperator, t: TFunction<"translation">) {


    let operators = [
        {
            value: QueryOperator.EqualExact,
            niceValue: t(OPERATORS.EQUALS),
            id: Object.keys(QueryOperator).indexOf(QueryOperator.EqualExact),
            isSelected: activeOperator === QueryOperator.EqualExact
        }
    ];

    switch (filterType) {
        case QualifierValueType.List:
            operators = [
                { value: QueryOperator.EqualExact, niceValue: t(OPERATORS.EQUALS), id: returnQueryOperatorIndex(QueryOperator.EqualExact), isSelected: activeOperator === QueryOperator.EqualExact },
                { value: QueryOperator.EqualIn, niceValue: t(OPERATORS.CONTAINS), id: returnQueryOperatorIndex(QueryOperator.EqualIn), isSelected: activeOperator === QueryOperator.EqualIn },
                { value: QueryOperator.EqualPartial, niceValue: t(OPERATORS.PARTIAL_EQUALS), id: returnQueryOperatorIndex(QueryOperator.EqualPartial), isSelected: activeOperator === QueryOperator.EqualPartial },
                { value: QueryOperator.EqualStartsWith, niceValue: t(OPERATORS.STARTS_WITH), id: returnQueryOperatorIndex(QueryOperator.EqualStartsWith), isSelected: activeOperator === QueryOperator.EqualStartsWith },
                { value: QueryOperator.EqualEndsWith, niceValue: t(OPERATORS.ENDS_WITH), id: returnQueryOperatorIndex(QueryOperator.EqualEndsWith), isSelected: activeOperator === QueryOperator.EqualEndsWith },
                { value: QueryOperator.NotEqual, niceValue: t(OPERATORS.NOT_EQUALS), id: returnQueryOperatorIndex(QueryOperator.NotEqual), isSelected: activeOperator === QueryOperator.NotEqual }
            ];
            break;

        case QualifierValueType.Numeric:
            operators = [
                { value: QueryOperator.EqualExact, niceValue: t(OPERATORS.EQUALS), id: returnQueryOperatorIndex(QueryOperator.EqualExact), isSelected: activeOperator === QueryOperator.EqualExact },
                { value: QueryOperator.NotEqual, niceValue: t(OPERATORS.NOT_EQUALS), id: returnQueryOperatorIndex(QueryOperator.NotEqual), isSelected: activeOperator === QueryOperator.NotEqual },
                { value: QueryOperator.LessThan, niceValue: t(OPERATORS.LESS_THAN), id: returnQueryOperatorIndex(QueryOperator.LessThan), isSelected: activeOperator === QueryOperator.LessThan },
                { value: QueryOperator.LessThanEqualTo, niceValue: t(OPERATORS.LESS_THAN_EQUAL_TO), id: returnQueryOperatorIndex(QueryOperator.LessThanEqualTo), isSelected: activeOperator === QueryOperator.LessThanEqualTo },
                { value: QueryOperator.GreaterThan, niceValue: t(OPERATORS.GREATER_THAN), id: returnQueryOperatorIndex(QueryOperator.GreaterThan), isSelected: activeOperator === QueryOperator.GreaterThan },
                { value: QueryOperator.GreaterThanEqualTo, niceValue: t(OPERATORS.GREATER_THAN_EQUAL_TO), id: returnQueryOperatorIndex(QueryOperator.GreaterThanEqualTo), isSelected: activeOperator === QueryOperator.GreaterThanEqualTo },
                { value: QueryOperator.Between, niceValue: t(OPERATORS.BETWEEN), id: returnQueryOperatorIndex(QueryOperator.Between), isSelected: activeOperator === QueryOperator.Between }
            ];
            break;

        case QualifierValueType.Team:
            operators = [
                { value: QueryOperator.For, niceValue: t(OPERATORS.FOR), id: returnQueryOperatorIndex(QueryOperator.For), isSelected: activeOperator === QueryOperator.For },
                { value: QueryOperator.Against, niceValue: t(OPERATORS.AGAINST), id: returnQueryOperatorIndex(QueryOperator.Against), isSelected: activeOperator === QueryOperator.Against },
                { value: QueryOperator.Home, niceValue: t(OPERATORS.HOME), id: returnQueryOperatorIndex(QueryOperator.Home), isSelected: activeOperator === QueryOperator.Home },
                { value: QueryOperator.Away, niceValue: t(OPERATORS.AWAY), id: returnQueryOperatorIndex(QueryOperator.Away), isSelected: activeOperator === QueryOperator.Away }
            ];
            break;

        case QualifierValueType.Player:
            operators = [
                { value: QueryOperator.For, niceValue: t(OPERATORS.FOR), id: returnQueryOperatorIndex(QueryOperator.For), isSelected: activeOperator === QueryOperator.For },
                { value: QueryOperator.Against, niceValue: t(OPERATORS.AGAINST), id: returnQueryOperatorIndex(QueryOperator.Against), isSelected: activeOperator === QueryOperator.Against }
            ];
            break;

        default:
            break;
    }

    return operators;
}


export const OperatorMenu: React.FC<OperatorMenuProps> = (props) => {
    const {operatorUpdated, activeOperator, filterType} = props;
    const {t} = useTranslation();
    const [options, setOptions] = useState(getAvailableOperators(filterType, activeOperator, t));
    const [isVisible, setIsVisible] = useState(false);

    const parent = useRef(null);
    const wrapperRef = useRef(null);
    const buttonRef = React.useRef<HTMLElement>(null);

    const {getPopperProps, getReferenceProps} = usePositioner({
        placement: 'bottom-start',
    });

    useEffect(() => {
        setOptions(getAvailableOperators(filterType, activeOperator, t));
    }, [activeOperator])

    const handleOptionClick = useCallback((item: { id: number; isSelected: boolean; value: QueryOperator; }) => {
        const updatedOptions = options.map(option =>
            option.id === item.id ? { ...option, isSelected: true } : { ...option, isSelected: false }
        );
        setOptions(updatedOptions);
        setIsVisible(false);
        item.isSelected = true;
        operatorUpdated(item.value);
    }, [operatorUpdated]);

    useOutsideClick(
        wrapperRef,
        () => {
            setIsVisible(false);
        },
        parent,
    );

    return (
        <div className={'operator-area'}>
            <div ref={parent}>
                <Button
                    {...getReferenceProps({ref: buttonRef})}
                    className={'operator-button'}
                    onClick={() => setIsVisible(true)}
                    variant={'text'}
                    size={'small'}>
                    <Typography variant={'heading-5'}>{activeOperator}</Typography>
                </Button>
            </div>
            {isVisible && (
                    <Portal {...getPopperProps()} data-theme={'light'}>
                        <div ref={wrapperRef} className={'operators-menu'}>
                            {
                                options.map(operator => <div
                                        key={operator.id}
                                        data-testid={`operator-option-${operator.id}`}
                                        onClick={() => handleOptionClick(operator)}
                                    >
                                    <Tooltip text={operator.niceValue}>
                                        <div className={'option-wrapper'}>
                                            <Typography variant={'heading-5'}>
                                                {operator.value}
                                            </Typography>
                                            {operator.isSelected && <IconCheck className={'option-selected'}/>}
                                        </div>
                                    </Tooltip>
                                    </div>
                                )
                            }
                        </div>
                    </Portal>
            )}
        </div>
    )
}