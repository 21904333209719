import {StringOperator} from "../../enums/stringFilter";
import {StringFilter} from "../../__types__/common";

export function generateFreeTextSearchItems(freeTextValue: string) : StringFilter[] {
    if (freeTextValue.includes(' ')) {
        return freeTextValue.split(' ').map((value, index) => ({value: value, operator: StringOperator.Contains} as StringFilter));
    }
    return [{
        value: freeTextValue,
        operator: StringOperator.Contains
    }];
}
