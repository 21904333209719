import {gql} from "@apollo/client";

export const PLAYLIST_COMMENTS = gql`
    query PlaylistComments($playlistId: String!) {
      playlist(id: $playlistId){
        id
        commentCount
        comments {
            id
            parentId
            commentTimestamp
            replies {
                id
            }
        }
      }
    }
`;

export const CLIP_COMMENTS = gql`
    query TagComments($tagId: String!) {
      tag(id: $tagId){
        id
        commentCount
        comments {
            id
            parentId
            commentTimestamp
            replies {
                id
            }
        }
      }
    }
`;

export const COMMENT = gql`
    query Comment($commentId: String!) {
      comment(id: $commentId){
        id
        parentId
        email
        commentTimestamp
        comment {
            version
            message
        }
        replies {
            id
            commentTimestamp
        }
      }
    }
`;

export const ADD_TAG_COMMENT = gql`
    mutation UpdateTagAddComment($input: [UpdateTagInput!]!) {
      updateTags(input: $input) {
          tags {
              id
              comments {
                id
                replies {
                    id
                }
              }
              commentCount
          }
      }
    }
`;

export const ADD_PLAYLIST_COMMENT = gql`
    mutation UpdatePlaylistAddComment($input: [UpdatePlaylistInput!]!) {
      updatePlaylists(input: $input) {
          playlists {
              id
              comments {
                id
                replies {
                    id
                }
              }
              commentCount
          }
      }
    }
`;