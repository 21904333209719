import React, {useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from "@tanstack/react-location";
import {usePersistedStore} from '../state/PersistedState';
import {shallow} from 'zustand/shallow';
import {Routes} from "../enums/Routes";
import {DateTime} from "luxon";
import {JOYRIDE} from "../services/i18n/i18n-constants";
import {JoyrideStyles} from "../configs/JoyrideConfig";
import {CustomJoyrideContainer} from "../components/customJoyide/CustomJoyrideContainer";
import ReactJoyride, {CallBackProps, STATUS} from "react-joyride";
import {usePermanentStore} from "../state/PerminantState";
import {useTranslation} from "react-i18next";

// @ts-ignore
export const RequireAuth = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const userId = usePersistedStore(state => state.user.userId, shallow);
    const userExpiry = usePersistedStore(state => state.user.expiry, shallow);
    const showInitialIntro = usePermanentStore((state) => state.showInitialIntro, shallow);
    const persistShowInitialIntro = usePermanentStore((state) => state.persistShowInitialIntro, shallow);
    const {t} = useTranslation();

    const handleJoyrideCallback = useCallback((data: CallBackProps) => {
        if (data.status === STATUS.FINISHED || data.status === STATUS.SKIPPED) {
            persistShowInitialIntro(false);
        }
    }, []);

    useEffect(() => {
        if (!userId) {
            navigate({
                to: `/${Routes.LOGOUT_PAGE}?forced=true`,
                replace: true
            });
        } else if (userExpiry && userExpiry < DateTime.now().toSeconds()) {
            navigate({
                to: `/${Routes.LOGOUT_PAGE}?forced=true`,
                replace: true
            });
        }
    }, [location.current.pathname, userId, userExpiry, navigate]);

    return userId
        ? <>
            { showInitialIntro && location.current.pathname.includes(Routes.MATCH_PAGE) && <ReactJoyride
                steps={[
                    {
                        title: t(JOYRIDE.INITIAL_TITLE),
                        target: '[data-testid=\'app-test-id\']',
                        content: t(JOYRIDE.INITIAL_CONTENTS),
                        disableBeacon: true,
                        placement: "center"
                    },
                    {
                        title: t(JOYRIDE.APPZI_TITLE),
                        target: 'iframe[data-appzi-dom=\'1\']',
                        content: t(JOYRIDE.APPZI_CONTENTS),
                        disableBeacon: true,
                        placement: "left"
                    }]}
                run={true}
                showProgress={true}
                showSkipButton={false}
                continuous={true}
                styles={JoyrideStyles}
                tooltipComponent={CustomJoyrideContainer}
                callback={handleJoyrideCallback}
            />}
            {children}</>
        : null;
}
