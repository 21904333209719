import { StoreSlice } from '../StoreSlice';
import {
    ILicensingCustomer
} from "../../../api/Customer/__types__/licensingTypes";

export interface LicensingSliceProps {
    customers: ILicensingCustomer[],
    currentCustomer: ILicensingCustomer,
    setCustomers: (customersByUser: ILicensingCustomer[]) => void,
    setCurrentCustomer: (customer: ILicensingCustomer) => void,
}

export const createLicensingSlice: StoreSlice<LicensingSliceProps> = (set, get) => ({
    customers: [],
    currentCustomer: {
        customerId: '',
        customerName: ''
    },

    setCustomers: (customersByUser: ILicensingCustomer[]) => set((state: LicensingSliceProps) => {
        state.customers = customersByUser;
        return state;
    }),
    setCurrentCustomer: (customer: ILicensingCustomer) => set((state: LicensingSliceProps) => {
        state.currentCustomer = customer;
        return state;
    })
});
