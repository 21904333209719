const CDN_BASEURL = process.env.REACT_APP_CDN_BASE_URL;

export const ICONS = `${CDN_BASEURL}/hubPlayer.svg`;
export const BLANK_VIDEO = `${CDN_BASEURL}/blank.mp4`;

export const CONTROLS = [
    "rewind",
    "play",
    "fast-forward",
    "frame-rewind",
    "frame-forward",
    "progress",
    "current-time",
    "duration",
    "mute",
    "volume",
    "settings",
    "pip",
    "airplay",
    "angle-selector",
    "fullscreen"
];

export const LIMITED_CONTROLS = [
    "play",
    "progress",
    "current-time",
    "duration",
    "settings",
    "pip",
    "airplay",
    "angle-selector",
    "fullscreen"
];

export const options = {
    seekTime: 5,
    debug: false,
    tooltips: {
        controls: true
    },
    speed: {
        selected: 1,
        options: [0.5, 1, 2, 4, 8, 16]
    },
    keyboard: {
        global: true
    },
    iconUrl: ICONS,
    blankUrl: BLANK_VIDEO
};
