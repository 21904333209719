export enum AssetType {
    NONE = 'NONE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
    PDF = 'PDF',
    DOCUMENT = 'DOCUMENT',
    WEBLINK = 'WEBLINK',
    OTHER = 'OTHER',
    UNAVAILABLE = 'UNAVAILABLE'
}

export enum PlaylistType {
    Collection = 'Collection',
    Presentation = 'Presentation',
    Playlist = 'Playlist',
    Session = 'Session'
}

export enum FilterItemType {
    Session = 'Session',
    Playlist = 'Playlist',
    Tag = 'Tag'
}

export enum SortBy {
    ModifiedTimestamp = 'modifiedTimestamp',
    CreationTimestamp = 'CreationTimestamp'
}

export enum TagType {
    Clip = 'CLIP'
}

export enum ApiErrors {
    AddTagComment = 'Could not add tag comment',
    AddPlaylistComment = 'Could not add playlist comment',
    Logout = 'Could not logout'
}
