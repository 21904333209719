import React, {useCallback} from "react";
import {Dialog, Button, ThemeProvider, Tooltip} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {GENERAL} from "../../services/i18n/i18n-constants";
import "./hubDialog.sass"
export interface HubDialogComponentProps {
    dialogTheme?: "dark" | "light"
    dialogSize?: 'small' | 'medium' | 'large';
    dialogHeader?: string
    isModalOpen?: boolean
    setIsModalOpen?: (isModalOpen: boolean) => void
    modalContent?: JSX.Element
    displayDialogFooterButtons?: boolean
    hideCloseButton?: boolean
    footerPrimaryButtonText?: string
    footerPrimaryButtonAction?: () => void
    footerPrimaryButtonDisabled?: boolean
    footerSecondaryButtonAction?: () => void,
    dialogId?: string
    allowDialogClosure?: boolean
    tooltipText?: string
}
export const HubDialogComponent: React.FC<HubDialogComponentProps> = (props) => {
    const {
        dialogTheme, dialogSize,
        dialogHeader, isModalOpen,
        setIsModalOpen , modalContent,
        displayDialogFooterButtons, hideCloseButton,
        footerPrimaryButtonText, footerPrimaryButtonAction,
        footerPrimaryButtonDisabled, footerSecondaryButtonAction,
        dialogId, allowDialogClosure,
        tooltipText
    } = props

    const { t } = useTranslation();

    const handleFooterPrimaryButtonClick = useCallback(() => {
        if(footerPrimaryButtonAction){
            footerPrimaryButtonAction()
        }
    }, [footerPrimaryButtonAction])

    const handleFooterSecondaryButtonClick = useCallback(() => {
        if(footerSecondaryButtonAction){
            footerSecondaryButtonAction()
        }
        if(setIsModalOpen) setIsModalOpen(false)
    }, [footerSecondaryButtonAction, setIsModalOpen])

    let id = `${dialogId}-dialog`

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => event.stopPropagation(), []);
    const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation(), []);
    const handleFocus = useCallback((event: React.FocusEvent<HTMLDivElement, Element>) => event.stopPropagation(), []);
    const handleMouseOver = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation(), []);
    const onDialogChange = useCallback((change: boolean) => setIsModalOpen && setIsModalOpen(change), []);

    return (
        <ThemeProvider theme={dialogTheme} testId={`${id}-theme`}>
            <Dialog
                size={dialogSize}
                header={dialogHeader}
                open={isModalOpen}
                hideCloseButton={hideCloseButton}
                allowDialogClosure={allowDialogClosure}
                onChange={onDialogChange}
            >
                <Dialog.Content testId={`${id}-content`}>
                    <div
                        onKeyDown={handleKeyDown}
                        onClick={handleClick}
                        onFocus={handleFocus}
                        onMouseOver={handleMouseOver}>
                        {modalContent}
                    </div>
                    {displayDialogFooterButtons? (
                        <Dialog.Footer>
                            <Button variant="text" onClick={handleFooterSecondaryButtonClick} testId={`${id}-secondary-button`}>
                                {t(GENERAL.CANCEL)}
                            </Button>
                            {footerPrimaryButtonDisabled? (
                                <Tooltip text={tooltipText} testId={`${id}-primary-button-tooltip`} className={"hub-dialog-tooltip-class"}>
                                    <span data-testid={`${id}-tooltip-wrapper`}>
                                         <Button
                                             style={footerPrimaryButtonDisabled ? { pointerEvents: 'none' } : {}}
                                             disabled={footerPrimaryButtonDisabled}
                                             testId={`${id}-primary-button-disabled`}
                                         >
                                             {t(footerPrimaryButtonText)}
                                         </Button>
                                    </span>
                                </Tooltip>
                            ) :
                                <Button
                                    onClick={handleFooterPrimaryButtonClick}
                                    disabled={footerPrimaryButtonDisabled}
                                    testId={`${id}-primary-button`}>{t(footerPrimaryButtonText)}
                                </Button>
                            }
                        </Dialog.Footer>
                    ) :null}
                </Dialog.Content>
            </Dialog>
        </ThemeProvider>

    );
};