import React from "react";
import {Typography} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import {GENERAL} from "../../services/i18n/i18n-constants";

export const ErrorFallback: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <article className={'screen'}>
            <Typography>{t(GENERAL.ERROR_FALLBACK)}</Typography>
        </article>
    )
};
