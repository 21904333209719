import React, {useMemo} from "react";
import {GridFooter} from "../../../components/gridFooter/gridFooter";
import {FixtureFilterType} from "../../../api/MatchTracker/enum/Common";
import {GameViewer} from "../../../components/gameViewer/gameViewer";
import './gamesResults.sass';
import {GamesTable} from "./gamesTable";
import {DateRange, GameResult} from "../../../__types__/globalTypes";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {Spinner} from "@catapultsports/referee-react";

export interface FixtureFilter {
    filterType: FixtureFilterType;
    id: string | undefined;
    name: string | undefined;
}

interface GamesResultsProps {
    dateRange: DateRange,
    competitionId: string,
    getVideo: boolean,
    getEvents: boolean,
    usePerfectMatch: boolean,
    switchType: (selectedResults: GameResult[]) => void,
    searchFilters: FixtureFilter[],
    seasonIds: [],
    teamIds: [],
    playerIds: [],
    includeFutureFixtures: boolean
}

export const GamesResults: React.FC<GamesResultsProps> = (props) => {
    const { dateRange, competitionId, getVideo, getEvents, usePerfectMatch, searchFilters, switchType, seasonIds, teamIds, playerIds, includeFutureFixtures } = props;
    const selectedResult = useSessionStore(state => state.games.selectedResult, shallow);
    const setSelectedResult = useSessionStore(state => state.setSelectedGameResult, shallow);

    const filtersLoaded = useMemo(() => {
        return searchFilters.every((filter) => filter.name != undefined);
    }, [searchFilters]);

    return (
        <>
        <section className={'flex horizontal game-results'}>
            {
                filtersLoaded ?
                    <>
                        <GamesTable
                            dateRange={dateRange}
                            competitionId={competitionId}
                            getVideo={getVideo}
                            getEvents={getEvents}
                            usePerfectMatch={usePerfectMatch}
                            searchFilters={searchFilters}
                            seasonsIds={seasonIds}
                            teamIds={teamIds}
                            playerIds={playerIds}
                            includeFutureFixtures={includeFutureFixtures}
                        />
                        {selectedResult && (
                            <GameViewer result={selectedResult} onClose={() => setSelectedResult(undefined)}/>
                        )}
                    </> :
                    <Spinner size={"large"}/>
            }
        </section>
            <GridFooter switchType={switchType} resultType={'game'} />
        </>
    )
};
