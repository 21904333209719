import {
    collectionLabelsActions,
    labelObjectBasicFields,
    labelObjectExtraFields,
    MetadataInput,
} from "./Common";
import {ITagItem} from "../../../api/Hub/tags/__types__/Tags";

export const mapMetadata = (metadata: MetadataInput[], tagId?: string, extraFields?: boolean) => {
    const mappedMetadata: (labelObjectExtraFields | labelObjectBasicFields)[] = [];

    metadata.map((metadataElement: any) => {
        if(!extraFields){
            if(!mappedMetadata.some(
                (mappedMetadataElement: any) => mappedMetadataElement.value === metadataElement.value
                    && mappedMetadataElement.groupId === metadataElement.metadataGroup.id
                    && !metadataElement.metadataGroup.hidden
            )){
                mappedMetadata.push(
                    {
                        value: metadataElement.value,
                        groupId: metadataElement.metadataGroup.id,
                        tagId: tagId
                    }
                )
            }
        }else{
            if(!mappedMetadata.some(
                (mappedMetadataElement: any) => mappedMetadataElement.value === metadataElement.value
                    && mappedMetadataElement.groupId === metadataElement.metadataGroup.id
                    && !metadataElement.metadataGroup.hidden
            )){
                mappedMetadata.push(
                    {
                        value: metadataElement.value,
                        id: metadataElement.id,
                        groupId: metadataElement.metadataGroup.id,
                        metadataGroupName: metadataElement.metadataGroup.name,
                    }
                )
            }
        }

    })
    return mappedMetadata
}


export let compareTwoArrayOfObjects = (
    firstArray: labelObjectBasicFields[] | labelObjectExtraFields[],
    secondArray: labelObjectBasicFields[] | labelObjectExtraFields [],
    comparisonValueOne: string,
    comparisonValueTwo: string

) => {
    return (
        firstArray.length === secondArray.length &&
        firstArray.every((elementOne: labelObjectBasicFields | labelObjectExtraFields) =>
            secondArray.some(
                (elementTwo) =>
                    elementOne[comparisonValueOne].toLowerCase() === elementTwo[comparisonValueOne].toLowerCase() &&
                    elementOne[comparisonValueTwo].toLowerCase() === elementTwo[comparisonValueTwo].toLowerCase()
            )
        )
    );
};

export const defineStatusOfCollectionLabelEvents = (collectionLabels: labelObjectExtraFields[], status: string, item: labelObjectExtraFields, value: string, groupElementId: string) => {
    let itemsToReturn = [...collectionLabels];
    let doesSelectOptionExistCollectionLabels = collectionLabels.some((item) => item.value === value && item.groupId === groupElementId)

    if(!doesSelectOptionExistCollectionLabels){
        let updatedItem = {...item, status: status}
        itemsToReturn = [...collectionLabels, updatedItem]
    }else{
        itemsToReturn.filter((item: any) => item.value === value&& item.groupId === groupElementId).map((item: any) => item.status = status)
    }

    return itemsToReturn
}

export const defineMetadataLabelsToSave = (tag: ITagItem, collectionLabels: labelObjectExtraFields[]) => {
    let tagMetadata = tag.metadata
    let metadataToSave = tagMetadata?.map((item) => ({value: item.value, groupId: item.metadataGroup.id, tagId: tag.id}))
    let haveLabelsChanged = false

    for(let label of collectionLabels){
        let doesLabelExist = tagMetadata?.some((item) => item.value === label.value && item.metadataGroup.id === label.groupId)

        if(!doesLabelExist && label.status === collectionLabelsActions.ADDED){
            metadataToSave?.push({value: label.value, groupId: label.groupId, tagId: tag.id})
            haveLabelsChanged = true
        }else if(doesLabelExist && label.status === collectionLabelsActions.DELETED){
            let itemIndex = metadataToSave?.findIndex((item) => item.value === label.value && item.groupId === label.groupId)
            metadataToSave?.splice(itemIndex, 1)
            haveLabelsChanged = true
        }
    }

    return {metadataToSave, haveLabelsChanged}
}