import { gql } from "@apollo/client";
import {FIXTURE_COLLECTED_VIDEO} from "./fixtureVideo.gql";
import {
    FIXTURE_COLLECTED_UNSUPPORTED,
    FIXTURE_DELIVERED_UNSUPPORTED,
    FIXTURE_INGESTED_UNSUPPORTED
} from "./fixtureUnsupported.gql";

export const FIXTURE_COLLECTED_MEDIA = gql`
    ${FIXTURE_COLLECTED_VIDEO}
    ${FIXTURE_COLLECTED_UNSUPPORTED}
    ${FIXTURE_INGESTED_UNSUPPORTED}
    ${FIXTURE_DELIVERED_UNSUPPORTED}
    fragment FixtureCollectedMedia on CollectedFile {
        fileDetails {
            fileType
        }
        ...FixtureCollectedVideo
        ...FixtureCollectedUnsupported
    }
`;

export const FIXTURE_INGESTED_MEDIA = gql`
    fragment FixtureIngestedMedia on IngestedFile {
        fileDetails {
            fileType
        }
        ...FixtureIngestedUnsupported
    }
`;

export const FIXTURE_DELIVERED_MEDIA = gql`
    fragment FixtureDeliveredMedia on DeliveredFile {
        fileDetails {
            fileType
            s3Link
        }
        epochStartTime
        ...FixtureDeliveredUnsupported
    }
`;
