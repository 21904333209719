import { gql } from "@apollo/client";

const CUSTOMERLOGIN = gql`
  mutation CUSTOMERLOGIN($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_expiry
      access_token
      id_token
      message
      refresh_token
    }
  }
`;

export { CUSTOMERLOGIN };
