import {ApolloError, useMutation} from "@apollo/client";
import {ApiErrors} from "../../../enums/apiErrors";
import {useEffect} from "react";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {UPDATE_TAG_ASSET} from "./updateTagAsset.gql";
import {GET_PLAYLIST} from "../../collections/collections.gql";

export const useUpdateTagAsset = (onError?: (error: ApolloError, errorMessage: string) => void, playlistId?: string, onSuccess?: () => void) => {
    const [updateTagMutation, { error }] = useMutation(UPDATE_TAG_ASSET, {
        refetchQueries: [
            {query: GET_PLAYLIST, variables: {id: playlistId, skip: !playlistId}},
        ],
        onCompleted: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    useEffect(() => {
        handleErrorRaised(error, UPDATE_TAG_ASSET, ApiErrors.CreateMetadata, onError);
    }, [error, UPDATE_TAG_ASSET]);

    return {
        updateTagAsset: (objectToUpdate? ) => updateTagMutation({
            variables: {
                input: objectToUpdate
            },
        })
    };
};
