import {ApolloError, useMutation} from "@apollo/client";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ADD_TAG_COMMENT} from "./comments.gql";
import {ApiErrors} from "../enum/Enum";

export interface UpdateTagComments {
    addTagComment: (tagId: string, contents: string, commentParentId?: string) => void;
    tagCommentsLoading: boolean;
}

export interface UpdateTagCommentsResponse {
    base : { id: string }
}

const useUpdateTagComments = (
    onSuccess?: (response: UpdateTagCommentsResponse) => void,
    onError?: (error: ApolloError, errorMessage: string) => void
): UpdateTagComments => {
    const [updateTagComments, { loading, error}] = useMutation(ADD_TAG_COMMENT, {
        onCompleted: (data: UpdateTagCommentsResponse) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (error: ApolloError) => {
            handleErrorRaised(error, ADD_TAG_COMMENT, ApiErrors.AddTagComment, onError);
        }
    });

    return {
        addTagComment: (tagId: string, contents: string, commentParentId?: string) => updateTagComments({
            variables: {
                input: [{
                    id: tagId,
                    createComments: [{
                        commentJSON: `{"SQComment" : ${JSON.stringify(contents)}, "SQCommentVersion" : 1}`,
                        replyId: commentParentId
                    }]
                }]
            }
        }),
        tagCommentsLoading: loading
    }
};

export default useUpdateTagComments;