import { gql } from "@apollo/client";

export const FIXTURE_COLLECTED_FILE_MENU = gql`
    fragment FixtureCollectedFileMenu on CollectedFile {
        base {
            id
        }

        fileDetails {
            s3Link
            friendlyName
        }
    }
`;

export const FIXTURE_INGESTED_FILE_MENU = gql`
    fragment FixtureIngestedFileMenu on IngestedFile {
        base {
            id
        }

        fileDetails {
            s3Link
            friendlyName
        }
    }
`;

export const FIXTURE_DELIVERED_FILE_MENU = gql`
    fragment FixtureDeliveredFileMenu on DeliveredFile {
        base {
            id
        }

        fileDetails {
            s3Link
            friendlyName
        }
    }
`;
