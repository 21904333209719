import {ApolloError, useQuery} from "@apollo/client";
import {MEMBERSHIPS} from "./memberships.gql";
import {IMembershipsResponse} from "../../__types__/Memberships";
import {ApiPageSize} from "../../../enums/apiMagic";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {useEffect} from "react";
import {ApiErrors} from "../../../enums/apiErrors";

const useMemberships = (customerIDs?: string[], pageSize?: number, onError?: (error: ApolloError, errorMessage: string) => void) => {
    const { loading, error, data, fetchMore } = useQuery<IMembershipsResponse>(MEMBERSHIPS, {
        variables: {
            pageNumber: 0,
            pageSize: pageSize ?? ApiPageSize.Thirty,
            filter: { customerIDs: customerIDs || [] }
        },
    });

    const loadNextPage = () => {
        if (data?.memberships.pageInfo?.pageNumber === undefined) return;
        fetchMore({
            variables: {
                pageNumber: data?.memberships.pageInfo?.pageNumber + 1,
                pageSize: pageSize ?? ApiPageSize.Thirty
            }
        });
    };

    useEffect(() => {
        handleErrorRaised(error, MEMBERSHIPS, ApiErrors.Memberships, onError);
    }, [error, MEMBERSHIPS]);

    return {
        state: {
            membershipsHasNextPage: !!data?.memberships.pageInfo.hasNextPage,
            memberships: data?.memberships?.edges,
            membershipsPageSize: pageSize ?? ApiPageSize.Five,
            membershipsLoading: loading
        },
        operations: { loadNextPage }
    }
};

export default useMemberships;