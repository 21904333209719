import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    noop, Select, SelectOption,
    Tooltip, Typography,
    useAlerts,
} from "@catapultsports/referee-react";
import {IPlaylistResponse} from "../../../api/Hub/collections/__types__/Playlist";
import {IconCameraSmall} from "@catapultsports/referee-react/icons";
import {COLLECTIONS, GENERAL} from "../../../services/i18n/i18n-constants";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import {useTranslation} from "react-i18next";
import "../playlistControls.sass"
import {useMixPanel} from "../../../providers/Mixpanel";
import {HubDialogComponent} from "../../hubDialog/hubDialog";
import * as _ from 'lodash';
import {useUpdateTagAsset} from "../../../api/Hub/tags/updateTagAsset/useUpdateTagAsset";
import "./playlistControlsHeaderChangeDefaultAngle.sass"

interface PlaylistControlsHeaderChangeDefaultAngleProps {
    data: IPlaylistResponse
    selectedTagIds?: string[]
    setSelectedTagIds?: (value: string[]) => void
}

export const collectionModalChangeDefaultAngleId= "change-default-angle-inside-collection-modal"

export const PlaylistControlsHeaderChangeDefaultAngle: React.FC<PlaylistControlsHeaderChangeDefaultAngleProps> = (props) => {
    const {
        data: playlistData,
        selectedTagIds,
        setSelectedTagIds
    } = props;
    const mixpanel = useMixPanel();
    const {showError, showInfo} = useAlerts();
    const {t} = useTranslation();

    const collectionId = useSessionStore(state => state.playlist.id, shallow);
    const [isChangeDefaultAngleModalOpen, setIsChangeDefaultAngleModalOpen] = useState<boolean>(false);
    const [selectedDefaultAngle, setSelectedDefaultAngle] = useState<string | null>(null);

    const areTagsSelected = useMemo(() => {
        return selectedTagIds && selectedTagIds?.length > 0
    } , [selectedTagIds])


    const selectedTags = useMemo(() => {
        return playlistData?.playlist?.tags?.filter(x => selectedTagIds?.some(y => y == x.id))
    }, [playlistData?.playlist?.tags, selectedTagIds])

    let  allAvailableAngles = useMemo(() => {
        if(selectedTags?.length > 0) {
            let assets = selectedTags?.map(tag => {
                return tag?.assets?.map(asset => {
                    return asset.name
                })
            })
            const fn = _.spread(_.union);
            const combinedAssets = fn(assets).map((asset, index) => ({value: asset, isSelected: false, id: `angle-select-option-${asset.toLowerCase().split(" ").join("-")}`}))
            return _.sortBy(combinedAssets, ['value']);
        }
        return [];

    }, [selectedTags])

    const onCameraAngleSelect = (items: SelectOption[]) => {
        setSelectedDefaultAngle(items[0].value)
    }

    const tagsWithoutAngleSelected = useMemo(() => {
        let objectsToReturn : {tagName: string, tagId: string}[] = []

        if(selectedTags?.length > 0 &&  selectedDefaultAngle) {
            selectedTags?.map(tag => {
                let assetFound  = tag?.assets?.some(asset => asset.name === selectedDefaultAngle)
                let object = {tagName: tag.name, tagId: tag.id}
                let doesObjectExist = objectsToReturn?.some(tag => tag.tagId === object.tagId)
                if(!assetFound && !doesObjectExist) {
                    objectsToReturn = [...objectsToReturn, object]
                }else{
                    objectsToReturn = objectsToReturn?.filter(tag => tag.tagId !== object.tagId)
                }
            })
        }
       return objectsToReturn
    }, [selectedDefaultAngle, selectedTags])

    const errorAlert = useCallback(() => {
        showError(t(COLLECTIONS.DEFAULT_VIDEO_ANGLE_UPDATE_ERROR))
    }, [showError, t])

    const onSuccess = useCallback((tagsUpdated: string) => {
        showInfo(t(COLLECTIONS.DEFAULT_VIDEO_ANGLE_UPDATED))
        setTimeout(() => {
            setSelectedTagIds && setSelectedTagIds([])
            setIsChangeDefaultAngleModalOpen(false)
        }, 1000)
        mixpanel.track(`Default Camera Angle Updated`, { tagIds: tagsUpdated, preferredAngle: selectedDefaultAngle });
    }, [selectedDefaultAngle, setSelectedTagIds, showInfo, t])


    const {updateTagAsset} = useUpdateTagAsset(errorAlert, collectionId)

    const handleUpdateConfirmation = useCallback(() => {
        let invalidTags = tagsWithoutAngleSelected?.map(tag => tag.tagId)
        let tagsToUpdate = selectedTags?.filter(tag => !invalidTags?.includes(tag.id))
        let updatePromises = tagsToUpdate?.map(tag => {
            tag.assets?.map((asset) => {
                if(asset.name === selectedDefaultAngle) {
                    return updateTagAsset({
                        id: asset.id,
                        isPreferred: true
                    })
                }else {
                    return updateTagAsset({
                        id: asset.id,
                        isPreferred: false
                    })
                }
            })
        })
        Promise.all(updatePromises).then(() => {
            onSuccess(tagsToUpdate.map(tag => tag.id).join(", "))
        }).catch((error) => {
            throw new Error(error)
        })
    }, [onSuccess, selectedDefaultAngle, selectedTags, tagsWithoutAngleSelected, updateTagAsset])

    useEffect(() => {
        if(isChangeDefaultAngleModalOpen) {
            setSelectedDefaultAngle(null)
        }
    }, [isChangeDefaultAngleModalOpen])

    return (
        <>
            <Tooltip
                text={
                    areTagsSelected ? t(COLLECTIONS.VIDEO_ANGLE_BUTTON_TOOLTIP) :
                        t(COLLECTIONS.SELECT_CLIPS)
                }
            >
            <span tabIndex={0}>
                 <IconCameraSmall
                     className={`icon ${areTagsSelected ? "" : "disabled-icon"}`}
                     onClick={areTagsSelected ? () => setIsChangeDefaultAngleModalOpen(true) : noop}
                     data-testid={"collection-actions-change-angle"}
                 />
            </span>
            </Tooltip>
            <div className={"outer-dialog-component"}>
                {isChangeDefaultAngleModalOpen?
                    <HubDialogComponent
                        dialogTheme={"dark"}
                        dialogSize={'small'}
                        dialogHeader={t(COLLECTIONS.UPDATE_VIDEO_ANGLE_HEADER) as string}
                        isModalOpen={isChangeDefaultAngleModalOpen}
                        setIsModalOpen={setIsChangeDefaultAngleModalOpen}
                        modalContent={
                            <div
                                data-testid={`${collectionModalChangeDefaultAngleId}-content-div`}
                                id={`${collectionModalChangeDefaultAngleId}-content-div`}
                            >
                                <Select
                                    testId={"change-default-angle-select-modal"}
                                    label={t(COLLECTIONS.VIDEO_ANGLES_AVAILABLE) as string}
                                    menuItems={allAvailableAngles}
                                    value={selectedDefaultAngle}
                                    onSelectItem={onCameraAngleSelect}
                                />
                                {tagsWithoutAngleSelected?.length > 0 ? (
                                    <Typography testId={"video-angle-not-available-for-clip"} variant={"body-1"}>
                                        {t(COLLECTIONS.VIDEO_ANGLE_NOT_AVAILABLE_FOR_CLIP, {videoAngle: selectedDefaultAngle, clipsWithoutVideoAngle: tagsWithoutAngleSelected?.map(tag => tag.tagName).join(", ")})}
                                    </Typography>
                                ): null}
                            </div>
                        }
                        displayDialogFooterButtons={true}
                        hideCloseButton={true}
                        footerPrimaryButtonText={t(GENERAL.UPDATE) as string}
                        footerPrimaryButtonAction={handleUpdateConfirmation}
                        footerPrimaryButtonDisabled={selectedDefaultAngle === null}
                        dialogId={collectionModalChangeDefaultAngleId}
                        allowDialogClosure={false}
                    />
                    : null
                }
            </div>
        </>

    )
}