import {ApolloError, useQuery} from "@apollo/client";
import {useEffect} from "react";
import {client} from "../matchTrackerClient";
import {Competitions} from "./__types__/Competitions";
import {COMPETITIONS} from "./competitions.gql";
import {IBaseIdAndNamePair} from "../__types__/Common";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseCompetitions {
    state: {
        competitionsLoading: boolean;
        competitionsPageSize: number;
        competitionsError: ApolloError | undefined;
        competitions: IBaseIdAndNamePair[] | null | undefined;
    };
}

const useCompetitions = ( pageSize: number = -1, pageNumber: number = 0, onError?: (error: ApolloError, errorMessage: string) => void): {
    state: {
        competitions: IBaseIdAndNamePair[] | undefined;
        competitionsLoading: boolean;
        competitionsError: undefined | ApolloError;
        competitionsPageSize: number
    }
} => {
    const { loading, error, data } = useQuery<Competitions>(COMPETITIONS, {
        variables: {
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    });

    useEffect(() => {
        handleErrorRaised(error, COMPETITIONS, ApiErrors.Competitions, onError);
    }, [error, COMPETITIONS]);

    return {
        state: {
            competitions: data?.competitions?.edges,
            competitionsPageSize: pageSize,
            competitionsLoading: loading,
            competitionsError: error,
        }
    };
};

export default useCompetitions;
