import { ApolloError, useQuery } from "@apollo/client";
import { FIXTURE_CONTENT } from "./fixtureContent.gql";
import {client} from "../matchTrackerClient";
import {ICollectedFiles, IDeliveredFiles, IFixtureContent, IIngestedFiles} from "./__types__/FixtureContent";

type SelectedFile =
    | ICollectedFiles
    | IIngestedFiles
    | IDeliveredFiles
    | undefined;

export interface UseFixtureContent {
    data: IFixtureContent | null | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

const useFixtureContent = (fixtureId: string | undefined): UseFixtureContent => {
    const { loading, error, data } = useQuery<IFixtureContent>(FIXTURE_CONTENT, { variables: { fixtureId }, client });

    return {
        data,
        loading,
        error
    };
};

export default useFixtureContent;
