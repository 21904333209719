import { StoreSlice } from '../StoreSlice';
import {ITagItem} from "../../../api/Hub/tags/__types__/Tags";
import {EventResult, GameResult} from "../../../__types__/globalTypes";
import {SortBy} from "../../../api/Hub/enum/Enum";

export interface HubSliceProps {
	playlist: {
		id: string
		tags: ITagItem[]
		currentStream: string | undefined
		autoPlay: boolean
		currentStreamIndex: number,
		currentTagId: string | undefined
	}
	collection: {
		id: number | undefined,
		name: string | undefined
	},
	comments: {
		tagId: string | undefined,
		tagName: string | undefined
	},
	games: {
		checkedIds: string[],
		selectedResult: GameResult | undefined,
		results: GameResult[]
	},
	events: {
		checkedIds: string[],
		selectedResult: EventResult | undefined,
		results: EventResult[]
	},
	resultItems: {
		matchtrackerIds: string[]
	},
	collections: {
		sortBy: SortBy,
		metadataIds: string[]
	},
	freeTextSearch: string | undefined,
	selectedHubVideoAngle: string | undefined,
	setPlaylistTags: (tags: ITagItem[]) => void,
	setPlaylistId: (id: string) => void,
	setCurrentStream: (stream: string | undefined) => void,
	setAutoPlayStream: (autoPlay: boolean) => void,
	setStreamIndex: (index: number) => void,
	clearCollection: () => void
	setCommentTag: (id: string, name: string) => void,
	clearCommentTag: () => void
	setCheckedGameIds: (ids: string[]) => void,
	setSelectedGameResult: (result: GameResult | undefined) => void,
	setGameResults: (results: GameResult[]) => void,
	setCheckedEventIds: (ids: string[]) => void,
	setSelectedEventResult: (result: EventResult | undefined) => void,
	setEventResults: (results: EventResult[]) => void,
	setCollectionsSortBy: (sortBy: SortBy) => void,
	setCollectionsMetadataIds: (metadataIds: string[]) => void,
	setMatchtrackerIds: (matchtrackerIds: string[]) => void,
	setFreeTextSearch: (value: string | undefined) => void,
	setCurrentPlaylistTagId: (value: string | undefined) => void,
	setSelectedHubVideoAngle: (value: string | undefined) => void
	numberOfRecords: number | undefined
	setNumberOfRecords: (numberOfRecords: number | undefined) => void
}

export const createHubSlice: StoreSlice<HubSliceProps> = (set, get) => ({
	playlist: {
		id: '',
		tags: [],
		currentStream: undefined,
		autoPlay: false,
		currentStreamIndex: -1,
		currentTagId: undefined
	},
	collection: {
		id: -1,
		name:''
	},
	comments: {
		tagId: undefined,
		tagName: undefined
	},
	games: {
		checkedIds: [],
		selectedResult: undefined,
		results: []
	},
	events: {
		checkedIds: [],
		selectedResult: undefined,
		results: []
	},
	resultItems: {
		matchtrackerIds: []
	},
	collections: {
		sortBy: SortBy.CreationTimestamp,
		metadataIds: []
	},
	freeTextSearch: undefined,
	selectedHubVideoAngle: undefined,
	numberOfRecords: undefined,
	setPlaylistTags: (tags: ITagItem[]) => set((state: HubSliceProps) => {
		state.playlist.tags = tags;
		return state;
	}),
	setCurrentStream: (stream: string | undefined) => set((state: HubSliceProps) => {
		state.playlist.currentStream = stream;
		return state;
	}),
	setStreamIndex: (index: number) => set((state: HubSliceProps) => {
		state.playlist.currentStreamIndex = index;
		return state;
	}),
	setAutoPlayStream: (autoPlay: boolean) => set((state: HubSliceProps) => {
		state.playlist.autoPlay = autoPlay;
		return state;
	}),
	setPlaylistId: (id: string) => set((state: HubSliceProps) => {
		state.playlist.id = id;
		return state;
	}),
	clearCollection: () => set((state: HubSliceProps) => {
		state.collection.id = undefined;
		state.collection.name = undefined;
		return state;
	}),
	setCommentTag: (id: string, name: string) => set((state: HubSliceProps) => {
		state.comments.tagId = id;
		state.comments.tagName = name;
		return state;
	}),
	clearCommentTag: () => set((state: HubSliceProps) => {
		state.comments.tagId = undefined;
		state.comments.tagName = undefined;
		return state;
	}),
	setCheckedGameIds: (ids: string[]) => set((state: HubSliceProps) => {
		state.games.checkedIds = ids;
		return state;
	}),
	setSelectedGameResult: (result: GameResult | undefined) => set((state: HubSliceProps) => {
		state.games.selectedResult = result;
		return state;
	}),
	setGameResults: (results: GameResult[]) => set((state: HubSliceProps) => {
		state.games.results = results;
		return state;
	}),
	setCheckedEventIds: (ids: string[]) => set((state: HubSliceProps) => {
		state.events.checkedIds = ids;
		return state;
	}),
	setSelectedEventResult: (result: EventResult | undefined) => set((state: HubSliceProps) => {
		state.events.selectedResult = result;
		return state;
	}),
	setEventResults: (results: EventResult[]) => set((state: HubSliceProps) => {
		state.events.results = results;
		return state;
	}),
	setCollectionsSortBy: (sortBy: SortBy) => set((state: HubSliceProps) => {
		state.collections.sortBy = sortBy;
		return state;
	}),
	setCollectionsMetadataIds: (metadataIds: string[]) => set((state: HubSliceProps) => {
		state.collections.metadataIds = metadataIds;
		return state;
	}),
	setMatchtrackerIds: (matchtrackerIds: string[]) => set((state: HubSliceProps) => {
		state.resultItems.matchtrackerIds = matchtrackerIds;
		return state;
	}),
	setFreeTextSearch: (value: string | undefined) => set((state: HubSliceProps) => {
		state.freeTextSearch = value;
		return state;
	}),
	setCurrentPlaylistTagId: (value: string | undefined) => set((state: HubSliceProps) => {
		state.playlist.currentTagId = value;
		return state;
	}),
	setSelectedHubVideoAngle: (value: string | undefined) => set((state: HubSliceProps) => {
		state.selectedHubVideoAngle = value;
		return state;
	}),
	setNumberOfRecords: (numberOfRecords: number | undefined) => set((state: HubSliceProps) => {
		state.numberOfRecords = numberOfRecords;
		return state;
	})
});
