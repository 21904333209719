import React, {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {noop, Spinner, Tooltip, Typography, useAlerts} from "@catapultsports/referee-react";
import './comment.sass';
import useComment from "../../api/Hub/comments/useComment";
import {useTranslation} from "react-i18next";
import {GENERAL, HUB} from "../../services/i18n/i18n-constants";
import TimeAgo from 'react-timeago';
import {CleanEmail} from "../../utils/stringUtils";
import {DateTime} from "luxon";
import {usePersistedStore} from "../../state/PersistedState";
import {shallow} from "zustand/shallow";
import {CommentsReplyEvent} from "./__types__/coments";
import {COMMENT_REPLY_DEPTH_MAX} from "../../api/enums/clientMagic";
import {DateWithTimeFormat} from "../../utils/constants";
import {scrollToReference} from "../../utils/scrollingUtils";

export interface CommentProps {
    commentId: string;
    onLoaded?: (repliesScrollerRef: RefObject<HTMLElement>) => void;
    depth: number;
    onReplyClicked?: (comment: CommentsReplyEvent) => void;
}

export const Comment: React.FC<CommentProps> = (props) => {
    const { commentId, onLoaded, depth, onReplyClicked } = props;
    const { showError } = useAlerts();
    const {t} = useTranslation();
    const { comment, loading } = useComment(commentId, () => showError(t(HUB.COMMENTS_ERROR)));
    let repliesLoaded = 0;
    const userEmail = usePersistedStore(state => state.user.email, shallow);
    const scrollerRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (!loading && comment && (!comment.replies || comment.replies.length === 0) && onLoaded) {
            onLoaded(scrollerRef);
        }
    }, [loading, comment, onLoaded, scrollerRef])

    const handleOnLoaded = useCallback((callerRef: RefObject<HTMLElement>) => {
        repliesLoaded+=1;
        if (repliesLoaded === comment?.replies.length && onLoaded) {
            onLoaded(scrollerRef);
        }
    }, [onLoaded, comment?.replies.length]);
    const handleReplyClicked = useCallback((comment: CommentsReplyEvent) => {
        if (onReplyClicked) {
            comment.depth++;
            onReplyClicked(comment);
        }
    }, [onReplyClicked]);

    return (
        <section className={'flex vertical comment'} ref={scrollerRef}>
            {
                loading ?
                    <Spinner /> :
                    comment &&
                        <section className={'comment-chain'}>
                            <section className={'flex vertical comment-wrapper'}>
                                <section className={`comment-contents ${comment.email === userEmail ? 'current-user-comment' : 'other-user-comment'}`} data-testid={`comment-${comment?.id}`}>
                                    <div className={'user-name'}><Typography variant={'label-1'}>{CleanEmail(comment?.email)}</Typography></div>
                                    <Typography>{comment?.comment.message}</Typography>
                                </section>
                                <section className={'flex horizontal comment-footer'}>
                                    {
                                        depth > COMMENT_REPLY_DEPTH_MAX ?
                                            <Typography>
                                            </Typography> :
                                            <Typography
                                                onClick={() => onReplyClicked ? onReplyClicked({...comment, depth: 0}) : noop()}
                                                className={'reply-text'}
                                                variant={'label-1'}>
                                                {t(GENERAL.REPLY)}
                                            </Typography>
                                    }
                                    <Tooltip text={DateTime.fromMillis(comment.commentTimestamp).toFormat(DateWithTimeFormat)} >
                                        <Typography variant={'caption-1'}>
                                            <TimeAgo date={comment.commentTimestamp} title={''} />
                                        </Typography>
                                    </Tooltip>
                                </section>
                            </section>
                            {
                                (comment.replies && comment.replies.length > 1 ?
                                        [...comment.replies].sort((a, b) => a.commentTimestamp - b.commentTimestamp):
                                        comment.replies)
                                    .map((reply) =>
                                        <section className={'flex horizontal'} >
                                            <div className={'comment-reply-spacer'}/>
                                            <Comment
                                                depth={depth + 1}
                                                data-testid={`comment-${comment.id}-reply-${reply.id}`}
                                                key={reply.id}
                                                commentId={reply.id}
                                                onLoaded={handleOnLoaded}
                                                onReplyClicked={handleReplyClicked}/>
                                        </section>)
                            }
                        </section>
            }
        </section>
    );
};
