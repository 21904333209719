import {gql} from "@apollo/client";

export const UPDATE_TAG_NOTES = gql`
    mutation updateTagsAddNotes($input: [UpdateTagInput!]!) {
        updateTags(input: $input) {
            tags {
                id
                notes {
                    id
                    heading
                    value
                }
            }
        }
    }
`;

export const UPDATE_TAGS_METADATA_GROUPS = gql`
    mutation updateTagsMetadataGroups($input: [UpdateTagInput!]!) {
        updateTags(input: $input) {
            tags {
                id
                metadata {
                    id
                    value
                }
                metadataGroups {
                    id
                    name
                }
            }
        }
    }
`;
