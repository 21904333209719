import {gql} from "@apollo/client";

export const UPDATE_TAG_ASSET = gql`
    mutation updateTagAssets($input: [UpdateAssetInput!]!) {
    updateAssets(input: $input) {
            assets {
                id
                startTime
                endTime
                isPreferred
            }
        }
    }
`