import {ApolloError, useQuery} from "@apollo/client";
import {useEffect} from "react";
import {IMetaDataGroupEdges, IMetadataGroupsFilters} from "./__types__/MetadataGroup";
import {METADATA_GROUP_VALUES} from "./metadataGroups.gql";
import {IPagedVariables} from "../common/Common";
import {handleErrorRaised} from "../../../utils/apolloUtils";
import {ApiErrors} from "../../enums/apiErrors";

export interface UseMetadataGroupValues {
    loading: boolean;
    metadataGroups: IMetaDataGroupEdges[] | null | undefined;
}

const useMetadataGroupValues = (groupNames: string[], onError?: (error: ApolloError, errorMessage: string) => void): UseMetadataGroupValues => {
    const {loading, error, data} = useQuery<{ metadataGroups: { edges: IMetaDataGroupEdges[] } }, IPagedVariables<IMetadataGroupsFilters>>(METADATA_GROUP_VALUES, {
        variables: {
            filter: { name: groupNames.map(groupName => {
                    return {
                        value: groupName,
                        conjunction: "or",
                        operator: "equals"
                    }
                })},
            pageSize: -1
        }
    });

    useEffect(() => {
        handleErrorRaised(error, METADATA_GROUP_VALUES, ApiErrors.MetadataGroups, onError);
    }, [error, METADATA_GROUP_VALUES, onError]);

    return {
        metadataGroups: data?.metadataGroups.edges,
        loading: loading
    };
};

export default useMetadataGroupValues;
